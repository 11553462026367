import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BaseApi from "../../../../Http/BaseApi";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";

const MPhilAwardee = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [mPhilAwardees, setMPhilAwardees] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    scholar_name: "",
    dissertation_title: "",
    month: "",
    year: "",
    awards: [
      {
        date: "",
        title: "",
        details: "",
      },
    ],
    iqac: "",
  });

  const headers = {
    scholar_name: {
      label: "Name of the scholar",
      path: "scholar_name",
    },
    dissertation_title: {
      label: "Title of the Dissertation",
      path: "dissertation_title",
    },
    month: { label: "Awarded Month", path: "monthName" },
    year: { label: "Awarded Year", path: "year" },

    date: {
      label: "Date of Award",
      path: "m_phil_achievements.date",
      isArray: true,
    },
    title: {
      label: "Title of award",
      path: "m_phil_achievements.title",
      isArray: true,
    },
    details: {
      label: "Details of Award",
      path: "m_phil_achievements.details",
      isArray: true,
    },
  };

  const validationSchema = Yup.object({
    scholar_name: Yup.string().required("This field is required!"),
    dissertation_title: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/m-phil-awardees");
      setMPhilAwardees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/m-phil-awardees/${editId}`, values);
        toast.success("M. Phil. Awardee Updated Successfully");
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/m-phil-awardees", values);
        toast.success("M. Phil. Awardee Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = mPhilAwardees.find(
        (mPhilAwardee) => mPhilAwardee.id === id
      );
      // Map achievements to awards, or provide at least one blank row if there are no achievements
      const awards =
        editableData.m_phil_achievements?.length > 0
          ? editableData.m_phil_achievements.map((mPhilAchievement) => ({
              date: mPhilAchievement.date,
              title: mPhilAchievement.title,
              details: mPhilAchievement.details,
            }))
          : [{ date: "", title: "", details: "" }];

      setInitialValues({
        id: editableData.id,
        scholar_name: editableData.scholar_name,
        dissertation_title: editableData.dissertation_title,
        month: editableData.month,
        year: editableData.year,

        awards: awards,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      scholar_name: "",
      dissertation_title: "",
      month: "",
      year: "",
      awards: [
        {
          date: "",
          title: "",
          details: "",
        },
      ],
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      scholar_name: "",
      dissertation_title: "",
      month: "",
      year: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/m-phil-awardees/${id}`);
      toast.success("M. Phil. Awardee Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>M. Phil. Awardees :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <>
              <FormPage
                partialEdit={
                  initialValues.iqac == "yes" &&
                  initialValues.status == "Ongoing"
                }
                initialValues={initialValues} // Pass initial values for editing
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isEditMode={isEditing}
              />
            </>
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            editable={true}
            editCheckField='status'
            headers={headers}
            data={mPhilAwardees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default MPhilAwardee;
