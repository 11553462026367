import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import BaseApi from "../../Http/BaseApi";
import {
  FaSpinner,
  FaEdit,
  FaTrash,
  FaPlus,
  FaArrowLeft,
} from "react-icons/fa";
import moment from "moment";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import CustomBlockNoteViewer from "../../Components/Utils/CustomBlockNoteViewer";
import Navigations from "./Navigations";

export default function ShowPost() {
  const { id } = useParams({});
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchPost() {
      try {
        const response = await BaseApi().get(`/website/posts/${id}`);
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    }

    fetchPost();
  }, [id]);
  return (
    <div className='container min-h-screen px-5 mx-auto mt-5 rounded-md shadow-md'>
      <div className='flex justify-between'>
        <Navigations></Navigations>
        <div className='flex gap-2'>
          <Link
            to='/website/posts'
            className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
          >
            <FaArrowLeft className='inline w-4 mr-2' /> Back
          </Link>
          <Link
            to={`/website/posts/create?edit_id=${id}`}
            className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
          >
            <FaEdit className='inline w-4 mr-2' /> Edit
          </Link>
        </div>
      </div>

      {post.featured_image?.length > 0 && (
        <img
          src={post.featured_image}
          alt={post.title}
          className='object-cover w-full mt-2 rounded h-80'
        />
      )}

      <PageTitle name={post.title} />

      {/* Posts Table */}
      <div className='bg-white '>
        <p>
          {post.status === "published"
            ? `Published On: ${moment(post.published_at).format(
                "DD MMM YYYY hh:mm A"
              )}`
            : "Status: Draft"}
        </p>

        <div className='my-8 overflow-hidden text-lg text-gray-900'>
          {post.content && (
            <CustomBlockNoteViewer initialValue={post.content} />
          )}
        </div>
      </div>
    </div>
  );
}
