import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";
import CustomSelect from "../../../../Components/Utils/CustomSelect";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);

  const roleTypes = [
    { id: 1, name: "Internal Guide" },
    { id: 2, name: "External Guide" },
    { id: 3, name: "Co-guide" },
  ];
  const studentsOrganizationTypes = [
    { id: 1, name: "Dibrugarh University" },
    { id: 2, name: "Other" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Students&apos; Project Details
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <Input
              type='text'
              name='title'
              label={
                <div className='flex items-center'>
                  Title of the Project
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.role}
              options={roleTypes}
              name='role'
              label={
                <div className='flex items-center'>
                  Your Role
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("role", selectedOption.name);
              }}
            />
            <Input
              type='text'
              label={
                <div className='flex items-center'>
                  Name(s) of the Student(s)
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='student_name'
            />
            <Input
              type='text'
              label={
                <div className='flex items-center'>
                  Semester/Year
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='year'
            />
            <Input
              type='text'
              name='department_centre'
              label={
                <div className='flex items-center'>
                  Student&apos;s Department/Centre
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.students_organization}
              options={studentsOrganizationTypes}
              name='students_organization'
              label={
                <div className='flex items-center'>
                  Student&apos;s Institution
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "students_organization",
                  selectedOption.name
                );
              }}
            />

            {values.students_organization === "Other" && (
              <Input
                type='text'
                name='other'
                label={
                  <div className='flex items-center'>
                    Other
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
            )}

            <Input
              type='date'
              name='start_date'
              label={
                <div className='flex items-center'>
                  Project Start Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='date'
              name='end_date'
              label={
                <div className='flex items-center'>
                  Project End Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <div className='flex justify-between col-span-2 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
