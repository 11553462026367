import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  institute: {
    label: "Ph. D. Scholar registered in DU/Other Institute",
    path: "institute",
  },
  other_institute: { label: "Other Institute", path: "other_institute" },
  status_designation: {
    label: "Present Status/Designation with name of the Organization",
    path: "status_designation",
  },
  status: { label: "Status", path: "status" },
  month: {
    label: "Awarded Month",
    path: "monthName",
  },
  year: {
    label: "Awarded Year",
    path: "year",
  },
  shodhganga_link: {
    label: "Shodhganga link of the thesis",
    path: "shodhganga_link",
  },
};

const PhdAwardee = () => {
  const [phdAwardees, setPhdAwardees] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/phd-awardees");
      setPhdAwardees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Ph. D. Awardee
      </h2>
      <TableViewProfile
        headers={headers}
        data={phdAwardees}
        type="phd-awardees"
        setData={setPhdAwardees}
      />
    </div>
  );
};

export default PhdAwardee;
