import React, { useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import IqacBasicInfo from "./Components/IqacBasicInfo";
import IqacExpertise from "./Components/IqacExpertise";
import IqacEduQualification from "./Components/IqacEduQualification";
import IqacExperience from "./Components/IqacExperience";
import IqacJournalPublication from "./Components/JournalPublication";
import IqacConferenceSeminar from "./Components/IqacConferenceSeminar";
import IqacBooksAndChapter from "./Components/IqacBooksAndChapter/IqacBooksAndChapter";
import IqacPatent from "./Components/Patent";
import IqacConsultancyExternalService from "./Components/IqacConsultancyExternalService";
import IqacProject from "./Components/Project";
import IqacHonorsAward from "./Components/IqacHonorsAward";
import IqacLecturesTalk from "./Components/LecturesTalk";
import IqacAcademicVisits from "./Components/IqacAcademicVisits";
import IqacPhdGuidance from "./Components/PhdGuidance";
import IqacCorporateLife from "./Components/IqacCorporateLife";
import IqacMentorMentee from "./Components/MentorMentee";
import IqacCollaboration from "./Components/IqacCollaboration";
import IqacBodiesOrganization from "./Components/IqacBodiesOrganization";
import IqacCommittee from "./Components/IqacCommittee";
import IqacFIPActivitiesParticipation from "./Components/IqacFIPActivitiesParticipation";
import IqacStartupDevelopment from "./Components/StartupDevelopment";
import IqacWorkshopTraining from "./Components/IqacWorkshopTraining";

const FacultyProfileIqac = () => {
  const [activeTab, setActiveTab] = useState(0); // Set initial activeTab to 0
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 250; // Adjust this value as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 250; // Adjust this value as needed
    }
  };

  const tabs = [
    {
      id: 0,
      label: "Basic Information",
      content: <IqacBasicInfo />,
    },
    // {
    //   id: 1,
    //   label: "Expertise",
    //   content: <IqacExpertise />,
    // },
    {
      id: 2,
      label: "Educational Qualifications",
      content: <IqacEduQualification />,
    },
    {
      id: 3,
      label: "Experience",
      content: <IqacExperience />,
    },
    {
      id: 4,
      label: "Research Publications",
      content: <IqacJournalPublication />,
    },
    {
      id: 5,
      label: "Conference/Seminar Paper",
      content: <IqacConferenceSeminar />,
    },
    {
      id: 6,
      label: "Books & Chapters",
      content: <IqacBooksAndChapter />,
    },
    {
      id: 7,
      label: "Patents",
      content: <IqacPatent />,
    },
    {
      id: 8,
      label: "Consultancy/External Services",
      content: <IqacConsultancyExternalService />,
    },
    {
      id: 9,
      label: "Projects",
      content: <IqacProject />,
    },
    {
      id: 10,
      label: "Honors and Awards",
      content: <IqacHonorsAward />,
    },
    {
      id: 11,
      label: "Invited Lecture/Talk",
      content: <IqacLecturesTalk />,
    },
    {
      id: 12,
      label: "Academic Visits",
      content: <IqacAcademicVisits />,
    },
    {
      id: 13,
      label: "Ph. D. Guidance",
      content: <IqacPhdGuidance />,
    },
    {
      id: 14,
      label: "Contribution to Corporate Life",
      content: <IqacCorporateLife />,
    },
    //{
    //   id: 15,
    //   label: "Mentor-Mentee",
    //   content: <IqacMentorMentee />,
    // },
    {
      id: 16,
      label: "Collaborations",
      content: <IqacCollaboration />,
    },
    {
      id: 17,
      label: "Memberships in Professional Bodies/Organization",
      content: <IqacBodiesOrganization />,
    },
    {
      id: 18,
      label: "Memberships in Significant Committees:",
      content: <IqacCommittee />,
    },
    {
      id: 19,
      label: "FIP activities/Participation in FDP",
      content: <IqacFIPActivitiesParticipation />,
    },
    {
      id: 20,
      label: "Startup",
      content: <IqacStartupDevelopment />,
    },
    {
      id: 21,
      label: "Participation in Programme",
      content: <IqacWorkshopTraining />,
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className='relative max-w-full mx-5'>
      <div className='flex items-center'>
        <button className='p-2' onClick={scrollLeft}>
          <FaAngleLeft />
        </button>
        <div
          ref={scrollContainerRef}
          className='flex w-full overflow-x-auto border-b border-gray-200 scroll-smooth'
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`whitespace-nowrap focus:outline-none py-2 px-4 mx-2 my-10 flex items-center ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => handleTabClick(tab.id)}
            >
              <span className='mr-2'>{tab.label}</span>
            </button>
          ))}
        </div>
        <button className='p-2' onClick={scrollRight}>
          <FaAngleRight />
        </button>
      </div>

      <div className='mt-4'>
        {tabs.map((tab) => (
          <div key={tab.id}>
            {activeTab === tab.id && (
              <>
                <p>{tab.content}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacultyProfileIqac;
