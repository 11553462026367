import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

export default function Select(props) {
  const [isEditable, setIsEditable] = useState(!props.secureEdit); // State to toggle edit mode

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const classNames = isEditable
    ? "focus:ring-indigo-500 focus:border-indigo-500"
    : "bg-gray-100 cursor-not-allowed";

  return (
    <>
      <div className='relative group'>
        <label
          htmlFor={props.id}
          className='block text-sm font-medium text-gray-700'
        >
          {props.label}
        </label>

        <Field
          readOnly={props.readOnly ? props.readOnly : !isEditable}
          as='select'
          id={props.id}
          name={props.name}
          autoComplete=''
          disabled={props.disabled ? props.disabled : !isEditable} // Use `disabled` instead of `readOnly`
          className={`rounded-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
        >
          {props.children}
        </Field>
        {/* Pencil Icon */}
        {props.secureEdit && !isEditable && (
          <FaPencilAlt
            className='absolute text-gray-400 cursor-pointer top-9 right-3 group-hover:text-indigo-600'
            onClick={handleEditClick}
            title='Edit'
          />
        )}
        <ErrorMessage
          name={props.name}
          component='div'
          className='py-2 text-red-500'
        />
      </div>
    </>
  );
}
