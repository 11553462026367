import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import Select from "../../Components/Utils/Select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../Components/Utils/CustomSelect";

export default function AdmittedStudentReport() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState();

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];

  // let formSections = useSelector((state) => state.applicants.formSections);

  const initialValues = {
    academic_session_id: null,
  };
  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await genReport(searchQuery);
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    getReports();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getReports() {
    setReports([]);
    setLoading(true);

    const response = await BaseApi().get(`reports/admitted-students`);
    setReports(response.data);
    setLoading(false);
  }
  async function genReport(searchQuery) {
    const response = await BaseApi().post(
      `reports/admitted-students`,
      searchQuery
    );
    getReports();
    setMessage(response.data);
  }

  async function handleMoveToTrash(filename) {
    const confirm = window.confirm(
      "Are you sure you want delete?\n " + filename
    );
    if (!confirm) return;
    setLoading(true);
    try {
      await BaseApi().patch(`reports/admitted-students/trash/${filename}`);
      setReports(reports.filter((report) => report.name !== filename));
      setMessage("File moved to trash successfully");
    } catch (error) {
      setMessage("Error moving file to trash");
    }
    setLoading(false);
  }

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='Student Report' />
            <div className=''>{message && <Alert message={message} />}</div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-2 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6'>
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='mt-6 text-left '>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      <span>Generate</span>
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>File Name</th>
                  <th className='table-head'>Generated At</th>
                  <th className='table-head'>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports?.length > 0 &&
                  reports.map((report, index) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++index}</td>
                          <td className='table-data'>{report.name}</td>
                          <td className='table-data'>{report.time}</td>
                          <td className='table-data'>
                            <a className='text-blue-600 ' href={report.link}>
                              Download
                            </a>

                            <button
                              className='ml-4 text-red-600'
                              onClick={() => handleMoveToTrash(report.name)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {reports?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
