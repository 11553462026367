/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import MediaManagement from "./MediaManagement"; // Import the MediaManagement component
import {
  FaArrowLeft,
  FaEdit,
  FaImage,
  FaTimes,
  FaTrash,
  FaUps,
} from "react-icons/fa";
import BaseApi from "../../Http/BaseApi";
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import PageTitle from "../../Components/Common/PageTitle";
import { useSearchParams, useNavigate, Link } from "react-router-dom"; // Import routing utilities
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";
import { Menu } from "@headlessui/react";
import moment from "moment";
import { toast } from "react-toastify";
import { FaDownLong, FaSortUp, FaUpLong } from "react-icons/fa6";

// Form validation schema using Yup
const validationSchema = Yup.object().shape({
  file_url: Yup.string().required("File is required"),
  location: Yup.string().required("Location is required"),
});

export default function Sliders() {
  const [image, setImage] = useState(null);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Manage modal open state
  const [loading, setLoading] = useState(true); // Loading state for fetching post data
  const formikRef = useRef();
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();
  const [sliders, setSliders] = useState([]);
  const [filteredSliders, setFilteredSliders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState("");

  const fetchSliders = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
      const response = await BaseApi().get(`/website/sliders?page=${page}`);
      setSliders(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      LaravelValidationError(error);
      console.error("Error fetching sliders", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSliders();
  }, []);

  useEffect(() => {
    setFilteredSliders(
      sliders.filter((slider) =>
        selectedLocation ? slider.location === selectedLocation : true
      )
    );
  }, [selectedLocation, sliders]);

  const locations = [
    { value: "slider", label: "Main Slider" },
    { value: "carousel", label: "Bottom Carousel" },
  ];
  const initialValues = {
    file_url: null,
    caption: "",
    display_order: "",
    location: "",
    status: "draft",
  };

  // Handle file URL selection from MediaManagement modal
  const handleFileSelect = (url) => {
    setImage(url);
    setIsMediaModalOpen(false); // Close modal after selection
    formikRef.current.setFieldValue("file_url", url);
  };

  // Fetch post data for editing
  useEffect(() => {
    const fetchSliderData = async () => {
      if (editId) {
        try {
          const response = await BaseApi().get(`/website/sliders/${editId}`);
          const sliderData = response.data;
          // Pre-fill form fields with the fetched data
          formikRef.current.setValues({
            file_url: sliderData.file_url,
            caption: sliderData.caption,
            display_order: sliderData.display_order,
            location: sliderData.location,
            status: sliderData.status,
          });
          setImage(sliderData.file_url);
        } catch (error) {
          console.error("Error fetching slider data", error);
        }
      }
      setLoading(false);
    };

    fetchSliderData();
  }, [editId]);

  const handleSubmit = async (values) => {
    // console.log("asd");

    try {
      setLoading(true);

      if (editId) {
        // If `edit_id` is present, update the
        const response = await BaseApi().put(
          `/website/sliders/${editId}`,
          values
        );
        const newSlider = response.data;
        const updatedSliders = sliders.map((slider) =>
          slider.id === editId ? newSlider : slider
        );

        setSliders(updatedSliders);
        setEditId(null);
        toast.success("Slider updated successfully");
      } else {
        // Create a new
        const response = await BaseApi().post(`/website/sliders`, values);
        toast.success("Slider added successfully");
        fetchSliders(); // Refresh the slider list
      }
      formikRef.current.resetForm();
      setImage(null);
    } catch (error) {
      LaravelValidationError(error); // Call ValidationError to show error toasts
      console.error("Error submitting slider", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (dataId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      await BaseApi().delete("/website/sliders/" + dataId);

      fetchSliders(); // Refresh the slider list
    } catch (error) {
      LaravelValidationError(error);
    }
  };

  const updateOrder = async (sliderId, newOrder) => {
    try {
      await BaseApi().post("/website/sliders/order", {
        slider_id: sliderId,
        new_order: newOrder,
      });
      fetchSliders(); // Refresh the slider list
      toast.success("Display order updated successfully");
    } catch (error) {
      LaravelValidationError(error);
      console.error("Error updating display order", error);
    }
  };

  return (
    <div className='container px-5 pb-10 mx-auto mt-5 rounded-md shadow-md'>
      <div className='flex justify-between gap-3'>
        <Navigations></Navigations>
      </div>
      <div className='flex gap-5'>
        <PageTitle name={editId ? "Edit Slider" : "Add New Slider"} />
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values, setFieldValue }) =>
          loading ? (
            <div className='flex items-center justify-center h-full'>
              <div>Loading...</div>
            </div>
          ) : (
            <Form>
              <div className='grid items-end grid-cols-1 gap-6 lg:grid-cols-12'>
                {/* Main Content Area */}
                <div className='grid items-start grid-cols-3 gap-3 lg:col-span-9'>
                  {/* Caption */}
                  <div className='mb-4 '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Caption (Optional)
                    </label>
                    <Field
                      name='caption'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter Caption...'
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    {touched.caption && errors.caption && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.caption}
                      </div>
                    )}
                  </div>
                  <div className='mb-4 '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Image
                    </label>
                    <button
                      type='button'
                      className='w-full px-4 py-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                      onClick={() => setIsMediaModalOpen(true)}
                    >
                      <FaImage className='inline' /> Select Image
                    </button>
                    <Field
                      className='w-full p-2 mt-1 border rounded-md'
                      name='file_url'
                      placeholder='Or paste image URL here'
                      onChange={(e) => {
                        handleChange(e);
                        setImage(e.target.value);
                      }}
                    />
                    {image && (
                      <div className='relative mt-4'>
                        <img
                          src={image}
                          alt='Featured'
                          className='object-contain w-full h-20 border rounded-md'
                        />
                      </div>
                    )}
                  </div>
                  <div className='mb-4 '>
                    {/* location dropdown */}
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Location
                    </label>
                    <Field
                      as='select'
                      name='location'
                      className='w-full p-2 border rounded-md'
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value=''>Select Location</option>
                      {locations.map((location) => (
                        <option key={location.value} value={location.value}>
                          {location.label}
                        </option>
                      ))}
                    </Field>
                    {touched.location && errors.location && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.location}
                      </div>
                    )}
                  </div>
                </div>

                {/* Sidebar Area */}
                <div className='flex flex-col justify-start space-y-6 lg:col-span-3'>
                  {/* Post Status */}
                  <div className='py-4 bg-white rounded-md'>
                    <div className='flex justify-end gap-4'>
                      {/* Draft Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600'
                        onClick={() => {
                          setFieldValue("status", "draft"); // Set status to draft
                          handleSubmit({
                            ...values,
                            status: "draft",
                          }); // Trigger form submission
                        }}
                      >
                        Save as Draft
                      </button>

                      {/* Publish Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600'
                        onClick={() => {
                          setFieldValue("status", "published"); // Set status to published

                          handleSubmit({ ...values, status: "published" }); // Trigger form submission
                        }}
                      >
                        {editId ? "Publish" : "Publish"}
                      </button>
                    </div>

                    {/* Published Date Section */}
                    {editId && (
                      <div className='flex justify-end mt-3'>
                        <button
                          type='button'
                          onClick={() => {
                            setEditId(null);
                            formikRef.current.resetForm();
                            setImage(null);
                          }}
                          className='px-2 py-1 mb-2 text-sm font-medium text-red-700 border border-red-700 rounded '
                        >
                          Cancel Edit
                        </button>
                      </div>
                    )}
                    {editId && (
                      <div className='flex justify-end mt-4'>
                        <label className='mb-2 text-sm font-medium text-gray-700 '>
                          Status: {values.status}
                        </label>
                      </div>
                    )}

                    {/* Visibility Section */}
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>

      {/* Media Management Modal */}
      {isMediaModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaModalOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement types='image' onSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      )}
      <hr className='my-5' />
      <div className='mt-5'>
        <PageTitle name='List of sliders' />
        <div>
          <span
            onClick={() => {
              setSelectedLocation("");
            }}
            className={`px-2 py-1 mr-3 cursor-pointer text-xs font-semibold text-white rounded-md ${
              selectedLocation == "" ? "bg-blue-500" : "bg-gray-300"
            }`}
          >
            All
          </span>
          {locations.map((location) => (
            <>
              {" "}
              <span
                onClick={() => {
                  setSelectedLocation(location.value);
                }}
                className={`px-2 py-1 mr-3 cursor-pointer text-xs font-semibold text-white rounded-md ${
                  location.value === selectedLocation
                    ? "bg-blue-500"
                    : "bg-gray-300"
                }`}
              >
                {location.label}
              </span>
            </>
          ))}

          <table className='w-full text-xs border-collapse table-auto'>
            <thead>
              <tr className='text-left border-b'>
                <th className='px-4 py-2'>#</th>
                <th className='px-4 py-2'>Move</th>
                <th className='px-4 py-2'>Caption</th>
                <th className='px-4 py-2'>Image</th>
                <th className='px-4 py-2'>Location</th>
                <th className='px-4 py-2'>Status</th>
                <th className='px-4 py-2'>Created at</th>
                <th className='px-4 py-2'>Updated at</th>
                <th className='px-4 py-2'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredSliders.length > 0 ? (
                filteredSliders.map((post, index) => (
                  <tr key={index} className='border-b'>
                    <td className='px-4 py-2'>
                      {index + 1 + (currentPage - 1) * 10}
                    </td>
                    <td className='px-4 py-2'>
                      {selectedLocation && (
                        <div className='flex items-center gap-2'>
                          <button
                            disabled={post.display_order === 1}
                            onClick={() =>
                              updateOrder(post.id, post.display_order - 1)
                            }
                            className={`text-blue-500 text-xl hover:text-blue-700 ${
                              post.display_order === 1
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                          >
                            <FaUpLong />
                          </button>
                          <button
                            disabled={
                              post.display_order === filteredSliders.length
                            }
                            onClick={() =>
                              updateOrder(post.id, post.display_order + 1)
                            }
                            className={`text-blue-500 text-xl hover:text-blue-700 ${
                              post.display_order === filteredSliders.length
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                          >
                            <FaDownLong />
                          </button>
                        </div>
                      )}
                      {!selectedLocation && (
                        <span className='text-gray-300'>
                          Select location first
                        </span>
                      )}
                    </td>
                    <td className='px-4 py-2'>{post.caption}</td>
                    <td className='px-4 py-2'>
                      <a href={post.file_url} target='_blank' rel='noreferrer'>
                        <img
                          src={post.file_url}
                          alt='Featured'
                          className='object-contain w-20 h-20 border rounded-md'
                        />
                      </a>
                    </td>
                    <td className='px-4 py-2'>
                      {locations.map((location) => (
                        <>
                          {location.value == post.location && (
                            <span
                              onClick={() => {
                                setSelectedLocation(location.value);
                              }}
                              key={location.value}
                              className={`px-2 py-1 cursor-pointer text-xs font-semibold text-white rounded-md ${
                                location.value === post.location
                                  ? "bg-blue-500"
                                  : "bg-gray-300"
                              }`}
                            >
                              {location.label}
                            </span>
                          )}
                        </>
                      ))}
                    </td>
                    <td className='px-4 py-2'>
                      {post.status == "published" ? (
                        <span className='text-green-500'>Published</span>
                      ) : (
                        <span className='text-gray-500'>Draft</span>
                      )}
                    </td>
                    <td className='px-4 py-2'>
                      {moment(post.created_at).format("DD MMM YYYY hh:mm A")}
                    </td>
                    <td className='px-4 py-2'>
                      {moment(post.updated_at).format("DD MMM YYYY hh:mm A")}
                    </td>

                    <td className='px-4 py-2'>
                      <div className='relative'>
                        <Menu
                          as='div'
                          className='relative inline-block text-left'
                        >
                          <Menu.Button className='flex gap-2 px-4 py-2 text-blue-500 hover:text-blue-700'>
                            Actions
                            <span aria-hidden className=''>
                              ▼
                            </span>
                          </Menu.Button>

                          <Menu.Items className='absolute right-0 z-10 w-48 mt-2 bg-white border border-gray-300 rounded-md shadow-lg focus:outline-none'>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    setEditId(post.id);
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                  className={`flex w-full items-center px-4 py-2 space-x-2 ${
                                    active ? "bg-gray-100" : ""
                                  }`}
                                >
                                  <FaEdit />
                                  <span>Edit</span>
                                </button>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => handleDelete(post.id)}
                                  className={`flex w-full items-center px-4 py-2 space-x-2 text-red-500 ${
                                    active ? "bg-gray-100" : ""
                                  }`}
                                >
                                  <FaTrash />
                                  <span>Delete</span>
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8' className='p-10 text-center'>
                    No sliders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
