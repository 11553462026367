import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const PhdAwardee = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [phdAwardees, setPhdAwardees] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    researcher_name: "",
    role: "",
    thesis_title: "",
    status_designation: "",
    shodhganga_link: "",
    status: "",
    month: "",
    year: "",
    // advisoryCommittee: "",
    awards: [
      {
        date: "",
        title: "",
        details: "",
      },
    ], // Array to store multiple awards
    iqac: "",
  });

  const headers = {
    researcher_name: {
      label: "Name of the Ph. D. Awardee",
      path: "researcher_name",
    },
    role: { label: "Role", path: "role" },
    thesis_title: { label: "Thesis Title", path: "thesis_title" },
    status: { label: "Status", path: "status" },
    status_designation: {
      label: "Present Status/Designation with name of the Organization",
      path: "status_designation",
    },
    month: {
      label: "Awarded Month",
      path: "monthName",
    },
    year: {
      label: "Awarded Year",
      path: "year",
    },
    shodhganga_link: {
      label: "Shodhganga link of the thesis",
      path: "shodhganga_link",
    },
    date: {
      label: "Date of Award",
      path: "achievements.award_date",
      isArray: true, // Mark this field as an array
    },
    title: {
      label: "Title of award",
      path: "achievements.title",
      isArray: true, // Mark this field as an array
    },
    details: {
      label: "Details of Award",
      path: "achievements.details",
      isArray: true, // Mark this field as an array
    },
  };

  const validationSchema = Yup.object({
    researcher_name: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    // status_designation: Yup.string().required("This field is required!"),
    // institute: Yup.string().required("This field is required!"),
    // other_institute: Yup.string().when("institute", {
    //   is: "Other Institute",
    //   then: Yup.string().required("This field is required!"),
    //   otherwise: Yup.string().nullable(),
    // }),
    status: Yup.string().required("This field is required!"),
    // awarded_date: Yup.string().when("status", {
    //   is: "Completed",
    //   then: Yup.string().required("This field is required!"),
    //   otherwise: Yup.string(),
    // }),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    // shodhganga_link: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/phd-awardees");
      setPhdAwardees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/phd-awardees/${editId}`, values);
        toast.success("Ph. D. Awardee Updated Successfully");
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/phd-awardees", values);
        toast.success("Ph. D. Awardee Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = phdAwardees.find(
        (phdAwardee) => phdAwardee.id === id
      );
      // Map achievements to awards, or provide at least one blank row if there are no achievements
      const awards =
        editableData.achievements?.length > 0
          ? editableData.achievements.map((achievement) => ({
              date: achievement.award_date, // Use award_date from achievements
              title: achievement.title, // Use title from achievements
              details: achievement.details, // Use details from achievements
            }))
          : [{ date: "", title: "", details: "" }]; // Provide one blank row if no achievements

      setInitialValues({
        id: editableData.id,
        researcher_name: editableData.researcher_name,
        role: editableData.role,
        thesis_title: editableData.thesis_title,
        status_designation: editableData.status_designation,
        // other_institute: editableData.other_institute,
        status: editableData.status,
        month: editableData.month,
        year: editableData.year,
        shodhganga_link: editableData.shodhganga_link,
        iqac: editableData.iqac,
        awards: awards,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      status_designation: "",
      // other_institute: "",
      status: "",
      month: "",
      year: "",
      shodhganga_link: "",
      awards: [
        {
          date: "",
          title: "",
          details: "",
        },
      ], // Initialize with an empty award field
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      role: "",
      status_designation: "",
      // other_institute: "",
      status: "",
      month: "",
      shodhganga_link: "",
      year: "",
      // advisoryCommittee: "",
      // awarded_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/phd-awardees/${id}`);
      toast.success("Ph. D. Awardee Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Ph.D Awardees :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <>
              <FormPage
                partialEdit={
                  initialValues.iqac == "yes" &&
                  initialValues.status == "Ongoing"
                }
                initialValues={initialValues} // Pass initial values for editing
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isEditMode={isEditing}
              />
            </>
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            editable={true}
            editCheckField='status'
            headers={headers}
            data={phdAwardees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default PhdAwardee;
