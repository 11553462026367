import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const Project = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [projects, setProjects] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    type: "",
    role: "",
    funding_agency: "",
    type_funding_agency: "",
    grant_no: "",
    amount_granted: "",
    status: "",
    start_date: "",
    end_date: "",
    project_related: "",
    collaborative_project: "",
    collaborative_project_details: "",
    research_fellow: "",
  });
  const headers = {
    title: { label: "Title of the Project", path: "title" },
    type: { label: "Project Type", path: "type" },
    role: { label: "Role", path: "role" },
    funding_agency: { label: "Funding Agency", path: "funding_agency" },
    type_funding_agency: {
      label: "Type of Funding Agency",
      path: "type_funding_agency",
    },
    grant_no: { label: "Grant No.", path: "grant_no" },
    amount_granted: { label: "Amount Granted", path: "amount_granted" },
    status: { label: "Status", path: "status" },
    start_date: { label: "Start Date", path: "start_date" },
    end_date: { label: "End Date", path: "end_date" },
    project_related: { label: "Project Related", path: "project_related" },
    collaborative_project: {
      label: " Is it a collaborative project?",
      path: "collaborative_project",
    },
    collaborative_project_details: {
      label: "Details",
      path: "collaborative_project_details",
    },
    research_fellow: {
      label: "Number of Research Fellows under the Project",
      path: "research_fellow",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    funding_agency: Yup.string().required("This field is required!"),
    type_funding_agency: Yup.string().required("This field is required!"),
    grant_no: Yup.string().required("This field is required!"),
    amount_granted: Yup.string().required("This field is required!"),
    status: Yup.string().required("This field is required!"),
    end_date: Yup.string().when("status", {
      is: "Completed",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    start_date: Yup.date().required("This field is required!"),
    project_related: Yup.string().required("This field is required!"),
    collaborative_project: Yup.string().required("This field is required!"),
    collaborative_project_details: Yup.string().when("collaborative_project", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    research_fellow: Yup.number()
      .typeError("Number of Research Fellows must be a number") // Custom message for invalid type (non-number)
      .required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/projects");
      setProjects(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/projects/${editId}`, values);
        toast.success(`Project Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/projects", values);
        toast.success(`Project Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = projects.find((project) => project.id === id);
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        type: editableData.type,
        role: editableData.role,
        funding_agency: editableData.funding_agency,
        type_funding_agency: editableData.type_funding_agency,
        grant_no: editableData.grant_no,
        amount_granted: editableData.amount_granted,
        status: editableData.status,
        start_date: editableData.start_date,
        end_date: editableData.end_date,
        project_related: editableData.project_related,
        collaborative_project: editableData.collaborative_project,
        collaborative_project_details:
          editableData.collaborative_project_details,
        research_fellow: editableData.research_fellow,
      });

      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      type: "",
      role: "",
      funding_agency: "",
      type_funding_agency: "",
      grant_no: "",
      amount_granted: "",
      status: "",
      start_date: "",
      end_date: "",
      project_related: "",
      collaborative_project: "",
      collaborative_project_details: "",
      research_fellow: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      type: "",
      role: "",
      funding_agency: "",
      type_funding_agency: "",
      grant_no: "",
      amount_granted: "",
      status: "",
      start_date: "",
      end_date: "",
      project_related: "",
      collaborative_project: "",
      collaborative_project_details: "",
      research_fellow: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/projects/${id}`);
      toast.success(`Data Deleted`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Project :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={projects}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Project;
