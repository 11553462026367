import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import BaseApi from "../../Http/BaseApi";
import {
  FaSpinner,
  FaEdit,
  FaTrash,
  FaPlus,
  FaGlobe,
  FaLock,
  FaCopy,
  FaFilter,
  FaUndo,
  FaEye,
} from "react-icons/fa";
import moment from "moment";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";
import { Menu } from "@headlessui/react";

export default function PostsIndex() {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    query: "",
    category_id: "",
    type: "published",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Fetch Categories for the filter form
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await BaseApi().get("/website/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };
    fetchCategories();
  }, []);

  // Fetch Posts with pagination and filters
  const fetchPosts = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
      const response = await BaseApi().get(
        `/website/posts?page=${page}&query=${filters.query || ""}&category_id=${
          filters.category_id || ""
        }&type=${filters.type || ""}`
      );
      setPosts(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      LaravelValidationError(error);
      console.error("Error fetching posts", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle URL search params for category and query
  useEffect(() => {
    const category_id = searchParams.get("category_id") || "";
    const query = searchParams.get("query") || "";
    const type = searchParams.get("type") || "";

    setFilters({
      query,
      category_id,
      type,
    });
    fetchPosts(currentPage, { query, category_id, type });
  }, [currentPage, searchParams]);

  // Handle Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Navigate to the page with a category_id query parameter
  const handleCategoryClick = (category_id) => {
    setSearchParams({ category_id });
    setCurrentPage(1); // Reset to first page after clicking on category
  };

  const handleDelete = async (dataId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      await BaseApi().delete("/website/posts/" + dataId);
      const currentPosts = posts.filter((p) => p.id != dataId);
      setPosts(currentPosts);
    } catch (error) {
      LaravelValidationError(error);
    }
  };
  const makeArchive = async (dataId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      await BaseApi().post("/website/posts/" + dataId + "/archive");
      // update archive_date;
      const currentPosts = posts.map((p) => {
        if (p.id == dataId) {
          p.archive_date = moment().format("YYYY-MM-DD HH:mm:ss");
        }
        return p;
      });
      setPosts(currentPosts);
    } catch (error) {
      LaravelValidationError(error);
    }
  };

  return (
    <div className='container min-h-screen px-5 mx-auto mt-5 rounded-md shadow-md'>
      <Navigations></Navigations>
      <div className='flex justify-between'>
        <PageTitle name='List of Posts' />

        <Link
          to='/website/posts/create'
          className='flex items-center px-4 py-2 mt-1 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
        >
          <FaPlus className='inline w-4' /> Create New{" "}
        </Link>
      </div>

      {/* Filter Form */}
      <Formik
        initialValues={{
          query: searchParams.get("query") || "",
          category_id: searchParams.get("category_id") || "",
          type: searchParams.get("type") || "",
        }}
        enableReinitialize
        onSubmit={(values) => {
          setSearchParams({
            query: values.query,
            category_id: values.category_id,
            type: values.type,
          });
          setCurrentPage(1); // Reset to first page after applying filters
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <Form
            className='grid items-end grid-cols-4 gap-3 mb-4 space-x-4 bg-white '
            onSubmit={handleSubmit}
          >
            {/* Search Input */}
            <div className='flex-grow'>
              <label className='block text-sm font-medium text-gray-700'>
                Search
              </label>
              <Field
                type='text'
                name='query'
                placeholder='Search posts...'
                className='w-full p-2 border rounded-md'
                onChange={handleChange}
              />
            </div>

            {/* Category Filter */}
            <div className='flex-grow'>
              <label className='block text-sm font-medium text-gray-700'>
                Category
              </label>
              <Field
                as='select'
                name='category_id'
                className='w-full p-2 border rounded-md'
                onChange={handleChange}
              >
                <option value=''>All Categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Field>
            </div>

            {/* Category Filter */}
            <div className='flex-grow'>
              <label className='block text-sm font-medium text-gray-700'>
                Type
              </label>
              <Field
                as='select'
                name='type'
                className='w-full p-2 border rounded-md'
                onChange={handleChange}
              >
                <option value=''>All</option>
                <option value='draft'>Draft</option>
                <option value='published'>Published</option>
                <option value='archived'>Archived</option>
              </Field>
            </div>

            {/* Submit Button */}
            <div className='flex items-end gap-4'>
              <button
                type='submit'
                className='px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700'
              >
                <FaFilter className='inline mr-2' />
                Filter
              </button>
              <button
                type='button'
                onClick={() => {
                  setSearchParams({});
                  setCurrentPage(1);
                }}
                className='px-4 py-2 text-white bg-gray-600 rounded-md hover:bg-gray-700'
              >
                <FaUndo className='inline mr-2' />
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Posts Table */}
      <div className='bg-white '>
        {loading ? (
          <div className='flex justify-center'>
            <FaSpinner className='text-3xl text-blue-600 animate-spin' />
          </div>
        ) : (
          <>
            <table className='w-full text-xs border-collapse table-auto'>
              <thead>
                <tr className='text-left border-b'>
                  <th className='px-4 py-2'>#</th>
                  <th className='px-4 py-2'>Title</th>
                  <th className='px-4 py-2'>Category</th>
                  <th className='px-4 py-2'>Department</th>
                  <th className='px-4 py-2'>Visibility</th>
                  <th className='px-4 py-2'>Published</th>
                  <th className='px-4 py-2'>Archived</th>
                  <th className='px-4 py-2'>Updated On</th>
                  <th className='px-4 py-2'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {posts.length > 0 ? (
                  posts.map((post, index) => (
                    <tr
                      key={post.id}
                      className={`border-b hover:bg-gray-100 ${
                        post.status == "draft" ? "opacity-50" : "opacity-100"
                      }`}
                    >
                      <td className='px-4 py-2'>
                        {index + 1 + (currentPage - 1) * 10}
                      </td>
                      <td className='px-4 py-2'>
                        <Link
                          to={`/website/posts/${post.id}`}
                          className='text-blue-500 hover:underline'
                        >
                          {post.title}
                        </Link>
                      </td>
                      <td className='px-4 py-2'>
                        {post.categories.map((category) => (
                          <span
                            key={category.id}
                            className='px-3 py-1 mr-1 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:underline'
                            onClick={() => handleCategoryClick(category.id)}
                          >
                            {category.name}
                          </span>
                        ))}
                      </td>
                      <td className='px-4 py-2'>
                        {post.department ? post.department.name : ""}
                      </td>
                      <td className='px-4 py-2'>
                        {post.visibility != "Private" && (
                          <FaGlobe className='inline mr-2' />
                        )}
                        {post.visibility == "Private" && (
                          <FaLock className='inline mr-2' />
                        )}

                        {post.department_id &&
                          post.is_visible_on_home == "Yes" && (
                            <>Both on Department and Home </>
                          )}
                        {!post.department_id &&
                          post.is_visible_on_home == "Yes" && <>On Home </>}
                        {post.department_id &&
                          post.is_visible_on_home == "No" && (
                            <>On Department </>
                          )}
                      </td>
                      <td className='px-4 py-2'>
                        {post.status === "published"
                          ? moment(post.published_date).format(
                              "DD MMM YYYY hh:mm A"
                            )
                          : "Draft"}
                      </td>

                      <td
                        className={`px-4 py-2 ${
                          post.archive_date &&
                          moment(post.archive_date).isBefore(moment())
                            ? "text-red-500  " // Archived (past date)
                            : "text-gray-700" // Not archived
                        }`}
                      >
                        {post.archive_date
                          ? moment(post.archive_date).format(
                              "DD MMM YYYY hh:mm A"
                            )
                          : "Not Set"}
                        {!post.archive_date && (
                          <>
                            <button
                              className='text-blue-600'
                              onClick={() => {
                                makeArchive(post.id);
                              }}
                            >
                              Make Archive
                            </button>
                          </>
                        )}
                      </td>
                      <td className='px-4 py-2'>
                        {moment(post.updated_at ?? post.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </td>
                      <td className='flex items-center px-4 py-2 space-x-2'>
                        <div className='relative'>
                          <Menu
                            as='div'
                            className='relative inline-block text-left'
                          >
                            <Menu.Button className='flex gap-2 px-4 py-2 text-blue-500 hover:text-blue-700'>
                              Actions
                              <span aria-hidden className=''>
                                ▼
                              </span>
                            </Menu.Button>

                            <Menu.Items className='absolute right-0 z-10 w-48 mt-2 bg-white border border-gray-300 rounded-md shadow-lg focus:outline-none'>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    target='_blank'
                                    href={`https://dibru.ac.in/posts/${post.slug}`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                    rel='noreferrer'
                                  >
                                    <FaEye />
                                    <span>View on website</span>
                                  </a>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={`/website/posts/create?edit_id=${post.id}`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaEdit />
                                    <span>Edit</span>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={`/website/posts/create?edit_id=${post.id}&duplicate=true`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaCopy />
                                    <span>Duplicate</span>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDelete(post.id)}
                                    className={`flex items-center px-4 py-2 space-x-2 text-red-500 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaTrash />
                                    <span>Delete</span>
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        </div>

                        {/* <Link
                          to={`/website/posts/create?edit_id=${post.id}`}
                          className='flex items-center space-x-1 text-blue-500 hover:underline'
                        >
                          <FaEdit /> <span>Edit</span>
                        </Link>
                        <Link
                          to={`/website/posts/create?edit_id=${post.id}&duplicate=true`}
                          className='flex items-center space-x-1 text-blue-500 hover:underline'
                        >
                          <FaEdit /> <span>Duplicate</span>
                        </Link> */}

                        {/* <span>|</span>
                        <button
                          onClick={() => {
                            handleDelete(post.id);
                          }}
                          className='flex items-center space-x-1 text-red-500 hover:underline'
                        >
                          <FaTrash /> <span>Delete</span>
                        </button> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='5' className='py-4 text-center'>
                      No posts found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className='flex justify-between mt-10'>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
                >
                  Previous
                </button>

                <span className='text-sm'>
                  Page {currentPage} of {totalPages}
                </span>

                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
