import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Utils/Button";
import {
  // cancelOldStudentAsync,
  rejectOldStudentAsync,
  showOldStudentAsync,
} from "../../Redux/OldStudentSlice";
// import * as Yup from "yup";
import { OLD_STUDENTS_STATUS } from "./../../Enums/enums";
import moment from "moment";
import RejectModal from "./RejectModal";

export default function ViewOldStudent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const oldStudentsSlice = useSelector((state) => state.oldStudents);
  const oldStudents = oldStudentsSlice.oldStudents;
  //   const authData = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function checkData() {
      if (id && id > 0) {
        const singleStudent = await oldStudents.find((value) => value.id == id);
        if (singleStudent != null) {
          console.log("call1");
          setStudent(singleStudent);
        } else {
          console.log(" call 2");

          dispatch(showOldStudentAsync({ id }))
            .unwrap()
            .then((value) => {
              setStudent(value);
            });
        }
      }
    }
    checkData();
  }, [oldStudents]);
  //   const checkerApprove = async () => {
  //     await dispatch(approveInvoiceAsync(invoice));
  //     await dispatch(showInvoiceAsync({ id }))
  //       .unwrap()
  //       .then((value) => {
  //         console.log();
  //         setInvoice(value);
  //       });
  //   };
  const handleReject = async (formValue) => {
    setLoading(true);
    console.log(formValue);
    await dispatch(rejectOldStudentAsync(formValue))
      .unwrap()
      .then(() => {});
    setShowModal(false);

    setLoading(true);
  };
  // const handleCancelAdmission = () => {
  //   dispatch(cancelOldStudentAsync(student));
  // };

  return (
    <>
      {student != null && (
        <div className='container p-5 mx-auto my-5'>
          <div className='md:flex no-wrap md:-mx-2 '>
            <div className='w-full md:w-3/12 md:mx-2'>
              <div className='p-3 bg-white border-t-4 border-green-400'>
                <div className='overflow-hidden image'>
                  <img
                    className='w-full h-auto mx-auto'
                    src={student.profile_pic}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (e.target.src != "/assets/no-photo.jpg")
                        e.target.src = "/assets/no-photo.jpg";
                    }}
                  />
                </div>
                <h1 className='my-1 text-xl font-bold leading-8 text-gray-900'>
                  {student.name}
                </h1>
                <h3 className='leading-6 text-gray-600 font-lg text-semibold'>
                  {student.programme?.department?.name}
                </h3>
                <h3 className='leading-6 text-gray-600 font-lg text-semibold'>
                  {student.programme?.name}
                </h3>
                <p className='text-sm leading-6 text-gray-500 hover:text-gray-600'>
                  Present Sem/Year:: {student.present_sem}
                </p>
                <p className='text-sm leading-6 text-gray-500 hover:text-gray-600'>
                  Year of first enrollment: {student.enrollment}
                </p>
                <ul className='px-3 py-2 mt-3 text-gray-600 bg-gray-100 divide-y rounded shadow-sm hover:text-gray-700 hover:shadow'>
                  <li className='flex items-center py-3'>
                    <span>Status</span>
                    <span className='ml-auto'>
                      <span className='px-2 py-1 text-sm text-white bg-green-500 rounded'>
                        {OLD_STUDENTS_STATUS[student.status]}
                      </span>
                    </span>
                  </li>
                  <li className='flex items-center py-3'>
                    <span>Academic Session</span>
                    <span className='ml-auto'>
                      {student.academic_session?.label}
                    </span>
                  </li>
                  <li className='flex items-center py-3'>
                    <span>Applied Date</span>
                    <span className='ml-auto'>
                      {moment(student.applied_date).format("Do MMM YYYY")}
                    </span>
                  </li>

                  <li className='flex items-center py-3'>
                    <span>Application No</span>
                    <span className='ml-auto'>{student.id}</span>
                  </li>
                </ul>
                <div className='my-4'></div>
                <div className='overflow-hidden image'>
                  <img
                    className='w-full h-auto mx-auto'
                    src={student.profile_sign}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (e.target.src != "/assets/no-sign.jpg")
                        e.target.src = "/assets/no-sign.jpg";
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='w-full h-64 mx-2 md:w-9/12'>
              {student.status == 2 && (
                <div className='p-3 text-red-500 bg-white rounded-sm shadow-sm'>
                  Rejected Category: {student.message} <br />
                  Rejected Remarks: {student.remark} <br />
                  {student.active == 0 && (
                    <>
                      <small>Waiting to resubmit</small>
                    </>
                  )}
                </div>
              )}

              <div className='p-3 bg-white rounded-sm shadow-sm'>
                <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                  <span className='text-green-500'>
                    <svg
                      className='h-5'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                      />
                    </svg>
                  </span>
                  <span className='tracking-wide'>About</span>
                </div>
                <div className='text-gray-700'>
                  <div className='grid text-sm md:grid-cols-2'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Student Name
                      </div>
                      <div className='px-4 py-2'>{student.name}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Guardian Name
                      </div>
                      <div className='px-4 py-2'>{student.guardian}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Gender</div>
                      <div className='px-4 py-2'>{student.gender}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Religion</div>
                      <div className='px-4 py-2'>{student.religion}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Caste</div>
                      <div className='px-4 py-2'>{student.caste?.name}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Date of Birth
                      </div>
                      <div className='px-4 py-2'>
                        {moment(student.dob).format("Do MMM YYYY")}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Mobile No.</div>
                      <div className='px-4 py-2'>{student.mobile}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Email</div>
                      <div className='px-4 py-2 break-all'>{student.email}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Address</div>
                      <div className='px-4 py-2 break-all'>
                        {student.address}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Hostel</div>
                      <div className='px-4 py-2 break-all'>
                        {student.stay_hostel == "yes"
                          ? student.hostel?.name
                          : "No"}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'> ID Proof </div>
                      <div className='px-4 py-2'>{student.identifier}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        ID Proof No.
                      </div>
                      <div className='px-4 py-2'>{student.identifier_no}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='my-4'></div>
              <div className='grid grid-cols-2'>
                <div className='px-4 py-2 text-center'>
                  {student.status == 0 && (
                    <Button
                      classNames='bg-green-600 hover:bg-green-700 focus:ring-green-500'
                      value={"Approve"}
                      icon={"CheckIcon"}
                      onClick={() => {
                        navigate(`/old-students/approve/${student.id}`);
                      }}
                    />
                  )}
                </div>
                <div className='px-4 py-2 text-center'>
                  {student.status == 0 && (
                    <Button
                      classNames='bg-red-600 hover:bg-red-700 focus:ring-red-500'
                      value={"Reject"}
                      onClick={() => {
                        setShowModal(true);
                      }}
                      icon={"XIcon"}
                    />
                  )}

                  {/* {student.status == 1 && (
                    <Button
                      classNames="bg-red-600 hover:bg-red-700 focus:ring-red-500"
                      value={"Cancel Admission"}
                      onClick={() => {
                        if (window.confirm("Are you sure?") == true) {
                          handleCancelAdmission();
                        } else {
                          return;
                        }
                      }}
                      icon={"XIcon"}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && student != null ? (
        <RejectModal
          student={student}
          handleReject={handleReject}
          setShowModal={setShowModal}
          loading={loading}
        />
      ) : null}
    </>
  );
}
