import {
  BlockNoteSchema,
  defaultBlockSpecs,
  filterSuggestionItems,
  insertOrUpdateBlock,
  BlockNoteEditor,
} from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import {
  SuggestionMenuController,
  getDefaultReactSlashMenuItems,
} from "@blocknote/react";

import { RiFilePdfFill } from "react-icons/ri";
import { PDF } from "./BlockNoteSchemas/PDF";
import { useEffect, useState } from "react";
import { FaImage, FaTimes } from "react-icons/fa";
import { ErrorMessage } from "formik";
import { ImageGallery } from "./BlockNoteSchemas/ImageGallery";

// Define schema with block specs
const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    pdf: PDF,
    gallery: ImageGallery,
  },
});

// Slash menu item to insert a PDF block
const insertPDF = (editor) => ({
  title: "Embed PDF",
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type: "pdf",
    });
  },
  aliases: ["pdf", "document", "embed", "file"],
  group: "Others",
  icon: <RiFilePdfFill />,
});

// Slash menu item to insert a Gallery block
const insertGallery = (editor) => ({
  title: "Image Gallery",
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type: "gallery",
    });
  },
  aliases: ["gallery", "images", "photos", "pictures"],
  group: "Others",
  icon: <FaImage />,
});

export default function CustomBlockNoteEditor({
  name,
  initialValue,
  onChange,
}) {
  const [htmlContent, setHtmlContent] = useState("");
  const [initialContent, setInitialContent] = useState(null); // Start with null
  const [editor, setEditor] = useState(null); // State to hold the editor instance

  // Function to save editor content to localStorage
  // async function saveToStorage(jsonBlocks) {
  //   localStorage.setItem("editorContent", JSON.stringify(jsonBlocks));
  // }

  // Function to load content from localStorage
  async function parseContent() {
    const storageString = initialValue;
    return storageString
      ? JSON.parse(storageString)
      : [
          {
            type: "paragraph",
            content: "",
          },
        ];
  }

  // Load initial content from localStorage on component mount
  useEffect(() => {
    parseContent().then((content) => {
      setInitialContent(content);
    });
  }, []);

  // Create editor instance after initialContent is loaded
  useEffect(() => {
    if (initialContent && !editor) {
      const newEditor = BlockNoteEditor.create({
        schema,
        initialContent,
      });

      // Set the editor instance
      setEditor(newEditor);

      // Set up onChange to save content to localStorage
      newEditor.onChange(async () => {
        const jsonBlocks = newEditor.document;
        // saveToStorage(jsonBlocks);
        const html = await newEditor.blocksToHTMLLossy(jsonBlocks);
        setHtmlContent(html);
        onChange(JSON.stringify(jsonBlocks));
      });
    }
  }, [initialContent, editor]);

  // Display loading state if editor is not yet created
  if (!editor) {
    return "Loading content...";
  }

  // Render the editor instance
  return (
    <>
      <div className='flex items-end justify-between'>
        <label className='block mb-2 text-sm font-medium text-gray-700'>
          Content
        </label>
      </div>

      <BlockNoteView
        theme='light'
        editor={editor}
        editable={true}
        formattingToolbar={true}
        linkToolbar={true}
        sideMenu={true}
        slashMenu={false}
        emojiPicker={true}
        filePanel={true}
        tableHandles={true}
      >
        {/* Replaces the default Slash Menu */}
        <SuggestionMenuController
          triggerCharacter={"/"}
          getItems={async (query) =>
            filterSuggestionItems(
              [
                ...getDefaultReactSlashMenuItems(editor),
                insertPDF(editor),
                insertGallery(editor),
              ],
              query
            )
          }
        />
      </BlockNoteView>
      <ErrorMessage name={name} component='div' className='py-2 text-red-500' />
    </>
  );
}
