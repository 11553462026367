import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import messageSlice from "../../Redux/MessageSlice";
import Alert from "../../Components/Utils/Alert";
import ActionConfirm from "../../Components/Utils/ActionConfirm";
import Badge from "../../Components/Utils/Badge";
// import { useNavigate } from "react-router-dom";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { getDepartmentsAsync } from "../../Redux/MasterSlice";
import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import EarnedLeaveEntryModal from "../Leave/EarnedLeaveEntryModal";
import can from "../../Middleware/Permission";
import { useLocation } from "react-router-dom"; // Import useLocation
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { FaCheck, FaCheckCircle, FaIdCard } from "react-icons/fa";

export default function StaffIndex() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);
  const [staff, setStaff] = useState([]);
  const authData = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState();
  const master = useSelector((state) => state.master);
  const departments = [...master.departments];
  const [searchInput, setSearchInput] = useState("");
  const [lastPage, setLastPage] = useState();
  const [showEarnLeaveModal, setShowEarnLeaveModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const location = useLocation(); // To get URL parameters

  console.log(loading);
  //   const dispatch = useDispatch();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const departmentIdFromUrl = queryParams.get("department_id") || "";
    const typeFromUrl = queryParams.get("type") || "";

    // Fetch departments if not already available
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }

    // Set initial values only if not already set
    if (initialValues?.type !== typeFromUrl) {
      setInitialValues({
        department_id: departmentIdFromUrl,
        status: "",
        type: typeFromUrl,
        page: 1,
      });

      // Submit the form programmatically after 2 seconds
      setTimeout(() => {
        if (departmentIdFromUrl || typeFromUrl) {
          formikRef.current?.submitForm();
        }
      }, 1000);
    }

    // Clear messages on mount
    dispatch(messageSlice.actions.clearMessage());
  }, [dispatch, location.search, location.type, departments, initialValues]);

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("This field is required!"),
  });

  async function getStaff(searchQuery) {
    console.log(searchQuery);
    setLoading(true);
    // if (authData.user.email === "establishmentb@dibru.ac.in") {
    //   searchQuery.type = 2; // Set 'type' property to an array with value 2
    // }
    // if (authData.user.email === "establishmenta@dibru.ac.in") {
    //   searchQuery.type = [1, 3]; // Set 'type' property to an array with values 1 and 2
    // }
    await BaseApi()
      .get(`staff`, { params: { ...searchQuery, paginate: true } })
      .then((data) => {
        let _staff = data.data.data;

        if (authData.user.email == "establishmenta@dibru.ac.in") {
          if (searchQuery.page == 1) {
            setStaff(_staff.filter((s) => s.type == "1" || s.type == 3));
          } else {
            setStaff((prevStaff) => [
              ...prevStaff,
              ..._staff.filter((s) => s.type == "1" || s.type == 3),
            ]);
          }
        } else if (authData.user.email == "establishmentb@dibru.ac.in") {
          if (searchQuery.page == 1) {
            setStaff(_staff.filter((s) => s.type == "2" || s.type == 6));
          } else {
            setStaff((prevStaff) => [
              ...prevStaff,
              ..._staff.filter((s) => s.type == "2" || s.type == 6),
            ]);
          }
        }
        // else if (authData.user.email == "accounts@dibru.ac.in") {
        //   if (searchQuery.page == 1) {
        //     setStaff(data.data);
        //   } else {
        //     setStaff((prevStaff) => [...prevStaff, ...data.data]);
        //   }

        // }
        else if (authData.user.email == "sws@dibru.ac.in") {
          if (searchQuery.page == 1) {
            setStaff(
              _staff.filter(
                (s) =>
                  s.type == "1" || s.type == 3 || s.type == 2 || s.type == 6
              )
            );
          } else {
            setStaff((prevStaff) => [
              ...prevStaff,
              ..._staff.filter(
                (s) =>
                  s.type == "1" || s.type == 3 || s.type == 2 || s.type == 6
              ),
            ]);
          }
        } else {
          if (searchQuery.page == 1) {
            setStaff(_staff);
          } else {
            setStaff((prevStaff) => [...prevStaff, ..._staff]);
          }
        }

        let nextPage = ++searchQuery.page;
        setLastPage(data.data?.last_page);
        if (nextPage <= data.data?.last_page) {
          setInitialValues({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: nextPage,
          });
          getStaff({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: nextPage,
          });
        } else {
          setInitialValues({
            department_id: searchQuery.department_id,
            status: searchQuery.status,
            type: searchQuery.type,
            page: 1,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
  }

  const handleDeactivate = async (event, payload, reason = null) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deactivateConfirm.state == 1) {
      await BaseApi()
        .post(`staff/change-status/${payload.id}`, { reason })
        .then((data) => {
          console.log(data.data.id);
          let _staff = [...staff];

          const index = _staff.findIndex((s) => s.id === data.data.id);
          _staff[index] = data.data;
          setStaff(_staff);
          setDeactivateConfirm({});
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeactivateConfirm({});
  };

  const handleModalClose = (data) => {
    console.log(data);
    // Store the data passed from the modal
    setShowEarnLeaveModal(false); // Close the modal
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='List of ERP Users' />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={getStaff}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>
                    {departments.length > 0 && (
                      <CustomSelect
                        key={values?.department_id}
                        defaultInputValue={
                          departments.find((d) => d.id == values?.department_id)
                            ?.name
                        }
                        options={departments}
                        name='department_id'
                        label='Select Department/Branch'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "department_id",
                            selectedOption?.id
                          );
                        }}
                      />
                    )}
                    <Select name='status' label='Select Status'>
                      <option value=''>Select Any One</option>
                      <option value='1'>Active</option>
                      <option value='0'>Inactive</option>
                      <option value=''>Any</option>
                      {/* <option value="2023">2024</option> */}
                    </Select>

                    <Select name='type' label='Select Type*'>
                      <option value=''>Select Any One</option>

                      {authData.user.email == "establishmenta@dibru.ac.in" && (
                        <>
                          <option value='1'>Teacher</option>
                          <option value='3'>Officer</option>
                        </>
                      )}
                      {authData.user.email == "establishmentb@dibru.ac.in" && (
                        <>
                          <option value='2'>Employee (Grade III)</option>
                          <option value='6'>Employee (Grade IV)</option>
                        </>
                      )}
                      {authData.user.email != "establishmenta@dibru.ac.in" &&
                        authData.user.email != "establishmentb@dibru.ac.in" && (
                          <>
                            <option value='1'>Teacher</option>
                            <option value='3'>Officer</option>
                            <option value='2'>Employee (Grade III)</option>
                            <option value='6'>Employee (Grade IV)</option>
                          </>
                        )}
                    </Select>

                    <div className='mt-5 text-left'>
                      <button
                        type='submit'
                        className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        disabled={loading}
                      >
                        {loading && (
                          <span>
                            Searching... {initialValues?.page} / {lastPage}
                          </span>
                        )}
                        {!loading && <span>Search</span>}
                      </button>
                      <button
                        type='button'
                        className='relative px-4 py-2 ml-3 text-sm font-medium text-white bg-orange-600 border border-transparent rounded-md group w-50 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
                        disabled={loading}
                        onClick={() => {
                          setInitialValues({
                            department_id: "",
                            status: "",
                            page: 1,
                          });
                          setStaff([]);
                        }}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className=''>
              {errorMessage && <Alert message={errorMessage} />}
            </div>

            {staff?.length > 0 && (
              <>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='inline-block m-0 text-gray-700 form-label'
                >
                  Search By Name
                </label>
                <input
                  className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                  placeholder='Type here...'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </>
            )}
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='w-16 table-head'>#</th>
                  <th className=' table-head'>Actions</th>

                  <th className='table-head'>Name</th>
                  {/* <th className='table-head'>Father&apos;s Name</th> */}
                  {/* <th className='table-head'>Mother&apos;s Name</th> */}
                  <th className='table-head'>Department/Branch</th>
                  <th className='table-head'>Designations</th>
                  <th className='table-head'>Nature of Employment</th>
                  <th className='table-head'>Email</th>
                  <th className='table-head'>Phone</th>
                  <th className='table-head'>Status</th>
                  <th className='table-head'>Permissions</th>
                </tr>
              </thead>
              <tbody>
                {staff?.length > 0 &&
                  staff
                    ?.filter((s) => {
                      if (searchInput?.length > 0) {
                        return (
                          s.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((singleStaff, i) => {
                      return (
                        <>
                          {" "}
                          <tr
                            className={
                              " " +
                              (singleStaff.status == 0
                                ? "bg-red-50"
                                : "odd:bg-white even:bg-gray-50")
                            }
                          >
                            <td className='w-32 table-data'>{++i}</td>
                            <td className=' table-data'>
                              <Menu
                                as='div'
                                className='relative inline-block text-left '
                              >
                                <div>
                                  <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-blue-600 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                                    Actions
                                    <ChevronDownIcon
                                      className='w-5 h-5 ml-1 -mr-1 text-blue-600'
                                      aria-hidden='true'
                                    />
                                  </Menu.Button>
                                </div>
                                <Menu.Items className='absolute left-0 z-50 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                  <div className='px-1 py-1 '>
                                    {can("Edit Staff") && (
                                      <>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={` group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                              onClick={() =>
                                                window.open(
                                                  `/staff/admin-edit/${singleStaff.id}`,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              Edit
                                            </button>
                                          )}
                                        </Menu.Item>

                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active ? " " : "text-gray-900"
                                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                              onClick={() =>
                                                window.open(
                                                  `/leave-report/${singleStaff.id}`,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              View Leaves
                                            </button>
                                          )}
                                        </Menu.Item>

                                        {(authData.user.id == 495 ||
                                          authData.user.id == 496) && (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                className={`${
                                                  active ? " " : "text-gray-900"
                                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                onClick={() => {
                                                  setShowEarnLeaveModal(true);
                                                  setSelectedStaff(singleStaff);
                                                }}
                                              >
                                                Update Earn Leave
                                              </button>
                                            )}
                                          </Menu.Item>
                                        )}

                                        <Menu.Item>
                                          {({ active }) => (
                                            <ActionConfirm
                                              classNames={`${
                                                active ? " " : "text-gray-900"
                                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                              btnLabel={
                                                singleStaff.status == 1 ? (
                                                  <span className='text-red-600 '>
                                                    Deactivate
                                                  </span>
                                                ) : (
                                                  <span className='text-green-500 '>
                                                    Activate
                                                  </span>
                                                )
                                              }
                                              actionState={deactivateConfirm}
                                              data={singleStaff}
                                              firstOnClick={() =>
                                                setDeactivateConfirm({
                                                  itemId: singleStaff.id,
                                                  state: 1,
                                                })
                                              }
                                              onYesClick={(event) => {
                                                if (singleStaff.status == 1) {
                                                  let reason = window.prompt(
                                                    "Are you sure? Please enter remarks"
                                                  );
                                                  if (
                                                    reason == null ||
                                                    reason.trim() == ""
                                                  ) {
                                                    return;
                                                  } else {
                                                    handleDeactivate(
                                                      event,
                                                      singleStaff,
                                                      reason
                                                    );
                                                  }
                                                } else {
                                                  handleDeactivate(
                                                    event,
                                                    singleStaff,
                                                    null
                                                  );
                                                }
                                              }}
                                              onNoClick={() =>
                                                setDeactivateConfirm({})
                                              }
                                            />
                                          )}
                                        </Menu.Item>
                                      </>
                                    )}
                                    {can("Manage Staff ID Card") && (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            className={
                                              singleStaff.id_card == null
                                                ? " text-orange-600 group flex rounded-md items-center w-full px-2 py-2 text-sm "
                                                : " text-green-600 group flex rounded-md items-center w-full px-2 py-2 text-sm "
                                            }
                                            icon={"IdentificationIcon"}
                                            onClick={() => {
                                              window.open(
                                                `/staff/id-card/${singleStaff.id}`
                                              );
                                            }}
                                          >
                                            {singleStaff.id_card == null
                                              ? "Gen. ID Card"
                                              : "View ID Card"}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    )}

                                    {authData.user.email == "sws@dibru.ac.in" &&
                                      singleStaff.id_card != null && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={
                                                singleStaff.id_card == null
                                                  ? " text-orange-600 group flex rounded-md items-center w-full px-2 py-2 text-sm "
                                                  : " text-green-600 group flex rounded-md items-center w-full px-2 py-2 text-sm "
                                              }
                                              icon={"IdentificationIcon"}
                                              onClick={() => {
                                                window.open(
                                                  `/staff/id-card/${singleStaff.id}`
                                                );
                                              }}
                                            >
                                              {singleStaff.id_card == null
                                                ? "Gen. ID Card"
                                                : "View ID Card"}
                                            </button>
                                          )}
                                        </Menu.Item>
                                      )}
                                  </div>
                                </Menu.Items>
                              </Menu>

                              {singleStaff.id_card != null && (
                                <>
                                  <button
                                    onClick={() =>
                                      window.open(
                                        `/staff/id-card/${singleStaff.id}`
                                      )
                                    }
                                    className='flex justify-end w-full gap-2 text-xs text-green-700 line-clamp-1'
                                  >
                                    <FaIdCard /> <FaCheckCircle />
                                  </button>
                                </>
                              )}
                            </td>

                            <td className='table-data'>{singleStaff.name}</td>
                            {/* <td className='table-data'>
                              {singleStaff.fathers_name}
                            </td>
                            <td className='table-data'>
                              {singleStaff.mothers_name}
                            </td> */}
                            <td className='table-data'>
                              {singleStaff?.department?.name}
                            </td>
                            <td className='table-data'>
                              {singleStaff?.designation?.name}
                            </td>
                            <td className='table-data'>
                              {singleStaff?.nature_of_employee}
                            </td>
                            <td className='table-data'>{singleStaff.email}</td>
                            <td className='table-data'>{singleStaff.phone}</td>

                            <td className='table-data'>
                              {singleStaff.status == 1 ? (
                                <>
                                  <span className='text-green-700'>Active</span>
                                </>
                              ) : (
                                <>
                                  <span className='text-red-700'>Inactive</span>
                                </>
                              )}
                              {singleStaff.deactivate_remarks && (
                                <div className='mt-1'>
                                  Remarks: {singleStaff.deactivate_remarks}
                                </div>
                              )}
                            </td>
                            <td className='table-data'>
                              {singleStaff.permissions.map((permission) => {
                                return (
                                  <div
                                    key={singleStaff.id + "-" + permission.id}
                                    id={singleStaff.id + "-" + permission.id}
                                    className='m-1'
                                  >
                                    <Badge
                                      status={permission.name}
                                      // icon="XIcon"
                                      iconClick={() => {
                                        // removePermission(
                                        //   user.id,
                                        //   permission.id
                                        // );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {!loading && staff?.length == 0 && (
              <>
                <div className='m-10 text-center'>No Data</div>
              </>
            )}

            {showEarnLeaveModal && (
              <EarnedLeaveEntryModal
                readOnlyDate={false}
                staff={selectedStaff}
                setShowEarnLeaveModal={handleModalClose}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
