import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Book",
    path: "title",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publication",
    path: "monthName",
  },
  year: {
    label: "Year of Publication",
    path: "year",
  },
  author_names: {
    label: "Author Names: (As per Book)",
    path: "author_names",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
};

const BooksAuthored = () => {
  const [booksAuthoreds, setBooksAuthoreds] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/authored-books");
      setBooksAuthoreds(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Books Authored
      </h2>
      <TableViewProfile
        headers={headers}
        data={booksAuthoreds}
        type="authored-books"
        setData={setBooksAuthoreds}
      />
    </div>
  );
};

export default BooksAuthored;
