import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Name of the Activity ", path: "name" },
  from_date: { label: "From date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  award_received: {
    label: "Whether Any Award received for this activity?",
    path: "award_received",
  },
  award_recognition: {
    label: "Name of the Award/Recognition",
    path: "award_recognition",
  },
  awarding_agency: {
    label: "Name of the Awarding Agency",
    path: "awarding_agency",
  },
  month: { label: "Month of Award", path: "monthName" },
  year: { label: "Year of Award", path: "year" },
};

const ExtensionActivitie = () => {
  const [extensionActivities, setExtensionActivities] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/extension-activities");
      setExtensionActivities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Outreach/Extension Activitie
      </h2>
      <TableViewProfile
        headers={headers}
        data={extensionActivities}
        type='extension-activities'
        setData={setExtensionActivities}
      />
    </div>
  );
};

export default ExtensionActivitie;
