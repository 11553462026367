import { FileBlockConfig } from "@blocknote/core";
import {
  AddFileButton,
  createReactBlockSpec,
  DefaultFilePreview,
  FileAndCaptionWrapper,
} from "@blocknote/react";

import { RiFilePdfFill } from "react-icons/ri";
import "./pdf-embed.css";

export const PDFPreview = (props) => {
  return (
    <embed
      type={"application/pdf"}
      src={props.block.props.url}
      contentEditable={false}
      draggable={false}
      onClick={() => props.editor.setTextCursorPosition(props.block)}
    />
  );
};

export const PDF = createReactBlockSpec(
  {
    type: "pdf",
    propSchema: {
      name: {
        default: "",
      },
      url: {
        default: "",
      },
      caption: {
        default: "",
      },
      showPreview: {
        default: true,
      },
      previewWidth: {
        default: 512,
      },
    },
    content: "none",
    isFileBlock: true,
  },
  {
    render: (props) => (
      <div className={"bn-file-block-content-wrapper"}>
        {props.block.props.url === "" ? (
          <AddFileButton
            {...props}
            editor={props.editor}
            buttonText={"Add PDF"}
            buttonIcon={<RiFilePdfFill size={24} />}
          />
        ) : !props.block.props.showPreview ? (
          <FileAndCaptionWrapper block={props.block} editor={props.editor}>
            <DefaultFilePreview block={props.block} editor={props.editor} />
          </FileAndCaptionWrapper>
        ) : (
          <FileAndCaptionWrapper block={props.block} editor={props.editor}>
            <PDFPreview block={props.block} editor={props.editor} />
          </FileAndCaptionWrapper>
        )}
      </div>
    ),
  }
);
