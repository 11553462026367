import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import WebsiteDashboard from "../Pages/Website/WebDashboard";
import CreatePosts from "../Pages/Website/CreatePosts";
import MediaManagement from "../Pages/Website/MediaManagement";
import PostsIndex from "../Pages/Website/PostsIndex";
import WebCategories from "../Pages/Website/WebCategories";
import can from "../Middleware/Permission";
import ShowPost from "../Pages/Website/ShowPost";
import ManageFacultyMembers from "../Pages/Website/DepartmentProfile/FacultyMembers/ManageFacultyMembers";
import CreatePages from "../Pages/Website/CreatePages";
import PagesIndex from "../Pages/Website/PagesIndex";
import ShowPage from "../Pages/Website/ShowPage";
import Sliders from "../Pages/Website/Sliders";

export default [
  <>
    <Route key='staff' element={<ProtectedRoute />}>
      {can("Manage Website") && (
        <>
          <Route path='website' element={<WebsiteDashboard />} />
          <Route path='website/dashboard' element={<WebsiteDashboard />} />
          <Route path='website/categories' element={<WebCategories />} />
          <Route path='website/posts' element={<PostsIndex />} />
          <Route path='website/posts/:id' element={<ShowPost />} />
          <Route path='website/posts/create' element={<CreatePosts />} />

          <Route
            path='website/department-profile/faculty-members'
            element={<ManageFacultyMembers />}
          />

          <Route
            path='website/media-management'
            element={<MediaManagement />}
          />

          <Route path='website/pages' element={<PagesIndex />} />
          <Route path='website/pages/:id' element={<ShowPage />} />
          <Route path='website/pages/create' element={<CreatePages />} />

          <Route path='website/sliders' element={<Sliders />} />
        </>
      )}
    </Route>
  </>,
];
