import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";

export default function Upload(props) {
  const [image, setImage] = useState(null);
  const [mimeType, setMimeType] = useState(null);

  return (
    <>
      <div className='flex items-start bg-grey-lighter'>
        <label className='relative flex flex-col items-center w-64 px-4 py-6 tracking-wide bg-white border rounded-lg cursor-pointer text-blue border-blue'>
          <small> {props.label}</small>

          <svg
            className='w-8 h-8 hover:text-indigo-600'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
          >
            <path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
          </svg>
          <div className='mt-2 text-base leading-normal '>Select a file</div>
          <input
            id={props.id}
            name={props.name}
            type='file'
            accept='image/jpeg, image/png, application/pdf'
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = (event) => {
                setImage(event.target.result);
                setMimeType(
                  event.target.result.split(",")[0].split(":")[1].split(";")[0]
                );
              };
              reader.readAsDataURL(file);

              props.onChange(e);
            }}
            className='hidden'
          />
          {/* clear icon */}

          <div className='text-xs text-center'>
            {mimeType?.startsWith("image") && (
              <img className='w-32 mt-4 rounded-md shadow-md' src={image} />
            )}
            {mimeType?.startsWith("application") && "Selected"}
            {props.defaultImage && image == null && (
              <img
                className='w-32 mt-4 rounded-md shadow-md'
                src={props.defaultImage}
              />
            )}
          </div>

          <ErrorMessage
            name={props.name}
            component='div'
            className='py-2 text-red-500'
          />
        </label>
        {props.onClear && image && (
          <FaTrash
            className='z-10 inline float-right w-8 text-right text-red-700 cursor-pointer align-right'
            onClick={() => {
              props.onClear();
              setImage(null);
              setMimeType(null);
            }}
          />
        )}
      </div>
    </>
  );
}
