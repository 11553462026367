import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const ThesisExaminerUniversitie = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [thesisExaminerUniversities, setThesisExaminerUniversities] = useState(
    []
  ); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    researcher_name: "",
    thesis_title: "",
    other_institute: "",
    month: "",
    year: "",
    awards: [
      {
        date: "",
        title: "",
        details: "",
      },
    ], // Array to store multiple awards
    iqac: "",
  });

  const headers = {
    researcher_name: {
      label: "Name of the Ph. D. Scholar",
      path: "researcher_name",
    },
    thesis_title: { label: "Title of the Thesis", path: "thesis_title" },
    other_institute: {
      label: "Name of the University/Institute",
      path: "other_institute",
    },
    month: {
      label: "Month",
      path: "monthName",
    },
    year: {
      label: "Year",
      path: "year",
    },
    date: {
      label: "Date of Award",
      path: "achievements.award_date",
      isArray: true, // Mark this field as an array
    },
    title: {
      label: "Title of award",
      path: "achievements.title",
      isArray: true, // Mark this field as an array
    },
    details: {
      label: "Details of Award",
      path: "achievements.details",
      isArray: true, // Mark this field as an array
    },
  };

  const validationSchema = Yup.object({
    researcher_name: Yup.string().required("This field is required!"),
    thesis_title: Yup.string().required("This field is required!"),
    other_institute: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/thesis-examiner-universities"
      );
      setThesisExaminerUniversities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(
          `/profile/thesis-examiner-universities/${editId}`,
          values
        );
        toast.success(`Present Research Scholar Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/thesis-examiner-universities", values);
        toast.success("Present Research Scholar Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = thesisExaminerUniversities.find(
        (thesisExaminerUniversitie) => thesisExaminerUniversitie.id === id
      );
      // Map achievements to awards, or provide at least one blank row if there are no achievements
      const awards =
        editableData.achievements?.length > 0
          ? editableData.achievements.map((achievement) => ({
              date: achievement.award_date, // Use award_date from achievements
              title: achievement.title, // Use title from achievements
              details: achievement.details, // Use details from achievements
            }))
          : [{ date: "", title: "", details: "" }]; // Provide one blank row if no achievements
      setInitialValues({
        id: editableData.id,
        researcher_name: editableData.researcher_name,
        thesis_title: editableData.thesis_title,
        other_institute: editableData.other_institute,
        month: editableData.month,
        year: editableData.year,
        iqac: editableData.iqac,
        awards: awards,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      other_institute: "",
      month: "",
      year: "",
      awards: [
        {
          date: "",
          title: "",
          details: "",
        },
      ], // Initialize with an empty award field
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      researcher_name: "",
      thesis_title: "",
      other_institute: "",
      month: "",
      year: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/thesis-examiner-universities/${id}`);
      toast.success("Present Research Scholar Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Thesis Examiner of other Universities :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <>
              <FormPage
                partialEdit={
                  initialValues.iqac == "yes" &&
                  initialValues.status == "Ongoing"
                }
                initialValues={initialValues} // Pass initial values for editing
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isEditMode={isEditing}
              />
            </>
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            editable={true}
            editCheckField="status"
            headers={headers}
            data={thesisExaminerUniversities}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ThesisExaminerUniversitie;
