import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../../Components/Utils/Input";
import CustomSelect from "../../../../../Components/Utils/CustomSelect";
import Button from "../../../../../Components/Utils/Button";
import BaseApi from "../../../../../Http/BaseApi";
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";

const FormPage = ({
  partialEdit,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const [researcherNames, setResearcherNames] = useState([]);

  const roleTypes = [
    { id: 1, name: "Supervisor" },
    { id: 2, name: "Co-Supervisor" },
  ];

  const statusTypes = [
    { id: 1, name: "Ongoing" },
    // { id: 3, name: "Discontinued" },
  ];

  const addAwardField = () => {
    const currentAwards = formikRef.current.values.awards;
    formikRef.current.setFieldValue("awards", [
      ...currentAwards,
      { date: "", title: "", details: "" },
    ]);
  };

  const removeAwardField = (index) => {
    const currentAwards = formikRef.current.values.awards;
    if (currentAwards.length > 1) {
      const updatedAwards = currentAwards.filter((_, i) => i !== index);
      formikRef.current.setFieldValue("awards", updatedAwards);
    }
  };

  useEffect(() => {
    const getResearcherNames = async () => {
      try {
        const response = await BaseApi().get(
          "/profile/search-present-research-scholars"
        );
        setResearcherNames(response.data);
      } catch (error) {
        console.error("Error fetching researcher names:", error);
      }
    };

    getResearcherNames();
  }, []);

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Present Research Scholar (Registered in DU)
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='p-5 mb-10'>
            <div className='grid grid-cols-2 gap-3 mb-3'>
              <CustomSelect
                defaultInputValue={initialValues.researcher_name}
                options={researcherNames}
                name='researcher_name'
                label={
                  <div className='flex items-center'>
                    Name of the Ph. D. Scholar
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.programme_student_id}`}
                getOptionLabel={(option) =>
                  `${option.name} (${option.unique_id})`
                }
                onChange={async (selectedOption) => {
                  console.log("Selected option:", selectedOption);
                  formikRef.current?.setFieldValue(
                    "researcher_name",
                    selectedOption.name + " (" + selectedOption.unique_id + ")"
                  );
                  formikRef.current?.setFieldValue(
                    "researcher_id",
                    selectedOption.programme_student_id
                  );
                  formikRef.current?.setFieldValue(
                    "thesis_title",
                    selectedOption.thesis_title
                  );
                }}
                readOnly={partialEdit}
              />

              <CustomSelect
                defaultInputValue={initialValues.role}
                options={roleTypes}
                name='role'
                label={
                  <div className='flex items-center'>
                    Role
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  console.log("Selected option:", selectedOption);
                  formikRef.current?.setFieldValue("role", selectedOption.name);
                }}
                readOnly={partialEdit}
              />
              <Input
                type='text'
                name='thesis_title'
                label={
                  <div className='flex items-center'>
                    Thesis Title
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                readOnly={true}
              />

              <CustomSelect
                defaultInputValue={initialValues.status}
                options={statusTypes}
                name='status'
                label={
                  <div className='flex items-center'>
                    Status
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  console.log("Selected option:", selectedOption);
                  formikRef.current?.setFieldValue(
                    "status",
                    selectedOption.name
                  );
                  if (selectedOption.name === "Status") {
                    formikRef.current?.setFieldValue("awarded_date", "");
                  }
                }}
              />
              {/* {values.status === "Completed" && (
              <Input
                type="date"
                name="awarded_date"
                label={
                  <div className="flex items-center">
                    Awarded Date
                    <span className="ml-1 text-red-600">*</span>
                  </div>
                }
              />
            )} */}
              <Input
                type='text'
                placeholder='Enter member names separated by commas'
                name='advisoryCommittee'
                label={
                  <div className='flex items-center'>
                    Members of the Research Advisory Committee
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
            </div>

            {/* Awards Fields */}

            <div className='mt-5 space-y-5'>
              <h2 className='flex items-center justify-center p-3 mt-2 font-normal text-center uppercase text-md '>
                Notable achievements by the Ph. D. Awardee
              </h2>
              {values.awards.map((award, index) => (
                <div key={index} className='flex items-center gap-3 '>
                  <div className='w-full'>
                    <Input
                      type='date'
                      name={`awards[${index}].date`}
                      label='Date of Award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='w-full'>
                    <Input
                      type='text'
                      name={`awards[${index}].title`}
                      label='Title of award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='w-full'>
                    <Input
                      type='text'
                      name={`awards[${index}].details`}
                      label='Details of Award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='flex mt-6'>
                    <div className='flex justify-end'>
                      <div className='cursor-pointer' onClick={addAwardField}>
                        <IoAddCircle className='text-green-600' size={32} />
                      </div>
                    </div>

                    <div className='flex justify-end col-span-3'>
                      {index > 0 && (
                        <div
                          className='ml-1 cursor-pointer'
                          onClick={() => removeAwardField(index)}
                        >
                          <IoRemoveCircle className='text-red-600' size={32} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='mt-10 text-sm text-red-400'>
              Click the{" "}
              <IoAddCircle className='inline text-green-600' size={32} /> Icon
              to add more achievements details.
            </div>

            <div className='flex justify-between col-span-2 mt-4'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>

              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
