import React, { useEffect, useRef, useState } from "react";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import CustomSelect from "../../Components/Utils/CustomSelect";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Alert from "../../Components/Utils/Alert";
// import { useNavigate } from "react-router-dom";
import BaseApi from "../../Http/BaseApi";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";

export default function ApplicantCountDurat() {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState([]);
  const [initialValues, setInitialValues] = useState();
  // const { message } = useSelector((state) => state.message);
  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];
  console.log("asdasdsad");
  // let formSections = useSelector((state) => state.applicants.formSections);

  //   const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      academic_session_id: null,
    });

    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });
  const handleSearch = async (searchQuery) => {
    setLoading(true);

    const counts = await BaseApi().get(`applicant-counts/durat`, {
      params: searchQuery,
    });
    setCounts(counts.data);
    setLoading(false);
  };
  let thClass =
    " text-gray-600 w-2/3 border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  //   let sl = 0;
  console.log(counts);
  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='New Applicant (DURAT) Count' />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 p-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Academic Session'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <tr>
                <th className={thClass}>DURAT</th>

                <th className={thClass}>Programme</th>
                <th className={thClass}>Start Roll No.</th>
                <th className={thClass}>End Roll No.</th>
                <th className={thClass}>Count</th>
              </tr>
              {counts?.length > 0 &&
                counts
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((subject) => {
                    return (
                      <>
                        <tr>
                          <td className={tdClass}>DURAT</td>

                          <td className={tdClass}>
                            <span
                              onDoubleClick={() => {
                                var searchParams = new URLSearchParams({
                                  form_id: 34,
                                  exam_venue_id: 17,
                                  academic_session_id:
                                    formikRef.current.values
                                      .academic_session_id,
                                  subject_id: subject.id,
                                }).toString();

                                // navigate(
                                //   `/attendance-sheet?${searchParams}`
                                // );

                                window.open(
                                  `/attendance-sheet?${searchParams}`,
                                  "_blank"
                                );
                              }}
                            >
                              {subject.name}
                            </span>
                          </td>
                          <td className={tdClass}>{subject.start_roll_no}</td>
                          <td className={tdClass}>{subject.end_roll_no}</td>

                          <td className={tdClass}>{subject.count}</td>
                        </tr>
                      </>
                    );
                  })}
              <tr>
                <th className={thClass + "  mb-5"}></th>
                <th className={thClass + "  mb-5"}></th>
                <th className={thClass + "  mb-5"}></th>

                <th className={thClass + "  mb-5"}>Total</th>
                <th className={thClass + " mb-5"}>
                  {counts?.reduce(
                    (partialSum, subject) => partialSum + subject.count,
                    0
                  )}
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
