import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaCalendar, FaUsers } from "react-icons/fa";
import { FaCalendarDay, FaCalendarDays } from "react-icons/fa6";

const headers = {
  name: { label: "Name of the Professional Body", path: "name" },
  member_type: { label: "Member Type", path: "member_type" },
  month: { label: "Month of Joining", path: "monthName" },
  year: { label: "Year of Joining", path: "year" },
  till_month: { label: "Till Month", path: "tillMonthName" },
  till_year: { label: "Till Year", path: "till_year" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatMonth = (month) => {
  const monthMap = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  return monthMap[month] || 0;
};

const BodiesOrganization = ({ view }) => {
  const [bodiesOrganizations, setBodiesOrganizations] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/bodies-organizations");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredOrganizations = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year and month in descending order
      // const sortedOrganizations = filteredOrganizations.sort((a, b) => {
      //   const yearA = parseInt(getValueByPath(a, headers.year.path), 10);
      //   const yearB = parseInt(getValueByPath(b, headers.year.path), 10);
      //   const monthA = formatMonth(getValueByPath(a, headers.month.path));
      //   const monthB = formatMonth(getValueByPath(b, headers.month.path));

      //   if (yearA !== yearB) return yearB - yearA; // Sort by year
      //   return monthB - monthA; // Sort by month if years are equal
      // });

      setBodiesOrganizations(filteredOrganizations);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(bodiesOrganizations.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderBodiesOrganization = (experience) => {
    const name = getValueByPath(experience, headers.name.path);
    const memberType = getValueByPath(experience, headers.member_type.path);
    const month = getValueByPath(experience, headers.month.path);
    const year = getValueByPath(experience, headers.year.path);
    const tillMonth = getValueByPath(experience, headers.till_month.path);
    const tillYear = getValueByPath(experience, headers.till_year.path);

    // Check if the required data is available
    if (!name || !memberType || !month || !year) {
      return null; // Return null if any required field is missing
    }

    return (
      <div className='items-start px-2 py-1 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <span className='px-3 py-1 mb-1 text-sm font-normal text-gray-500 bg-green-100 rounded-full'>
            {memberType}
          </span>
          {month && year && (
            <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Since {month} {year}
            </span>
          )}

          {/* Conditionally render tillMonth and tillYear only if both are available */}
          {tillMonth && tillYear && (
            <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Till: {tillMonth} {tillYear}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <FaUsers className='mt-1' />
          Memberships in Professional Bodies/Organization
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {bodiesOrganizations.length}
        </span>
      </div>

      {bodiesOrganizations.length ? (
        <div>
          {bodiesOrganizations
            .slice(0, visibleCount)
            .map((bodiesOrganization, index) => (
              <div key={index}>
                {renderBodiesOrganization(bodiesOrganization)}
              </div>
            ))}
          {bodiesOrganizations.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default BodiesOrganization;
