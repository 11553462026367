import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Consultancy/External Service
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='grid grid-cols-2 gap-3 p-5 '>
          <Input
            type='text'
            name='name'
            label={
              <div className='flex items-center'>
                Title of the Consultancy/External Service
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='text'
            name='organization_name'
            label={
              <div className='flex items-center'>
                Organization Name
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='textarea'
            name='details'
            label={
              <div className='flex items-center'>
                Brief Description
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='text'
            name='role'
            label={
              <div className='flex items-center'>
                Your Role in the Consultancy
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='text'
            name='amount'
            placeholder='Please enter 0 if no amount earned'
            label={
              <div className='flex items-center'>
                Amount Earned through Consultancy
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='date'
            name='start_date'
            label={
              <div className='flex items-center'>
                Start Date
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input type='date' name='end_date' label='End Date' />

          <div className='flex justify-between col-span-2 mt-10'>
            <button
              className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
              onClick={onCancel}
            >
              <XIcon className='w-5 h-5 mr-2' /> Cancel
            </button>
            {isEditMode ? (
              <Button type='submit' value='Update' icon={"SaveIcon"} />
            ) : (
              <Button type='submit' value='Save' icon={"SaveIcon"} />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FormPage;
