import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import BaseApi from "../../Http/BaseApi";
import {
  FaSpinner,
  FaEdit,
  FaTrash,
  FaPlus,
  FaGlobe,
  FaLock,
  FaCopy,
  FaFilter,
  FaUndo,
} from "react-icons/fa";
import moment from "moment";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";
import { Menu } from "@headlessui/react";
import { FaEye } from "react-icons/fa6";

export default function PagesIndex() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    query: "",
    type: "published",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSpecialPagesModal, setShowSpecialPagesModal] = useState(false); // Modal state for special pages

  const specialPages = [
    {
      id: 1,
      title: "Other Offices and Branches",
      slug: "other-offices-and-branches",
      type: "special",
      content: "profile",
    },
  ];

  // Fetch Pages with pagination and filters
  const fetchPages = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
      const response = await BaseApi().get(
        `/website/pages?page=${page}&query=${filters.query || ""}&type=${
          filters.type || ""
        }`
      );
      setPages(response.data.data);

      setTotalPages(response.data.last_page);
    } catch (error) {
      LaravelValidationError(error);
      console.error("Error fetching pages", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle URL search params for   query
  useEffect(() => {
    const query = searchParams.get("query") || "";
    const type = searchParams.get("type") || "";

    setFilters({
      query,
      type,
    });
    fetchPages(currentPage, { query, type });
  }, [currentPage, searchParams]);

  // Handle Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (dataId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      await BaseApi().delete("/website/pages/" + dataId);
      const currentPages = pages.filter((p) => p.id != dataId);
      setPages(currentPages);
    } catch (error) {
      LaravelValidationError(error);
    }
  };

  return (
    <div className='container min-h-screen px-5 mx-auto mt-5 rounded-md shadow-md'>
      <Navigations></Navigations>
      <div className='flex justify-between'>
        <PageTitle name='List of Pages' />
        <div className='flex gap-2'>
          <button
            type='button'
            onClick={() => {
              setShowSpecialPagesModal(true);
            }}
            className='flex items-center px-4 py-2 mt-1 space-x-1 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
          >
            <FaEye className='inline w-4' />
            <span>Special Pages</span>
          </button>

          <Link
            to='/website/pages/create'
            className='flex items-center px-4 py-2 mt-1 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
          >
            <FaPlus className='inline w-4' /> Create New
          </Link>
        </div>
      </div>

      {/* Filter Form */}
      <Formik
        initialValues={{
          query: searchParams.get("query") || "",
          type: searchParams.get("type") || "",
        }}
        enableReinitialize
        onSubmit={(values) => {
          setSearchParams({
            query: values.query,
            type: values.type,
          });
          setCurrentPage(1); // Reset to first page after applying filters
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <Form
            className='grid items-end grid-cols-4 gap-3 mb-4 space-x-4 bg-white '
            onSubmit={handleSubmit}
          >
            {/* Search Input */}
            <div className='flex-grow'>
              <label className='block text-sm font-medium text-gray-700'>
                Search
              </label>
              <Field
                type='text'
                name='query'
                placeholder='Search pages...'
                className='w-full p-2 border rounded-md'
                onChange={handleChange}
              />
            </div>

            <div className='flex-grow'>
              <label className='block text-sm font-medium text-gray-700'>
                Type
              </label>
              <Field
                as='select'
                name='type'
                className='w-full p-2 border rounded-md'
                onChange={handleChange}
              >
                <option value=''>All</option>
                <option value='draft'>Draft</option>
                <option value='published'>Published</option>
              </Field>
            </div>

            {/* Submit Button */}
            <div className='flex items-end gap-4'>
              <button
                type='submit'
                className='px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700'
              >
                <FaFilter className='inline mr-2' />
                Filter
              </button>
              <button
                type='button'
                onClick={() => {
                  setSearchParams({});
                  setCurrentPage(1);
                }}
                className='px-4 py-2 text-white bg-gray-600 rounded-md hover:bg-gray-700'
              >
                <FaUndo className='inline mr-2' />
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Pages Table */}
      <div className='bg-white '>
        {loading ? (
          <div className='flex justify-center'>
            <FaSpinner className='text-3xl text-blue-600 animate-spin' />
          </div>
        ) : (
          <>
            <table className='w-full text-xs border-collapse table-auto'>
              <thead>
                <tr className='text-left border-b'>
                  <th className='px-4 py-2'>#</th>
                  <th className='px-4 py-2'>Title</th>
                  <th className='px-4 py-2'>Subtitle</th>

                  <th className='px-4 py-2'>Published</th>

                  <th className='px-4 py-2'>Updated On</th>
                  <th className='px-4 py-2'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {pages.length > 0 ? (
                  pages.map((page, index) => (
                    <tr
                      key={page.id}
                      className={`border-b hover:bg-gray-100 ${
                        page.status == "draft" ? "opacity-50" : "opacity-100"
                      }`}
                    >
                      <td className='px-4 py-2'>
                        {index + 1 + (currentPage - 1) * 10}
                      </td>
                      <td className='px-4 py-2'>
                        <Link
                          to={`/website/pages/${page.id}`}
                          className='text-blue-500 hover:underline'
                        >
                          {page.title}
                        </Link>
                      </td>
                      <td className='px-4 py-2'>{page.subtitle}</td>

                      <td className='px-4 py-2'>
                        {page.status === "published"
                          ? moment(page.published_date).format(
                              "DD MMM YYYY hh:mm A"
                            )
                          : "Draft"}
                      </td>

                      <td className='px-4 py-2'>
                        {moment(page.updated_at ?? page.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </td>
                      <td className='flex items-center px-4 py-2 space-x-2'>
                        <div className='relative'>
                          <Menu
                            as='div'
                            className='relative inline-block text-left'
                          >
                            <Menu.Button className='flex gap-2 px-4 py-2 text-blue-500 hover:text-blue-700'>
                              Actions
                              <span aria-hidden className=''>
                                ▼
                              </span>
                            </Menu.Button>

                            <Menu.Items className='absolute right-0 z-10 w-48 mt-2 bg-white border border-gray-300 rounded-md shadow-lg focus:outline-none'>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    target='_blank'
                                    href={`https://dibru.ac.in/${page.slug}`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                    rel='noreferrer'
                                  >
                                    <FaEye />
                                    <span>View on website</span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={`/website/pages/create?edit_id=${page.id}`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaEdit />
                                    <span>Edit</span>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={`/website/pages/create?edit_id=${page.id}&duplicate=true`}
                                    className={`flex items-center px-4 py-2 space-x-2 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaCopy />
                                    <span>Duplicate</span>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDelete(page.id)}
                                    className={`flex items-center px-4 py-2 space-x-2 text-red-500 ${
                                      active ? "bg-gray-100" : ""
                                    }`}
                                  >
                                    <FaTrash />
                                    <span>Delete</span>
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='5' className='py-4 text-center'>
                      No pages found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className='flex justify-between mt-10'>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
                >
                  Previous
                </button>

                <span className='text-sm'>
                  Page {currentPage} of {totalPages}
                </span>

                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}

        {showSpecialPagesModal && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='relative w-full max-w-4xl p-6 bg-white rounded-lg'>
              <button
                className='absolute text-gray-500 top-2 right-2 hover:text-gray-700'
                onClick={() => setShowSpecialPagesModal(false)}
              >
                ✕
              </button>
              <h2 className='mb-4 text-lg font-semibold'>Special Pages</h2>
              <table className='w-full text-sm border-collapse table-auto'>
                <thead>
                  <tr className='border-b'>
                    <th className='px-4 py-2 text-left'>#</th>
                    <th className='px-4 py-2 text-left'>Title</th>
                    <th className='px-4 py-2 text-left'>Slug</th>
                    <th className='px-4 py-2 text-left'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {specialPages.map((page, index) => {
                    let editUrl = "";
                    if (page.content === "profile") {
                      editUrl =
                        "/website/department-profile/faculty-members?page_slug=" +
                        page.slug;
                    }
                    return (
                      <tr key={page.id} className='border-b hover:bg-gray-50'>
                        <td className='px-4 py-2'>{index + 1}</td>
                        <td className='px-4 py-2'>
                          <a
                            className='text-blue-700'
                            target='_blank'
                            href={`https://dibru.ac.in/${page.slug}`}
                            rel='noreferrer'
                          >
                            {page.title}
                          </a>
                        </td>
                        <td className='px-4 py-2'>{page.slug}</td>
                        <td className='px-4 py-2'>
                          <Link
                            to={editUrl}
                            className='text-blue-500 hover:underline'
                          >
                            <FaEdit className='inline mr-2' />
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
