import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  status: { label: "Status", path: "status" },
  advisoryCommittee: {
    label: "Members of the Research Advisory Committee",
    path: "advisoryCommittee",
  },
  date: {
    label: "Date of Award",
    path: "date",
  },
  title: {
    label: "Title of award",
    path: "title",
  },
  details: {
    label: "Details of Award",
    path: "details",
  },
};

const PresentResearchScholar = () => {
  const [presentResearchScholars, setPresentResearchScholars] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/present-research-scholars"
      );
      setPresentResearchScholars(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Present Research Scholar
      </h2>
      <TableViewProfile
        headers={headers}
        data={presentResearchScholars}
        type="present-research-scholars"
        setData={setPresentResearchScholars}
      />
    </div>
  );
};

export default PresentResearchScholar;
