import React, { useEffect, useState } from "react";
import {
  FaAward,
  FaClipboardList,
  FaLink,
  FaUserGraduate,
} from "react-icons/fa6";
import {
  FaCalendar,
  FaCalendarDay,
  FaRegThumbsUp,
  FaUniversity,
} from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";
import { RiGraduationCapFill } from "react-icons/ri";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  institute: {
    label: "Ph. D. Scholar registered in DU/Other Institute",
    path: "institute",
  },
  other_institute: { label: "Other Institute", path: "other_institute" },
  status_designation: {
    label: "Present Status/Designation with name of the Organization",
    path: "status_designation",
  },
  status: { label: "Status", path: "status" },
  month: {
    label: "Awarded Month",
    path: "monthName",
  },
  year: {
    label: "Awarded Year",
    path: "year",
  },
  shodhganga_link: {
    label: "Shodhganga link of the thesis",
    path: "shodhganga_link",
  },
  achievements: {
    label: "Achievements",
    path: "achievements",
    isArray: true, // Mark this field as an array
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const PhdAwardee = ({ view }) => {
  const [phdAwardees, setPhdAwardees] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/phd-awardees");
      const filterField = view === "iqac" ? "iqac" : "website";
      setPhdAwardees(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(phdAwardees.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderPhdAwardee = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const role = getValueByPath(guidance, headers.role.path);
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);
    const shodhgangaLink = getValueByPath(
      guidance,
      headers.shodhganga_link.path
    );
    const institute = getValueByPath(guidance, headers.institute.path);
    const otherInstitute = getValueByPath(
      guidance,
      headers.other_institute.path
    );
    const achievements = getValueByPath(guidance, headers.achievements.path);

    return (
      <div className="items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-1">
          {role && (
            <h3 className="text-lg font-medium text-blue-900">Role: {role}</h3>
          )}
          <p className=" items-center gap-2">
            {scholarName && (
              <span className="text-green-600 flex text-sm gap-1">
                <FaUserGraduate className="inline flex-shrink-0 mt-1" />{" "}
                {scholarName}
              </span>
            )}
            {thesisTitle && (
              <div className="flex text-sm gap-1">
                <FaClipboardList className="text-yellow-500 flex-shrink-0 mt-1" />
                <span className="text-gray-800">{thesisTitle}</span>
              </div>
            )}
          </p>
          <div className="flex items-center gap-2">
            <span className="flex items-center text-sm gap-1">
              <FaRegThumbsUp className="text-yellow-500" />
              Awarded
            </span>
            <p className="flex items-center text-sm gap-1">
              <FaUniversity className="text-yellow-500" />
              <span className="text-gray-800 ">Dibrugarh University</span>
            </p>
          </div>
          {shodhgangaLink && (
            <a href={shodhgangaLink} className="flex text-sm gap-1 ">
              <FaLink className="text-blue-600 flex-shrink-0 mt-1" />
              Shodhganga link: {shodhgangaLink}
            </a>
          )}
          {achievements?.filter(
            (achievement) => achievement.award_date || achievement.title
          ).length > 0 && (
            <>
              <h4 className="text-sm font-medium text-blue-500">
                Notable Achievements
              </h4>
              {achievements
                .filter(
                  (achievement) => achievement.award_date || achievement.title
                )
                .map((achievement, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 mr-2 text-xs text-gray-800 bg-green-100 rounded-md"
                  >
                    {achievement.award_date && (
                      <>
                        <FaCalendarDay className="inline w-3 text-yellow-500 mb-1" />{" "}
                        {achievement.award_date}{" "}
                      </>
                    )}
                    {achievement.title && (
                      <>
                        <FaAward className="inline w-4 text-yellow-500 mb-1" />{" "}
                        {achievement.title}
                      </>
                    )}
                  </span>
                ))}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between px-2 py-1 mb-3 border-b">
        <h2 className="flex gap-2 justify-start text-xl font-medium text-blue-900">
          <RiGraduationCapFill className="mt-1" />
          Ph. D. Awardee
        </h2>
        <span className="px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
          {phdAwardees.length}
        </span>
      </div>
      {phdAwardees.length ? (
        <div>
          {phdAwardees.slice(0, visibleCount).map((guidance, index) => (
            <div key={index}>{renderPhdAwardee(guidance)}</div>
          ))}
          {phdAwardees.length > 5 && (
            <div className="flex justify-center mt-2">
              <button
                className="text-sm text-blue-500 rounded hover:text-blue-900"
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default PhdAwardee;
