import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Book Chapter",
    path: "title",
  },
  book_name: {
    label: "Book Name",
    path: "book_name",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publication",
    path: "monthName",
  },
  year: {
    label: "Year of Publication",
    path: "year",
  },
  author_names: {
    label: "Author Names: (As per Book)",
    path: "author_names",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  editors: {
    label: "Editor(s)",
    path: "editors",
  },
};

const BookChapter = () => {
  const [bookChapters, setBookChapters] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/book-chapters");
      setBookChapters(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Books Chapters
      </h2>
      <TableViewProfile
        headers={headers}
        data={bookChapters}
        type="book-chapters"
        setData={setBookChapters}
      />
    </div>
  );
};

export default BookChapter;
