import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaUsers, FaCalendar, FaCalendarDays } from "react-icons/fa6";
import { FaCalendarDay } from "react-icons/fa";

const headers = {
  name: { label: "Name of the Committee", path: "name" },
  member_type: { label: "Member Type", path: "member_type" },
  month: { label: "Month of Membership", path: "month" },
  year: { label: "Year of Membership", path: "year" },
  till_month: { label: "Till Month", path: "till_month" },
  till_year: { label: "Till Year", path: "till_year" },
};

// A mapping of month numbers to month names
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Committee = ({ view }) => {
  const [committees, setCommittees] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/committees");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredCommittees = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year (descending) and month (descending within year)
      // const sortedCommittees = filteredCommittees.sort((a, b) => {
      //   const yearA = parseInt(getValueByPath(a, headers.year.path), 10);
      //   const yearB = parseInt(getValueByPath(b, headers.year.path), 10);
      //   const monthA = getValueByPath(a, headers.month.path);
      //   const monthB = getValueByPath(b, headers.month.path);

      //   // Compare years first
      //   if (yearA !== yearB) return yearB - yearA;

      //   // Compare months numerically (as they are numbers)
      //   return monthB - monthA;
      // });

      setCommittees(filteredCommittees);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(committees.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderCommittees = (committee) => {
    const name = getValueByPath(committee, headers.name.path);
    const memberType = getValueByPath(committee, headers.member_type.path);
    const month = getValueByPath(committee, headers.month.path);
    const year = getValueByPath(committee, headers.year.path);
    const tillMonth = getValueByPath(committee, headers.till_month.path);
    const tillYear = getValueByPath(committee, headers.till_year.path);

    // Check if the required data is available and valid
    if (!name || !memberType || !month || !year) {
      return null; // Return null if any required field is missing
    }

    // Convert month number to month name (1-12 to "January"-"December")
    const monthName = monthNames[month - 1]; // Adjust for zero-based index
    // const tillMonthName = monthNames[month - 1]; // Adjust for zero-based index

    return (
      <div className='items-start px-2 py-1 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <span className='px-3 py-1 mb-1 text-sm font-normal text-gray-500 bg-green-100 rounded-full'>
            {memberType}
          </span>

          {month && year && (
            <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
              <FaCalendarDays className='text-yellow-500' />
              Since {monthName} {year}
            </span>
          )}
          {/* Conditionally render tillMonth and tillYear only if both are available */}
          {tillMonth && tillYear && (
            <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
              <FaCalendarDay className='text-yellow-500' />
              Till: {monthNames[tillMonth - 1]} {tillYear}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-2 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <FaUsers className='mt-1' />
          Memberships in Significant Committees
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {committees.length}
        </span>
      </div>

      {committees.length ? (
        <div>
          {committees.slice(0, visibleCount).map((committee, index) => (
            <div key={index}>{renderCommittees(committee)}</div>
          ))}
          {committees.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Committee;
