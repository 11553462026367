import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const WorkshopTraining = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [workshopTrainings, setWorkshopTrainings] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    description: "",
    organized_by: "",
    held_at: "",
    programme_type: "",
    level: "",
    role: "",
    start_date: "",
    end_date: "",
  });
  const headers = {
    name: { label: "Name of the workshop/ training programme", path: "name" },
    description: { label: "Brief Description", path: "description" },
    organized_by: { label: " Organized by", path: "organized_by" },
    held_at: { label: "Held at", path: "held_at" },
    programme_type: { label: "Type of Programme", path: "programme_type" },
    level: { label: "Level", path: "level" },
    role: { label: "Your Role", path: "role" },
    start_date: { label: "Start Date", path: "start_date" },
    end_date: { label: "End Date", path: "end_date" },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    description: Yup.string().required("This field is required!"),
    organized_by: Yup.string().required("This field is required!"),
    held_at: Yup.string().required("This field is required!"),
    programme_type: Yup.string().required("This field is required!"),
    // year: Yup.number()
    //   .typeError("Year must be a valid year")
    //   .required("This field is required!")
    //   .min(1980, "Year must be at least 1980")
    //   .max(new Date().getFullYear(), `Year can't be in the future`),
    role: Yup.string().required("This field is required!"),
    level: Yup.string().required("This field is required!"),
    start_date: Yup.string().required("This field is required!"),
    end_date: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/workshop-trainings");
      setWorkshopTrainings(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/workshop-trainings/${editId}`, values);
        toast.success(`Workshop Training Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/workshop-trainings", values);
        toast.success(`Workshop Training Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = workshopTrainings.find(
        (workshopTraining) => workshopTraining.id === id
      );
      setInitialValues({
        id: editableData.id,
        name: editableData.name,
        description: editableData.description,
        organized_by: editableData.organized_by,
        held_at: editableData.held_at,
        programme_type: editableData.programme_type,
        level: editableData.level,
        role: editableData.role,
        start_date: editableData.start_date,
        end_date: editableData.end_date,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      name: "",
      description: "",
      organized_by: "",
      held_at: "",
      programme_type: "",
      level: "",
      role: "",
      start_date: "",
      end_date: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      name: "",
      description: "",
      organized_by: "",
      held_at: "",
      programme_type: "",
      level: "",
      role: "",
      start_date: "",
      end_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/workshop-trainings/${id}`);
      toast.success(`Data Deleted`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Participation in Programme :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={workshopTrainings}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default WorkshopTraining;
