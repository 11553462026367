import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Title of the Activity", path: "name" },
  nature: { label: "Nature of the Activity", path: "nature" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  place: { label: "Place of Activity", path: "place" },
};

const CorporateLife = () => {
  const [corporateLives, setCorporateLives] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/corporate-lives");
      setCorporateLives(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Contribution to Corporate Life
      </h2>
      <TableViewProfile
        headers={headers}
        data={corporateLives}
        type='corporate-lives'
        setData={setCorporateLives}
      />
    </div>
  );
};

export default CorporateLife;
