import React, { useState, useEffect } from "react";
import {
  FaArrowRight,
  FaCheck,
  FaEye,
  FaFile,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFileVideo,
  FaFileWord,
  FaPlus,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select"; // Import react-select for multiselect
import imageCompression from "browser-image-compression"; // Import image compression library

// Validation schema for Formik
const uploadValidationSchema = Yup.object().shape({
  files: Yup.mixed()
    .required("At least one file is required")
    .test("file-count", "You can only upload up to 30 files", (value) =>
      value ? value.length <= 30 : true
    ),
});
const allowedFileTypes = [
  "image/*", // Allow all image types
  "application/pdf", // PDF files
  "application/msword", // Older Word files (DOC)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Newer Word files (DOCX)
  "video/*", // Allow all video types
  "application/vnd.ms-excel", // Older Excel files (XLS)
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Newer Excel files (XLSX)
  "text/csv", // CSV files
];

// Options for the multiselect
const typeOptions = [
  { value: "image", label: "Images" },
  { value: "pdf", label: "PDF" },
  { value: "video", label: "Video" },
  { value: "doc", label: "Documents" },
  { value: "excel", label: "Excel" },
  { value: "csv", label: "CSV" },
];

const defaultAllowedTypes = ["image", "pdf", "video", "doc", "excel", "csv"]; // Default allowed types

// Function to determine file type and return an appropriate preview
// Function to determine file type and return an appropriate preview
const renderFilePreview = (file, isModal = false) => {
  const fileType = file.mime_type;

  // Different dimensions for modal and grid
  const imageHeight = isModal ? "h-64" : "h-28";
  const iconHeight = isModal ? "h-32" : "h-10";
  const iconSize = isModal ? "text-8xl" : "text-6xl";

  if (fileType.startsWith("image/")) {
    return (
      <img
        src={`${file.file}`}
        alt={file.alt_text}
        className={`${
          isModal ? "object-contain" : "object-cover"
        } w-full mb-2 rounded ${imageHeight}`}
      />
    );
  } else if (fileType === "application/pdf") {
    return (
      <FaFilePdf
        className={`object-cover w-full mb-2 ${iconSize} text-red-500 ${iconHeight}`}
      />
    );
  } else if (
    fileType === "application/msword" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return (
      <FaFileWord
        className={`object-cover w-full mb-2 ${iconSize} text-blue-500 ${iconHeight}`}
      />
    );
  } else if (
    fileType === "application/vnd.ms-excel" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return (
      <FaFileExcel
        className={`object-cover w-full mb-2 ${iconSize} text-green-500 ${iconHeight}`}
      />
    );
  } else if (fileType === "text/csv") {
    return (
      <FaFileCsv
        className={`object-cover w-full mb-2 ${iconSize} text-green-500 ${iconHeight}`}
      />
    );
  } else if (fileType.startsWith("video/")) {
    return (
      <FaFileVideo
        className={`object-cover w-full mb-2 ${iconSize} text-green-500 ${iconHeight}`}
      />
    );
  } else {
    return (
      <FaFile
        className={`object-cover w-full mb-2 ${iconSize} text-gray-500 ${iconHeight}`}
      />
    );
  }
};

export default function MediaManagement({
  onSelect = null,
  types = "",
  setIsMediaManagerOpen = null,
  onMultiSelect = null,
}) {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // New state for media modal
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);

  // Function to open the media modal with the selected file
  const handleOpenMediaModal = (file) => {
    setSelectedMediaFile(file);
    setIsMediaModalOpen(true);
  };

  // Function to close the media modal
  const handleCloseMediaModal = () => {
    setIsMediaModalOpen(false);
    setSelectedMediaFile(null);
  };

  // Function to delete the media from the modal
  const handleDeleteFromModal = async (file) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this item?`
    );
    if (confirmDelete) {
      try {
        await BaseApi().delete(`/website/media/${file.id}`);
        fetchMedia(currentPage); // Re-fetch the media after delete to update the list
        handleCloseMediaModal();
      } catch (error) {
        console.error("Error deleting media file", error);
      }
    }
  };

  // Function to copy the media URL
  const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      toast.success("URL copied to clipboard");
    });
    if (onSelect) {
      onSelect(url);
    }
    if (setIsMediaManagerOpen) {
      setIsMediaManagerOpen(false);
    }
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [lastPage, setLastPage] = useState(1); // Total pages available

  // const allowedTypes = types.split(",").map((type) => type.trim());

  const [allowedTypes, setAllowedTypes] = useState(
    types ? types.split(",").map((type) => type.trim()) : defaultAllowedTypes
  );

  // Fetch media files from backend with pagination
  const fetchMedia = async (page = 1) => {
    try {
      const response = await BaseApi().get(
        `/website/media?page=${page}&types=${allowedTypes.join(",")}`
      );
      setMediaFiles(response.data.data); // Update media files for current page
      setCurrentPage(response.data.current_page); // Update current page
      setLastPage(response.data.last_page); // Update last page
    } catch (error) {
      console.error("Error fetching media files", error);
    }
  };

  // On component mount, fetch media files
  useEffect(() => {
    fetchMedia(currentPage);
  }, [currentPage, allowedTypes]); // Re-fetch when currentPage changes

  // Handle file upload one by one
  const handleFileUpload = async (values, { setSubmitting, resetForm }) => {
    const files = values.files;
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i].file);
      formData.append("alt_text", files[i].alt_text || "");

      try {
        const response = await BaseApi().post("/website/media", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [files[i].file.name]: percentCompleted, // Track progress for each file
            }));
          },
        });
      } catch (error) {
        console.error("Error uploading file", error);
      }
    }
    // After uploading, fetch the first page of media files
    fetchMedia(1); // Fetch the first page to refresh the media grid
    resetForm();
    setIsModalOpen(false);
    setSubmitting(false);
    setUploadProgress({});
  };

  // Compression options
  const imageCompressionOptions = {
    maxSizeMB: 1, // Maximum size in MB (adjust as needed)
    maxWidthOrHeight: 1000, // Maximum width or height (adjust as needed)
    useWebWorker: true,
  };

  // Handle file selection and previews
  const handleFileChange = async (event, setFieldValue) => {
    const files = Array.from(event.target.files);

    const processedFiles = await Promise.all(
      files.map(async (file) => {
        const validTypes = allowedFileTypes.some((type) =>
          file.type.match(type)
        );
        if (!validTypes) {
          toast.error(`Invalid file type: ${file.name}`);
          return null;
        }

        // Compress and resize only if the file is an image
        let processedFile = file;
        // if (file.type.startsWith("image/")) {
        if (file.type.startsWith("image/") && file.type !== "image/gif") {
          try {
            processedFile = await imageCompression(
              file,
              imageCompressionOptions
            );
          } catch (error) {
            console.error("Error compressing image:", error);
            toast.error("Failed to process the image. Please try again.");
          }
        }

        return { file: processedFile, alt_text: file.name };
      })
    );

    // Filter out any null values (invalid file types)
    const selectedFilesArray = processedFiles.filter(
      (fileObj) => fileObj !== null
    );

    setSelectedFiles(selectedFilesArray);
    setFieldValue("files", selectedFilesArray);
  };

  // Handle individual media selection
  const handleSelect = (media) => {
    if (selectedMedia.includes(media)) {
      setSelectedMedia(selectedMedia.filter((item) => item !== media));
    } else {
      setSelectedMedia([...selectedMedia, media]);
    }
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedMedia([]); // Deselect all
    } else {
      setSelectedMedia(mediaFiles); // Select all media
    }
    setSelectAll(!selectAll);
  };

  // Handle delete selected media
  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedMedia.length} items?`
    );
    if (confirmDelete) {
      try {
        await Promise.all(
          selectedMedia.map(async (media) => {
            await BaseApi().delete(`/website/media/${media.id}`);
          })
        );
        fetchMedia(currentPage); // Re-fetch the media after delete to update list
        setSelectedMedia([]); // Clear selected items
        setSelectAll(false); // Reset select all
      } catch (error) {
        console.error("Error deleting media files", error);
      }
    }
  };

  const copySelectedItems = async () => {
    try {
      // make comma seperated urls from selectedMedia
      let urls = selectedMedia.map((media) => media.file);
      onMultiSelect(urls);

      setSelectedMedia([]); // Clear selected items
      setSelectAll(false); // Reset select all
    } catch (error) {
      console.error("Error deleting media files", error);
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className='container py-8 mx-auto'>
      {/* Heading and Upload Button */}
      <div className='flex items-center justify-between mb-6'>
        <h2 className='text-2xl font-bold'>Media Library</h2>
        <div className='flex items-center justify-end flex-grow space-x-4'>
          <Select
            options={typeOptions}
            isMulti
            defaultValue={typeOptions.filter((opt) =>
              allowedTypes.includes(opt.value)
            )}
            onChange={(selected) => {
              const selectedValues = selected.map((opt) => opt.value);
              setAllowedTypes(selectedValues);
              setCurrentPage(1); // Reset to first page after changing filter
            }}
            className='flex items-center px-4 py-2 '
            placeholder='Filter by file type'
          />
          <button
            onClick={() => {
              setUploadProgress({});
              setSelectedFiles([]);
              setIsModalOpen(true);
            }}
            className='flex items-center px-4 py-2 text-white transition bg-blue-600 rounded-md hover:bg-blue-700'
          >
            <FaPlus className='mr-2' /> Upload New
          </button>

          {selectedMedia.length > 0 && (
            <button
              onClick={handleDelete}
              className='flex items-center px-4 py-2 text-white transition bg-red-600 rounded-md hover:bg-red-700'
            >
              <FaTrash className='mr-2' /> Delete Selected
            </button>
          )}
        </div>
      </div>

      {/* Select All Checkbox */}
      {mediaFiles.length > 0 && (
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={selectAll}
              onChange={handleSelectAll}
              className='mr-2'
            />
            Select All
          </label>
        </div>
      )}

      {/* Media Grid */}
      <div className='grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-5'>
        {mediaFiles.length > 0 ? (
          mediaFiles.map((file) => (
            <div key={file.id} className='relative p-2 border rounded-md group'>
              <div className='flex items-center h-32'>
                {renderFilePreview(file)}
              </div>
              {/* Display the preview based on file type */}
              <div className='grid grid-cols-3'>
                <p className='col-span-2 text-sm text-gray-700 truncate'>
                  {file.alt_text}
                </p>
                <p className='text-xs text-right text-gray-700 truncate'>
                  {moment(file.created_at).format("DD MMM YYYY")} <br />
                  {moment(file.created_at).format("HH:mm A")}
                </p>
              </div>
              {/* Checkbox for selection - always visible if checked, shown on hover otherwise */}
              <input
                type='checkbox'
                checked={selectedMedia.includes(file)}
                onChange={() => handleSelect(file)}
                className={`absolute top-2 right-2 transition-opacity ${
                  selectedMedia.includes(file)
                    ? "opacity-100"
                    : "opacity-0 group-hover:opacity-100"
                }`}
              />
              {/* Copy URL Button - shown on hover */}
              <button
                onClick={() => handleCopyUrl(file.file)}
                className='absolute flex items-center px-2 py-1 text-sm text-white transition-opacity bg-gray-800 rounded-md opacity-0 bottom-2 left-2 group-hover:opacity-100 hover:bg-gray-600'
              >
                <FaCopy className='mr-1' /> Copy URL
              </button>
              {/* View Media Button - shown on hover */}
              <button
                onClick={() => handleOpenMediaModal(file)}
                className='absolute flex items-center px-2 py-1 text-sm text-white transition-opacity bg-gray-800 rounded-md opacity-0 bottom-2 right-2 group-hover:opacity-100 hover:bg-gray-600'
              >
                <FaEye className='mr-1' /> View
              </button>
            </div>
          ))
        ) : (
          <p className='col-span-4 text-gray-500'>
            No media files uploaded yet.
          </p>
        )}
      </div>
      <div className='absolute mt-5 bottom-5 right-5'>
        {selectedMedia.length > 0 && (
          <button
            type='button'
            onClick={copySelectedItems}
            className='flex items-center px-4 py-2 text-white transition bg-green-600 rounded-md hover:bg-green-700'
          >
            <FaArrowRight className='mr-2' /> Next
          </button>
        )}
      </div>

      {/* Pagination Controls */}
      {lastPage > 1 && (
        <div className='flex justify-between mt-6'>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Previous
          </button>
          <p className='text-sm'>
            Page {currentPage} of {lastPage}
          </p>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === lastPage}
            className='px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Next
          </button>
        </div>
      )}

      {/* Upload Modal */}
      {isModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative flex flex-col w-full max-w-lg bg-white rounded-md h-[90vh]'>
            <button
              onClick={() => setIsModalOpen(false)}
              className='absolute text-2xl text-black top-2 right-2'
            >
              <FaTimes />
            </button>
            <h3 className='p-6 mb-4 text-xl font-bold'>Upload New Media</h3>

            {/* Scrollable middle part */}
            <div className='flex-grow p-6 mb-20 overflow-y-auto '>
              {/* Formik Form */}
              <Formik
                initialValues={{ files: [], alt_text: "" }}
                validationSchema={uploadValidationSchema}
                onSubmit={handleFileUpload}
              >
                {({
                  setFieldValue,
                  isSubmitting, // Now we can access `isSubmitting` here
                  handleSubmit,
                  errors,
                  touched,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* File Input */}
                    <div className='mb-4'>
                      <label className='block mb-1 text-sm font-medium text-gray-700'>
                        Select Files (up to 30)
                      </label>
                      <input
                        type='file'
                        multiple
                        className='w-full p-2 border rounded-md'
                        accept='image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv' // Allow images, PDFs, DOCs, videos, Excel, and CSV
                        onChange={(event) =>
                          handleFileChange(event, setFieldValue)
                        }
                      />

                      {touched.files && errors.files && (
                        <div className='text-sm text-red-500'>
                          {errors.files}
                        </div>
                      )}
                    </div>

                    {/* File Previews */}
                    <div className='space-y-4'>
                      {selectedFiles.map((fileObj, index) => (
                        <div
                          key={index}
                          className='flex items-center space-x-4'
                        >
                          {/* Thumbnail/Icon 30% */}
                          <div className='flex items-center w-1/4'>
                            {fileObj.file.type.startsWith("image/") ? (
                              <img
                                src={URL.createObjectURL(fileObj.file)}
                                alt='Preview'
                                className='object-cover w-16 h-16 border rounded'
                              />
                            ) : (
                              renderFilePreview({
                                mime_type: fileObj.file.type,
                              })
                            )}
                          </div>

                          {/* File Name & Alt Text Input 60% */}
                          <div className='flex flex-col w-2/4 space-y-2'>
                            <label className='overflow-hidden text-sm text-gray-600 truncate whitespace-nowrap'>
                              {fileObj.file.name}
                            </label>
                            <Field
                              name={`files[${index}].alt_text`}
                              placeholder='Alt text'
                              className='w-full p-2 border rounded-md'
                            />
                          </div>

                          {/* Progress/Checkbox 10% */}
                          <div className='flex items-center w-1/4'>
                            {uploadProgress[fileObj.file.name] && (
                              <div className='flex items-center justify-center'>
                                {uploadProgress[fileObj.file.name] < 100 ? (
                                  <div className='text-sm text-gray-600'>
                                    {uploadProgress[fileObj.file.name] === 0 ? (
                                      <span>Loading...</span>
                                    ) : (
                                      <span>
                                        {uploadProgress[fileObj.file.name]}%
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  <div className='text-green-600'>
                                    <FaCheck className='inline w-4' />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Submit Button */}
                    <div className='absolute bottom-0 left-0 w-full p-2 bg-white border-t'>
                      <button
                        type='submit'
                        className='w-full px-4 py-2 text-white transition bg-blue-600 rounded-md hover:bg-blue-700'
                        disabled={isSubmitting || selectedFiles.length === 0}
                      >
                        {isSubmitting ? "Uploading..." : "Upload Media"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      {/* Media Preview Modal */}
      {/* Media Preview Modal */}
      {isMediaModalOpen && selectedMediaFile && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-2/3 max-w-lg p-6 bg-white rounded-md'>
            <button
              onClick={handleCloseMediaModal}
              className='absolute text-2xl text-black top-2 right-2'
            >
              <FaTimes />
            </button>
            <h3 className='mb-4 text-xl font-bold'>Media Preview</h3>
            {/* Render preview based on the file type */}
            {renderFilePreview(selectedMediaFile, true)}
            {/* Set `isModal` to true */}
            <p className='mb-4 text-gray-700'>
              {selectedMediaFile.alt_text || "No caption"}
            </p>
            <p className='text-xs text-right text-gray-700 truncate'>
              {moment(selectedMediaFile.created_at).format("DD MMM YYYY")}{" "}
              <br />
              {moment(selectedMediaFile.created_at).format("HH:mm A")}
            </p>
            <div className='flex justify-between'>
              <button
                onClick={() => handleCopyUrl(selectedMediaFile.file)}
                className='flex items-center px-2 py-1 text-sm text-white transition-opacity bg-gray-800 rounded-md opacity-100 bottom-2 left-2 hover:bg-gray-600'
              >
                <FaCopy className='mr-1' /> Copy URL
              </button>
              <button
                onClick={() => handleDeleteFromModal(selectedMediaFile)}
                className='flex items-center px-2 py-1 text-sm text-white transition-opacity bg-red-800 rounded-md opacity-100 bottom-2 left-2 hover:bg-red-600'
              >
                <FaTrash className='mr-2' /> Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
