/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MediaManagement from "./MediaManagement"; // Import the MediaManagement component
import {
  FaArrowLeft,
  FaGlobe,
  FaImage,
  FaLock,
  FaPencilAlt,
  FaSave,
  FaTimes,
} from "react-icons/fa";
import BaseApi from "../../Http/BaseApi";
import DatePicker from "react-datepicker"; // Import a date-time picker component
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import PageTitle from "../../Components/Common/PageTitle";
import { useSearchParams, useNavigate, Link } from "react-router-dom"; // Import routing utilities
import CustomBlockNoteEditor from "../../Components/Utils/CustomBlockNoteEditor";
import { toast } from "react-toastify";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentsAsync } from "../../Redux/MasterSlice";
import Select from "react-select"; // Import react-select for multiselect
import moment from "moment";

// Slugify function to generate slugs
const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-"); // Replace multiple - with single -

// Form validation schema using Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  slug: Yup.string()
    .required("Slug is required")
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Invalid slug format"),
  content: Yup.string().required("Content is required"),
  categories: Yup.array().min(1, "Select at least one category"),
  // excerpt: Yup.string().required("Excerpt is required"), // New excerpt field validation
});

export default function CreatePosts() {
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Manage modal open state
  const [isMediaManagerOpen, setIsMediaManagerOpen] = useState(false); // Manage modal open state
  const [categories, setCategories] = useState([]); // State to store categories
  const [loading, setLoading] = useState(true); // Loading state for fetching post data
  const formikRef = useRef();
  const [searchParams] = useSearchParams(); // Get URL parameters
  const editId = searchParams.get("edit_id"); // Extract edit_id if available
  const duplicate = searchParams.get("duplicate") || false;
  const navigate = useNavigate();
  const master = useSelector((state) => state.master);

  const departments = [...master.departments];
  const dispatch = useDispatch();

  const visibilityOptions = [
    {
      id: "Yes",
      name: "Both on Department and Home",
    },
    {
      id: "No",
      name: "Only on Department",
    },
  ];
  const initialValues = {
    title: "",
    slug: "",
    content: "",
    categories: [],
    status: "draft",
    published_date: null, // Publish date initially null
    archive_date: null, // New archive date field
    excerpt: "", // New excerpt field
    featured_image: null,
    department_id: "",
    is_visible_on_home: "Yes",
    visibility: "Public", // Default visibility is Public
  };

  // Handle file URL selection from MediaManagement modal
  const handleFileSelect = (url) => {
    setFeaturedImage(url);
    setIsMediaModalOpen(false); // Close modal after selection
    formikRef.current.setFieldValue("featured_image", url);
  };

  // Fetch categories from API on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await BaseApi().get(`/website/categories`);
        setCategories(response.data); // Set categories data
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };
    fetchCategories(); // Call the fetch function

    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
  }, []);

  // Fetch post data for editing
  useEffect(() => {
    const fetchPostData = async () => {
      if (editId) {
        try {
          const response = await BaseApi().get(`/website/posts/${editId}`);
          const postData = response.data;
          // Pre-fill form fields with the fetched data
          formikRef.current.setValues({
            title: postData.title,
            slug: postData.slug,
            content: postData.content,
            categories: postData.categories.map((cat) => cat.id),
            status: duplicate ? "" : postData.status,
            published_date:
              postData.published_date && !duplicate
                ? new Date(postData.published_date)
                : null,
            archive_date:
              postData.archive_date && !duplicate
                ? new Date(postData.archive_date)
                : null,

            excerpt: postData.excerpt,
            featured_image: postData.featured_image,
            department_id: postData.department_id,
            is_visible_on_home: postData.is_visible_on_home,
            visibility: postData.visibility || "Public",
            duplicate: duplicate,
          });
          setFeaturedImage(postData.featured_image);
        } catch (error) {
          console.error("Error fetching post data", error);
        }
      }
      setLoading(false);
    };

    fetchPostData();
  }, [editId]);

  // Function to handle category selection (checkbox array)
  const handleCategoryChange = (setFieldValue, values, categoryId) => {
    const selectedCategories = [...values.categories];
    if (selectedCategories.includes(categoryId)) {
      // Remove category if already selected
      setFieldValue(
        "categories",
        selectedCategories.filter((id) => id !== categoryId)
      );
      if (categoryId == 9) {
        setFieldValue("department_id", "");
        setFieldValue("is_visible_on_home", "Yes");
      }
    } else {
      // Add category if not selected
      setFieldValue("categories", [...selectedCategories, categoryId]);
    }
  };

  const handleStatusChange = (status, setFieldValue) => {
    if (status === "published") {
      // Set published date to now if published
      setFieldValue("published_date", new Date());
    } else if (status === "draft") {
      // Set published date to null if draft
      setFieldValue("published_date", null);
    }
    setFieldValue("status", status); // Set the status
  };

  const handleSubmit = async (values) => {
    // console.log("asd");

    try {
      setLoading(true);
      let published_date = values.published_date;
      if (values.status === "published" && !published_date) {
        published_date = new Date();
      }
      const formattedValues = {
        ...values,
        published_date: published_date
          ? moment(published_date).format("YYYY-MM-DD HH:mm:ss")
          : null, // Use null if the date is not set
        archive_date: values.archive_date
          ? moment(values.archive_date).format("YYYY-MM-DD HH:mm:ss")
          : null, // Use null if the date is not set
      };

      if (editId && !values.duplicate) {
        // If `edit_id` is present, update the post
        await BaseApi().put(`/website/posts/${editId}`, formattedValues);
        console.log("Post updated successfully");
      } else {
        // Create a new post
        await BaseApi().post(`/website/posts`, formattedValues);
        console.log("Post created successfully");
      }
      navigate("/website/posts"); // Redirect after successful creation/update
    } catch (error) {
      LaravelValidationError(error); // Call ValidationError to show error toasts
      console.error("Error submitting post", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container px-5 pb-10 mx-auto mt-5 rounded-md shadow-md'>
      <div className='flex justify-between gap-3'>
        <Navigations></Navigations>
        <Link
          to='/website/posts'
          className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
        >
          <FaArrowLeft className='inline w-4 mr-2' /> Back
        </Link>
      </div>
      <div className='flex gap-5'>
        <PageTitle
          name={
            (editId && !duplicate ? "Edit Post" : "Create new Post") +
            (duplicate ? " (Duplicate)" : "")
          }
        />

        <div className='flex items-center justify-start'>
          <button
            type='button'
            className='px-4 py-2 text-xs text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
            onClick={() => {
              setIsMediaManagerOpen(true);
            }}
          >
            <FaImage className='inline' /> Open Media Manager
          </button>
        </div>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values, setFieldValue }) =>
          loading ? (
            <div className='flex items-center justify-center h-full'>
              <div>Loading...</div>
            </div>
          ) : (
            <Form>
              <div className='grid grid-cols-1 gap-6 lg:grid-cols-12'>
                {/* Main Content Area */}
                <div className='lg:col-span-9'>
                  {/* Title */}
                  <div className='mb-4 bg-white rounded-md '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Title
                    </label>
                    <Field
                      name='title'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter post title...'
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("slug", slugify(e.target.value));
                      }}
                    />
                    {touched.title && errors.title && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.title}
                      </div>
                    )}
                  </div>

                  {/* Slug */}
                  <div className='mb-4 bg-white '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Slug
                    </label>
                    <Field
                      name='slug'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter post slug...'
                    />
                    {touched.slug && errors.slug && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.slug}
                      </div>
                    )}
                  </div>

                  {/* Content */}
                  <div className='mb-4 overflow-y-scroll bg-white min-h-[200px] max-h-[500px] '>
                    <CustomBlockNoteEditor
                      name='content'
                      initialValue={values.content}
                      onChange={(content) => {
                        // console.log(content);
                        formikRef.current?.setFieldValue("content", content);
                      }}
                    />
                  </div>

                  {/* Excerpt */}
                  <div className='mb-4 bg-white '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Excerpt
                    </label>
                    <Field
                      as='textarea'
                      name='excerpt'
                      rows={4}
                      className='w-full p-2 border rounded-md'
                      placeholder='Write a short excerpt here...'
                    />
                    {touched.excerpt && errors.excerpt && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.excerpt}
                      </div>
                    )}
                  </div>
                </div>

                {/* Sidebar Area */}
                <div className='flex flex-col justify-start space-y-6 lg:col-span-3'>
                  {/* Save Button */}
                  {/* <button
                    type='submit'
                    className='px-4 py-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                  >
                    <FaSave className='inline' />{" "}
                    {editId ? "Update Post" : "Save Post"}
                  </button> */}

                  {/* Post Status */}
                  <div className='py-4 bg-white rounded-md'>
                    <div className='flex gap-4'>
                      {/* Draft Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600'
                        onClick={() => {
                          setFieldValue("status", "draft"); // Set status to draft
                          setFieldValue("published_date", null); // Clear publish date for draft
                          handleSubmit({
                            ...values,
                            status: "draft",
                            published_date: null,
                          }); // Trigger form submission
                        }}
                      >
                        Save as Draft
                      </button>

                      {/* Publish Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600'
                        onClick={() => {
                          setFieldValue("status", "published"); // Set status to published
                          if (!values.published_date) {
                            setFieldValue("published_date", new Date()); // Set default publish date if not already set
                          }
                          handleSubmit({ ...values, status: "published" }); // Trigger form submission
                        }}
                      >
                        {editId ? "Publish" : "Publish"}
                      </button>
                    </div>

                    {/* Published Date Section */}

                    {editId && (
                      <div className='mt-4'>
                        <label className='mb-2 text-sm font-medium text-gray-700 '>
                          Status: {values.status}
                        </label>
                      </div>
                    )}

                    <div className='mt-4'>
                      <label className='mb-2 text-sm font-medium text-gray-700 '>
                        Publish Date
                      </label>
                      {!values.isEditingDate ? (
                        <div className='flex items-center justify-between text-sm'>
                          <span>
                            {values.published_date
                              ? values.published_date.toLocaleString()
                              : "Immediately"}
                          </span>
                          <button
                            type='button'
                            className='text-blue-500 hover:text-blue-700'
                            onClick={() => setFieldValue("isEditingDate", true)} // Enable editing mode
                          >
                            <FaPencilAlt className='inline' /> Edit
                          </button>
                        </div>
                      ) : (
                        <div>
                          <DatePicker
                            selected={values.published_date}
                            onChange={(date) =>
                              setFieldValue("published_date", date)
                            }
                            showTimeSelect
                            dateFormat='Pp'
                            className='w-full p-2 border rounded-md'
                          />
                          <div className='mt-2'>
                            <button
                              type='button'
                              className='px-4 py-2 mr-2 text-white bg-green-500 rounded-md hover:bg-green-600'
                              onClick={() =>
                                setFieldValue("isEditingDate", false)
                              } // Save and exit editing mode
                            >
                              Save
                            </button>
                            <button
                              type='button'
                              className='px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600'
                              onClick={() =>
                                setFieldValue("isEditingDate", false)
                              } // Cancel editing
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Visibility Section */}
                    <div className='mt-4'>
                      <label className='block mb-2 text-sm font-medium text-gray-700'>
                        Visibility
                      </label>
                      <div className='flex gap-4 text-sm'>
                        <label className='flex items-center'>
                          <input
                            type='radio'
                            name='visibility'
                            value='Public'
                            checked={values.visibility == "Public"}
                            onChange={() =>
                              setFieldValue("visibility", "Public")
                            }
                            className='mr-2'
                          />
                          <FaGlobe className='mr-2' /> Public
                        </label>
                        <label className='flex items-center'>
                          <input
                            type='radio'
                            name='visibility'
                            value='Private'
                            checked={values.visibility == "Private"}
                            onChange={() =>
                              setFieldValue("visibility", "Private")
                            }
                            className='mr-2'
                          />
                          <FaLock className='mr-2' /> Private
                        </label>
                      </div>
                    </div>

                    <div className='mt-4'>
                      <label className='block mb-2 text-sm font-medium text-gray-700'>
                        Archive Date
                      </label>
                      {/* {values.archive_date} */}
                      <DatePicker
                        selected={values.archive_date}
                        onChange={(date) =>
                          setFieldValue("archive_date", date || null)
                        }
                        isClearable
                        placeholderText='Archive date (optional)'
                        showTimeSelect
                        dateFormat='Pp'
                        className='w-full p-2 border rounded-md'
                      />
                    </div>
                  </div>

                  {/* Categories with Add Category Button */}
                  <div className='bg-white '>
                    <h5 className='mb-3 text-lg font-medium'>Categories</h5>
                    {categories.map((category) => (
                      <label key={category.id} className='block text-sm'>
                        <input
                          type='checkbox'
                          name='categories'
                          value={category.id}
                          checked={values.categories.includes(category.id)}
                          onChange={() =>
                            handleCategoryChange(
                              setFieldValue,
                              values,
                              category.id
                            )
                          }
                          className='mr-2'
                        />
                        {category.name}
                      </label>
                    ))}
                    <ErrorMessage
                      name='categories'
                      component='div'
                      className='py-2 text-red-500'
                    />
                  </div>

                  {values.categories.includes(9) && (
                    <>
                      <div className='w-full bg-white '>
                        <h5 className='mb-3 text-lg font-medium'>
                          Select Department (Optional)
                          <small className='block text-xs'>
                            To show in &apos;News and Events&apos; section of
                            department page
                          </small>
                        </h5>

                        <Select
                          options={departments}
                          defaultValue={departments.find(
                            (option) => option.id === values.department_id
                          )}
                          isClearable={true}
                          name='department_id'
                          value={departments.find(
                            (option) => option.id === values.department_id
                          )} // This should be an option object
                          getOptionLabel={(option) => option.name}
                          onChange={(selected) => {
                            // const selectedValues = selected.map((opt) => opt.value);
                            console.log("selected", selected);
                            setFieldValue("department_id", selected?.id);
                            if (!selected) {
                              setFieldValue("is_visible_on_home", "Yes");
                            }
                          }}
                          className='flex items-center justify-between flex-grow w-full py-2 '
                          placeholder='Select department (optional)'
                        />
                        <ErrorMessage
                          name='department_id'
                          component='div'
                          className='py-2 text-red-500'
                        />
                      </div>

                      {values.department_id && (
                        <div className='w-full bg-white '>
                          <h5 className='mb-3 text-lg font-medium'>
                            Assign Visibility
                          </h5>

                          <Select
                            options={visibilityOptions}
                            isClearable={false}
                            name='is_visible_on_home'
                            value={visibilityOptions.find(
                              (vo) => vo.id == values.is_visible_on_home
                            )} // This should be an option object
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(selected) => {
                              // const selectedValues = selected.map((opt) => opt.value);
                              console.log("selected", selected);
                              setFieldValue("is_visible_on_home", selected?.id);
                            }}
                            className='flex items-center justify-between flex-grow w-full py-2 '
                            placeholder='Select'
                          />
                          <ErrorMessage
                            name='department_id'
                            component='div'
                            className='py-2 text-red-500'
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Featured Image */}
                  <div className='bg-white '>
                    <h5 className='mb-3 text-lg font-medium'>Featured Image</h5>
                    <button
                      type='button'
                      className='w-full px-4 py-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                      onClick={() => setIsMediaModalOpen(true)}
                    >
                      <FaImage className='inline' /> Select Featured Image
                    </button>

                    {featuredImage && (
                      <div className='mt-4'>
                        <img
                          src={featuredImage}
                          alt='Featured'
                          className='object-cover w-full h-40 border rounded-md'
                        />
                        <a
                          target='_blank'
                          href={featuredImage}
                          className='mt-2 text-sm text-blue-700 break-all pointer-cursor'
                          rel='noreferrer'
                        >
                          Selected: {featuredImage}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>

      {/* Media Management Modal */}
      {isMediaModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaModalOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement types='image' onSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      )}

      {/* Media Manager Modal */}
      {isMediaManagerOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaManagerOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement setIsMediaManagerOpen={setIsMediaManagerOpen} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
