import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const Collaboration = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [collaborations, setCollaborations] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    collaboration_name: "",
    collaboration_type: "",
    other_collaboration: "",
    from_date: "",
    to_date: "",
    collaboration_objective: "",
    collaboration_details: "",
  });
  const headers = {
    collaboration_name: {
      label: "Name of the Institute/ Organization/Individual",
      path: "collaboration_name",
    },
    collaboration_type: {
      label: "Type of Collaboration",
      path: "collaboration_type",
    },
    other_collaboration: {
      label: "Other Collaboration",
      path: "other_collaboration",
    },
    from_date: { label: "From Date Collaboration", path: "from_date" },
    to_date: { label: "To Date Collaboration", path: "to_date" },
    collaboration_objective: {
      label: "Objective of the Collaboration",
      path: "collaboration_objective",
    },
    collaboration_details: {
      label: "Level of the collaboration",
      path: "collaboration_details",
    },
  };

  const validationSchema = Yup.object({
    collaboration_name: Yup.string().required("This field is required!"),
    collaboration_type: Yup.string().required("This field is required!"),
    other_collaboration: Yup.string().when("collaboration_type", {
      is: "Others",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    from_date: Yup.string().required("This field is required!"),
    // to_date: Yup.string().required("To Date is required"),
    collaboration_objective: Yup.string()
      .required("This field is required!")
      .max(
        255,
        "Objective of the Collaboration must be at most 255 characters"
      ),
    collaboration_details: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/collaborations");
      setCollaborations(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/collaborations/${editId}`, values);
        toast.success(`Collaboration Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/collaborations", values);
        toast.success(`Collaboration Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = collaborations.find(
        (collaboration) => collaboration.id === id
      );
      setInitialValues({
        id: editableData.id,
        collaboration_name: editableData.collaboration_name,
        collaboration_type: editableData.collaboration_type,
        other_collaboration: editableData.other_collaboration,
        from_date: editableData.from_date,
        to_date: editableData.to_date,
        collaboration_objective: editableData.collaboration_objective,
        collaboration_details: editableData.collaboration_details,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      collaboration_name: "",
      collaboration_type: "",
      other_collaboration: "",
      from_date: "",
      to_date: "",
      collaboration_objective: "",
      collaboration_details: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      collaboration_name: "",
      collaboration_type: "",
      other_collaboration: "",
      from_date: "",
      to_date: "",
      collaboration_objective: "",
      collaboration_details: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/collaborations/${id}`);
      toast.success(`Collaboration Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Collaborations :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={collaborations}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Collaboration;
