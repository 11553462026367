import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const lectureTypes = [
    { id: 1, name: "Invited Talk" },
    { id: 2, name: "Keynote Address" },
    { id: 3, name: "Plenary Session" },
    { id: 4, name: "Resource Person" },
  ];

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Invited Lecture/Talk
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="grid grid-cols-2 gap-3 p-5 ">
          <Input
            type="text"
            name="title"
            label={
              <div className="flex items-center">
                Title of the lecture/talk
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <CustomSelect
            defaultInputValue={initialValues.type}
            options={lectureTypes}
            name="type"
            label={
              <div className="flex items-center">
                Type of lecture/talk
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
            isSearchable={true}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => `${option.name}`}
            onChange={async (selectedOption) => {
              formikRef.current?.setFieldValue("type", selectedOption.name);
            }}
          />
          <Input
            type="textarea"
            label={
              <div className="flex items-center">
                Name of the Workshop/Seminar/Occasion/Event
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
            name="workshop_name"
          />
          <Input
            type="text"
            label={
              <div className="flex items-center">
                Name of the organizer(s)
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
            name="organization_name"
          />

          <Input
            type="date"
            name="lecture_date"
            label={
              <div className="flex items-center">
                Date
                <span className="ml-1 text-red-600">*</span>
              </div>
            }
          />
          <Input
            type="textarea"
            label="Brief Description"
            name="lecture_details"
          />
          <div className="flex justify-between col-span-2 mt-10">
            <button
              className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={onCancel}
            >
              <XIcon className="w-5 h-5 mr-2" /> Cancel
            </button>
            {isEditMode ? (
              <Button type="submit" value="Update" icon={"SaveIcon"} />
            ) : (
              <Button type="submit" value="Save" icon={"SaveIcon"} />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FormPage;
