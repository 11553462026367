import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  program_type: { label: "Type of the Programme", path: "program_type" },
  other_program_type: {
    label: "Other Programme",
    path: "other_program_type",
  },
  programme: { label: "Title of the Programme", path: "programme" },
  role: { label: "Role", path: "role" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  published: {
    label: "Whether any proceeding published?",
    path: "published",
  },
  proceeding_title: {
    label: "Title of the  Proceeding",
    path: "proceeding_title",
  },
  month: { label: "Month of Publication", path: "monthName" },
  year: { label: "Year of Publication", path: "year" },
  editor_name: { label: "Editor Name with Affiliation", path: "editor_name" },
  issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
};

const ProgrammeOrganized = () => {
  const [programmeOrganizeds, setProgrammeOrganizeds] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/programme-organizeds");
      setProgrammeOrganizeds(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Programme Organized
      </h2>
      <TableViewProfile
        headers={headers}
        data={programmeOrganizeds}
        type='programme-organizeds'
        setData={setProgrammeOrganizeds}
      />
    </div>
  );
};

export default ProgrammeOrganized;
