import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";
import CustomSelect from "../../../../Components/Utils/CustomSelect";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);

  const types = [
    { id: 1, name: "SWAYAM MOOCS" },
    { id: 2, name: "e-PGPathshala" },
    { id: 3, name: "NPTEL/NMEICT" },
    { id: 4, name: "Institutional CMS" },
    { id: 5, name: "Others" },
  ];

  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        E-Content Development
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <CustomSelect
              options={types}
              name='type'
              label={
                <div className='flex items-center'>
                  Type
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                console.log("Selected option:", selectedOption);
                formikRef.current?.setFieldValue("type", selectedOption.name);
                console.log(
                  "Formik values after update:",
                  formikRef.current?.values
                );
              }}
            />

            {values.type === "Others" && (
              <>
                <Input
                  type='text'
                  name='others_type'
                  label={
                    <div className='flex items-center'>
                      Others Type
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
              </>
            )}

            <Input
              type='text'
              name='title'
              label={
                <div className='flex items-center'>
                  Title of the E-content
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <Input
              type='text'
              name='modules'
              label={
                <div className='flex items-center'>
                  Number of Modules
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='enrolled_student'
              label={
                <div className='flex items-center'>
                  Number of Enrolled Students
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='url'
              label={
                <div className='flex items-center'>
                  URL
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <CustomSelect
              defaultInputValue={
                monthTypes.find((month) => month.id == initialValues.month)
                  ?.name
              }
              options={monthTypes}
              name='month'
              label={
                <div className='flex items-center'>
                  Month of Launch
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("month", selectedOption.id);
              }}
            />
            <Input
              type='text'
              name='year'
              label={
                <div className='flex items-center'>
                  Year of Launch
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <div className='flex justify-between col-span-2 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
