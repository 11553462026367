import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

export default function Input(props) {
  const [isEditable, setIsEditable] = useState(!props.secureEdit); // State to toggle edit mode

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const classNames = isEditable
    ? "focus:ring-indigo-500 focus:border-indigo-500"
    : "bg-gray-100 cursor-not-allowed";

  return (
    <>
      <div className='relative group'>
        {props.label ? (
          <label
            htmlFor={props.id}
            className='block text-sm font-medium text-gray-700'
          >
            {props.label}
          </label>
        ) : (
          <span></span>
        )}
        <Field
          as={props.type == "textarea" ? "textarea" : "input"}
          readOnly={props.readOnly ? props.readOnly : !isEditable}
          step={props.step ?? ".01"}
          id={props.id}
          type={props.type === "textarea" ? undefined : props.type ?? "text"}
          name={props.name}
          placeholder={props.placeholder}
          // defaultInputValue={props.defaultInputValue}
          className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
        />
        {/* Pencil Icon */}
        {props.secureEdit && !isEditable && (
          <FaPencilAlt
            className='absolute text-gray-400 cursor-pointer top-9 right-3 group-hover:text-indigo-600'
            onClick={handleEditClick}
            title='Edit'
          />
        )}
        <ErrorMessage
          name={props.name}
          component='div'
          className='py-2 text-red-500'
        />
      </div>
    </>
  );
}
