import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import {
  FaBriefcase,
  FaCalendarDays,
  FaIndianRupeeSign,
  FaRegThumbsUp,
  FaUser,
} from "react-icons/fa6";
import moment from "moment";
import { SiHiveBlockchain } from "react-icons/si";
import { GrProjects } from "react-icons/gr";

const headers = {
  title: { label: "Title of the Project", path: "title" },
  type: { label: "Project Type", path: "type" },
  role: { label: "Role", path: "role" },
  funding_agency: { label: "Funding Agency", path: "funding_agency" },
  type_funding_agency: {
    label: "Type of Funding Agency",
    path: "type_funding_agency",
  },
  grant_no: { label: "Grant No.", path: "grant_no" },
  amount_granted: { label: "Amount Granted", path: "amount_granted" },
  status: { label: "Status", path: "status" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "";
};

const Project = ({ view }) => {
  const [projects, setProjects] = useState([]); // Array to store projects
  const [visibleCount, setVisibleCount] = useState(5); // Set initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/projects");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredProjects = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest date first)
      // const sortedProjects = filteredProjects.sort((a, b) => {
      //   const startDateA = getValueByPath(a, headers.start_date.path);
      //   const startDateB = getValueByPath(b, headers.start_date.path);
      //   const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
      //   const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
      //   return dateB - dateA; // Descending order
      // });

      setProjects(filteredProjects);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(projects.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderProject = (project) => {
    const title = getValueByPath(project, headers.title.path);
    const fundingAgency = getValueByPath(project, headers.funding_agency.path);
    const typeFundingAgency = getValueByPath(
      project,
      headers.type_funding_agency.path
    );
    const role = getValueByPath(project, headers.role.path);
    const amountGranted = getValueByPath(project, headers.amount_granted.path);
    const status = getValueByPath(project, headers.status.path);

    const startDate = formatDate(
      getValueByPath(project, headers.start_date.path)
    );
    const endDate = getValueByPath(project, headers.end_date.path)
      ? formatDate(getValueByPath(project, headers.end_date.path))
      : "Present";

    // Check if all required fields are valid before rendering
    if (!title || !fundingAgency || !startDate) {
      return null; // Skip rendering if critical data is missing
    }

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>
          <p className='flex gap-1 text-sm'>
            <FaBriefcase className='flex-shrink-0 mt-1 text-green-600' />{" "}
            <span className='whitespace-nowrap'>Funding Agency: </span>
            <span className='text-green-600'>{fundingAgency}</span>
          </p>
          {typeFundingAgency && (
            <p className='flex gap-1 text-sm'>
              <FaBriefcase className='flex-shrink-0 mt-1 text-yellow-500' />
              <span className='whitespace-nowrap'>Type Funding Agency: </span>
              <span className='text-green-600 '>{typeFundingAgency}</span>
            </p>
          )}

          <div className='items-center space-y-1 lg:flex lg:gap-3'>
            {status && (
              <span className='flex items-center gap-1 text-sm '>
                <FaRegThumbsUp
                  className={
                    status === "Ongoing" ? "text-yellow-500" : "text-green-500"
                  }
                />
                {status}
              </span>
            )}

            {role && (
              <span className='flex items-center gap-1 text-sm '>
                <FaUser className='flex-shrink-0 text-yellow-500' />
                {role}
              </span>
            )}

            {amountGranted && (
              <span className='flex items-center gap-1 text-sm '>
                <FaIndianRupeeSign className='text-yellow-500' />
                {amountGranted}
              </span>
            )}

            <span className='flex items-center gap-1 text-sm'>
              <FaCalendarDays className='text-yellow-500' />
              {startDate} - {endDate}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex items-center gap-2 text-xl font-medium text-blue-900'>
          <GrProjects />
          Projects
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {projects.length}
        </span>
      </div>

      {projects.length ? (
        <div>
          {projects.slice(0, visibleCount).map((project, index) => (
            <div key={index}>{renderProject(project)}</div>
          ))}
          {projects.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Project;
