import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import { FaBriefcase, FaCalendarCheck, FaCalendarDays } from "react-icons/fa6";
import { RiSpeakFill } from "react-icons/ri";

const headers = {
  title: { label: "Title of the lecture/talk", path: "title" },
  type: { label: "Type of lecture/talk", path: "type" },
  organization_name: {
    label: "Organization Name",
    path: "organization_name",
  },
  workshop_name: {
    label: "Name of the Workshop/Seminar/Occasion/Event",
    path: "workshop_name",
  },
  lecture_date: { label: "Lecture Date", path: "lecture_date" },
  lecture_details: { label: "Lecture Details", path: "lecture_details" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const LecturesTalk = ({ view }) => {
  const [lecturesTalks, setLecturesTalks] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/lectures-talks");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredTalks = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by lecture_date in descending order
      // const sortedTalks = filteredTalks.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.lecture_date.path));
      //   const dateB = moment(getValueByPath(b, headers.lecture_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setLecturesTalks(filteredTalks);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(lecturesTalks.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderLectureTalk = (lecture) => {
    const title = getValueByPath(lecture, headers.title.path);
    const organizationName = getValueByPath(
      lecture,
      headers.organization_name.path
    );
    const workshopName = getValueByPath(lecture, headers.workshop_name.path);
    const lectureDate = getValueByPath(lecture, headers.lecture_date.path)
      ? moment(getValueByPath(lecture, headers.lecture_date.path)).format(
          "DD MMM YYYY"
        )
      : null;
    const type = getValueByPath(lecture, headers.type.path);

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>

          <div className='gap-2 '>
            {/* Conditionally render the organization field if the data is valid */}
            {organizationName && (
              <p className='flex gap-1 text-sm'>
                <FaBriefcase className='flex-shrink-0 mt-1 text-green-600' />{" "}
                Organization:
                <span className='text-green-600 '>{organizationName}</span>
              </p>
            )}

            {/* Conditionally render the workshop name if data is valid */}
            {workshopName && (
              <p className='flex gap-1 text-sm'>
                <FaCalendarCheck className='flex-shrink-0 mt-1 text-yellow-500' />{" "}
                Workshop/Seminar/Event:
                <span className='text-green-600'>{workshopName}</span>
              </p>
            )}
          </div>

          {/* Conditionally render the type of talk if data is valid */}
          {type && (
            <p className='flex items-center gap-1 text-sm whitespace-nowrap'>
              <span className='px-3 py-1 text-green-800 bg-green-100 rounded-full'>
                {type}
              </span>
            </p>
          )}

          {/* Conditionally render the lecture date if data is valid */}
          {lectureDate && (
            <div className='flex items-center gap-3'>
              <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaCalendarDays className='text-yellow-500' />
                {lectureDate}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-2 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <RiSpeakFill className='mt-1' />
          Invited Lecture/Talk
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {lecturesTalks.length}
        </span>
      </div>

      {lecturesTalks.length ? (
        <div>
          {lecturesTalks.slice(0, visibleCount).map((lectureTalk, index) => (
            <div key={index}>{renderLectureTalk(lectureTalk)}</div>
          ))}

          {lecturesTalks.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default LecturesTalk;
