import React, { useEffect, useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import Button from "../../../../../Components/Utils/Button";
import CustomSelect from "../../../../../Components/Utils/CustomSelect";
import Input from "../../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";
import Upload from "../../../../../Components/Utils/Upload";

function SyncUrlAndFileUrl() {
  const { values, setFieldValue } = useFormikContext(); // Access Formik values and setFieldValue

  useEffect(() => {
    if (values.url.length > 0) {
      setFieldValue("file_url", "");
    }
    if (values.file_url) {
      setFieldValue("url", "");
    }
  }, [values.file_url, values.url, setFieldValue]);

  return null; // This component doesn't render anything, just used for side-effects
}

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);
  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const indexedInTypes = [
    { id: 1, name: "Scopus" },
    { id: 2, name: "Web of Science(core)" },
    { id: 3, name: "Web of Science (others)" },
    { id: 4, name: "Both Scopus and WOS" },
  ];

  const bookTypeTypes = [
    {
      id: 1,
      name: "Textbook",
    },
    {
      id: 2,
      name: "Reference Book",
    },
    {
      id: 3,
      name: "Research Monograph",
    },
    {
      id: 4,
      name: "Others",
    },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Book Chapter
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <Input
              type='text'
              name='title'
              label={
                <div className='flex items-center'>
                  Title of the Book Chapter
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='book_name'
              label={
                <div className='flex items-center'>
                  Book Name
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.book_type}
              options={bookTypeTypes}
              name='book_type'
              label={
                <div className='flex items-center'>
                  Book Type
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "book_type",
                  selectedOption.name
                );
              }}
            />

            {values.book_type === "Others" && (
              <Input
                type='text'
                name='book_type_others'
                label={
                  <div className='flex items-center'>
                    Others
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
            )}

            <Input
              type='text'
              placeholder='Enter Author Names separated by commas, if more than one author'
              name='author_names'
              label={
                <div className='flex items-center'>
                  Author Names: (As per Book)
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <Input
              type='text'
              placeholder='Enter Editor Names separated by commas, if more than one editor'
              name='editors'
              label={
                <div className='flex items-center'>
                  Editor(s)
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <Input
              type='text'
              name='publisher'
              label={
                <div className='flex items-center'>
                  Publisher
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={
                monthTypes.find((month) => month.id == initialValues.month)
                  ?.name
              }
              options={monthTypes}
              name='month'
              label={
                <div className='flex items-center'>
                  Month of Publication
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("month", selectedOption.id);
              }}
            />
            <Input
              type='text'
              name='year'
              label={
                <div className='flex items-center'>
                  Year of Publication
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <Input
              type='text'
              name='isbn'
              label={
                <div className='flex items-center'>
                  ISBN
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              options={indexedInTypes}
              name='indexed_in'
              label={
                <div className='flex items-center'>
                  Indexed in
                  {/* <span className='ml-1 text-red-600'>*</span> */}
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                console.log("Selected option:", selectedOption);
                formikRef.current?.setFieldValue(
                  "indexed_in",
                  selectedOption.name
                );
                console.log(
                  "Formik values after update:",
                  formikRef.current?.values
                );
              }}
            />

            {!values.file_url && (
              <div>
                <Input
                  type='text'
                  name='url'
                  label={<div className='flex items-center'>URL/DOI</div>}
                />
                <small className='text-red-600'>
                  In case of DOI, please write the full URL such as
                  <span className='text-blue-500'>
                    {" "}
                    https://doi.org/10.1093/ajae/aaq063
                  </span>
                </small>
              </div>
            )}

            {values.url.length == 0 && (
              <div className='space-y-1'>
                <label>
                  <div className='flex items-center'>
                    If URL/DOI not available, please upload the coverpage of the
                    book, list of contents, and first page of the book chapter
                    in a single file
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                </label>
                <Upload
                  label={
                    <ul className='text-red-600'>
                      <li>File upload max 500 KB</li>
                      <li>Image/PDF only</li>
                    </ul>
                  }
                  type='file'
                  name='file_url'
                  onClear={() => {
                    formikRef.current.setFieldValue("file_url", "");
                  }}
                  onChange={(event) => {
                    console.log("asd");
                    let myFile = event.currentTarget.files[0];
                    formikRef.current.setFieldValue("url", "");
                    formikRef.current.setFieldValue("file_url", myFile);
                  }}
                />
              </div>
            )}
            <SyncUrlAndFileUrl />

            <div className='flex justify-between col-span-2 mt-10'>
              {/* <Button
            type="back"
            value="Back"
            icon={"SaveIcon"}
            onClick={onCancel}
          /> */}

              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>

              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
