import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import { FaCalendarDays, FaRegComments, FaUserTie } from "react-icons/fa6";
import { MdLocalActivity } from "react-icons/md";

const headers = {
  fdp_name: { label: "Name of the FDP", path: "fdp_name" },
  start_date: { label: "FDP Start date", path: "start_date" },
  end_date: { label: "FDP End date", path: "end_date" },
  organizer: { label: "Organizer", path: "organizer" },
  mode: { label: "Mode of FDP", path: "mode" },
  details: { label: "Details of FDP", path: "details" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const FIPActivitiesParticipation = ({ view }) => {
  const [fIPActivitiesParticipations, setFIPActivitiesParticipations] =
    useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/fip-activities-participations"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest first)
      // const sortedData = filteredData.sort((a, b) => {
      //   const dateA = getValueByPath(a, headers.start_date.path);
      //   const dateB = getValueByPath(b, headers.start_date.path);

      //   return moment(dateB).diff(moment(dateA)); // Latest date first
      // });

      setFIPActivitiesParticipations(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(fIPActivitiesParticipations.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderFIPActivitiesParticipation = (fIPActivitiesParticipation) => {
    const fdpName = getValueByPath(
      fIPActivitiesParticipation,
      headers.fdp_name.path
    );
    const startDateRaw = getValueByPath(
      fIPActivitiesParticipation,
      headers.start_date.path
    );
    const endDateRaw = getValueByPath(
      fIPActivitiesParticipation,
      headers.end_date.path
    );
    const organizer = getValueByPath(
      fIPActivitiesParticipation,
      headers.organizer.path
    );
    const mode = getValueByPath(fIPActivitiesParticipation, headers.mode.path);

    // Validate dates
    const startDate =
      startDateRaw && moment(startDateRaw, moment.ISO_8601, true).isValid()
        ? formatDate(startDateRaw)
        : null;
    const endDate =
      endDateRaw && moment(endDateRaw, moment.ISO_8601, true).isValid()
        ? formatDate(endDateRaw)
        : null;

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{fdpName}</h3>

          {organizer && (
            <span className='flex gap-1 text-sm '>
              <FaUserTie className='flex-shrink-0 mt-1 text-yellow-500' />
              Organizer: <span className='text-green-600'>{organizer}</span>
            </span>
          )}
          <div className='flex items-center gap-3'>
            {mode && (
              <span className='flex items-center gap-1 text-sm '>
                <FaRegComments className='text-yellow-500' />
                Mode: <span className='text-green-600'>{mode}</span>
              </span>
            )}
            {/* Render date information only if both dates are valid */}
            {startDate && endDate && (
              <span className='flex items-center gap-1 text-sm '>
                <FaCalendarDays className='text-yellow-500' />
                {startDate} - {endDate}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 mb-3 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900 '>
          <MdLocalActivity />
          FIP activities/Participation in FDP
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {fIPActivitiesParticipations.length}
        </span>
      </div>
      {fIPActivitiesParticipations.length ? (
        <div>
          {fIPActivitiesParticipations
            .slice(0, visibleCount)
            .map((fIPActivitiesParticipation, index) => (
              <div key={index}>
                {renderFIPActivitiesParticipation(fIPActivitiesParticipation)}
              </div>
            ))}
          {fIPActivitiesParticipations.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default FIPActivitiesParticipation;
