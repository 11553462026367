import React, { useEffect, useState } from "react";
import {
  FaCalendarDays,
  FaClipboardList,
  FaUserGraduate,
} from "react-icons/fa6";
import { FaUniversity } from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  thesis_title: { label: "Title of the Thesis", path: "thesis_title" },
  other_institute: {
    label: "Name of the University/Institute",
    path: "other_institute",
  },
  month: {
    label: "Month",
    path: "monthName",
  },
  year: {
    label: "Year",
    path: "year",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const isValidDate = (month, year) => {
  const validMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return validMonths.includes(month) && !isNaN(year) && year > 0;
};

const ThesisExaminerUniversitie = ({ view }) => {
  const [thesisExaminerUniversities, setThesisExaminerUniversities] = useState(
    []
  ); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/thesis-examiner-universities"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      setThesisExaminerUniversities(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderThesisExaminerUniversitie = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const otherInstitute = getValueByPath(
      guidance,
      headers.other_institute.path
    );
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);
    const year = getValueByPath(guidance, headers.year.path);
    const month = getValueByPath(guidance, headers.month.path);

    return (
      <div className="items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-1">
          {scholarName && (
            <h3 className="flex gap-2 items-center text-lg font-medium text-blue-900">
              <FaUserGraduate /> {scholarName}
            </h3>
          )}
          <p className="flex items-center gap-2">
            {otherInstitute && (
              <span className="text-green-600 flex items-center text-sm gap-1 whitespace-nowrap">
                <FaUniversity className="inline" /> {otherInstitute}
              </span>
            )}

            {thesisTitle && (
              <span className="text-gray-800 flex items-center text-sm gap-1 whitespace-nowrap">
                <FaClipboardList className="inline" />
                {thesisTitle}
              </span>
            )}
          </p>

          {/* Only render date-related information if valid */}
          {isValidDate(month, year) && (
            <div className="flex items-center gap-3">
              <span className="flex items-center text-sm gap-1 whitespace-nowrap">
                <FaCalendarDays className="text-yellow-500" />
                {month} {year}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between px-2 py-1 mb-3 border-b">
        <h2 className="flex gap-2 justify-start text-xl font-medium text-blue-900">
          <FaUniversity className="mt-1" />
          Thesis Examiner of other Universities
        </h2>
        <span className="px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
          {thesisExaminerUniversities.length}
        </span>
      </div>

      {thesisExaminerUniversities.length ? (
        <div>
          {thesisExaminerUniversities.map((guidance, index) => (
            <div key={index}>{renderThesisExaminerUniversitie(guidance)}</div>
          ))}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default ThesisExaminerUniversitie;
