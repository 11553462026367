import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Select from "../../Components/Utils/Select";
// import { Form, Formik } from "formik";
import * as Yup from "yup";

import {
  getAcademicSessionsAsync,
  getProgrammesAsync,
} from "../../Redux/MasterSlice";
// import CustomSelect from "../../Components/Utils/CustomSelect";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import PageTitle from "../../Components/Common/PageTitle";
import moment from "moment";
import BasicInput from "../../Components/Utils/BasicInput";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import CustomSelect from "../../Components/Utils/CustomSelect";
import {
  getAdmittedStudentsAsync,
  updateAdmittedStudentsAsync,
} from "../../Redux/StudentSlice";
import can from "../../Middleware/Permission";
import { YearSemesters } from "../../Constants/YearSemesters";
import BaseApi from "../../Http/BaseApi";
import Select from "../../Components/Utils/Select";
// import BasicInput from "../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { SearchNor } from "../../Constants/SearchNor";
// import Select from "../../Components/Utils/Select";

export default function StudentSubjects() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  // const [errorMessage, setErrorMessage] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();
  const master = useSelector((state) => state.master);
  const programmes = [...master.programmes];
  const academicSessions = [...master.academicSessions];
  const [students, setStudents] = useState([]);
  const [subjectmasters, setSubjectmasters] = useState({});

  // let formSections = useSelector((state) => state.applicants.formSections);
  let sl = 0;

  const initialValues = {
    programme_id: null,
    semester: null,
    academic_session_id: null,
    nor: 10,
  };

  const validationSchema = Yup.object().shape({
    programme_id: Yup.string().nullable().required("This field is required!"),
    semester: Yup.string().nullable().required("This field is required!"),
    academic_session_id: Yup.string()
      .nullable()
      .required("This field is required!"),
  });

  useEffect(() => {
    if (programmes.length === 0) {
      dispatch(getProgrammesAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    getSubjectMasters();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const getSubjectMasters = async () => {
    const _subjectmasters = await BaseApi().get(`fyigp-subject-masters`);

    console.log(_subjectmasters.data);
    setSubjectmasters(_subjectmasters.data);
  };

  const handleSearch = async (searchQuery) => {
    setEdit(false);

    setLoading(true);
    const response = await BaseApi().get(`admitted-students`, {
      params: searchQuery,
    });
    setStudents(response.data);
    setLoading(false);
  };

  const handleUpdate = async (payload) => {
    try {
      const response = await BaseApi().post(
        `add-or-update-student-subjects`,
        payload
      );

      const updatedProgrammeStudent = response.data;

      // Update the specific student in the state
      setStudents((prevStudents) =>
        prevStudents.map((student) => {
          if (student.id === updatedProgrammeStudent.id) {
            // Replace the student's studentsubject with the updated data
            return {
              ...student,
              studentsubject: updatedProgrammeStudent.studentsubject,
            };
          }
          return student;
        })
      );

      toast.success("Data Updated!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Failed to update data!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
    }
  };

  const SubjectSelect = ({
    edit,
    student,
    cbcsTypeId,
    subjectmasters,
    subject,
    label,
    handleUpdate,
  }) => {
    return (
      <>
        {edit ? (
          <select
            defaultValue={subject?.id}
            name={label.toLowerCase()}
            className='w-32 px-1 py-2 text-xs border border-gray-300 rounded-md'
            onChange={(e) => {
              handleUpdate({
                programme_student_id: student.id,
                cbcs_type_id: cbcsTypeId,
                subjectmaster_id: e.target.value,
              });
            }}
          >
            <option value=''>Select {label}</option>
            {subjectmasters
              .filter((subject) => {
                return (
                  subject.cbcs_type_id === cbcsTypeId &&
                  subject.semester === student.semester
                );
              })
              .map((subject) => {
                return (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                );
              })}
          </select>
        ) : (
          subject?.name
        )}
      </>
    );
  };

  return (
    <>
      <div className='py-2'>
        <div className='container mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='FYIPGP Subjects' />

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={programmes.filter((p) => p.category_id == 6)}
                    name='programme_id'
                    label='Select Programme'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "programme_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <>
                    <CustomSelect
                      options={YearSemesters}
                      name='semester'
                      label='Select Year/Semester'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "semester",
                          selectedOption.value
                        );
                      }}
                    />

                    <CustomSelect
                      options={academicSessions}
                      name='academic_session_id'
                      label='Select Academic Session'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.label} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.id
                        );
                      }}
                    />
                  </>

                  <div className='mt-5 text-left'>
                    <button
                      type='submit'
                      className='relative px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group w-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && <span>Searching...</span>}
                      {!loading && <span>Search</span>}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form> */}
            {students?.length > 0 && (
              <div>
                <div>
                  {edit && (
                    <>
                      <Button
                        type='button'
                        classNames=' mr-2   bg-green-600 hover:bg-green-700 focus:ring-green-500'
                        value={"Done"}
                        icon={"CheckIcon"}
                        onClick={() => {
                          setEdit(false);
                        }}
                      />
                    </>
                  )}
                  {(authData.panel == "department" ||
                    authData.user.email == "admission@dibru.ac.in") &&
                    !edit && (
                      <Button
                        type='button'
                        classNames='mr-2   bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                        value={"Edit"}
                        icon={"PencilIcon"}
                        onClick={() => {
                          setEdit(true);
                        }}
                      />
                    )}
                </div>
                <div>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='inline-block mt-5 text-gray-700 form-label'
                  >
                    Search By Name
                  </label>
                  <input
                    className='  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               '
                    placeholder='Type here...'
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Name</th>

                  <th className='table-head'>Enrollment ID</th>
                  <th className='table-head'>Major</th>
                  <th className='table-head'>Minor</th>
                  <th className='table-head'>GEC</th>
                  <th className='table-head'>AEC</th>
                  <th className='table-head'>SEC</th>
                  <th className='table-head'>VAC</th>
                </tr>
              </thead>
              <tbody>
                {students?.length > 0 &&
                  students
                    .filter((s) => {
                      if (searchInput.length > 0) {
                        return (
                          s.student.name
                            .toString()
                            .toLowerCase()
                            .indexOf(searchInput.toLowerCase()) > -1
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((student) => {
                      const major = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 36
                      )?.subjectmaster;
                      const minor = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 37
                      )?.subjectmaster;
                      const gec = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 38
                      )?.subjectmaster;
                      const aec = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 39
                      )?.subjectmaster;

                      const sec = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 40
                      )?.subjectmaster;
                      const vac = student.studentsubject?.find(
                        (subject) => subject.subjectmaster?.cbcs_type_id === 41
                      )?.subjectmaster;

                      return (
                        <>
                          <tr>
                            <td className='table-data'>{++sl}</td>

                            <td className='table-data'>
                              {student.student?.name}
                            </td>
                            <td
                              className='cursor-pointer table-data'
                              onClick={() => {
                                window.open(`/admitted-students/${student.id}`);
                              }}
                            >
                              {student.unique_id || student.unique_idd}
                            </td>

                            <td className='table-data'>{major?.name}</td>
                            <td className='table-data'>
                              {/* Minor Subject */}
                              <SubjectSelect
                                edit={edit}
                                student={student}
                                cbcsTypeId={37}
                                subjectmasters={subjectmasters}
                                subject={minor}
                                label='Minor'
                                handleUpdate={handleUpdate}
                              />
                            </td>
                            <td className='table-data'>
                              {/* GEC Subject */}
                              <SubjectSelect
                                edit={edit}
                                student={student}
                                cbcsTypeId={38}
                                subjectmasters={subjectmasters}
                                subject={gec}
                                label='GEC'
                                handleUpdate={handleUpdate}
                              />
                            </td>

                            <td className='table-data'>
                              {/* AEC Subject */}
                              <SubjectSelect
                                edit={edit}
                                student={student}
                                cbcsTypeId={39}
                                subjectmasters={subjectmasters}
                                subject={aec}
                                label='AEC'
                                handleUpdate={handleUpdate}
                              />
                            </td>
                            <td className='table-data'>
                              {/* SEC Subject */}
                              <SubjectSelect
                                edit={edit}
                                student={student}
                                cbcsTypeId={40}
                                subjectmasters={subjectmasters}
                                subject={sec}
                                label='SEC'
                                handleUpdate={handleUpdate}
                              />
                            </td>
                            <td className='table-data'>
                              {/* VAC Subject */}
                              <SubjectSelect
                                edit={edit}
                                student={student}
                                cbcsTypeId={41}
                                subjectmasters={subjectmasters}
                                subject={vac}
                                label='VAC'
                                handleUpdate={handleUpdate}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
              </tbody>
            </table>
            {/* </Form>
            </Formik> */}

            {students?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
