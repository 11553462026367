import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Name of the Award", path: "name" },
  month: { label: "Month of the Award", path: "monthName" },
  year: { label: "Year of the Award", path: "year" },
  from_date: { label: "From date", path: "from_date" },
  to_date: {
    label: "To date",
    path: "to_date",
  },
  awarding_agency: { label: "Awarding Agency", path: "awarding_agency" },
};

const Support = () => {
  const [supports, setSupports] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/supports");
      setSupports(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
       <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        National/International Fellowship/Financial Support :
 
      </h2>
      <TableViewProfile
        headers={headers}
        data={supports}
        type='supports'
        setData={setSupports}
      />
    </div>
  );
};

export default Support;
