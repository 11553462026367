import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../../Components/Utils/Input";
import CustomSelect from "../../../../../Components/Utils/CustomSelect";
import Button from "../../../../../Components/Utils/Button";
import Upload from "../../../../../Components/Utils/Upload";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);
  const statusTypes = [
    { id: 1, name: "Published" },
    { id: 2, name: "Granted" },
  ];

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Patent
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="grid grid-cols-3 gap-3 p-5 ">
            <Input
              type="text"
              name="title"
              label={
                <div className="flex items-center">
                  Title of the Patent
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              placeholder="Enter Inventor Names separated by commas, if more than one inventor"
              name="inventors_creators"
              label={
                <div className="flex items-center">
                  Inventors
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="applicant_name"
              label={
                <div className="flex items-center">
                  Applicant Name
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="application_number"
              label={
                <div className="flex items-center">
                  Application Number
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.status}
              options={statusTypes}
              name="status"
              label={
                <div className="flex items-center">
                  Status
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                console.log("Selected option:", selectedOption);
                formikRef.current?.setFieldValue("status", selectedOption.name);
              }}
            />
            {values.status === "Granted" && (
              <>
                <div className="space-y-1">
                  <label>
                    <div className="flex items-center">
                      Granted Certificate
                      <span className="ml-1 text-red-600">*</span>
                    </div>
                  </label>
                  <Upload
                    label={
                      <ul className="text-red-600">
                        <li>File upload max 500 KB</li>
                        <li>Image/PDF only</li>
                      </ul>
                    }
                    type="file"
                    name="granted_certificate_url"
                    onChange={(event) => {
                      console.log("asd");
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue(
                        "granted_certificate_url",
                        myFile
                      );
                    }}
                  />
                </div>
                <Input
                  type="text"
                  name="patent_number"
                  label={
                    <div className="flex items-center">
                      Patent Number
                      <span className="ml-1 text-red-600">*</span>
                    </div>
                  }
                />
                <Input
                  type="date"
                  name="patent_granted_date"
                  label={
                    <div className="flex items-center">
                      Patent Granted Date
                      <span className="ml-1 text-red-600">*</span>
                    </div>
                  }
                />
              </>
            )}

            {values.status === "Published" && (
              <>
                <div className="space-y-1">
                  <label>
                    <div className="flex items-center">
                      Published Certificate
                      <span className="ml-1 text-red-600">*</span>
                    </div>
                  </label>
                  <Upload
                    label={
                      <ul className="text-red-600">
                        <li>File upload max 500 KB</li>
                        <li>Image/PDF only</li>
                      </ul>
                    }
                    type="file"
                    name="published_certificate_url"
                    onChange={(event) => {
                      console.log("asd");
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue(
                        "published_certificate_url",
                        myFile
                      );
                    }}
                  />
                </div>
              </>
            )}

            <Input
              type="text"
              name="filling_country"
              label={
                <div className="flex items-center">
                  Filing Country
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="invention"
              label={
                <div className="flex items-center">
                  Field of Invention
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="date"
              name="filling_date"
              label={
                <div className="flex items-center">
                  Filing Date
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="date"
              name="published_date"
              label={
                <div className="flex items-center">
                  Published Date
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />

            <div className="flex justify-between col-span-3 mt-10">
              {/* <Button
              type='back'
              value='Back'
              icon={"SaveIcon"}
              onClick={onCancel}
            /> */}
              <button
                className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={onCancel}
              >
                <XIcon className="w-5 h-5 mr-2" /> Cancel
              </button>
              {isEditMode ? (
                <Button type="submit" value="Update" icon={"SaveIcon"} />
              ) : (
                <Button type="submit" value="Save" icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
