import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const CorporateLife = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [corporateLives, setCorporateLives] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    nature: "",
    from_date: "",
    to_date: "",
    place: "",
  });
  const headers = {
    name: { label: "Title of the Activity", path: "name" },
    nature: { label: "Nature of the Activity", path: "nature" },
    from_date: { label: "From Date", path: "from_date" },
    to_date: { label: "To Date", path: "to_date" },
    place: { label: "Place of Activity", path: "place" },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    nature: Yup.string().required("This field is required!"),
    from_date: Yup.string().required("This field is required!"),
    place: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/corporate-lives");
      setCorporateLives(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/corporate-lives/${editId}`, values);
        toast.success("Contribution to Corporate Life Updated Successfully");
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/corporate-lives", values);
        toast.success("Contribution to Corporate Life Added Successfully");
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = corporateLives.find(
        (corporateLife) => corporateLife.id === id
      );
      setInitialValues({
        id: editableData.id,
        name: editableData.name,
        nature: editableData.nature,
        from_date: editableData.from_date,
        to_date: editableData.to_date,
        place: editableData.place,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      name: "",
      nature: "",
      from_date: "",
      to_date: "",
      place: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      name: "",
      nature: "",
      from_date: "",
      to_date: "",
      place: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/corporate-lives/${id}`);
      toast.success("Contribution to Corporate Life Deleted Successfully");
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Contribution to Corporate Life :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={corporateLives}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default CorporateLife;
