import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaBuilding, FaCalendarDays, FaUser } from "react-icons/fa6";
import { SiHiveBlockchain } from "react-icons/si";
import { PiStudentBold } from "react-icons/pi";
import { RiOrganizationChart } from "react-icons/ri";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineProject, AiTwotoneProject } from "react-icons/ai";

const headers = {
  title: {
    label: "Title of the Project",
    path: "title",
  },
  role: {
    label: "Your Role",
    path: "role",
  },
  student_name: {
    label: "Name(s) of the Student(s)",
    path: "student_name",
  },
  year: { label: "Semester/Year", path: "year" },
  department_centre: {
    label: "Student's Department/Centre",
    path: "department_centre",
  },
  students_organization: {
    label: "Student's Institution",
    path: "students_organization",
  },
  other: {
    label: "Other",
    path: "other",
  },
  start_date: { label: "Project Start Date", path: "start_date" },
  end_date: { label: "Project End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => moment(date).format("DD MMM YYYY");

const StudentsProjectDetail = ({ view }) => {
  const [projects, setProjects] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/students-project-details");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredProjects = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest date first)
      // const sortedProjects = filteredProjects.sort((a, b) => {
      //   const startDateA = getValueByPath(a, headers.start_date.path);
      //   const startDateB = getValueByPath(b, headers.start_date.path);
      //   const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
      //   const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
      //   return dateB - dateA; // Descending order
      // });

      setProjects(filteredProjects);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(projects.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderProject = (project) => {
    const title = getValueByPath(project, headers.title.path);
    const role = getValueByPath(project, headers.role.path);
    const studentName = getValueByPath(project, headers.student_name.path);
    const year = getValueByPath(project, headers.year.path);
    const departmentCentre = getValueByPath(
      project,
      headers.department_centre.path
    );
    const studentsOrganization = getValueByPath(
      project,
      headers.students_organization.path
    );
    const startDate = getValueByPath(project, headers.start_date.path);
    const endDate = getValueByPath(project, headers.end_date.path);

    // Check if start date and end date are valid
    const isStartDateValid = moment(startDate, "YYYY-MM-DD", true).isValid();
    const isEndDateValid = moment(endDate, "YYYY-MM-DD", true).isValid();

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {title && (
            <h3 className='text-lg font-medium text-blue-900'>{title}</h3>
          )}

          {role && (
            <span className='flex items-center gap-1 text-sm '>
              <FaUser className='text-yellow-500' />
              {role}
            </span>
          )}

          {studentName && (
            <p className='flex gap-1 text-sm '>
              <PiStudentBold className='flex-shrink-0 mt-1 text-yellow-500' />
              <span className='whitespace-nowrap'>
                Name(s) of the Student(s):{" "}
              </span>
              <span className='text-green-600'>{studentName}</span>
            </p>
          )}

          <div className='flex gap-3'>
            {year && (
              <span className='flex gap-1 text-sm '>
                <FaCalendarAlt className='flex-shrink-0 mt-1 text-yellow-500' />
                {year}
              </span>
            )}
            {departmentCentre && (
              <span className='flex gap-1 text-sm '>
                <FaBuilding className='flex-shrink-0 mt-1 text-yellow-500' />
                {departmentCentre}
              </span>
            )}
          </div>

          <div className='flex gap-4'>
            {studentsOrganization && (
              <span className='flex items-center gap-1 text-sm '>
                <RiOrganizationChart className='text-yellow-500' />
                {studentsOrganization}
              </span>
            )}
            {isStartDateValid && isEndDateValid && (
              <span className='flex items-center gap-1 text-sm '>
                <FaCalendarDays className='text-yellow-500' />
                {formatDate(startDate)} - {formatDate(endDate || "Present")}
              </span>
            )}
            {/* Optionally handle if only start date is valid */}
            {!isEndDateValid && isStartDateValid && (
              <span className='flex items-center gap-1 text-sm '>
                <FaCalendarDays className='text-yellow-500' />
                {formatDate(startDate)} - Present
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900 '>
          <AiOutlineProject />
          Students&apos; Project Details
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {projects.length}
        </span>
      </div>
      {projects.length ? (
        <div>
          {projects.slice(0, visibleCount).map((project, index) => (
            <div key={index}>{renderProject(project)}</div>
          ))}
          {projects.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default StudentsProjectDetail;
