/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import MediaManagement from "./MediaManagement"; // Import the MediaManagement component
import { FaArrowLeft, FaImage, FaPencilAlt, FaTimes } from "react-icons/fa";
import BaseApi from "../../Http/BaseApi";
import DatePicker from "react-datepicker"; // Import a date-time picker component
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import PageTitle from "../../Components/Common/PageTitle";
import { useSearchParams, useNavigate, Link } from "react-router-dom"; // Import routing utilities
import CustomBlockNoteEditor from "../../Components/Utils/CustomBlockNoteEditor";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";
import { useSelector } from "react-redux";
import moment from "moment";

// Slugify function to generate slugs
const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-"); // Replace multiple - with single -

// Form validation schema using Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  slug: Yup.string()
    .required("Slug is required")
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Invalid slug format"),
  content: Yup.string().required("Content is required"),
  // excerpt: Yup.string().required("Excerpt is required"), // New excerpt field validation
});

export default function CreatePages() {
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Manage modal open state
  const [isMediaManagerOpen, setIsMediaManagerOpen] = useState(false); // Manage modal open state
  const [loading, setLoading] = useState(true); // Loading state for fetching page data
  const formikRef = useRef();
  const [searchParams] = useSearchParams(); // Get URL parameters
  const editId = searchParams.get("edit_id"); // Extract edit_id if available
  const duplicate = searchParams.get("duplicate") || false;
  const navigate = useNavigate();
  const master = useSelector((state) => state.master);

  const initialValues = {
    title: "",
    subtitle: "",
    slug: "",
    content: "",
    status: "draft",
    published_date: null, // Publish date initially null

    featured_image: null,
  };

  // Handle file URL selection from MediaManagement modal
  const handleFileSelect = (url) => {
    setFeaturedImage(url);
    setIsMediaModalOpen(false); // Close modal after selection
    formikRef.current.setFieldValue("featured_image", url);
  };

  // Fetch page data for editing
  useEffect(() => {
    const fetchPageData = async () => {
      if (editId) {
        try {
          const response = await BaseApi().get(`/website/pages/${editId}`);
          const pageData = response.data;
          // Pre-fill form fields with the fetched data
          formikRef.current.setValues({
            title: pageData.title,
            subtitle: pageData.subtitle,
            slug: pageData.slug,
            content: pageData.content,
            status: duplicate ? "" : pageData.status,
            published_date:
              pageData.published_date && !duplicate
                ? new Date(pageData.published_date)
                : null,

            featured_image: pageData.featured_image,

            duplicate: duplicate,
          });
          setFeaturedImage(pageData.featured_image);
        } catch (error) {
          console.error("Error fetching page data", error);
        }
      }
      setLoading(false);
    };

    fetchPageData();
  }, [editId]);

  const handleSubmit = async (values) => {
    // console.log("asd");

    try {
      setLoading(true);
      let published_date = values.published_date;
      if (values.status === "published" && !published_date) {
        published_date = new Date();
      }
      const formattedValues = {
        ...values,
        published_date: published_date
          ? moment(published_date).format("YYYY-MM-DD HH:mm:ss")
          : null, // Use null if the date is not set
        archive_date: values.archive_date
          ? moment(values.archive_date).format("YYYY-MM-DD HH:mm:ss")
          : null, // Use null if the date is not set
      };

      if (editId && !values.duplicate) {
        // If `edit_id` is present, update the page
        await BaseApi().put(`/website/pages/${editId}`, formattedValues);
        console.log("Page updated successfully");
      } else {
        // Create a new page
        await BaseApi().post(`/website/pages`, formattedValues);
        console.log("Page created successfully");
      }
      navigate("/website/pages"); // Redirect after successful creation/update
    } catch (error) {
      LaravelValidationError(error); // Call ValidationError to show error toasts
      console.error("Error submitting page", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container px-5 pb-10 mx-auto mt-5 rounded-md shadow-md'>
      <div className='flex justify-between gap-3'>
        <Navigations></Navigations>
        <Link
          to='/website/pages'
          className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
        >
          <FaArrowLeft className='inline w-4 mr-2' /> Back
        </Link>
      </div>
      <div className='flex gap-5'>
        <PageTitle
          name={
            (editId && !duplicate ? "Edit Page" : "Create new Page") +
            (duplicate ? " (Duplicate)" : "")
          }
        />

        <div className='flex items-center justify-start'>
          <button
            type='button'
            className='px-4 py-2 text-xs text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
            onClick={() => {
              setIsMediaManagerOpen(true);
            }}
          >
            <FaImage className='inline' /> Open Media Manager
          </button>
        </div>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values, setFieldValue }) =>
          loading ? (
            <div className='flex items-center justify-center h-full'>
              <div>Loading...</div>
            </div>
          ) : (
            <Form>
              <div className='grid grid-cols-1 gap-6 lg:grid-cols-12'>
                {/* Main Content Area */}
                <div className='lg:col-span-9'>
                  {/* Title */}
                  <div className='mb-4 bg-white rounded-md '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Title
                    </label>
                    <Field
                      name='title'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter page title...'
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("slug", slugify(e.target.value));
                      }}
                    />
                    {touched.title && errors.title && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.title}
                      </div>
                    )}
                  </div>

                  {/* Slug */}
                  <div className='mb-4 bg-white '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Slug
                    </label>
                    <Field
                      name='slug'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter page slug...'
                    />
                    {touched.slug && errors.slug && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.slug}
                      </div>
                    )}
                  </div>

                  {/* subtitle */}
                  <div className='mb-4 bg-white rounded-md '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Subtitle (Optional)
                    </label>
                    <Field
                      name='subtitle'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter page subtitle...'
                    />
                    {touched.subtitle && errors.subtitle && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.subtitle}
                      </div>
                    )}
                  </div>

                  {/* Content */}
                  <div className='mb-4 overflow-y-scroll bg-white min-h-[200px] max-h-[500px] '>
                    <CustomBlockNoteEditor
                      name='content'
                      initialValue={values.content}
                      onChange={(content) => {
                        // console.log(content);
                        formikRef.current?.setFieldValue("content", content);
                      }}
                    />
                  </div>
                </div>

                {/* Sidebar Area */}
                <div className='flex flex-col justify-start space-y-6 lg:col-span-3'>
                  {/* Page Status */}
                  <div className='py-4 bg-white rounded-md'>
                    <div className='flex gap-4'>
                      {/* Draft Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600'
                        onClick={() => {
                          setFieldValue("status", "draft"); // Set status to draft
                          setFieldValue("published_date", null); // Clear publish date for draft
                          handleSubmit({
                            ...values,
                            status: "draft",
                            published_date: null,
                          }); // Trigger form submission
                        }}
                      >
                        Save as Draft
                      </button>

                      {/* Publish Button */}
                      <button
                        type='button'
                        disabled={loading}
                        className='px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600'
                        onClick={() => {
                          setFieldValue("status", "published"); // Set status to published
                          if (!values.published_date) {
                            setFieldValue("published_date", new Date()); // Set default publish date if not already set
                          }
                          handleSubmit({ ...values, status: "published" }); // Trigger form submission
                        }}
                      >
                        {editId ? "Publish" : "Publish"}
                      </button>
                    </div>

                    {/* Published Date Section */}

                    {editId && (
                      <div className='mt-4'>
                        <label className='mb-2 text-sm font-medium text-gray-700 '>
                          Status: {values.status}
                        </label>
                      </div>
                    )}

                    <div className='mt-4'>
                      <label className='mb-2 text-sm font-medium text-gray-700 '>
                        Publish Date
                      </label>
                      {!values.isEditingDate ? (
                        <div className='flex items-center justify-between text-sm'>
                          <span>
                            {values.published_date
                              ? values.published_date.toLocaleString()
                              : "Immediately"}
                          </span>
                          <button
                            type='button'
                            className='text-blue-500 hover:text-blue-700'
                            onClick={() => setFieldValue("isEditingDate", true)} // Enable editing mode
                          >
                            <FaPencilAlt className='inline' /> Edit
                          </button>
                        </div>
                      ) : (
                        <div>
                          <DatePicker
                            selected={values.published_date}
                            onChange={(date) =>
                              setFieldValue("published_date", date)
                            }
                            showTimeSelect
                            dateFormat='Pp'
                            className='w-full p-2 border rounded-md'
                          />
                          <div className='mt-2'>
                            <button
                              type='button'
                              className='px-4 py-2 mr-2 text-white bg-green-500 rounded-md hover:bg-green-600'
                              onClick={() =>
                                setFieldValue("isEditingDate", false)
                              } // Save and exit editing mode
                            >
                              Save
                            </button>
                            <button
                              type='button'
                              className='px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600'
                              onClick={() =>
                                setFieldValue("isEditingDate", false)
                              } // Cancel editing
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Featured Image */}
                  <div className='bg-white '>
                    <h5 className='mb-3 text-lg font-medium'>Featured Image</h5>
                    <button
                      type='button'
                      className='w-full px-4 py-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                      onClick={() => setIsMediaModalOpen(true)}
                    >
                      <FaImage className='inline' /> Select Featured Image
                    </button>

                    {featuredImage && (
                      <div className='mt-4'>
                        <img
                          src={featuredImage}
                          alt='Featured'
                          className='object-cover w-full h-40 border rounded-md'
                        />
                        <a
                          target='_blank'
                          href={featuredImage}
                          className='mt-2 text-sm text-blue-700 break-all pointer-cursor'
                          rel='noreferrer'
                        >
                          Selected: {featuredImage}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>

      {/* Media Management Modal */}
      {isMediaModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaModalOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement types='image' onSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      )}

      {/* Media Manager Modal */}
      {isMediaManagerOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaManagerOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement setIsMediaManagerOpen={setIsMediaManagerOpen} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
