import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaCakeCandles, FaFileLines, FaLink, FaUsers } from "react-icons/fa6";
import { FaCalendarCheck } from "react-icons/fa";
import { AiTwotoneContainer } from "react-icons/ai";

const headers = {
  type: { label: "Type ", path: "type" },
  title: { label: "Title of the E-content", path: "title" },
  others_type: { label: "Others Type ", path: "others_type" },
  modules: { label: "Number of Modules", path: "modules" },
  enrolled_student: {
    label: "Number of Enrolled Students",
    path: "enrolled_student",
  },
  url: { label: "URL", path: "url" },
  month: { label: "Month of Launch", path: "monthName" },
  year: { label: "Year of Launch", path: "year" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const EContentDevelopment = ({ view }) => {
  const [eContentDevelopments, setEContentDevelopments] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/e-content-developments");
      const filterField = view === "iqac" ? "iqac" : "website";
      setEContentDevelopments(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(eContentDevelopments.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderConference = (eContentDevelopment) => {
    const type = getValueByPath(eContentDevelopment, headers.type.path);
    const othersType = getValueByPath(
      eContentDevelopment,
      headers.others_type.path
    );
    const modules = getValueByPath(eContentDevelopment, headers.modules.path);
    const enrolledStudent = getValueByPath(
      eContentDevelopment,
      headers.enrolled_student.path
    );
    const url = getValueByPath(eContentDevelopment, headers.url.path);
    const month = getValueByPath(eContentDevelopment, headers.month.path);
    const year = getValueByPath(eContentDevelopment, headers.year.path);

    return (
      <div className="items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-1">
          {type && (
            <>
              <h3 className="text-lg font-medium text-blue-900">{type}</h3>
              {othersType && (
                <span className="flex items-center text-sm gap-1 ">
                  [{othersType}],
                </span>
              )}
            </>
          )}
          {modules && (
            <span className="flex items-center text-sm gap-1 ">
              <FaFileLines className="text-yellow-500" />
              Number of Modules: {modules}
            </span>
          )}
          {enrolledStudent && (
            <span className="flex items-center text-sm gap-1 ">
              <FaUsers className="text-yellow-500" />
              Number of Enrolled Students: {enrolledStudent}
            </span>
          )}
          {url && (
            <a href={url} className="flex text-sm gap-1 ">
              <FaLink className="text-blue-600 flex-shrink-0 mt-1" />
              URL: {url}
            </a>
          )}
          {year && month && (
            <span className="flex items-center text-sm gap-1 ">
              <FaCalendarCheck className="text-yellow-500" />
              {year} - {month}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center justify-between gap-2 px-2 py-1 text-xl font-medium text-blue-900 border-b">
        <h2 className="flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900">
          <AiTwotoneContainer />
          E-Content Development
        </h2>
        <span className="px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
          {eContentDevelopments.length}
        </span>
      </div>

      {eContentDevelopments.length ? (
        <div>
          {eContentDevelopments
            .slice(0, visibleCount)
            .map((eContentDevelopment, index) => (
              <div key={index}>{renderConference(eContentDevelopment)}</div>
            ))}
          {eContentDevelopments.length > 5 && (
            <div className="flex justify-center mt-2">
              <button
                className="text-sm text-blue-500 rounded hover:text-blue-900"
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default EContentDevelopment;
