import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import Input from "../../../../Components/Utils/Input";
import Button from "../../../../Components/Utils/Button";
import { XIcon } from "@heroicons/react/outline";
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getDesignationsAsync } from "../../../../Redux/MasterSlice";
import ToggleSwitch from "../../../../Components/Utils/ToggleSwitch";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);

  const dispatch = useDispatch();

  const master = useSelector((state) => state.master);
  const otherDesignations = [...master.designations];

  useEffect(() => {
    if (otherDesignations.length === 0) {
      dispatch(getDesignationsAsync());
    }
  }, [dispatch, otherDesignations.length]);

  const [otherDesignationCount, setOtherDesignationCount] = useState(
    Math.max(initialValues?.otherDesignations?.length ?? 0, 1)
  );

  const handleAddOtherDesignation = () => {
    setOtherDesignationCount((prevCount) => prevCount + 1);
  };

  const handleRemoveOtherDesignation = (index, remove) => {
    setOtherDesignationCount((prevCount) => Math.max(prevCount - 1, 1));
    // Remove from Formik's state
    remove(index);
  };

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Basic Information
      </h1>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5 '>
            <div className='col-span-2'>
              <h3 className='font-medium text-blue-600'>
                Additional Designations/Positions:
              </h3>
            </div>
            <div className='grid grid-cols-2 col-span-2'>
              <FieldArray name='otherDesignations'>
                {({ remove }) => (
                  <>
                    {[...Array(otherDesignationCount)].map((_, index) => (
                      <div key={index} className='flex items-center mt-3'>
                        <Input
                          name={`otherDesignations.${index}`}
                          ltype='text'
                          label='Additional Designations/Positions'
                        />

                        {/* <CustomSelect
                          className='flex-1'
                          options={otherDesignations}
                          name={`otherDesignations.${index}.id`}
                          label={`Select Additional Designations/Positions`}
                          isSearchable={true}
                          getOptionValue={(option) => `${option.id}`}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={async (selectedOption) => {
                            formikRef.current?.setFieldValue(
                              `otherDesignations.${index}`,
                              selectedOption.id
                            );
                            console.log(
                              "Formik values after update:",
                              formikRef.current?.values
                            );
                          }}
                        /> */}
                        {index === otherDesignationCount - 1 && (
                          <div
                            className='flex items-center ml-2 cursor-pointer'
                            onClick={() => handleAddOtherDesignation()}
                          >
                            <IoAddCircle
                              className='mt-5 text-green-600'
                              size={32}
                            />
                          </div>
                        )}
                        {index !== otherDesignationCount - 1 && (
                          <div
                            className='flex items-center ml-2 cursor-pointer'
                            onClick={() =>
                              handleRemoveOtherDesignation(index, remove)
                            }
                          >
                            <IoRemoveCircle
                              className='mt-5 text-red-600'
                              size={32}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>
            <div className='col-span-2'>
              <h3 className='font-medium text-blue-600'>
                Academic Profile Links:
              </h3>
            </div>
            <Input
              type='text'
              name='scopus_link'
              label='Scopus Link'
              placeholder='Your Scopus Link'
            />
            <Input
              type='text'
              name='orcid_link'
              label='ORCiD Link'
              placeholder='Your ORCiD Link'
            />
            <Input
              type='text'
              name='google_scholar_link'
              label='Google Scholar Link'
              placeholder='Your Google Scholar Link'
            />
            <Input
              type='text'
              name='web_of_science_link'
              label='Web of Science Link'
              placeholder='Your Web of Science Link'
            />
            <Input
              type='text'
              name='vidwan_link'
              label='Vidwan Link'
              placeholder='Your Vidwan Link'
            />
            <Input
              type='text'
              name='linkedin_link'
              label='LinkedIn Link'
              placeholder='Your LinkedIn Link'
            />
            <Input
              type='text'
              name='research_gate_link'
              label='Research Gate Link'
              placeholder='Your Research Gate Link'
            />
            <Input
              type='text'
              name='irins_link'
              label='IRINS Profile Link'
              placeholder='Your IRINS Profile Link'
            />
            <ToggleSwitch
              name='mobile_visibility'
              label='Mobile no. visibility on website'
            />
            <div className='flex justify-between col-span-2 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
