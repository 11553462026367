import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  name: { label: "Name of the workshop/ training programme", path: "name" },
  description: { label: "Brief Description", path: "description" },
  organized_by: { label: " Organized by", path: "organized_by" },
  held_at: { label: "Held at", path: "held_at" },
  programme_type: { label: "Type of Programme", path: "programme_type" },
  // other: { label: "Other", path: "other" },
  role: { label: "Your Role", path: "role" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const WorkshopTraining = () => {
  const [workshopTrainings, setWorkshopTrainings] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/workshop-trainings");
      setWorkshopTrainings(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Participation in Programme
      </h2>
      <TableViewProfile
        headers={headers}
        data={workshopTrainings}
        type="workshop-trainings"
        setData={setWorkshopTrainings}
      />
    </div>
  );
};

export default WorkshopTraining;
