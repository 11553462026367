import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import {
  FaArrowDown,
  FaArrowUp,
  FaEdit,
  FaImage,
  FaPlus,
  FaSave,
  FaSortAlphaUp,
  FaSortDown,
  FaSortUp,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentsAsync } from "../../../../Redux/MasterSlice";
import MediaManagement from "../../MediaManagement";
import ReactSelect from "react-select";

export default function ManageFacultyMembers() {
  const params = new URLSearchParams(window.location.search);
  const page_slug = params.get("page_slug") || null;

  const master = useSelector((state) => state.master);
  const departments = [...master.departments];

  const dispatch = useDispatch();

  const [staff, setStaff] = useState([]); // Dynamic staff list
  const [searchTerm, setSearchTerm] = useState(""); // Search term for staff
  const [categories, setCategories] = useState({}); // Fetched categories
  const [categoryOrder, setCategoryOrder] = useState([]); // Order of category IDs
  const [activeDropdown, setActiveDropdown] = useState(null); // Track which dropdown is open
  const [newCategoryName, setNewCategoryName] = useState(""); // New category name
  const [isAddingCategory, setIsAddingCategory] = useState(false); // Track if adding category
  const [selectedDepartment, setSelectedDepartment] = useState(); // Selected department
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Media modal state
  const [editingMember, setEditingMember] = useState(null); // Track member being edited
  const [editingCategory, setEditingCategory] = useState(null); // Track category being edited
  const [editedCategoryName, setEditedCategoryName] = useState(""); // Temporary category name

  const [newMember, setNewMember] = useState({
    name: "",
    photo: "",
    subheading1: "",
    subheading2: "",
    link: "",
  });
  const [activeCategoryForNewMember, setActiveCategoryForNewMember] =
    useState(null); // Track which category is active for new member addition

  useEffect(() => {
    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
  }, []);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchStaff();
    }, 300); // 300ms debounce delay

    // Cleanup timeout if searchTerm changes before the delay
    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  const fetchStaff = async () => {
    let url = "";
    if (searchTerm.trim().length > 2) {
      url = `/website/staff?search=${searchTerm}`;
    } else {
      url = `/website/staff?department_id=${selectedDepartment.id}`;
    }
    console.log("fetching staff", url);
    const staffResponse = await BaseApi().get(url);
    const allStaff = staffResponse.data;
    setStaff(allStaff); // Set all staff members
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch all staff
        const staffResponse = await BaseApi().get(
          `/website/staff?department_id=${
            selectedDepartment ? selectedDepartment?.id : "undefined"
          }`
        );
        const allStaff = staffResponse.data;
        setStaff(allStaff); // Set all staff members

        // Fetch saved categories and their members
        const categoryResponse = await BaseApi().get(
          `/website/staff-categories?department_id=${
            selectedDepartment ? selectedDepartment?.id : ""
          }&page_slug=${page_slug ?? ""}`
        );
        const fetchedCategories = categoryResponse.data;

        // Transform fetched categories into the required format
        const transformedCategories = fetchedCategories.reduce(
          (acc, category) => {
            acc[category.id] = {
              name: category.name,
              members: category.members.map((member) => ({
                id: member.id,
                name: member.name,
                designation: member.designation, // Include member's designation
                photo: member.photo,
                web_photo: member.web_photo,
                profile_pic: member.profile_pic,
                web_profile_pic: member.web_profile_pic,
                isExternal: member.isExternal,
                subheading1: member.subheading1,
                link: member.link,
              })),
            };
            return acc;
          },
          {}
        );

        // Set the category order
        const fetchedCategoryOrder = fetchedCategories.map(
          (category) => category.id
        );

        setCategories(transformedCategories);
        setCategoryOrder(fetchedCategoryOrder);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    if (selectedDepartment || page_slug) {
      fetchInitialData();
    }
  }, [selectedDepartment, page_slug]);

  // Handle adding a staff member to a category
  const handleAddToCategory = (memberId, categoryId) => {
    const memberToAdd = staff.find((member) => member.id === memberId);

    if (!memberToAdd) return;

    // Prevent duplicate entries in the same category
    if (
      categories[categoryId].members.some((member) => member.id === memberId)
    ) {
      toast.error("Member already exists in this category.");
      return;
    }

    setCategories((prev) => ({
      ...prev,
      [categoryId]: {
        ...prev[categoryId],
        members: [...prev[categoryId].members, memberToAdd],
      },
    }));

    setActiveDropdown(null); // Close dropdown after selection
  };

  // Handle sorting members within a category
  const moveMember = (categoryId, memberId, direction) => {
    const categoryMembers = [...categories[categoryId].members];
    const index = categoryMembers.findIndex((member) => member.id === memberId);

    if (index === -1) return;

    // Swap positions
    if (
      (direction === "up" && index > 0) ||
      (direction === "down" && index < categoryMembers.length - 1)
    ) {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      [categoryMembers[index], categoryMembers[newIndex]] = [
        categoryMembers[newIndex],
        categoryMembers[index],
      ];

      setCategories((prev) => ({
        ...prev,
        [categoryId]: {
          ...prev[categoryId],
          members: categoryMembers,
        },
      }));
    }
  };

  // Handle sorting categories
  const moveCategory = (categoryId, direction) => {
    const index = categoryOrder.findIndex((id) => id === categoryId);

    if (index === -1) return;

    // Swap positions
    if (
      (direction === "up" && index > 0) ||
      (direction === "down" && index < categoryOrder.length - 1)
    ) {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedOrder = [...categoryOrder];
      [updatedOrder[index], updatedOrder[newIndex]] = [
        updatedOrder[newIndex],
        updatedOrder[index],
      ];

      setCategoryOrder(updatedOrder);
    }
  };

  const removeCategory = (categoryId) => {
    if (!window.confirm("Are you sure you want to delete this category?")) {
      return;
    }

    // Update the state to remove the category
    setCategories((prev) => {
      const updatedCategories = { ...prev };
      delete updatedCategories[categoryId]; // Remove the category
      return updatedCategories;
    });

    setCategoryOrder((prev) => prev.filter((id) => id !== categoryId)); // Update the order
  };

  const saveToDatabase = async () => {
    try {
      // Prepare payload
      const payload = {
        department_id: selectedDepartment ? selectedDepartment.id : "",
        page_slug: page_slug ?? "",
        categoryOrder, // Array of category IDs in their order
        categories: categoryOrder.map((categoryId) => ({
          id: categoryId,
          name: categories[categoryId].name,
          members: categories[categoryId].members.map((member) => ({
            id: member.isExternal ? null : member.id, // Null ID for new members
            name: member.name,
            photo: member.photo,
            subheading1: member.isExternal
              ? member.designation.name
              : member.subheading1,
            subheading2: member.subheading2,
            link: member.link,
            isExternal: member.isExternal || false,
          })),
        })),
      };

      // Send the payload to the backend
      const response = await BaseApi().post(
        "/website/staff-categories/save",
        payload
      );

      if (response.status === 200) {
        toast.success("Data saved successfully!");
      } else {
        toast.error("Failed to save data. Please try again.");
      }
    } catch (error) {
      // console.error("Error saving data:", error);
      toast.error("An error occurred while saving. Please try again.");
    }
  };

  const addNewCategory = async () => {
    if (!newCategoryName.trim()) {
      toast.error("Category name cannot be empty.");
      return;
    }

    try {
      // Save the new category to the backend
      const response = await BaseApi().post(
        "/website/staff-categories/saveCategory",
        {
          name: newCategoryName,
          department_id: selectedDepartment?.id,
          page_slug: page_slug,
        }
      );

      const newCategory = response.data;

      // Update state with the new category
      setCategories((prev) => ({
        ...prev,
        [newCategory.id]: { name: newCategory.name, members: [] },
      }));
      setCategoryOrder((prev) => [...prev, newCategory.id]);
      setNewCategoryName("");
      setIsAddingCategory(false); // Close the form
      toast.success("New category added successfully!");
    } catch (error) {
      console.error("Error adding new category:", error);
      toast.error(
        "An error occurred while adding the category. Please try again."
      );
    }
  };

  const handleFileSelect = (url) => {
    if (editingMember) {
      setEditingMember((prev) => ({ ...prev, photo: url }));
    } else {
      setNewMember((prev) => ({
        ...prev,
        photo: url,
      }));
    }

    setIsMediaModalOpen(false); // Close modal after selection
  };

  const addNewMember = (categoryId) => {
    if (!newMember.name.trim()) {
      toast.error("Name is required.");
      return;
    }
    console.log(newMember);

    // Update state without saving to the database
    setCategories((prev) => ({
      ...prev,
      [categoryId]: {
        ...prev[categoryId],
        members: [
          ...prev[categoryId].members,
          {
            id: `new-${Date.now()}`, // Temporary unique ID
            name: newMember.name,
            photo: newMember.photo,
            profile_pic: newMember.photo,
            designation: { name: newMember.subheading1 },
            subheading2: newMember.subheading2,
            link: newMember.link,
            isExternal: true, // Mark as a new member
          },
        ],
      },
    }));

    // Clear the form and close the modal
    setActiveCategoryForNewMember(null);
    setNewMember({
      name: "",
      photo: "",
      subheading1: "",
      subheading2: "",
      link: "",
    });

    toast.success("Member added to category. Remember to click Save Changes.");
  };

  const removeMember = async (categoryId, memberId) => {
    if (!window.confirm("Are you sure you want to remove this member?")) {
      return;
    }

    try {
      // Update the state to remove the member
      setCategories((prev) => ({
        ...prev,
        [categoryId]: {
          ...prev[categoryId],
          members: prev[categoryId].members.filter(
            (member) => member.id !== memberId
          ),
        },
      }));

      toast.success("Member removed successfully!");
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error("Failed to remove member. Please try again.");
    }
  };

  return (
    <>
      <div className='grid items-start justify-center grid-cols-3 gap-3 mx-10 my-2 '>
        <h2 className='text-2xl font-semibold text-left'>
          {page_slug ? "Page Edit " : "Department Profile: Faculty section"}
        </h2>

        {page_slug && (
          <a
            href={`https://dibru.ac.in/${page_slug}`}
            className='text-blue-500'
            target='_blank'
            rel='noreferrer'
          >
            {`https://dibru.ac.in/${page_slug}`}
          </a>
        )}
        {!page_slug && (
          <ReactSelect
            id='department'
            options={departments?.map((department) => ({
              value: department.id,
              label: department.name,
            }))} // Provide the options
            value={departments
              ?.map((department) => ({
                value: department.id,
                label: department.name,
              }))
              .find((option) => option.value === selectedDepartment?.id)} // Set the selected option
            onChange={(selectedOption) => {
              const selected = departments?.find(
                (department) => department.id === selectedOption.value
              );
              setSelectedDepartment(selected);
            }} // Update the state on selection
            placeholder='Select a department...'
            isClearable // Allow clearing the selection
            className='basic-single'
            classNamePrefix='select'
          />
        )}
        {(selectedDepartment || page_slug) && (
          <div className='mb-1 text-xs text-right rounded '>
            <button
              onClick={() => setIsAddingCategory(true)}
              className='px-3 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100'
            >
              <FaPlus className='inline mr-2' /> Add New Category
            </button>
            {isAddingCategory && (
              <div className='mt-4'>
                <input
                  type='text'
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder='Enter category name'
                  className='w-[300px] px-3 py-2  border border-gray-300 rounded'
                />
                <div className='mt-2'>
                  <button
                    onClick={addNewCategory}
                    className='px-4 py-2 mr-2 text-white bg-green-500 rounded'
                  >
                    Save <FaSave className='inline ml-2' />
                  </button>
                  <button
                    onClick={() => {
                      setIsAddingCategory(false);
                      setNewCategoryName("");
                    }}
                    className='px-4 py-2 text-white bg-red-500 rounded'
                  >
                    Cancel <FaTimes className='inline ml-2' />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {(selectedDepartment || page_slug) && (
        <>
          <div className='relative grid grid-cols-12 gap-4 p-5'>
            {/* Members List */}
            <div className='col-span-3 p-5 border-r border-gray-200'>
              <h4>Teachers/Officers</h4>
              <input
                type='text'
                placeholder='Search'
                className='w-full p-2 my-1 border rounded'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {staff.map((member) => (
                <div
                  key={member.id}
                  className='relative flex items-center justify-between p-2 border-b border-gray-200'
                >
                  <div className='flex items-center gap-3'>
                    <img
                      src={
                        member.web_photo
                          ? member.web_profile_pic
                          : member.photo
                          ? member.profile_pic
                          : "https://ui-avatars.com/api/?name=" +
                            member.name +
                            "&background=0D8ABC&color=fff"
                      }
                      alt={member.name}
                      className='w-8 h-8 rounded-full'
                    />

                    <span>
                      {member.name} <br />
                      <small className='line-clamp-1 '>
                        {member.department?.name}
                      </small>
                      <small>{member.designation?.name}</small>
                    </span>
                  </div>
                  <button
                    onClick={() =>
                      setActiveDropdown((prev) =>
                        prev === member.id ? null : member.id
                      )
                    }
                    className='px-2 text-white bg-blue-500 rounded cursor-pointer'
                  >
                    +
                  </button>

                  {/* Dropdown beside the button */}
                  {activeDropdown === member.id && (
                    <div className='absolute top-1/2 left-[105%] transform -translate-y-1/2 bg-white border border-gray-300 rounded shadow-md p-2 w-full z-50'>
                      {Object.keys(categories).map((categoryId) => (
                        <button
                          key={categoryId}
                          onClick={() =>
                            handleAddToCategory(member.id, categoryId)
                          }
                          className='block w-full p-2 mb-2 text-left bg-gray-100 border border-gray-200 rounded cursor-pointer'
                        >
                          {categories[categoryId].name}
                        </button>
                      ))}
                      {/* cancel button */}
                      <button
                        onClick={() => setActiveDropdown(null)}
                        className='block w-full px-4 py-2 mt-2 mb-2 text-center text-red-500 bg-gray-100 border border-red-500 rounded cursor-pointer'
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Categories with Members */}
            <div className='grid grid-cols-1 col-span-9 gap-1'>
              {categoryOrder.map((categoryId, catIndex) => (
                <div
                  key={categoryId}
                  className='text-sm border border-gray-200 rounded '
                >
                  <div className='flex items-center justify-between p-2 mb-2 hover:bg-green-50'>
                    {editingCategory === categoryId ? (
                      <div className='flex items-center gap-2'>
                        <input
                          type='text'
                          value={editedCategoryName}
                          onChange={(e) =>
                            setEditedCategoryName(e.target.value)
                          }
                          className='px-2 py-1 border border-gray-300 rounded'
                        />
                        <button
                          onClick={() => {
                            // Update the state with the new category name
                            setCategories((prev) => ({
                              ...prev,
                              [categoryId]: {
                                ...prev[categoryId],
                                name: editedCategoryName,
                              },
                            }));
                            setEditingCategory(null); // Stop editing
                          }}
                          className='px-2 py-1 text-white bg-green-500 rounded'
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setEditingCategory(null); // Cancel editing
                            setEditedCategoryName(categories[categoryId].name); // Reset to the original name
                          }}
                          className='px-2 py-1 text-white bg-red-500 rounded'
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <h5 className='font-semibold'>
                        {categories[categoryId].name}
                        <button
                          onClick={() => {
                            setEditingCategory(categoryId); // Start editing
                            setEditedCategoryName(categories[categoryId].name); // Set initial value
                          }}
                          className='ml-2 text-blue-400 hover:text-blue-700'
                        >
                          <FaEdit />
                        </button>
                      </h5>
                    )}
                    <div>
                      <button
                        onClick={() => moveCategory(categoryId, "up")}
                        disabled={catIndex === 0}
                        className='p-1 mr-2 text-white bg-green-500 rounded cursor-pointer disabled:opacity-50'
                      >
                        <FaArrowUp />
                      </button>
                      <button
                        onClick={() => moveCategory(categoryId, "down")}
                        disabled={catIndex === categoryOrder.length - 1}
                        className='p-1 mr-2 text-white bg-green-500 rounded cursor-pointer disabled:opacity-50'
                      >
                        <FaArrowDown />
                      </button>

                      <button
                        onClick={() => removeCategory(categoryId)}
                        className='p-1 text-white bg-red-500 rounded cursor-pointer'
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                  {categories[categoryId].members.map((member, index) => (
                    <div
                      key={member.id}
                      className='flex items-center justify-between p-2 border-b border-gray-200 hover:bg-blue-50'
                    >
                      <div className='flex items-center gap-3'>
                        <img
                          src={
                            member.web_photo
                              ? member.web_profile_pic
                              : member.photo
                              ? member.profile_pic
                              : "https://ui-avatars.com/api/?name=" +
                                member.name +
                                "&background=0D8ABC&color=fff"
                          }
                          alt={member.name}
                          className='w-8 h-8 rounded-full'
                        />
                        <span>
                          {member.name} <br />
                          <small>{member.designation.name}</small> <br />
                          <small>{member.subheading1}</small> <br />
                          {member.link && (
                            <a
                              target='_blank'
                              className='text-blue-600'
                              href={member.link}
                              rel='noreferrer'
                            >
                              External Link
                            </a>
                          )}
                        </span>
                      </div>
                      <div>
                        <button
                          onClick={() =>
                            moveMember(categoryId, member.id, "up")
                          }
                          disabled={index === 0}
                          className='p-1 mr-2 text-white bg-blue-500 rounded cursor-pointer disabled:opacity-50'
                        >
                          <FaArrowUp />
                        </button>
                        <button
                          onClick={() =>
                            moveMember(categoryId, member.id, "down")
                          }
                          disabled={
                            index === categories[categoryId].members.length - 1
                          }
                          className='p-1 mr-2 text-white bg-blue-500 rounded cursor-pointer disabled:opacity-50'
                        >
                          <FaArrowDown />
                        </button>
                        <button
                          onClick={() =>
                            setEditingMember({ ...member, categoryId })
                          }
                          className='p-1 mr-2 text-white bg-yellow-500 rounded cursor-pointer'
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => removeMember(categoryId, member.id)}
                          className='p-1 text-white bg-red-500 rounded cursor-pointer'
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className='w-full mx-auto text-center'>
                    <button
                      onClick={() => setActiveCategoryForNewMember(categoryId)}
                      className='block p-2 mx-auto mt-2 border border-gray-300 rounded cursor-pointer hover:bg-green-50'
                    >
                      <FaPlus className='inline' /> Add External Member
                    </button>

                    {activeCategoryForNewMember === categoryId && (
                      <div className='p-4 mt-4 w-[300px] mx-auto mb-2 border border-gray-300 rounded bg-gray-50'>
                        <h4 className='mb-2 text-lg font-bold'>
                          Add New Member
                        </h4>
                        <div className='mb-2'>
                          <input
                            type='text'
                            placeholder='Name'
                            value={newMember.name}
                            onChange={(e) =>
                              setNewMember((prev) => ({
                                ...prev,
                                name: e.target.value,
                              }))
                            }
                            className='w-full px-3 py-2 border border-gray-300 rounded'
                          />
                        </div>
                        <button
                          type='button'
                          className='w-full px-4 py-2 mb-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                          onClick={() => setIsMediaModalOpen(true)}
                        >
                          <FaImage className='inline' /> Select Profile Image
                        </button>
                        {newMember.photo && (
                          <div className='my-2'>
                            <img
                              src={newMember.photo}
                              alt='Featured'
                              className='object-cover w-8 h-8 border rounded-full'
                            />
                          </div>
                        )}

                        <div className='mb-2'>
                          <input
                            type='text'
                            placeholder='Subheading 1'
                            value={newMember.subheading1}
                            onChange={(e) =>
                              setNewMember((prev) => ({
                                ...prev,
                                subheading1: e.target.value,
                              }))
                            }
                            className='w-full px-3 py-2 border border-gray-300 rounded'
                          />
                        </div>
                        <div className='mb-2'>
                          <input
                            type='text'
                            placeholder='External Link'
                            value={newMember.link}
                            onChange={(e) =>
                              setNewMember((prev) => ({
                                ...prev,
                                link: e.target.value,
                              }))
                            }
                            className='w-full px-3 py-2 border border-gray-300 rounded'
                          />
                        </div>

                        <div>
                          <button
                            onClick={() => addNewMember(categoryId)}
                            className='px-4 py-2 mr-2 text-white bg-green-500 rounded'
                          >
                            Save
                          </button>
                          <button
                            onClick={() => {
                              setActiveCategoryForNewMember(null);
                              setNewMember({
                                name: "",
                                photo: "",
                                subheading1: "",
                                subheading2: "",
                                link: "",
                              });
                            }}
                            className='px-4 py-2 text-white bg-red-500 rounded'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Edit Modal */}
                    {editingMember && (
                      <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
                        <div className='relative max-w-md p-4 bg-white rounded-lg min-w-md'>
                          <h4 className='mb-4 text-lg font-bold'>
                            Edit Member
                          </h4>
                          {!editingMember.isExternal && (
                            <>
                              <div className='flex items-center justify-start gap-3'>
                                <img
                                  src={
                                    editingMember.web_photo
                                      ? editingMember.web_profile_pic
                                      : editingMember.photo
                                      ? editingMember.profile_pic
                                      : "https://ui-avatars.com/api/?name=" +
                                        editingMember.name +
                                        "&background=0D8ABC&color=fff"
                                  }
                                  alt={editingMember.name}
                                  className='w-8 h-8 rounded-full'
                                />
                                <span className='flex flex-col items-start'>
                                  {editingMember.name} <br />
                                  <small>
                                    {editingMember.designation.name}
                                  </small>
                                </span>
                              </div>
                              <div className='text-left mt-2 w-[300px]'>
                                Subheading:
                              </div>
                              <input
                                type='text'
                                value={editingMember.subheading1 || ""}
                                onChange={(e) =>
                                  setEditingMember((prev) => ({
                                    ...prev,
                                    subheading1: e.target.value,
                                  }))
                                }
                                placeholder='Subheading 1'
                                className='w-full px-3 py-2 mb-4 border border-gray-300 rounded'
                              />
                            </>
                          )}
                          {editingMember.isExternal && (
                            <div className='w-full'>
                              <div className='text-left'>Name:</div>
                              <input
                                type='text'
                                value={editingMember.name}
                                onChange={(e) =>
                                  setEditingMember((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                                placeholder='Name'
                                className='w-full px-3 py-2 mb-4 border border-gray-300 rounded'
                              />

                              <button
                                type='button'
                                className='w-full px-4 py-2 mb-2 text-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white'
                                onClick={() => setIsMediaModalOpen(true)}
                              >
                                <FaImage className='inline' /> Select Profile
                                Image
                              </button>
                              {editingMember.photo && (
                                <div className='my-2'>
                                  <img
                                    src={editingMember.photo}
                                    alt='Featured'
                                    className='object-cover w-8 h-8 border rounded-full'
                                  />
                                </div>
                              )}
                              <div className='text-left'>Subheading:</div>
                              <input
                                type='text'
                                value={editingMember.designation?.name || ""}
                                onChange={(e) =>
                                  setEditingMember((prev) => ({
                                    ...prev,
                                    designation: {
                                      ...prev.designation,
                                      name: e.target.value,
                                    },
                                  }))
                                }
                                placeholder='Subheading 1'
                                className='w-full px-3 py-2 mb-4 border border-gray-300 rounded'
                              />
                              <div className='text-left'>External Link:</div>
                              <input
                                type='text'
                                value={editingMember.link || ""}
                                onChange={(e) =>
                                  setEditingMember((prev) => ({
                                    ...prev,
                                    link: e.target.value,
                                  }))
                                }
                                placeholder='External Link'
                                className='w-full px-3 py-2 mb-4 border border-gray-300 rounded'
                              />
                            </div>
                          )}

                          <div className='flex justify-end gap-2'>
                            <button
                              onClick={() => {
                                // Update the state with the edited member
                                const updatedMembers = categories[
                                  editingMember.categoryId
                                ].members.map((member) => {
                                  if (member.id === editingMember.id) {
                                    if (!editingMember.isExternal) {
                                      // For internal members: update only subheading1
                                      return {
                                        ...member,
                                        subheading1: editingMember.subheading1,
                                      };
                                    } else {
                                      // For external members: update name, photo, profile_pic, and subheading1
                                      return {
                                        ...member,
                                        name: editingMember.name,
                                        photo: editingMember.photo,
                                        profile_pic: editingMember.photo, // Update profile_pic as well
                                        designation: {
                                          ...member.designation,
                                          name: editingMember.designation?.name, // Update subheading1
                                        },
                                        link: editingMember.link,
                                      };
                                    }
                                  }
                                  return member;
                                });

                                setCategories((prev) => ({
                                  ...prev,
                                  [editingMember.categoryId]: {
                                    ...prev[editingMember.categoryId],
                                    members: updatedMembers,
                                  },
                                }));

                                setEditingMember(null); // Close the modal
                              }}
                              className='px-4 py-2 text-white bg-green-500 rounded'
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setEditingMember(null)}
                              className='px-4 py-2 text-white bg-red-500 rounded'
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Save Button */}
            <div className='col-span-12 mb-10 text-right'>
              <button
                onClick={saveToDatabase}
                className='px-4 py-2 text-white bg-green-500 rounded cursor-pointer'
              >
                Save Changes
              </button>
            </div>
          </div>
        </>
      )}

      {/* Media Management Modal */}
      {isMediaModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaModalOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement types='image' onSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
