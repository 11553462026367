import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const StudentsProjectDetail = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [studentsProjectDetails, setStudentsProjectDetails] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    role: "",
    student_name: "",
    year: "",
    department_centre: "",
    students_organization: "",
    other: "",
    start_date: "",
    end_date: "",
  });
  const headers = {
    title: {
      label: "Title of the Project",
      path: "title",
    },
    role: {
      label: "Your Role",
      path: "role",
    },
    student_name: {
      label: "Name(s) of the Student(s)",
      path: "student_name",
    },
    year: { label: "Semester/Year", path: "year" },
    department_centre: {
      label: "Student's Department/Centre",
      path: "department_centre",
    },
    students_organization: {
      label: "Student's Institution",
      path: "students_organization",
    },
    other: {
      label: "Other",
      path: "other",
    },
    start_date: { label: "Project Start Date", path: "start_date" },
    end_date: { label: "Project End Date", path: "end_date" },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    student_name: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    year: Yup.string().required("This field is required!"),
    department_centre: Yup.string().required("This field is required!"),
    students_organization: Yup.string().required("This field is required!"),
    other: Yup.string().when("students_organization", {
      is: "Other",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),
    start_date: Yup.string().required("This field is required!"),
    end_date: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/students-project-details");
      setStudentsProjectDetails(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(
          `/profile/students-project-details/${editId}`,
          values
        );
        toast.success(`Students Project Detail Updated Successfully`);

        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/students-project-details", values);
        toast.success(`Students Project Detail Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = studentsProjectDetails.find(
        (studentsProjectDetail) => studentsProjectDetail.id === id
      );
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        role: editableData.role,
        student_name: editableData.student_name,
        year: editableData.year,
        department_centre: editableData.department_centre,
        students_organization: editableData.students_organization,
        other: editableData.other,
        start_date: editableData.start_date,
        end_date: editableData.end_date,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      role: "",
      student_name: "",
      year: "",
      department_centre: "",
      students_organization: "",
      other: "",
      start_date: "",
      end_date: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      role: "",
      student_name: "",
      year: "",
      department_centre: "",
      students_organization: "",
      other: "",
      start_date: "",
      end_date: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/students-project-details/${id}`);
      toast.success(`Students Project Detail Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Students &apos; Project Details :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={studentsProjectDetails}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default StudentsProjectDetail;
