/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import MediaManagement from "./MediaManagement"; // Import the MediaManagement component
import {
  FaCross,
  FaEdit,
  FaSave,
  FaSpinner,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import BaseApi from "../../Http/BaseApi";
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import PageTitle from "../../Components/Common/PageTitle";
import { useSearchParams, useNavigate, Link } from "react-router-dom"; // Import routing utilities
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import Navigations from "./Navigations";

// Slugify function to generate slugs
const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-"); // Replace multiple - with single -

// Form validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  slug: Yup.string()
    .required("Slug is required")
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Invalid slug format"),
});

export default function WebCategories() {
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Manage modal open state
  const [categories, setCategories] = useState([]); // State to store categories
  const [loading, setLoading] = useState(true); // Loading state for fetching post data
  const formikRef = useRef();
  const [searchParams] = useSearchParams(); // Get URL parameters
  const [editId, setEditId] = useState();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const initialValues = {
    name: "",
    slug: "",
    featured_image: null,
  };

  // Handle file URL selection from MediaManagement modal
  const handleFileSelect = (url) => {
    setFeaturedImage(url);
    setIsMediaModalOpen(false); // Close modal after selection
    formikRef.current.setFieldValue("featured_image", url);
  };

  // Fetch category data for editing
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await BaseApi().get(`/website/categories`);
      const categories = response.data;
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching category data", error);
    }

    setLoading(false);
  };

  const onEdit = (category) => {
    setEditId(category.id);

    formikRef.current.setFieldValue("name", category.name);
    formikRef.current.setFieldValue("slug", category.slug);
    formikRef.current.setFieldValue("featured_image", category.featured_image);
    setFeaturedImage(category.featured_image);
  };
  const handleSubmit = async (values, { resetForm }) => {
    console.log("asd");

    try {
      if (editId) {
        // If `edit_id` is present, update the category
        await BaseApi().put(`/website/categories/${editId}`, values);
        console.log("category updated successfully");
      } else {
        // Create a new post
        await BaseApi().post(`/website/categories`, values);
        console.log("category created successfully");
      }
    } catch (error) {
      LaravelValidationError(error); // Call ValidationError to show error toasts
      console.error("Error submitting category", error);
    } finally {
      resetForm();
      setFeaturedImage(null);
      fetchCategories();
      setEditId(null);
    }
  };

  return (
    <div className='container px-5 mx-auto mt-5 rounded-md shadow-md'>
      <Navigations></Navigations>
      <PageTitle name={editId ? "Edit Category" : "Create new Category"} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values, setFieldValue }) =>
          loading ? (
            <div className='flex items-center justify-center h-full'>
              <div>Loading...</div>
            </div>
          ) : (
            <Form>
              <div className='grid grid-cols-1 gap-6 lg:grid-cols-12'>
                {/* Main Content Area */}
                <div className='lg:col-span-9'>
                  {/* Name */}
                  <div className='mb-4 bg-white rounded-md '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Name
                    </label>
                    <Field
                      name='name'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter category name...'
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("slug", slugify(e.target.value));
                      }}
                    />
                    {touched.name && errors.name && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.name}
                      </div>
                    )}
                  </div>

                  {/* Slug */}
                  <div className='mb-4 bg-white '>
                    <label className='block mb-2 text-sm font-medium text-gray-700'>
                      Slug
                    </label>
                    <Field
                      name='slug'
                      className='w-full p-2 border rounded-md'
                      placeholder='Enter category slug...'
                    />
                    {touched.slug && errors.slug && (
                      <div className='mt-1 text-sm text-red-500'>
                        {errors.slug}
                      </div>
                    )}
                  </div>
                </div>

                {/* Sidebar Area */}
                <div className='space-y-6 lg:col-span-3'>
                  {/* Save Button */}
                  <div className='flex gap-2'>
                    <button
                      type='submit'
                      className='w-full py-2 text-white transition bg-blue-600 rounded-md hover:bg-blue-700'
                    >
                      <FaSave className='inline' />{" "}
                      {editId ? "Update Category" : "Save Category"}
                    </button>
                    <button
                      onClick={() => {
                        setFeaturedImage(null);
                        setEditId(null);
                        formikRef.current.resetForm();
                      }}
                      className='flex items-center justify-center px-2 py-2 text-red-500 border rounded-md hover:bg-red-500 hover:text-white'
                    >
                      <FaTimes className='inline' /> Cancel
                    </button>
                  </div>

                  {/* Featured Image */}
                  <div className='bg-white '>
                    <h5 className='mb-3 text-lg font-medium'>Featured Image</h5>
                    <button
                      type='button'
                      className='px-4 py-2 mt-1 text-white bg-blue-500 rounded-md'
                      onClick={() => setIsMediaModalOpen(true)}
                    >
                      Select Featured Image
                    </button>

                    {featuredImage && (
                      <div className='mt-4'>
                        <img
                          src={featuredImage}
                          alt='Featured'
                          className='object-cover w-full h-40 border rounded-md'
                        />
                        <a
                          target='_blank'
                          href={featuredImage}
                          className='mt-2 text-sm text-blue-700 break-all pointer-cursor'
                          rel='noreferrer'
                        >
                          Selected: {featuredImage}
                        </a>
                        <button
                          className='ml-5 text-red-500'
                          onClick={() => {
                            setFeaturedImage(null);
                            setFieldValue("featured_image", null);
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>

      {/* Media Management Modal */}
      {isMediaModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
            <button
              className='absolute top-0 right-0 m-2 text-2xl'
              onClick={() => setIsMediaModalOpen(false)}
            >
              <FaTimes />
            </button>
            {/* make scrollable */}
            <div className='h-[90vh] overflow-y-scroll'>
              <MediaManagement types='image' onSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      )}

      <h1>List of categories</h1>
      <div className='bg-white '>
        {loading ? (
          <div className='flex justify-center'>
            <FaSpinner className='text-3xl text-blue-600 animate-spin' />
          </div>
        ) : (
          <>
            <table className='w-full border-collapse table-auto'>
              <thead>
                <tr className='text-left border-b'>
                  <th className='px-4 py-2'>#</th>
                  <th className='px-4 py-2'>Name</th>
                  <th className='px-4 py-2'>Slug</th>
                  <th className='px-4 py-2'>Image</th>

                  <th className='px-4 py-2'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {categories.length > 0 ? (
                  categories.map((category, index) => (
                    <tr
                      key={category.id}
                      className={`border-b hover:bg-gray-100 ${
                        category.status == "draft"
                          ? "opacity-50"
                          : "opacity-100"
                      }`}
                    >
                      <td className='px-4 py-2'>
                        {index + 1 + (currentPage - 1) * 10}
                      </td>
                      <td className='px-4 py-2'>{category.name}</td>
                      <td className='px-4 py-2'>{category.slug}</td>
                      <td className='px-4 py-2'>
                        <img
                          src={category.featured_image}
                          className='w-64 h-auto '
                        />
                      </td>

                      <td className='flex items-center px-4 py-2 space-x-2'>
                        <button
                          onClick={() => {
                            onEdit(category);
                          }}
                          className='flex items-center space-x-1 text-blue-500 hover:underline'
                        >
                          <FaEdit /> <span>Edit</span>
                        </button>
                        <span>|</span>
                        <button className='flex items-center space-x-1 text-red-500 hover:underline'>
                          <FaTrash /> <span>Delete</span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='5' className='py-4 text-center'>
                      No categories found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
          </>
        )}
      </div>
    </div>
  );
}
