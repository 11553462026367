"use client";
import {
  BlockNoteSchema,
  defaultBlockSpecs,
  filterSuggestionItems,
  insertOrUpdateBlock,
  BlockNoteEditor,
} from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import {
  SuggestionMenuController,
  getDefaultReactSlashMenuItems,
} from "@blocknote/react";

import { RiFilePdfFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { PDF } from "./BlockNoteSchemas/PDF";
import { ImageGallery } from "./BlockNoteSchemas/ImageGallery";

// Define schema with block specs
const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    pdf: PDF,
    gallery: ImageGallery,
  },
});

export default function CustomBlockNoteViewer({ initialValue }) {
  const [initialContent, setInitialContent] = useState(null); // Start with null
  const [editor, setEditor] = useState(null); // State to hold the editor instance

  async function parseContent() {
    const storageString = initialValue;
    return storageString
      ? JSON.parse(storageString)
      : [
          {
            type: "paragraph",
            content: "Enter your content here.",
          },
        ];
  }

  // Load initial content from localStorage on component mount
  useEffect(() => {
    parseContent().then((content) => {
      setInitialContent(content);
    });
  }, []);

  // Create editor instance after initialContent is loaded
  useEffect(() => {
    if (initialContent && !editor) {
      const newEditor = BlockNoteEditor.create({
        schema,
        initialContent,
      });

      // Set the editor instance
      setEditor(newEditor);
    }
  }, [initialContent, editor]);

  // Display loading state if editor is not yet created
  if (!editor) {
    return "Loading content...";
  }

  // Render the editor instance
  return (
    <div className='-mx-10'>
      <BlockNoteView
        theme='light'
        editor={editor}
        editable={false}
        formattingToolbar={true}
        linkToolbar={true}
        sideMenu={true}
        slashMenu={false}
        emojiPicker={true}
        filePanel={true}
        tableHandles={true}
      ></BlockNoteView>
    </div>
  );
}
