import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { FaCheckSquare, FaIdCard, FaUser, FaUsers } from "react-icons/fa";
import {
  FaCalendarDays,
  FaLightbulb,
  FaMapLocationDot,
  FaRegMap,
} from "react-icons/fa6";
import moment from "moment";

const headers = {
  title: { label: "Title of the Invention", path: "title" },
  inventors_creators: { label: "Inventors", path: "inventors_creators" },
  applicant_name: { label: "Applicant’s Name", path: "applicant_name" },
  application_number: {
    label: "Application Number",
    path: "application_number",
  },
  status: { label: "Status", path: "status" },
  patent_number: { label: "Patent Number", path: "patent_number" },
  patent_granted_date: {
    label: "Patent Granted Date",
    path: "patent_granted_date",
  },
  filling_country: { label: "Filing Country", path: "filling_country" },
  invention: { label: "Field of Invention", path: "invention" },
  filling_date: { label: "Filing Date", path: "filling_date" },
  published_date: { label: "Published Date", path: "published_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

// Helper function to check for invalid dates
const isInvalidDate = (date) => {
  const parsedDate = moment(date, "YYYY-MM-DD", true);
  return (
    !parsedDate.isValid() || date === "00-00-0000" || date === "0000-00-00"
  );
};

// Format date function
const formatDate = (date) => {
  return !isInvalidDate(date) ? moment(date).format("DD MMM YYYY") : null;
};

const Patent = ({ view }) => {
  const [patents, setPatents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Set initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/patents");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredPatents = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by published_date (latest date first)
      // const sortedPatents = filteredPatents.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.published_date.path));
      //   const dateB = moment(getValueByPath(b, headers.published_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setPatents(filteredPatents);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(patents.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderPatent = (patent) => {
    const title = getValueByPath(patent, headers.title.path);
    const inventorsCreators = getValueByPath(
      patent,
      headers.inventors_creators.path
    );
    const applicantName = getValueByPath(patent, headers.applicant_name.path);
    const applicationNumber = getValueByPath(
      patent,
      headers.application_number.path
    );
    const status = getValueByPath(patent, headers.status.path);
    const patentNumber = getValueByPath(patent, headers.patent_number.path);
    const invention = getValueByPath(patent, headers.invention.path);
    const fillingDate = formatDate(
      getValueByPath(patent, headers.filling_date.path)
    );
    const publishedDate = formatDate(
      getValueByPath(patent, headers.published_date.path)
    );
    const patentGrantedDate = formatDate(
      getValueByPath(patent, headers.patent_granted_date.path)
    );
    const fillingCountry = getValueByPath(patent, headers.filling_country.path);

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {/* Title */}
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>

          {/* Inventors */}
          {inventorsCreators && (
            <p className='flex items-center gap-2 text-base text-green-600 whitespace-nowrap'>
              <FaUser />
              <span className='text-green-600'>{inventorsCreators}</span>
            </p>
          )}

          {/* Main Info Section */}
          <div className='flex-wrap items-center space-y-1 lg:flex lg:gap-4'>
            {/* Application Number */}
            {applicationNumber && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaIdCard className='flex-shrink-0 text-yellow-500' />
                Application No: {applicationNumber}
              </div>
            )}

            {/* Field of Invention */}
            {invention && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaLightbulb className='flex-shrink-0 text-yellow-500' />
                {invention}
              </div>
            )}

            {/* Status */}
            {status && (
              <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaCheckSquare
                  className={
                    status === "Published"
                      ? "text-yellow-500 "
                      : "text-green-500"
                  }
                />
                {status}
              </span>
            )}

            {/* Filing Date */}
            {fillingDate && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaCalendarDays className='text-yellow-500' />
                Filed on: {fillingDate}
              </div>
            )}

            {/* Published Date */}
            {publishedDate && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaCalendarDays className='text-yellow-500' />
                Published on: {publishedDate}
              </div>
            )}

            {/* Patent Info for Granted Status */}
            {status === "Granted" && (
              <>
                {/* Patent Number */}
                {patentNumber && (
                  <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                    <FaIdCard className='text-yellow-500' />
                    Patent No: {patentNumber}
                  </div>
                )}

                {/* Granted Date */}
                {patentGrantedDate && (
                  <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                    <FaCalendarDays className='text-yellow-500' />
                    Granted on: {patentGrantedDate}
                  </div>
                )}
              </>
            )}

            {/* Filing Country */}
            {fillingCountry && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaMapLocationDot className='text-red-600' />
                {fillingCountry}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Header with count */}
      <div className='flex justify-between px-2 py-1 border-b'>
        <h2 className='flex items-center gap-2 text-xl font-medium text-blue-900'>
          <FaUsers />
          Patents
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2 dark:bg-blue-900 dark:text-blue-300'>
          {patents.length}
        </span>
      </div>

      {/* Patent List */}
      {patents.length ? (
        <div>
          {patents.slice(0, visibleCount).map((patent, index) => (
            <div key={index}>{renderPatent(patent)}</div>
          ))}

          {patents.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Patent;
