import React from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";

const TableComponent = ({ headers, data, handleEdit, handleDelete }) => {
  // Add a check to ensure 'data' is an array and not undefined or null
  const validData = Array.isArray(data) ? data : [];

  return (
    <div className='overflow-x-auto'>
      <Button
        classNames='float-right mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
        value={"Download CSV"}
        onClick={() => tableToCSV("datatable")}
        icon={"DownloadIcon"}
      />
      <table
        id='datatable'
        className='min-w-full my-5 bg-white border border-gray-200 '
      >
        <thead>
          <tr className='bg-gray-100 border-b'>
            {Object.values(headers).map((header, index) => (
              <th
                key={index}
                className='px-4 py-2 text-sm font-medium text-left text-gray-700 uppercase'
              >
                {header.label}
              </th>
            ))}
            <th className='px-4 py-2 text-sm font-medium text-left text-gray-700 uppercase'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {validData.length > 0 ? (
            validData.map((item, index) => (
              <tr
                key={index}
                className={`border-b ${
                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                }`}
              >
                {Object.keys(headers).map((key) => (
                  <td key={key} className='px-4 py-2 text-sm'>
                    {key === "status" ? (
                      <span
                        className={
                          item[key] === "Active"
                            ? "text-green-600"
                            : "text-gray-600"
                        }
                      >
                        {item[key]}
                      </span>
                    ) : (
                      <> {item[key]} </>
                    )}
                  </td>
                ))}

                <td className='flex px-4 py-2 space-x-2'>
                  <button
                    className='flex items-center px-2 py-1 text-blue-500 bg-white border border-blue-500 rounded-full shadow hover:bg-blue-50'
                    onClick={() => handleEdit(item.id)}
                  >
                    <PencilIcon className='w-5 h-5' />
                  </button>
                  <button
                    className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50'
                    onClick={() => handleDelete(item.id)}
                  >
                    <TrashIcon className='w-5 h-5' />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={Object.keys(headers).length + 1}
                className='px-4 py-2 text-sm text-center text-gray-600'
              >
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
