import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import CustomSelect from "../../Components/Utils/CustomSelect";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);

  const statusTypes = [
    { id: 1, name: "Active" },
    { id: 3, name: "Inactive" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        External Ph. D. Supervisor
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className='grid grid-cols-2 gap-3 p-5 '>
          <Input
            type='text'
            name='supervisor_name'
            label={
              <div className='flex items-center'>
                Supervisor Name
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />

          <Input
            type='text'
            name='designation'
            label={
              <div className='flex items-center'>
                Designation
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='text'
            name='institute'
            label={
              <div className='flex items-center'>
                Institute
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
          />
          <Input
            type='date'
            name='recognition_date'
            label='Recognition Date (Optional)'
          />

          <CustomSelect
            defaultInputValue={initialValues.status}
            options={statusTypes}
            name='status'
            label={
              <div className='flex items-center'>
                Status
                <span className='ml-1 text-red-600'>*</span>
              </div>
            }
            isSearchable={true}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => `${option.name}`}
            onChange={async (selectedOption) => {
              console.log("Selected option:", selectedOption);
              formikRef.current?.setFieldValue("status", selectedOption.name);
              if (selectedOption.name === "Status") {
                formikRef.current?.setFieldValue("active", "");
              }
            }}
          />

          <div className='flex justify-between col-span-2 mt-10'>
            <button
              className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
              onClick={onCancel}
            >
              <XIcon className='w-5 h-5 mr-2' /> Cancel
            </button>
            {isEditMode ? (
              <Button type='submit' value='Update' icon={"SaveIcon"} />
            ) : (
              <Button type='submit' value='Save' icon={"SaveIcon"} />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FormPage;
