import { ErrorMessage } from "formik";
import React, { useState, useRef, useEffect } from "react";

function SearchableDropdown(props) {
  const [selected, setSelected] = useState(false);
  let classNames = props.readOnly ? "bg-gray-100" : "";

  const options = props.options;
  options.map((element) => {
    // console.log(part);
    element.name =
      element.full_name +
      (element.designation?.name != undefined
        ? "[" + element.designation?.name + "]"
        : "") +
      (element.department?.name != undefined
        ? "[" + element.department?.name + "]"
        : "");
    return element;
  });
  const [searchTerm, setSearchTerm] = useState(
    props?.defaultOption?.name ?? ""
  );
  // const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // console.log(dropdownRef);
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
        if (selected == false && searchTerm.length > 0) {
          // console.log('searchterm',searchTerm.length);
          // console.log('selected',selected);
          handleClear();
        }
      }
    };
    let blurTimeout;
    const handleBlur = () => {
      blurTimeout = setTimeout(() => {
        setShowOptions(false);
        handleClear(); // Clearing the search and selected option
        if (selected == false && searchTerm.length > 0) {
          // console.log('searchterm',searchTerm.length);
          // console.log('selected',selected);
          handleClear();
        }
      }, 200); // Adjust the delay as needed
    };

    document.addEventListener("mousedown", handleClickOutside);

    const inputElement = dropdownRef.current.querySelector("input"); // Get the input element
    inputElement.addEventListener("blur", handleBlur); // Listen for the blur event on the input

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      inputElement.removeEventListener("blur", handleBlur);
      clearTimeout(blurTimeout);
    };
  }, [dropdownRef, searchTerm, selected]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setShowOptions(true);
    props.setSelectedOption("");
    setSelected(false);
  };

  const handleClear = () => {
    setSearchTerm("");
    props.setSelectedOption("");
    setShowOptions(false);
    setSelected(false);
  };

  const handleSelect = (option) => {
    props.setSelectedOption(option);
    setSearchTerm(option.name);
    setShowOptions(false);
    setSelected(true);
  };

  const filteredOptions = options.filter(
    (option) =>
      option.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  );

  return (
    <div className=''>
      <label
        htmlFor={props.id}
        className='block text-sm font-medium text-gray-700'
      >
        {props.label}
      </label>
      <div ref={dropdownRef}>
        <div className='relative'>
          <input
            type='text'
            value={searchTerm}
            onChange={handleSearch}
            placeholder='Enter atleast 3 character to search'
            className={`appearance-none mt-2 rounded-none   block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
          />
          {searchTerm.length > 0 && (
            <button
              type='button'
              className='absolute top-0 right-0 m-2'
              onClick={handleClear}
            >
              x
            </button>
          )}
        </div>
        {showOptions && searchTerm.length > 2 && (
          <ul className='absolute z-10 bg-white rounded-md shadow-xl'>
            {filteredOptions.map((option) => (
              <li
                key={option.id}
                onClick={() => handleSelect(option)}
                className='p-2 hover:bg-gray-200'
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component='div'
        className='py-2 text-red-500'
      />
    </div>
  );
}

export default SearchableDropdown;
