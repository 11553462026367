import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaPeopleGroup,
  FaRegObjectUngroup,
} from "react-icons/fa6";
import { PiChatsCircleFill } from "react-icons/pi";

const headers = {
  collaboration_name: {
    label: "Name of the Institute/ Organization/Individual",
    path: "collaboration_name",
  },
  collaboration_type: {
    label: "Type of Collaboration",
    path: "collaboration_type",
  },
  other_collaboration: {
    label: "Other Collaboration",
    path: "other_collaboration",
  },
  from_date: { label: "From Date Collaboration", path: "from_date" },
  to_date: { label: "To Date Collaboration", path: "to_date" },
  collaboration_objective: {
    label: "Objective of the Collaboration",
    path: "collaboration_objective",
  },
  collaboration_details: {
    label: "Level of the collaboration",
    path: "collaboration_details",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : null;
};

const Collaboration = ({ view }) => {
  const [collaborations, setCollaborations] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/collaborations");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredCollaborations = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // // Sort by from_date in descending order
      // const sortedCollaborations = filteredCollaborations.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.from_date.path));
      //   const dateB = moment(getValueByPath(b, headers.from_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setCollaborations(filteredCollaborations);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    setVisibleCount(isExpanded ? 5 : collaborations.length);
    setIsExpanded(!isExpanded);
  };

  const renderCollaboration = (collaboration) => {
    const collaborationName = getValueByPath(
      collaboration,
      headers.collaboration_name.path
    );
    const collaborationType = getValueByPath(
      collaboration,
      headers.collaboration_type.path
    );
    const otherCollaboration = getValueByPath(
      collaboration,
      headers.other_collaboration.path
    );
    const collaborationObjective = getValueByPath(
      collaboration,
      headers.collaboration_objective.path
    );
    const collaborationDetails = getValueByPath(
      collaboration,
      headers.collaboration_details.path
    );
    const fromDate = formatDate(
      getValueByPath(collaboration, headers.from_date.path)
    );
    const toDate = formatDate(
      getValueByPath(collaboration, headers.to_date.path)
    );

    // Check for invalid data
    const isValidData =
      collaborationName &&
      (collaborationType || otherCollaboration) &&
      collaborationObjective &&
      collaborationDetails &&
      fromDate;

    // Return null if data is invalid
    if (!isValidData) return null;

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>
            {collaborationName}
          </h3>

          {/* Updated Logic to Display Other Collaboration if Type is "Others" */}
          {collaborationType === "Others" ? (
            <div className='flex gap-1 text-sm '>
              <FaPeopleGroup className='flex-shrink-0 mt-1 text-yellow-500' />
              Type: <span className=''>{otherCollaboration}</span>
            </div>
          ) : (
            collaborationType && (
              <div className='flex gap-1 text-sm '>
                <FaPeopleGroup className='mt-1 text-yellow-500' />
                Type: <span className=''>{collaborationType}</span>
              </div>
            )
          )}

          {collaborationDetails && (
            <span className='flex items-center gap-1 text-sm '>
              <FaFileLines className='text-yellow-500' />
              Level: {collaborationDetails}
            </span>
          )}

          <div className='gap-3 '>
            {collaborationObjective && (
              <span className='flex gap-1 text-sm '>
                <FaRegObjectUngroup className='flex-shrink-0 mt-1 text-yellow-500' />
                Objective: {collaborationObjective}
              </span>
            )}

            <div className='flex items-center gap-1 text-sm'>
              {fromDate || toDate ? (
                <>
                  <FaCalendarDays className='text-yellow-500' />
                  {fromDate}
                  {toDate && ` - ${toDate}`}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <PiChatsCircleFill className='mt-1' />
          Collaboration
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {collaborations.length}
        </span>
      </div>

      {collaborations.length ? (
        <div>
          {collaborations.slice(0, visibleCount).map((collaboration, index) => (
            <div key={index}>{renderCollaboration(collaboration)}</div>
          ))}
          {collaborations.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Collaboration;
