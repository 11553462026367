import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BaseApi from "../../../../Http/BaseApi";
import Button from "../../../../Components/Utils/Button";
import FormPage from "./FormPage";
import Modal from "../../utils/Modal";
import TableComponent from "../../utils/TableComponent";

const ExtensionActivitie = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [extensionActivities, setExtensionActivities] = useState([]);
  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    from_date: "",
    to_date: "",
    award_received: "",
    award_recognition: "",
    awarding_agency: "",
    month: "",
    year: "",
  });
  const headers = {
    name: { label: "Name of the Activity ", path: "name" },
    from_date: { label: "From date", path: "from_date" },
    to_date: { label: "To Date", path: "to_date" },
    award_received: {
      label: "Whether Any Award received for this activity?",
      path: "award_received",
    },
    award_recognition: {
      label: "Name of the Award/Recognition",
      path: "award_recognition",
    },
    awarding_agency: {
      label: "Name of the Awarding Agency",
      path: "awarding_agency",
    },
    month: { label: "Month of Award", path: "monthName" },
    year: { label: "Year of Award", path: "year" },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    from_date: Yup.string().required("This field is required!"),
    to_date: Yup.string().required("This field is required!"),
    award_received: Yup.string().required("This field is required!"),
    award_recognition: Yup.string().when("award_received", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    awarding_agency: Yup.string().when("award_received", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    month: Yup.string().when("award_received", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    year: Yup.number().when("award_received", {
      is: "Yes",
      then: Yup.number()
        .typeError("Year must be a valid year")
        .required("This field is required!")
        .min(1980, "Year must be at least 1980")
        .max(new Date().getFullYear(), `Year can't be in the future`),
      otherwise: Yup.number().notRequired(),
    }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/extension-activities");
      setExtensionActivities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/extension-activities/${editId}`, values);
        toast.success(`E-Content Development Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/extension-activities", values);
        toast.success(`E-Content Development Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = extensionActivities.find(
        (extensionActivitie) => extensionActivitie.id === id
      );
      setInitialValues({
        id: editableData.id,
        name: editableData.name,
        from_date: editableData.from_date,
        to_date: editableData.to_date,
        award_received: editableData.award_received,
        award_recognition: editableData.award_recognition,
        awarding_agency: editableData.awarding_agency,
        month: editableData.month,
        year: editableData.year,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      name: "",
      from_date: "",
      to_date: "",
      award_received: "",
      award_recognition: "",
      awarding_agency: "",
      month: "",
      year: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      name: "",
      from_date: "",
      to_date: "",
      award_received: "",
      award_recognition: "",
      awarding_agency: "",
      month: "",
      year: "",
    });
    setIsEditing(false);
    setIsAdding(false);
    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/extension-activities/${id}`);
      toast.success(`E-Content Development Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Outreach/Extension Activitie :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={extensionActivities}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ExtensionActivitie;
