import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import BaseApi from "../../Http/BaseApi";
import {
  FaSpinner,
  FaEdit,
  FaTrash,
  FaPlus,
  FaArrowLeft,
} from "react-icons/fa";
import moment from "moment";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PageTitle from "../../Components/Common/PageTitle";
import LaravelValidationError from "../../Components/Utils/LaravelValidationError";
import CustomBlockNoteViewer from "../../Components/Utils/CustomBlockNoteViewer";
import Navigations from "./Navigations";

export default function ShowPage() {
  const { id } = useParams({});
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchPage() {
      try {
        const response = await BaseApi().get(`/website/pages/${id}`);
        setPage(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching page:", error);
      }
    }

    fetchPage();
  }, [id]);
  return (
    <div className='container min-h-screen px-5 mx-auto mt-5 rounded-md shadow-md'>
      <div className='flex justify-between'>
        <Navigations></Navigations>
        <div className='flex gap-2'>
          <Link
            to='/website/pages'
            className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
          >
            <FaArrowLeft className='inline w-4 mr-2' /> Back
          </Link>
          <Link
            to={`/website/pages/create?edit_id=${id}`}
            className='flex items-center px-4 py-2 space-x-1 text-blue-500 transition duration-200 ease-in-out border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white hover:border-transparent '
          >
            <FaEdit className='inline w-4 mr-2' /> Edit
          </Link>
        </div>
      </div>

      {page.featured_image?.length > 0 && (
        <img
          src={page.featured_image}
          alt={page.title}
          className='object-cover w-full mt-2 rounded h-80'
        />
      )}

      <PageTitle name={page.title} />
      <h3>{page.subtitle}</h3>

      {/* Pages Table */}
      <div className='mt-2 bg-white '>
        <p>
          {page.status === "published"
            ? `Published On: ${moment(page.published_at).format(
                "DD MMM YYYY hh:mm A"
              )}`
            : "Status: Draft"}
        </p>

        <div className='my-8 overflow-hidden text-lg text-gray-900'>
          {page.content && (
            <CustomBlockNoteViewer initialValue={page.content} />
          )}
        </div>
      </div>
    </div>
  );
}
