import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const EduQualification = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [qualifications, setQualifications] = useState([]); // Array to store qualifications
  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    qualification_master_id: "",
    qualification: "",
    institute: "",
    subject: "",
    pass_year: "",
  });
  const headers = {
    qualification_master_id: {
      label: "Qualification Type",
      path: "qualification_master.name",
    },
    qualification: { label: "Qualification", path: "qualification" },
    institute: { label: "Institute", path: "institute" },
    subject: { label: "Subject/Specialization", path: "subject" },
    pass_year: { label: "Pass Year", path: "pass_year" },
  };

  const validationSchema = Yup.object({
    qualification_master_id: Yup.string().required("Qualification is required"),
    qualification: Yup.string().required("Qualification is required"),
    institute: Yup.string().required("Institute is required"),
    subject: Yup.string().required("Subject is required"),
    pass_year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/educational-qualifications"
      );
      setQualifications(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(
          `/profile/educational-qualifications/${editId}`,
          values
        );
        toast.success(`Educational Qualifications Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/educational-qualifications", values);
        toast.success(`Educational Qualifications Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = qualifications.find(
        (qualification) => qualification.id === id
      );
      setInitialValues({
        id: editableData.id,
        qualification_master_id: editableData.qualification_master_id,
        qualification: editableData.qualification,
        institute: editableData.institute,
        subject: editableData.subject,
        pass_year: editableData.pass_year,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      qualification_master_id: "",
      qualification: "",
      institute: "",
      subject: "",
      pass_year: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      qualification_master_id: "",
      qualification: "",
      institute: "",
      subject: "",
      pass_year: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/educational-qualifications/${id}`);
      toast.success(`Educational Qualification Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50">
          <h1>Educational Qualifications :</h1>
          <Button
            type="add"
            value="Add more"
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className="p-2 border bg-blue-50">
          <TableComponent
            headers={headers}
            data={qualifications}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default EduQualification;
