import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import TableViewProfile from "../../../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Book",
    path: "title",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publication",
    path: "monthName",
  },
  year: {
    label: "Year of Publication",
    path: "year",
  },
  editor_names: {
    label: "Editor Names: (As per Book)",
    path: "editor_names",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
  book_type_others: {
    label: "Book Type Others",
    path: "book_type_others",
  },
  indexed_in: {
    label: "Indexed In",
    path: "indexed_in",
  },
};

const BooksEdited = () => {
  const [booksEdits, setBooksEdits] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/edited-books");
      setBooksEdits(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase">
        Books Edited
      </h2>
      <TableViewProfile
        headers={headers}
        data={booksEdits}
        type="edited-books"
        setData={setBooksEdits}
      />
    </div>
  );
};

export default BooksEdited;
