import React from "react";
import { Link } from "react-router-dom";
import * as HIcons from "@heroicons/react/outline";

function SideBarLink(props) {
  const { ...icons } = HIcons;
  const TheIcon = icons[props.icon];

  // const SvgIcon = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     stroke="#000000"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <path d="M9 18l6-6-6-6" />
  //   </svg>
  // );
  return (
    <Link
      to={props.to}
      className='flex items-center p-2 px-1 mt-3 text-gray-900 duration-300 rounded-md cursor-pointer hover:bg-gray-200'
      href='#!'
      data-mdb-ripple='true'
      data-mdb-ripple-color='primary'
    >
      <TheIcon className='w-4 h-4 mr-2 text-gray-900' aria-hidden='true' />
      <span>{props.label}</span>
    </Link>
  );
}

export default SideBarLink;
