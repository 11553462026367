import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { MdEditDocument } from "react-icons/md";

const headers = {
  title: {
    label: "Title of the Book",
    path: "title",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publication",
    path: "monthName",
  },
  year: {
    label: "Year of Publication",
    path: "year",
  },
  editor_names: {
    label: "Editor Names: (As per Book)",
    path: "editor_names",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
  book_type_others: {
    label: "Book Type Others",
    path: "book_type_others",
  },
  indexed_in: {
    label: "Indexed In",
    path: "indexed_in",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const BooksEdited = ({ view }) => {
  const [booksEdits, setBooksEdits] = useState([]);
  const [showAll, setShowAll] = useState(false); // State for Read More/Read Less

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/edited-books");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredBooks = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year and month (descending)
      // const sortedBooks = filteredBooks.sort((a, b) => {
      //   const yearDiff = parseInt(b.year) - parseInt(a.year);
      //   if (yearDiff !== 0) return yearDiff;

      //   // Assuming months are stored as full month names (e.g., "January")
      //   const monthNames = [
      //     "January",
      //     "February",
      //     "March",
      //     "April",
      //     "May",
      //     "June",
      //     "July",
      //     "August",
      //     "September",
      //     "October",
      //     "November",
      //     "December",
      //   ];
      //   return (
      //     monthNames.indexOf(b.monthName) - monthNames.indexOf(a.monthName)
      //   );
      // });

      setBooksEdits(filteredBooks);
    } catch (error) {
      console.error(error);
    }
  };

  const renderBooksEdited = (book) => {
    const editorNames = getValueByPath(book, headers.editor_names.path);
    const year = getValueByPath(book, headers.year.path);
    const title = getValueByPath(book, headers.title.path);
    const publisher = getValueByPath(book, headers.publisher.path);
    const isbn = getValueByPath(book, headers.isbn.path);

    return (
      <div className='pb-2 mb-2 border-b'>
        <p className='font-light'>
          <span className='font-semibold'>{editorNames}</span> ({year}).{" "}
          <i>{title}</i>. {publisher}. ISBN: {isbn}
        </p>
      </div>
    );
  };

  // Limit to 5 books initially, show all if `showAll` is true
  const visibleBooks = showAll ? booksEdits : booksEdits.slice(0, 5);

  return (
    <div>
      {/* Header with count */}
      <div className='flex justify-between px-2 py-2 mb-3 border-b'>
        <h2 className='flex gap-2 text-xl font-medium text-blue-900'>
          <MdEditDocument className='mt-1' />
          Books Edited
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2 dark:bg-blue-900 dark:text-blue-300'>
          {booksEdits.length}
        </span>
      </div>

      {booksEdits.length ? (
        <div>
          {visibleBooks.map((book, index) => (
            <div key={index} className='flex items-start px-2'>
              <span className='flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full'>
                {index + 1}
              </span>
              {renderBooksEdited(book)}
            </div>
          ))}

          {/* Show Read More / Read Less based on state */}
          {booksEdits.length > 5 && (
            <div className='mt-2 text-center'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default BooksEdited;
