import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  const projectTypes = [
    { id: 1, name: "National" },
    { id: 2, name: "International" },
  ];
  const statusTypes = [
    { id: 1, name: "Ongoing" },
    { id: 2, name: "Completed" },
  ];
  const roleTypes = [
    { id: 1, name: "Pl" },
    { id: 2, name: "Co-Pl" },
  ];
  const projectRelatedTypes = [
    { id: 1, name: "Teaching" },
    { id: 2, name: "Research" },
    { id: 3, name: "Both Teaching and Research" },
  ];
  const collaborativeProjectTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];
  const typeFundingAgencyTypes = [
    { id: 1, name: "Government Agency" },
    { id: 2, name: "Institutional Body" },
    { id: 3, name: "PSU, Industry" },
    { id: 4, name: "Corporate Home" },
    { id: 5, name: "Chairs in the Universities" },
    { id: 6, name: "Non-Profit Organization" },
    { id: 7, name: "University" },
  ];

  return (
    <>
      <h1 className="flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase ">
        Project
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="grid grid-cols-2 gap-3 p-5 ">
            <Input
              type="text"
              name="title"
              label={
                <div className="flex items-center">
                  Title of the Project
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.type}
              options={projectTypes}
              name="type"
              label={
                <div className="flex items-center">
                  Project Type
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("type", selectedOption.name);
              }}
            />
            <CustomSelect
              defaultInputValue={initialValues.role}
              options={roleTypes}
              name="role"
              label={
                <div className="flex items-center">
                  Project Role
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("role", selectedOption.name);
              }}
            />
            <CustomSelect
              defaultInputValue={initialValues.project_related}
              options={projectRelatedTypes}
              name="project_related"
              label={
                <div className="flex items-center">
                  Project related to
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "project_related",
                  selectedOption.name
                );
              }}
            />

            <CustomSelect
              defaultInputValue={initialValues.collaborative_project}
              options={collaborativeProjectTypes}
              name="collaborative_project"
              label={
                <div className="flex items-center">
                  Is it a collaborative project?
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "collaborative_project",
                  selectedOption.name
                );
              }}
            />

            {values.collaborative_project == "Yes" && (
              <Input
                type="text"
                name="collaborative_project_details"
                label={
                  <div className="flex items-center">
                    Collaboration Details
                    <span className="ml-1 text-red-600">*</span>
                  </div>
                }
              />
            )}

            <Input
              type="text"
              name="funding_agency"
              label={
                <div className="flex items-center">
                  Funding Agency
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />

            <CustomSelect
              defaultInputValue={initialValues.type_funding_agency}
              options={typeFundingAgencyTypes}
              name="type_funding_agency"
              label={
                <div className="flex items-center">
                  Type of Funding Agency
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "type_funding_agency",
                  selectedOption.name
                );
              }}
            />

            <Input
              type="text"
              placeholder="Type '0' in case of no research fellow"
              name="research_fellow"
              label={
                <div className="flex items-center">
                  Number of Research Fellows under the Project
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              defaultInputValue={0}
            />

            <Input
              type="text"
              name="grant_no"
              label={
                <div className="flex items-center">
                  Grant No.
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <Input
              type="text"
              name="amount_granted"
              label={
                <div className="flex items-center">
                  Amount Granted
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.status}
              options={statusTypes}
              name="status"
              label={
                <div className="flex items-center">
                  Status
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("status", selectedOption.name);
              }}
            />

            <Input
              type="date"
              name="start_date"
              label={
                <div className="flex items-center">
                  Start Date
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            />

            {(values.status == "Completed" || values.status == 2) && (
              <Input
                type="date"
                label={
                  <div className="flex items-center">
                    End Date
                    <span className="ml-1 text-red-600">*</span>
                  </div>
                }
                name="end_date"
              />
            )}

            <div className="flex justify-between col-span-2 mt-10">
              <button
                className="flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={onCancel}
              >
                <XIcon className="w-5 h-5 mr-2" /> Cancel
              </button>
              {isEditMode ? (
                <Button type="submit" value="Update" icon={"SaveIcon"} />
              ) : (
                <Button type="submit" value="Save" icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
