import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import Input from "../../../../Components/Utils/Input";
import { ClockIcon, XIcon } from "@heroicons/react/outline";
import Upload from "../../../../Components/Utils/Upload";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);
  const [isDraft, setIsDraft] = useState(false); // State to manage draft mode

  const indexedInTypes = [
    { id: 1, name: "Scopus" },
    { id: 2, name: "Web of Science(core)" },
    { id: 3, name: "Web of Science (others)" },
    { id: 4, name: "Both Scopus and WOS" },
  ];
  const publishedTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];
  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const conferenceTypes = [
    { id: 1, name: "National" },
    { id: 2, name: "International (Abroad)" },
    {
      id: 3,
      name: "International (In India)",
    },
  ];
  const handleDraftClick = async () => {
    setIsDraft(true); // Set draft mode to true

    // Clear validation errors if any exist
    await formikRef.current.setErrors({});

    // Immediately submit the form without triggering validation
    formikRef.current.submitForm();
  };

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Conference/Seminar Paper
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={!isDraft ? validationSchema : null} // Apply validation schema only if not draft
        validateOnChange={!isDraft} // Disable validation on change when in draft mode
        validateOnBlur={!isDraft} // Disable validation on blur when in draft mode
        onSubmit={(values) => {
          const payload = { ...values, draft: isDraft }; // Add draft param to payload

          onSubmit(payload);
          setIsDraft(false); // Reset draft mode after submit
        }}
      >
        {({ values }) => (
          <Form className='grid grid-cols-3 gap-3 p-5 '>
            <Input
              type='textarea'
              name='title'
              label={
                <div className='flex items-center'>
                  Title of the Paper
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='conference_name'
              label={
                <div className='flex items-center'>
                  Name of the Conference/Seminar
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.conference_type}
              options={conferenceTypes}
              name='conference_type'
              label={
                <div className='flex items-center'>
                  Type of the Conference/Seminar
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "conference_type",
                  selectedOption.name
                );
              }}
            />
            <Input
              type='text'
              name='organizer'
              label={
                <div className='flex items-center'>
                  Organizer
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='date'
              name='conference_start_date'
              label={
                <div className='flex items-center'>
                  Start Date of Conference/Seminar
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='date'
              name='conference_end_date'
              label={
                <div className='flex items-center'>
                  End Date of Conference/Seminar
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              placeholder='Enter Author Names separated by commas, if more than one author'
              name='author_names'
              label={
                <div className='flex items-center'>
                  Author Names: (As per Conference Paper)
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.published}
              options={publishedTypes}
              name='published'
              label={
                <div className='flex items-center'>
                  Whether published in the proceeding?
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "published",
                  selectedOption.name
                );

                // If 'No' is selected, clear other related fields
                if (selectedOption.name == "No") {
                  formikRef.current?.setFieldValue(
                    "conference_proceeding_title",
                    ""
                  );
                  formikRef.current?.setFieldValue("month", "");
                  formikRef.current?.setFieldValue("year", "");
                  formikRef.current?.setFieldValue("editor_name", "");
                  formikRef.current?.setFieldValue("issn_isbn", "");
                  formikRef.current?.setFieldValue("publisher_name", "");
                  formikRef.current?.setFieldValue("page_no", "");
                  formikRef.current?.setFieldValue("indexed_in", "");
                  formikRef.current?.setFieldValue("url", "");
                  formikRef.current?.setFieldValue("file_url", ""); // For file upload
                }
              }}
            />

            {values.published === "Yes" && (
              <>
                <Input
                  type='text'
                  name='conference_proceeding_title'
                  label={
                    <div className='flex items-center'>
                      Title of the Conference Proceeding
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <CustomSelect
                  defaultInputValue={
                    monthTypes.find((month) => month.id == values.month)?.name
                  }
                  options={monthTypes}
                  name='month'
                  label={
                    <div className='flex items-center'>
                      Month of Publication
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  isSearchable={true}
                  getOptionValue={(option) => `${option.id}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={async (selectedOption) => {
                    formikRef.current?.setFieldValue(
                      "month",
                      selectedOption.id
                    );
                  }}
                />
                <Input
                  type='text'
                  name='year'
                  label={
                    <div className='flex items-center'>
                      Year of Publication
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='editor_name'
                  label={
                    <div className='flex items-center'>
                      Editor Name with Affiliation
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='issn_isbn'
                  label={
                    <div className='flex items-center'>
                      ISSN/ISBN
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='publisher_name'
                  label={
                    <div className='flex items-center'>
                      Publisher Name
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='page_no'
                  label={
                    <div className='flex items-center'>
                      Page No/Paper No
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <CustomSelect
                  options={indexedInTypes}
                  name='indexed_in'
                  label={
                    <div className='flex items-center'>
                      Indexed in
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  isSearchable={true}
                  getOptionValue={(option) => `${option.id}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={async (selectedOption) => {
                    console.log("Selected option:", selectedOption);
                    formikRef.current?.setFieldValue(
                      "indexed_in",
                      selectedOption.name
                    );
                    console.log(
                      "Formik values after update:",
                      formikRef.current?.values
                    );
                  }}
                />

                {/* <Input type="text" name="url" label="URL/DOI" /> */}

                <Input
                  type='text'
                  name='url'
                  label={
                    <div className='flex items-center'>
                      URL/DOI
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <div className='space-y-1'>
                  <label>
                    <div className='flex items-center'>
                      If URL/DOI not available, then please upload the first
                      page of the published paper
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  </label>
                  <Upload
                    label={
                      <ul className='text-red-600'>
                        <li>File upload max 500 KB</li>
                        <li>Image/PDF only</li>
                      </ul>
                    }
                    type='file'
                    name='file_url'
                    onChange={(event) => {
                      console.log("asd");
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue("file_url", myFile);
                    }}
                  />
                </div>
              </>
            )}

            <div className='flex justify-between col-span-3 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {values.status != "Submitted" && (
                <button
                  type='button'
                  onClick={handleDraftClick} // Use custom draft handler
                  className='flex items-center px-2 py-1 text-purple-500 bg-white border border-purple-500 rounded-full shadow hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                >
                  <ClockIcon className='w-5 h-5 mr-2' /> Save as Draft
                </button>
              )}
              {!isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
