import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import AccountMasters from "../Pages/Accounts/AccountMasters";
import FeeCollection from "../Pages/Accounts/FeeCollection/FeeCollection";
import HostelFeeCollection from "../Pages/Accounts/FeeCollection/HostelFeeCollection";
import PaymentGroup from "../Pages/Accounts/PaymentGroup/PaymentGroup";
import PaymentGroupHead from "../Pages/Accounts/PaymentGroupHead/PaymentGroupHead";
import PaymentGroupProgrammeIndex from "../Pages/Accounts/PaymentGroupProgramme/PaymentGroupProgrammeIndex";
import PaymentHead from "../Pages/Accounts/PaymentHead/PaymentHead";
import DeletedPayments from "../Pages/Accounts/Payments/DeletedPayments";
import PaymentIndex from "../Pages/Accounts/Payments/PaymentIndex";
import PaymentReceipt from "../Pages/Accounts/Payments/PaymentReceipt";
import PendingPayments from "../Pages/Accounts/Payments/PendingPayments";
import ApplicantCount from "../Pages/Applicants/ApplicantCount";
import ApplicantCountBedcet from "../Pages/Applicants/ApplicantCountBedcet";
import ApplicantCountDupget from "../Pages/Applicants/ApplicantCountDupget";
import ApplicantCountDurat from "../Pages/Applicants/ApplicantCountDurat";
import ApplicantShow from "../Pages/Applicants/ApplicantShow";
import ApplicantIndex from "../Pages/Applicants/AppplicantIndex";
import ApplicantSearch from "../Pages/Applicants/AppplicantSearch";
import AttendanceSheet from "../Pages/Applicants/AttendanceSheet";
import StaffChangePassword from "../Pages/Auth/Staff/ChangePassword";
import BudgetProposalEntry from "../Pages/Budgets/BudgetProposalEntry";
import BudgetProposalSubmit from "../Pages/Budgets/BudgetProposalSubmit";
import ExHostelerIndex from "../Pages/Hostel/ExHostelerIndex";
import HostelerIndex from "../Pages/Hostel/HostelerIndex";
import HostelMessFees from "../Pages/Hostel/HostelMessFees";
import NotReportedHostelerIndex from "../Pages/Hostel/NotReportedHostelerIndex";
import IdCardMaster from "../Pages/IdCardMaster/IdCardMaster";
import HolidayCalendar from "../Pages/Leave/HolidayCalendar";
import LeaveApprovalIndex from "../Pages/Leave/LeaveApprovalIndex";
import LeaveApprovalResolved from "../Pages/Leave/LeaveApprovalResolved";
import LeaveApprove from "../Pages/Leave/LeaveApprove";
import LeaveCreate from "../Pages/Leave/LeaveCreate";
import LeaveCreateIndex from "../Pages/Leave/LeaveCreateIndex";
import LeaveReport from "../Pages/Leave/LeaveReport";
import LeaveStaffIndex from "../Pages/Leave/LeaveStaffIndex";
import OverallLeaveReport from "../Pages/Leave/OverallLeaveReport";
import ViewLeaveDetails from "../Pages/Leave/ViewLeaveDetails";
import ReportProblem from "../Pages/ReportProblem";
import AdmittedStudentReport from "../Pages/Reports/AdmittedStudentReport";
import EditAuthority from "../Pages/Staff/EditAuthority";
import EditEmail from "../Pages/Staff/EditEmail";
import EditPhone from "../Pages/Staff/EditPhone";
import ProfilePage from "../Pages/Staff/ProfilePage";
import StaffAdminEdit from "../Pages/Staff/StaffAdminEdit";
import StaffCreate from "../Pages/Staff/StaffCreate";
import StaffEdit from "../Pages/Staff/StaffEdit";
import StaffIndex from "../Pages/Staff/StaffIndex";
import StaffPermission from "../Pages/Staff/StaffPermission";

import AdmittedStudentIndex from "../Pages/Student/AdmittedStudentIndex";
import ExStudents from "../Pages/Student/ExStudents";
import StudentIDCard from "../Pages/Student/StudentIDCard";
import ViewAdmittedStudent from "../Pages/Student/ViewAdmittedStudent";
import OfferedSubjects from "../Pages/Subjects/OfferedSubjects";
import HostelTransfer from "../Pages/Hostel/HostelTransfer";
import StudentPaymentReports from "../Pages/Reports/StudentPaymentReports";
import PHDRecords from "../Pages/Student/PHDRecords";
import StaffIDCard from "../Pages/Staff/StaffIDCard";
import StaffEditForIdCard from "../Pages/Staff/StaffEditForIdCard";
import PHDLastFees from "../Pages/Student/PHDLastFees";
import RegisteredPHDRecords from "../Pages/Student/RegisteredPHDRecords";
import AwardedPHDRecords from "../Pages/Student/AwardedPHDRecords";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Programmes from "../Pages/Masters/Programmes/Programmes";
import StudentCount from "../Pages/Student/StudentCount";
import Designations from "../Pages/Masters/Designations/Designations";
import Masters from "../Pages/Masters/Masters";
import SubjectStatus from "../Pages/Subjects/SubjectStatus";
import FellowshipHolders from "../Pages/Student/FellowshipHolders";
import StaffListToViewLeaves from "../Pages/Staff/StaffListToViewLeaves";
import VoucherPortal from "../Pages/Accounts/VoucherPortal";
import FacultyProfile from "../Pages/FacultyProfile/FacultyProfile";
import NoticeCategories from "../Pages/NoticeCategories/NoticeCategories";
import NoticeAndCirculars from "../Pages/NoticeAndCirculars/NoticeAndCircularsCreate";
import ViewProfile from "../Pages/FacultyProfile/ViewProfile/ViewProfile";
import DetailPage from "../Pages/FacultyProfile/utils/DetailPage";
import IqacFacultyProfile from "../Pages/FacultyProfile/IqacFacultyProfile/IqacFacultyProfile";
import WebsiteFacultyProfile from "../Pages/FacultyProfile/WebsiteFacultyProfile/WebsiteFacultyProfile";
import PaySlips from "../Pages/PaySlips/PaySlips";
import FileCreate from "../Pages/FileTracking/FileCreate";
import FileMovement from "../Pages/FileTracking/FileMovement";
import ListOfFile from "../Pages/FileTracking/ListOfFiles";
import FileDetail from "../Pages/FileTracking/FileDetails";
import ListOfFiles from "../Pages/FileTracking/ListOfFiles";
import FileDetails from "../Pages/FileTracking/FileDetails";
import FileTrackingDashboard from "../Pages/FileTracking/FileTrackingDashboard";
import StaffFtDepartments from "../Pages/FileTracking/StaffFtDepartments";
import NoticeAndCircularsCreate from "../Pages/NoticeAndCirculars/NoticeAndCircularsCreate";
import NoticeAndCircularsIndex from "../Pages/NoticeAndCirculars/NoticeAndCircularsIndex";
import StudentSubjects from "../Pages/Student/StudentSubjects";
import ExternalPhdSupervisor from "../Pages/ExternalPhdSupervisor/ExternalPhdSupervisor";

export default [
  <>
    <Route path='faculty-profile-website' element={<WebsiteFacultyProfile />} />
    <Route key='staff' element={<ProtectedRoute />}>
      <Route path='dashboard' element={<Dashboard />} />
      {/* admitted students */}
      <Route path='admitted-students' element={<AdmittedStudentIndex />} />
      <Route path='admitted-students/:id' element={<ViewAdmittedStudent />} />
      <Route path='admitted-students/id-card/:id' element={<StudentIDCard />} />
      <Route path='id-card-masters' element={<IdCardMaster />} />
      {/* new applicants part */}
      <Route path='applicants/' element={<ApplicantIndex />} />
      <Route path='applicant-counts' element={<ApplicantCount />} />
      <Route path='applicant-search' element={<ApplicantSearch />} />
      <Route
        path='applicant-counts/dupget'
        element={<ApplicantCountDupget />}
      />
      <Route
        path='applicant-counts/bedcet'
        element={<ApplicantCountBedcet />}
      />
      <Route path='applicant-counts/durat' element={<ApplicantCountDurat />} />
      <Route path='applicants/:id' element={<ApplicantShow />} />
      {/* <Route path="applicants/make-admission/:id" element={<MakeAdmission />} /> */}
      {/* Attendance Sheet */}
      <Route path='attendance-sheet/' element={<AttendanceSheet />} />
      {/* Accounts */}
      <Route path='payment-heads' element={<PaymentHead />} />
      <Route path='payment-groups' element={<PaymentGroup />} />
      <Route path='payment-group-heads' element={<PaymentGroupHead />} />
      <Route
        path='payment-group-programmes'
        element={<PaymentGroupProgrammeIndex />}
      />
      <Route path='fee-collection' element={<FeeCollection />} />
      <Route path='hostel-fee-collection' element={<HostelFeeCollection />} />
      <Route path='account-masters' element={<AccountMasters />} />
      <Route path='payments' element={<PaymentIndex />} />
      <Route path='payments/receipt/:id' element={<PaymentReceipt />} />
      <Route path='payments-pending' element={<PendingPayments />} />
      <Route path='payments-deleted' element={<DeletedPayments />} />
      {/* Hostelers */}
      <Route path='hostelers' element={<HostelerIndex />} />
      <Route path='ex-hostelers' element={<ExHostelerIndex />} />
      <Route
        path='not-reported-hostelers'
        element={<NotReportedHostelerIndex />}
      />
      <Route path='hostel-mess-fees' element={<HostelMessFees />} />
      <Route path='hostel-transfer' element={<HostelTransfer />} />
      {/* managestaff  */}
      <Route path='profile' element={<ProfilePage />} />
      <Route path='staff' element={<StaffIndex />} />
      <Route path='staff-list' element={<StaffListToViewLeaves />} />
      <Route path='staff/admin-edit/:id' element={<StaffAdminEdit />} />
      <Route path='staff/edit/:id' element={<StaffEdit />} />
      <Route
        path='staff/edit-for-id-card/:id'
        element={<StaffEditForIdCard />}
      />
      <Route path='staff/edit-authority/:id' element={<EditAuthority />} />
      <Route path='staff/edit-phone/:id' element={<EditPhone />} />
      <Route path='staff/edit-email/:id' element={<EditEmail />} />
      <Route path='staff/create' element={<StaffCreate />} />
      <Route path='staff/permission' element={<StaffPermission />} />
      <Route path='staff/id-card/:id' element={<StaffIDCard />} />
      <Route path='change-password' element={<StaffChangePassword />} />
      <Route path='ex-students' element={<ExStudents />} />
      <Route path='fellowship-holders' element={<FellowshipHolders />} />
      <Route path='phd-students-records' element={<PHDRecords />} />
      <Route
        path='registered-phd-students-records'
        element={<RegisteredPHDRecords />}
      />
      <Route
        path='awarded-phd-students-records'
        element={<AwardedPHDRecords />}
      />
      <Route path='phd-monthly-fee' element={<PHDLastFees />} />
      {/* Reports */}
      <Route
        path='admitted-student-report'
        element={<AdmittedStudentReport />}
      />
      <Route
        path='student-payments-report'
        element={<StudentPaymentReports />}
      />
      {/* budgets */}
      <Route
        path='budget-proposal-entry/:type'
        element={<BudgetProposalEntry />}
      />
      <Route path='budget-proposal-submit' element={<BudgetProposalSubmit />} />
      {/* Leaves */}
      <Route path='leaves/create' element={<LeaveCreateIndex />} />
      <Route path='leaves/create/:id' element={<LeaveCreate />} />
      <Route path='leaves' element={<LeaveStaffIndex />} />
      <Route path='leaves/:id' element={<ViewLeaveDetails />} />
      <Route path='leave/approvals' element={<LeaveApprovalIndex />} />
      <Route
        path='leave/resolved-approvals'
        element={<LeaveApprovalResolved />}
      />
      <Route path='leave/approvals/:id' element={<LeaveApprove />} />
      <Route path='leave-report' element={<LeaveReport />} />
      <Route path='leave-report/:id' element={<OverallLeaveReport />} />
      <Route path='holiday-calendar' element={<HolidayCalendar />} />
      <Route path='report-problem' element={<ReportProblem />} />
      <Route path='subjects/' element={<OfferedSubjects />} />
      <Route path='subjects/status' element={<SubjectStatus />} />
      <Route path='programmes' element={<Programmes />} />
      <Route path='student-counts' element={<StudentCount />} />
      {/* masters */}
      <Route path='masters' element={<Masters />} />
      <Route path='designations' element={<Designations />} />
      <Route path='voucher-portal' element={<VoucherPortal />} />
      <Route path='faculty-profile' element={<FacultyProfile />} />
      <Route path='notice-categories' element={<NoticeCategories />} />
      <Route path='notice-circulars' element={<NoticeAndCircularsIndex />} />
      <Route
        path='notice-circulars-create'
        element={<NoticeAndCircularsCreate />}
      />
      <Route path='view-profile' element={<ViewProfile />} />
      <Route path='/profile/details/:type/:id' element={<DetailPage />} />
      <Route path='faculty-profile-iqac' element={<IqacFacultyProfile />} />
      {/* file tracking */}
      <Route
        path='staff/file-tracking-departments'
        element={<StaffFtDepartments />}
      />
      <Route path='file-tracking' element={<FileTrackingDashboard />} />
      <Route path='file-create' element={<FileCreate />} />
      <Route path='file-movement/:id' element={<FileMovement />} />
      <Route path='list-of-files' element={<ListOfFiles />} />
      <Route path='file-details/:id' element={<FileDetails />} />
      <Route path='pay-slips' element={<PaySlips />} />
      <Route path='student-subjects' element={<StudentSubjects />} />
      <Route
        path='external-phd-supervisor'
        element={<ExternalPhdSupervisor />}
      />
    </Route>
    ,
  </>,
];
