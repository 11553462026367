import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  title: { label: "Title of the lecture/talk", path: "title" },
  type: { label: "Type of lecture/talk", path: "type" },
  organization_name: {
    label: "Organization Name",
    path: "organization_name",
  },
  workshop_name: {
    label: "Name of the Workshop/Seminar/Occasion/Event",
    path: "workshop_name",
  },
  lecture_date: { label: "Date", path: "lecture_date" },
  lecture_details: { label: "Details", path: "lecture_details" },
};

const LecturesTalk = () => {
  const [lecturesTalks, setLecturesTalks] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/lectures-talks");
      setLecturesTalks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Invited Lecture/Talk
      </h2>
      <TableViewProfile
        headers={headers}
        data={lecturesTalks}
        type="lectures-talks"
        setData={setLecturesTalks}
      />
    </div>
  );
};

export default LecturesTalk;
