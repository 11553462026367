import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Navigations() {
  const links = [
    {
      name: "Back to  Dashboard",
      icon: <FaArrowLeft className='inline w-4 mr-2' />,
      link: "/website/dashboard",
    },
  ];

  return (
    <nav className=''>
      <ul className='flex '>
        {links.map((link, index) => (
          <li key={index}>
            <Link
              to={link.link}
              className='block py-2 text-blue-700 transition duration-200 rounded-lg hover:text-blue-500 '
            >
              {link.icon}
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
