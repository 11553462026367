import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../../Components/Utils/Input";
import CustomSelect from "../../../../../Components/Utils/CustomSelect";
import Button from "../../../../../Components/Utils/Button";
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";

const FormPage = ({
  partialEdit,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);

  const addAwardField = () => {
    const currentAwards = formikRef.current.values.awards;
    formikRef.current.setFieldValue("awards", [
      ...currentAwards,
      { date: "", title: "", details: "" },
    ]);
  };

  const removeAwardField = (index) => {
    const currentAwards = formikRef.current.values.awards;
    if (currentAwards.length > 1) {
      const updatedAwards = currentAwards.filter((_, i) => i !== index);
      formikRef.current.setFieldValue("awards", updatedAwards);
    }
  };

  const roleTypes = [
    { id: 1, name: "Supervisor" },
    { id: 2, name: "Co-Supervisor" },
  ];

  const statusTypes = [{ id: 1, name: "Awarded" }];

  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase'>
        Ph.D. Awardees (Registered in DU)
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='p-5 mb-10'>
            <div className='grid grid-cols-2 gap-3'>
              <Input
                type='text'
                name='researcher_name'
                label={
                  <div className='flex items-center'>
                    Name of the Ph. D. Awardee
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                readOnly={partialEdit}
              />

              <CustomSelect
                defaultInputValue={initialValues.role}
                options={roleTypes}
                name='role'
                label={
                  <div className='flex items-center'>
                    Role
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue("role", selectedOption.name);
                }}
                readOnly={partialEdit}
              />
              <Input
                type='text'
                name='thesis_title'
                label='Thesis Title'
                readOnly={partialEdit}
              />

              {values.institute === "Other Institute" && (
                <Input
                  type='text'
                  name='other_institute'
                  label={
                    <div className='flex items-center'>
                      Other Institute
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  readOnly={partialEdit}
                />
              )}

              <CustomSelect
                defaultInputValue={initialValues.status}
                options={statusTypes}
                name='status'
                label={
                  <div className='flex items-center'>
                    Status
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue(
                    "status",
                    selectedOption.name
                  );
                  if (selectedOption.name === "Status") {
                    formikRef.current?.setFieldValue("awarded_date", "");
                  }
                }}
              />

              <CustomSelect
                defaultInputValue={
                  monthTypes.find((month) => month.id == initialValues.month)
                    ?.name
                }
                options={monthTypes}
                name='month'
                label={
                  <div className='flex items-center'>
                    Ph. D. Awarded Month
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue("month", selectedOption.id);
                }}
              />
              <Input
                type='text'
                name='year'
                label={
                  <div className='flex items-center'>Ph. D. Awarded Year</div>
                }
              />

              <Input
                type='text'
                name='status_designation'
                label={
                  <div className='flex items-center'>
                    Present Status/Designation with name of the Organization
                    {/* <span className='ml-1 text-red-600'>*</span> */}
                  </div>
                }
              />

              <Input
                type='text'
                name='shodhganga_link'
                label={
                  <div className='flex items-center'>
                    Shodhganga link of the thesis
                  </div>
                }
              />
            </div>

            {/* Awards Fields */}

            <div className='mt-5 space-y-5'>
              <h2 className='flex items-center justify-center p-3 mt-2 font-normal text-center uppercase text-md '>
                Notable achievements by the Ph. D. Awardee
              </h2>
              {values.awards.map((award, index) => (
                <div key={index} className='flex items-center gap-3 '>
                  <div className='w-full'>
                    <Input
                      type='date'
                      name={`awards[${index}].date`}
                      label='Date of Award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='w-full'>
                    <Input
                      type='text'
                      name={`awards[${index}].title`}
                      label='Title of award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='w-full'>
                    <Input
                      type='text'
                      name={`awards[${index}].details`}
                      label='Details of Award'
                      readOnly={partialEdit}
                    />
                  </div>

                  <div className='flex mt-6'>
                    <div className='flex justify-end'>
                      <div className='cursor-pointer' onClick={addAwardField}>
                        <IoAddCircle className='text-green-600' size={32} />
                      </div>
                    </div>

                    <div className='flex justify-end col-span-3'>
                      {index > 0 && (
                        <div
                          className='ml-1 cursor-pointer'
                          onClick={() => removeAwardField(index)}
                        >
                          <IoRemoveCircle className='text-red-600' size={32} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='mt-10 text-sm text-red-400'>
              Click the{" "}
              <IoAddCircle className='inline text-green-600' size={32} /> Icon
              to add more achievements details.
            </div>

            <div className='flex justify-between mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>

              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
