import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import FormPage from "./FormPage";
import Modal from "../FacultyProfile/utils/Modal";
import Button from "../../Components/Utils/Button";
import BaseApi from "../../Http/BaseApi";
import TableComponent from "./TableComponent";

const ExternalPhdSupervisor = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [externalPhdSupervisors, setExternalPhdSupervisors] = useState([]);

  const [editId, setEditId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    id: "",
    supervisor_name: "",
    designation: "",
    institute: "",
    recognition_date: "",
    status: "Active",
  });
  const headers = {
    supervisor_name: { label: "Supervisor Name", path: "supervisor_name" },
    designation: { label: "Designation", path: "designation" },
    institute: { label: "Institute", path: "institute" },
    recognition_date: { label: "Recognition date", path: "recognition_date" },
    status: { label: "Status", path: "status" },
  };

  const validationSchema = Yup.object({
    supervisor_name: Yup.string().required("This field is required!"),
    designation: Yup.string().required("This field is required!"),
    institute: Yup.string().required("This field is required!"),
    status: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/external-phd-supervisors");
      setExternalPhdSupervisors(response.data.data);
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/external-phd-supervisors/${editId}`, values);
        toast.success(`External Ph. D. Supervisor Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/external-phd-supervisors", values);
        toast.success(`External Ph. D. Supervisor Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = externalPhdSupervisors.find(
        (externalPhdSupervisor) => externalPhdSupervisor.id === id
      );

      setInitialValues({
        id: editableData.id,
        supervisor_name: editableData.supervisor_name,
        designation: editableData.designation,
        institute: editableData.institute,
        recognition_date: editableData.recognition_date,
        status: editableData.status ?? "Active",
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      supervisor_name: "",
      designation: "",
      institute: "",
      recognition_date: "",
      status: "Active",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      supervisor_name: "",
      designation: "",
      institute: "",
      recognition_date: "",
      status: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/external-phd-supervisors/${id}`);
      toast.success(`External Ph. D. Supervisor Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase '>
          <h1 className='font-bold'>External Ph. D. Supervisors :</h1>
          <Button
            type='add'
            value='Add New'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 '>
          <TableComponent
            headers={headers}
            data={externalPhdSupervisors}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ExternalPhdSupervisor;
