import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaBriefcase,
  FaCalendar,
  FaCalendarDays,
  FaMapLocationDot,
} from "react-icons/fa6";
import { PiUsersFourFill } from "react-icons/pi";
import {
  FaCalendarCheck,
  FaCalendarDay,
  FaCalendarWeek,
  FaMapMarker,
} from "react-icons/fa";

const headers = {
  name: { label: "Title of the Activity", path: "name" },
  nature: { label: "Nature of the Activity", path: "nature" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  place: { label: "Place of Activity", path: "place" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : null;
};

const isValidActivity = (activity) => {
  const name = getValueByPath(activity, headers.name.path);
  const nature = getValueByPath(activity, headers.nature.path);
  const fromDate = formatDate(getValueByPath(activity, headers.from_date.path));
  const place = getValueByPath(activity, headers.place.path);

  return name && nature && fromDate && place;
};

const CorporateLife = ({ view }) => {
  const [corporateLives, setCorporateLives] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/corporate-lives");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredLives = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by 'from_date' in descending order
      // const sortedLives = filteredLives.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.from_date.path));
      //   const dateB = moment(getValueByPath(b, headers.from_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setCorporateLives(filteredLives);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(corporateLives.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderCorporateLife = (activity) => {
    const name = getValueByPath(activity, headers.name.path);
    const nature = getValueByPath(activity, headers.nature.path);
    const fromDate = formatDate(
      getValueByPath(activity, headers.from_date.path)
    );

    const toDate = formatDate(getValueByPath(activity, headers.to_date.path));

    const place = getValueByPath(activity, headers.place.path);

    // Only render the activity if it's valid
    if (!isValidActivity(activity)) {
      return null;
    }

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          <p className='flex items-center gap-1 text-sm '>
            <FaBriefcase className='flex-shrink-0 text-green-600' />
            <span className='text-green-600'>{nature}</span>
          </p>

          <p className='flex items-center gap-3 text-sm '>
            <span className='flex items-center gap-1 text-sm text-gray-800 '>
              <FaCalendarDays className='text-yellow-500' />
              {toDate ? (
                <>
                  {fromDate} - {toDate}
                </>
              ) : (
                fromDate
              )}
            </span>

            <span className='flex items-center gap-1 text-sm text-gray-800'>
              {" "}
              <FaMapLocationDot className='text-red-600' />
              {place}
            </span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <PiUsersFourFill className='mt-1' />
          Contribution to Corporate Life
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {corporateLives.length}
        </span>
      </div>

      {corporateLives.length ? (
        <div>
          {corporateLives.slice(0, visibleCount).map((activity, index) => (
            <div key={index}>{renderCorporateLife(activity)}</div>
          ))}
          {corporateLives.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default CorporateLife;
