import React, { useEffect, useState } from "react";
import {
  FaClipboardList,
  FaPeopleGroup,
  FaUserGraduate,
} from "react-icons/fa6";
import { FaCalendar, FaCalendarDay, FaRegThumbsUp } from "react-icons/fa";
import BaseApi from "../../../../../../Http/BaseApi";
import { FaAward } from "react-icons/fa6";

const headers = {
  researcher_name: {
    label: "Name of the Ph. D. Scholar",
    path: "researcher_name",
  },
  role: { label: "Role", path: "role" },
  thesis_title: { label: "Thesis Title", path: "thesis_title" },
  status: { label: "Status", path: "status" },
  advisoryCommittee: {
    label: "Members of the Research Advisory Committee",
    path: "advisoryCommittee",
  },
  achievements: {
    label: "Achievements",
    path: "achievements",
    isArray: true, // Mark this field as an array
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const PresentResearchScholar = ({ view }) => {
  const [presentResearchScholars, setPresentResearchScholars] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/present-research-scholars"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      setPresentResearchScholars(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(presentResearchScholars.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderPresentResearchScholar = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.researcher_name.path);
    const role = getValueByPath(guidance, headers.role.path);
    const thesisTitle = getValueByPath(guidance, headers.thesis_title.path);
    const status = getValueByPath(guidance, headers.status.path);
    const advisoryCommittee = getValueByPath(
      guidance,
      headers.advisoryCommittee.path
    );

    const achievements = getValueByPath(guidance, headers.achievements.path);

    return (
      <div className="items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600">
        <div className="p-2 space-y-1">
          {role && (
            <h3 className="text-lg font-medium text-blue-900">Role: {role}</h3>
          )}
          <p className="flex items-center gap-1 text-sm whitespace-nowrap">
            {scholarName && (
              <span className="text-green-600">
                <FaUserGraduate className="inline" /> {scholarName}
              </span>
            )}
          </p>
          {thesisTitle && (
            <div className="flex gap-1 text-sm ">
              <FaClipboardList className="text-yellow-500 flex-shrink-0 mt-1" />
              <span className="text-gray-800">{thesisTitle}</span>
            </div>
          )}

          <div className="flex items-center gap-3">
            {status && (
              <span className="flex items-center gap-1 text-sm ">
                <FaRegThumbsUp
                  className={
                    status === "Ongoing" ? "text-yellow-500" : "text-green-500"
                  }
                />
                {status}
              </span>
            )}
            {advisoryCommittee && (
              <span className="flex items-center gap-1 text-sm">
                <FaPeopleGroup className="text-yellow-500" />
                {advisoryCommittee}
              </span>
            )}
          </div>
          {achievements?.filter(
            (achievement) => achievement.award_date || achievement.title
          ).length > 0 && (
            <>
              <h4 className="text-sm font-medium text-blue-500">
                Notable Achievements
              </h4>
              {achievements
                .filter(
                  (achievement) => achievement.award_date || achievement.title
                )
                .map((achievement, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 mr-2 text-xs text-gray-800 bg-green-100 rounded-md"
                  >
                    {achievement.award_date && (
                      <>
                        <FaCalendarDay className="inline w-3 text-yellow-500 mb-1" />{" "}
                        {achievement.award_date}{" "}
                      </>
                    )}
                    {achievement.title && (
                      <>
                        <FaAward className="inline w-4 text-yellow-500 mb-1" />{" "}
                        {achievement.title}
                      </>
                    )}
                  </span>
                ))}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between px-2 py-1 mb-3 border-b">
        <h2 className="flex justify-start gap-2 text-xl font-medium text-blue-900">
          <FaUserGraduate className="mt-1" />
          Present Research Scholars
        </h2>
        <span className="px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
          {presentResearchScholars.length}
        </span>
      </div>

      {presentResearchScholars.length ? (
        <div>
          {presentResearchScholars
            .slice(0, visibleCount)
            .map((guidance, index) => (
              <div key={index}>{renderPresentResearchScholar(guidance)}</div>
            ))}
          {presentResearchScholars.length > 5 && (
            <div className="flex justify-center mt-2">
              <button
                className="text-sm text-blue-500 rounded hover:text-blue-900"
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className="py-4 text-center text-gray-500">No data found</p>
      )}
    </div>
  );
};

export default PresentResearchScholar;
