import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BaseApi from "../../Http/BaseApi";
import moment from "moment";
import { BasePayrollDomain } from "../../env";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function PaySlips() {
  const [paySlips, setPaySlips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(true);

  const [otpToken, setOtpToken] = useState(null); // OTP token from the backend
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    setChecking(true);
    const verifyToken = async () => {
      const otpToken = localStorage.getItem("otp_token");
      if (!otpToken) {
        setIsAuthorized(false);
      }

      try {
        await BaseApi().post("/otp/verify-token", {
          otp_token: otpToken,
        });
        setIsAuthorized(true);
      } catch (error) {
        setIsAuthorized(false);
        localStorage.removeItem("otp_token"); // Remove invalid token
      } finally {
        setChecking(false);
      }
    };

    verifyToken();
  }, []);

  const handleGenerateOtp = async () => {
    setLoading(true);
    try {
      const response = await BaseApi().post("/otp/generate");
      setOtpToken(response.data.otp_token);
      toast.success("OTP sent successfully to your email/mobile number!");
    } catch (error) {
      toast.error("Error sending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await BaseApi().post("/otp/validate", {
        otp,
        otp_token: otpToken,
      });
      localStorage.setItem("otp_token", response.data.otp_token); // Store the token

      toast.success("OTP verified successfully!");
      setOtpVerified(true);
    } catch (error) {
      toast.error("Invalid or expired OTP.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      BaseApi()
        .get(`pay-slips`)
        .then((response) => {
          setPaySlips(response.data);
        })
        .catch(() => {
          toast.error("Error on fetching pay slips", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        });
    }
  }, [isAuthorized]);

  const handleDownload = async (paySlip) => {
    setLoading(true);
    const url = `${BasePayrollDomain}/salaryRecords/salarySlip?staff_id=${
      paySlip.staff_id
    }&sal_pbr_id=${paySlip.sal_pbr_id}&month=${moment(
      paySlip.salary_month
    ).format("YYYY-MM")}`;

    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important for handling binary data
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        `salarySlip_${moment(paySlip.salary_month).format("YYYY-MM")}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl); // Clean up URL object
      setLoading(false);
    } catch (error) {
      toast.error("Error downloading the PDF", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  if (checking) {
    return null; // Show nothing while verifying the token
  }

  if (!otpVerified && !checking && !isAuthorized) {
    return (
      <div className='flex flex-col items-center justify-start min-h-screen p-5 bg-gray-100'>
        <h2 className='mb-4 text-2xl font-semibold text-gray-800'>
          Protected area, please verify your identity
        </h2>
        <button
          onClick={handleGenerateOtp}
          disabled={loading || otpToken}
          className='px-4 py-2 mb-4 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:opacity-50 '
        >
          {loading ? "Sending OTP..." : "Send OTP"}
        </button>

        {otpToken && (
          <>
            <small className='mb-2'>
              OTP sent to your email/mobile number, please enter the OTP below
            </small>
            <div className='flex items-center'>
              <input
                type='text'
                placeholder='Enter OTP'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='p-2 border border-gray-300 rounded'
              />
              <button
                onClick={handleVerifyOtp}
                disabled={loading}
                className='px-4 py-2 ml-2 text-white bg-green-600 rounded hover:bg-green-700'
              >
                {loading ? "Verifying..." : "Verify"}
              </button>
            </div>
          </>
        )}
        {loading && <small className='mt-2'>Please wait...</small>}
      </div>
    );
  }

  return (
    <div className='min-h-screen p-10 bg-gray-100'>
      <h1 className='mb-6 text-3xl font-semibold text-left text-gray-800'>
        Download Pay Slips
        {/* report a problem */}
        <Link
          to='/report-problem'
          className='ml-4 text-sm text-red-600 hover:underline'
        >
          Report a problem
        </Link>
      </h1>

      <div className='overflow-x-auto'>
        <table className='min-w-full overflow-hidden bg-white rounded-sm shadow'>
          <thead className='text-white bg-gray-800'>
            <tr>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Year
              </th>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Month
              </th>
              <th className='px-6 py-3 text-xs font-medium tracking-wider text-left uppercase'>
                Download
              </th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {paySlips.map((paySlip) => (
              <tr key={paySlip.id}>
                <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                  {moment(paySlip.salary_month).format("YYYY")}
                </td>
                <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                  {moment(paySlip.salary_month).format("MMMM")}
                </td>
                <td className='w-64 px-6 py-4 whitespace-nowrap'>
                  <button
                    onClick={() => handleDownload(paySlip)}
                    disabled={loading}
                    className='flex items-center text-indigo-600 hover:text-indigo-900'
                  >
                    <FaFileDownload className='inline mr-1 text-sm' />
                    Download{loading ? "ing" : ""}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='my-5'>
          ** Payslips prior to March, 2024 will be available soon.
        </div>
      </div>
    </div>
  );
}

export default PaySlips;
