import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaPen,
  FaPlus,
  FaImages,
  FaBell,
  FaWpforms,
  FaClipboardList,
  FaRegClipboard,
  FaRegNewspaper,
  FaCalendarAlt,
  FaExclamationTriangle,
  FaInfoCircle,
  FaStickyNote,
} from "react-icons/fa";
import { GiCoffeeCup, GiAchievement, GiBookPile } from "react-icons/gi"; // Import other icons as needed
import BaseApi from "../../Http/BaseApi";
import { FaFileLines } from "react-icons/fa6";
const categoryStyles = {
  1: { icon: <FaBell className='text-xl' />, color: "bg-yellow-100" }, // Notifications
  2: { icon: <FaWpforms className='text-xl' />, color: "bg-green-100" }, // Results
  3: { icon: <FaClipboardList className='text-xl' />, color: "bg-blue-100" }, // Notification for Colleges
  4: { icon: <FaRegClipboard className='text-xl' />, color: "bg-red-100" }, // Tenders
  5: { icon: <FaRegNewspaper className='text-xl' />, color: "bg-purple-100" }, // Recruitment Notices
  6: { icon: <FaCalendarAlt className='text-xl' />, color: "bg-teal-100" }, // Upcoming Events
  7: {
    icon: <FaExclamationTriangle className='text-xl' />,
    color: "bg-orange-100",
  }, // Campus News
  8: { icon: <FaInfoCircle className='text-xl' />, color: "bg-gray-100" }, // Important Updates
  9: { icon: <FaStickyNote className='text-xl' />, color: "bg-pink-100" }, // Header Ticker
};
export default function WebsiteDashboard() {
  const [postCounts, setPostCounts] = useState([]);

  // Fetch counts for posts and media
  useEffect(() => {
    async function fetchCounts() {
      try {
        const postResponse = await BaseApi().get("/website/posts/count");
        setPostCounts(postResponse.data);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    }

    fetchCounts();
  }, []);

  return (
    <div className='min-h-screen p-8 mx-auto max-w-7xl bg-gray-50'>
      <h1 className='mb-8 text-3xl font-bold text-gray-800'>
        Website Dashboard
      </h1>

      <div className='grid grid-cols-1 gap-3 mb-8 md:grid-cols-9 lg:grid-cols-9'>
        {/* Loop through postCounts to display each category */}
        {postCounts.map((category) => {
          const { icon, color } = categoryStyles[category.web_category_id] || {
            icon: <FaPen className='text-5xl' />,
            color: "bg-white",
          };

          return (
            <Link
              to={`/website/posts?category_id=${category.web_category_id}&type=published`}
              key={category.web_category_id}
              className={`flex items-center justify-between p-2 ${color} rounded-md shadow-md hover:scale-105 transform transition`}
            >
              <div>
                <h2 className='text-xs font-semibold text-gray-700'>
                  {category.name}
                </h2>
                <p className='text-2xl font-bold text-blue-600'>
                  {category.post_count}
                </p>
              </div>
              {icon}
            </Link>
          );
        })}
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
        <Link
          to='/website/posts?type=published'
          className='flex items-center px-4 py-2 font-semibold text-blue-500 transition border border-blue-500 rounded-lg hover:bg-blue-500 hover:text-white'
        >
          <FaPen className='mr-2' />
          <span>View Posts</span>
        </Link>

        <Link
          to='/website/posts/create'
          className='flex items-center px-4 py-2 font-semibold text-purple-500 transition border border-purple-500 rounded-lg hover:bg-purple-500 hover:text-white'
        >
          <FaPlus className='mr-2' />
          <span>Create Post</span>
        </Link>

        <Link
          to='/website/media-management'
          className='flex items-center px-4 py-2 font-semibold text-green-500 transition border border-green-500 rounded-lg hover:bg-green-500 hover:text-white'
        >
          <FaImages className='mr-2' />
          <span>Media Management</span>
        </Link>

        <Link
          to='/website/pages?type=published'
          className='flex items-center px-4 py-2 font-semibold text-red-500 transition border border-red-500 rounded-lg hover:bg-red-500 hover:text-white'
        >
          <FaFileLines className='mr-2' />
          <span>View Pages</span>
        </Link>

        <Link
          to='/website/pages/create'
          className='flex items-center px-4 py-2 font-semibold text-teal-500 transition border border-teal-500 rounded-lg hover:bg-teal-500 hover:text-white'
        >
          <FaPlus className='mr-2' />
          <span>Create Page</span>
        </Link>
        <Link
          to='/website/department-profile/faculty-members'
          className='flex items-center px-4 py-2 font-semibold text-orange-500 transition border border-orange-500 rounded-lg hover:bg-orange-500 hover:text-white'
        >
          <FaImages className='mr-2' />
          <span>Department Faculty Members</span>
        </Link>
        <Link
          to='/website/sliders'
          className='flex items-center px-4 py-2 font-semibold transition border rounded-lg text-cyan-500 border-cyan-500 hover:bg-cyan-500 hover:text-white'
        >
          <FaImages className='mr-2' />
          <span>Manage Sliders</span>
        </Link>
      </div>
    </div>
  );
}
