import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  type: { label: "Type ", path: "type" },
  others_type: { label: "Others Type ", path: "others_type" },
  modules: { label: "Number of Modules", path: "modules" },
  enrolled_student: {
    label: "Number of Enrolled Students",
    path: "enrolled_student",
  },
  url: { label: "URL", path: "url" },
  month: { label: "Month of Launch", path: "monthName" },
  year: { label: "Month of Launch", path: "year" },
};

const EContentDevelopment = () => {
  const [eContentDevelopments, setEContentDevelopments] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/e-content-developments");
      setEContentDevelopments(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        E-Content Development
      </h2>
      <TableViewProfile
        headers={headers}
        data={eContentDevelopments}
        type="e-content-developments"
        setData={setEContentDevelopments}
      />
    </div>
  );
};

export default EContentDevelopment;
