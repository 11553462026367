import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  title: { label: "Title of the Project", path: "title" },
  type: { label: "Project Type", path: "type" },
  role: { label: "Role", path: "role" },
  funding_agency: { label: "Funding Agency", path: "funding_agency" },
  type_funding_agency: {
    label: "Type of Funding Agency",
    path: "type_funding_agency",
  },
  grant_no: { label: "Grant No.", path: "grant_no" },
  amount_granted: { label: "Amount Granted", path: "amount_granted" },
  status: { label: "Status", path: "status" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
  project_related: { label: "Project related to", path: "project_related" },
  collaborative_project: {
    label: "Is it a collaborative project?",
    path: "collaborative_project",
  },
  collaborative_project_details: {
    label: "Details",
    path: "collaborative_project_details",
  },
  research_fellow: {
    label: "Number of Research Fellows under the Project",
    path: "research_fellow",
  },
};

const Project = () => {
  const [projects, setProjects] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/projects");
      setProjects(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        Project
      </h2>
      <TableViewProfile
        headers={headers}
        data={projects}
        type="projects"
        setData={setProjects}
      />
    </div>
  );
};

export default Project;
