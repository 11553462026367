import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { cancelOldStudentAsync } from "../../Redux/OldStudentSlice";
// import * as Yup from "yup";
import { ADMITTED_STUDENTS_STATUS } from "../../Enums/enums";
import moment from "moment";
import {
  deletePunishmentsAsync,
  showAdmittedStudentAsync,
} from "../../Redux/StudentSlice";
import {
  AcademicCapIcon,
  CheckIcon,
  PencilIcon,
  XIcon,
} from "@heroicons/react/outline";
import BaseApi from "../../Http/BaseApi";
import can from "../../Middleware/Permission";
import { YearSemesters } from "../../Constants/YearSemesters";
import Pagination from "../../Components/Utils/Pagination";
import UpdateFellowshipModal from "./PhdFellowship/UpdateFellowship";

export default function ViewAdmittedStudent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [student, setStudent] = useState();
  const [nameEditable, setNameEditable] = useState(false);
  const [dheIdEditable, setDheIdEditable] = useState(false);
  const [abcIdEditable, setAbcIdEditable] = useState(false);
  const [regNoEditable, setRegNoEditable] = useState(false);
  const studentsSlice = useSelector((state) => state.students);
  const students = studentsSlice.admittedStudents;
  //   const authData = useSelector((state) => state.auth);
  const [payments, setPayments] = useState();
  const authData = useSelector((state) => state.auth);
  const [paginations, setPaginations] = useState();
  const [showUpdateFellowshipModal, setShowUpdateFellowshipModal] =
    useState(false);

  useEffect(() => {
    async function checkData() {
      if (id && id > 0) {
        const singleStudent = await students.find((value) => value.id == id);
        if (singleStudent != null) {
          console.log("call1");
          callPayments({
            unique_id: singleStudent?.unique_id ?? singleStudent?.unique_idd,
            status: 1,
          });
          setStudent(singleStudent);
        } else {
          console.log(" call 2");

          dispatch(showAdmittedStudentAsync({ id }))
            .unwrap()
            .then((value) => {
              callPayments({
                unique_id: value?.unique_id ?? value?.unique_idd,
                status: 1,
              });
              setStudent(value);
            });
        }
      }
    }
    checkData();
  }, [students]);

  async function callPayments(searchQuery) {
    console.log(searchQuery);
    const _payments = await BaseApi().get(`payments`, {
      params: searchQuery,
    });

    // setPayments(_payments.data);

    const { data, ...paginationInfo } = _payments.data;

    setPayments(data);
    setPaginations(paginationInfo);
  }

  //   const checkerApprove = async () => {
  //     await dispatch(approveInvoiceAsync(invoice));
  //     await dispatch(showInvoiceAsync({ id }))
  //       .unwrap()
  //       .then((value) => {
  //         console.log();
  //         setInvoice(value);
  //       });
  //   };
  // const handleReject = (formValue) => {
  //   console.log(formValue);
  //   dispatch(rejectOldStudentAsync(formValue))
  //     .unwrap()
  //     .then(() => {
  //       setShowModal(false);
  //     });
  // };
  // const handleCancelAdmission = () => {
  //   dispatch(cancelOldStudentAsync(student));
  // };
  const updateName = async () => {
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { name: student.student?.name },
      table: "students",
    });
    setNameEditable(false);
  };
  const updateDheId = async () => {
    console.log(student.student?.dhe_unique_id);
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { dhe_unique_id: student.student?.dhe_unique_id },
      table: "students",
    });
    setDheIdEditable(false);
  };

  const updateAbcId = async () => {
    console.log(student.student?.abc_id);
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { abc_id: student.student?.abc_id },
      table: "students",
    });
    setAbcIdEditable(false);
  };

  const updateRegNo = async () => {
    console.log(student.student?.du_registration_no);
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { du_registration_no: student.student?.du_registration_no },
      table: "students",
    });
    setRegNoEditable(false);
  };

  const requestEdit = async (value) => {
    if (!window.confirm("Are you sure?")) return;
    await BaseApi().post(`admitted-students/${student.id}`, {
      fields: { request_photo: value },
      table: "students",
    });
    let _student = {
      ...student,
      student: { ...student.student },
    };
    _student.student.request_photo = value;
    setStudent(_student);
  };

  const deletePunishment = async (punishment) => {
    await dispatch(deletePunishmentsAsync(punishment));

    let _student = {
      ...student,
    };
    let _punishments = _student.punishments?.filter(
      (p) => p.id != punishment.id
    );

    _student.punishments = _punishments;
    setStudent(_student);
  };

  return (
    <>
      {student != null && (
        <div className='container p-5 mx-auto my-5'>
          <div className='mb-10 md:flex no-wrap md:-mx-2 '>
            <div className='w-full md:w-3/12 md:mx-2'>
              <div className='p-3 bg-white border-t-4 border-green-400'>
                <div className='relative overflow-hidden image group'>
                  <img
                    className='w-full h-auto mx-auto duration-300 group-hover:opacity-25'
                    src={student.student?.student_photo}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (e.target.src != "/assets/no-photo.jpg")
                        e.target.src = "/assets/no-photo.jpg";
                    }}
                  />
                  {student.semester == 1 &&
                    (can("Manage ID Card") ||
                      authData.panel == "department") && (
                      <a className='absolute inset-0 z-10 flex items-center justify-center text-2xl font-semibold duration-300 opacity-0 group-hover:opacity-100'>
                        {student.student?.request_photo == 1 && (
                          <div
                            className='text-center text-red-600 cursor-pointer'
                            onClick={() => {
                              requestEdit(0);
                            }}
                          >
                            Cancel Request
                            <p className='text-sm'>
                              Notify Student to Re-upload Photo
                            </p>
                          </div>
                        )}

                        {student.student?.request_photo != 1 && (
                          <div
                            className='text-center text-blue-600 cursor-pointer'
                            onClick={() => {
                              requestEdit(1);
                            }}
                          >
                            Request Uplaod
                            <p className='text-sm'>
                              Notify Student to Re-upload Photo
                            </p>
                          </div>
                        )}
                      </a>
                    )}
                </div>

                <h1 className='relative my-1 text-xl font-bold leading-8 text-gray-900 group'>
                  <span className='opacity-100 group-hover:opacity-10'>
                    {!nameEditable && student.student?.name}
                  </span>
                  {nameEditable && (
                    <input
                      autoFocus
                      className='border'
                      name='name'
                      value={student.student?.name}
                      onChange={(e) => {
                        let _student = {
                          ...student,
                          student: { ...student.student },
                        };
                        _student.student.name = e.target.value;
                        setStudent(_student);
                      }}
                    />
                  )}
                  {nameEditable && (
                    <>
                      <XIcon
                        onClick={() => {
                          setNameEditable(false);
                        }}
                        className='float-right w-5 mt-2 text-red-600 cursor-pointer'
                      />
                      <CheckIcon
                        onClick={() => {
                          updateName();
                        }}
                        className='float-right w-5 mt-2 text-green-600 cursor-pointer'
                      />
                    </>
                  )}
                  {!nameEditable && can("Edit Students") && (
                    <div className='absolute inset-0 z-10 flex items-center justify-center text-2xl font-semibold duration-300 opacity-0 group-hover:opacity-100'>
                      <PencilIcon
                        onClick={() => {
                          setNameEditable(true);
                        }}
                        className='w-5 cursor-pointer '
                      />
                    </div>
                  )}
                </h1>
                <h3 className='leading-6 text-gray-600 font-lg text-semibold'>
                  {student?.department?.name}
                </h3>
                <h3 className='leading-6 text-gray-600 font-lg text-semibold'>
                  {student.programme?.name}
                </h3>
                <p className='text-sm leading-6 text-gray-500 hover:text-gray-600'>
                  Sem/Year::{" "}
                  {
                    YearSemesters.find((ys) => ys.value == student.semester)
                      ?.label
                  }
                </p>
                <ul className='px-3 py-2 mt-3 text-gray-600 bg-gray-100 divide-y rounded shadow-sm hover:text-gray-700 hover:shadow'>
                  <li className='flex items-center py-3'>
                    <span>Status:</span>
                    <span className='ml-auto'>
                      <span className='px-2 py-1 text-sm text-white bg-green-500 rounded'>
                        {ADMITTED_STUDENTS_STATUS[student.status]}
                      </span>
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        {student.alumni == 1 && "Alumni"} <br />
                        {student.discontinued == 1 && "Discontinued"}
                      </span>
                    </span>
                  </li>
                  <li className='flex items-center py-3'>
                    <span>Academic Session:</span>
                    <span className='ml-auto'>
                      {student.semester > 0 && student.academic_session?.label}
                      {student.semester == 0 && "N/A"}
                    </span>
                  </li>
                  {/* <li className="flex items-center py-3">
                    <span>Applied Date</span>
                    <span className="ml-auto">
                      {moment(student.applied_date).format("Do MMM YYYY")}
                    </span>
                  </li> */}

                  <li className='flex items-center py-3'>
                    <span>ID:</span>
                    <span className='ml-auto'>
                      {student.unique_id || student.unique_idd}
                    </span>
                  </li>
                  <li className='relative items-center py-3 text-xs group'>
                    DHE ID:
                    <span className='opacity-100 group-hover:opacity-10'>
                      {" "}
                      {!dheIdEditable &&
                        (student.student?.dhe_unique_id || "Not Found")}
                    </span>
                    {dheIdEditable && (
                      <input
                        autoFocus
                        className='w-full p-1 text-sm border'
                        name='dhe_unique_id'
                        value={student.student?.dhe_unique_id}
                        onChange={(e) => {
                          let _student = {
                            ...student,
                            student: { ...student.student },
                          };
                          _student.student.dhe_unique_id = e.target.value;
                          setStudent(_student);
                        }}
                      />
                    )}
                    {dheIdEditable && (
                      <>
                        <div className='flex justify-center'>
                          <XIcon
                            onClick={() => {
                              setDheIdEditable(false);
                            }}
                            className='w-5 my-2 text-red-600 cursor-pointer '
                          />
                          <CheckIcon
                            onClick={() => {
                              updateDheId();
                            }}
                            className='w-5 my-2 text-green-600 cursor-pointer '
                          />
                        </div>
                      </>
                    )}
                    {!dheIdEditable &&
                      (can("Manage DHE ID") ||
                        authData.panel == "department") && (
                        <div className='absolute inset-0 z-10 flex items-center justify-center text-2xl font-semibold duration-300 opacity-0 group-hover:opacity-100'>
                          <PencilIcon
                            onClick={() => {
                              setDheIdEditable(true);
                            }}
                            className='w-5 cursor-pointer '
                          />
                        </div>
                      )}
                  </li>

                  <li className='relative items-center py-3 text-xs group'>
                    ABC ID:
                    <span className='opacity-100 group-hover:opacity-10'>
                      {" "}
                      {!abcIdEditable &&
                        (student.student?.abc_id || "Not Found")}
                    </span>
                    {abcIdEditable && (
                      <input
                        autoFocus
                        className='w-full p-1 text-xs border'
                        name='abc_id'
                        value={student.student?.abc_id}
                        onChange={(e) => {
                          let _student = {
                            ...student,
                            student: { ...student.student },
                          };
                          _student.student.abc_id = e.target.value;
                          setStudent(_student);
                        }}
                      />
                    )}
                    {abcIdEditable && (
                      <>
                        <div className='flex justify-center'>
                          <XIcon
                            onClick={() => {
                              setAbcIdEditable(false);
                            }}
                            className='w-5 my-2 text-red-600 cursor-pointer '
                          />
                          <CheckIcon
                            onClick={() => {
                              updateAbcId();
                            }}
                            className='w-5 my-2 text-green-600 cursor-pointer '
                          />
                        </div>
                      </>
                    )}
                    {!abcIdEditable &&
                      (can("Manage ABC ID") ||
                        authData.panel == "department") && (
                        <div className='absolute inset-0 z-10 flex items-center justify-center text-2xl font-semibold duration-300 opacity-0 group-hover:opacity-100'>
                          <PencilIcon
                            onClick={() => {
                              setAbcIdEditable(true);
                            }}
                            className='w-5 cursor-pointer '
                          />
                        </div>
                      )}
                  </li>
                  <li className='relative items-center py-3 text-xs group'>
                    DU Reg. No.:
                    <span className='opacity-100 group-hover:opacity-10'>
                      {" "}
                      {!regNoEditable &&
                        (student.student?.du_registration_no || "Not Found")}
                    </span>
                    {regNoEditable && (
                      <input
                        autoFocus
                        className='w-full p-1 text-xs border'
                        name='du_registration_no'
                        value={student.student?.du_registration_no}
                        onChange={(e) => {
                          let _student = {
                            ...student,
                            student: { ...student.student },
                          };
                          _student.student.du_registration_no = e.target.value;
                          setStudent(_student);
                        }}
                      />
                    )}
                    {regNoEditable && (
                      <>
                        <div className='flex justify-center'>
                          <XIcon
                            onClick={() => {
                              setRegNoEditable(false);
                            }}
                            className='w-5 my-2 text-red-600 cursor-pointer '
                          />
                          <CheckIcon
                            onClick={() => {
                              updateRegNo();
                            }}
                            className='w-5 my-2 text-green-600 cursor-pointer '
                          />
                        </div>
                      </>
                    )}
                    {!regNoEditable &&
                      (can("Manage Registration No") ||
                        authData.panel == "department") && (
                        <div className='absolute inset-0 z-10 flex items-center justify-center text-2xl font-semibold duration-300 opacity-0 group-hover:opacity-100'>
                          <PencilIcon
                            onClick={() => {
                              setRegNoEditable(true);
                            }}
                            className='w-5 cursor-pointer '
                          />
                        </div>
                      )}
                  </li>
                </ul>
                <div className='my-4'></div>
                <div className='overflow-hidden image'>
                  <img
                    className='w-full h-auto mx-auto'
                    src={student.student?.student_signature}
                    onError={(e) => {
                      console.log(e.target.src);
                      if (e.target.src != "/assets/no-sign.jpg")
                        e.target.src = "/assets/no-sign.jpg";
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='w-full mx-2 md:w-9/12'>
              <div className='p-3 bg-white rounded-sm shadow-sm'>
                {student.punishments?.length > 0 && (
                  <div className='mb-5 space-x-2 leading-8 text-red-900 '>
                    <span className='ml-2 text-xl font-bold text-red-600'>
                      Disciplinary actions:
                    </span>
                    <table className='min-w-full mt-5 bg-white'>
                      <tr>
                        <td className='table-head'>Details</td>
                        <td className='table-head'>From</td>
                        <td className='table-head'>To</td>
                        <td className='table-head'>Action</td>
                      </tr>
                      {student.punishments?.map((punishment) => {
                        return (
                          <>
                            <tr>
                              <td className='table-data'>
                                {punishment.remarks}
                              </td>
                              <td className='table-data'>
                                {punishment.from_date}
                              </td>
                              <td className='table-data'>
                                {punishment.to_date}
                              </td>
                              <td className='table-data'>
                                <button
                                  className='p-5 outline-button-danger'
                                  onClick={() => {
                                    if (
                                      window.confirm("Are you sure?") == true
                                    ) {
                                      deletePunishment(punishment);
                                    } else {
                                      return;
                                    }
                                  }}
                                  type='button'
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                )}
                <div className='flex items-center space-x-2 font-semibold leading-8 text-gray-900'>
                  <span className='text-green-500'>
                    <svg
                      className='h-5'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                      />
                    </svg>
                  </span>
                  <span className='tracking-wide'>About</span>
                </div>
                <div className='text-gray-700'>
                  <div className='grid text-sm md:grid-cols-2'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Student Name
                      </div>
                      <div className='px-4 py-2'>{student.student?.name}</div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Gender</div>
                      <div className='px-4 py-2'>{student.student?.gender}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Religion</div>
                      <div className='px-4 py-2'>
                        {student.student?.religion}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Caste</div>
                      <div className='px-4 py-2'>
                        {student.student.hcaste?.name}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Community</div>
                      <div className='px-4 py-2'>
                        {student.student?.community_id != null &&
                          student.student?.community_id != 9 && (
                            <>{student.student?.community?.name}</>
                          )}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        Date of Birth
                      </div>
                      <div className='px-4 py-2'>
                        {moment(student.student?.dob).format("Do MMM YYYY")}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Mobile No.</div>
                      <div className='px-4 py-2'>{student.student?.phone}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Email</div>
                      <div className='px-4 py-2 break-all'>
                        {student.student?.email}
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>Hostel</div>
                      <div className='px-4 py-2 break-all'>
                        {student.student?.hostels?.find(
                          (h) =>
                            h.pivot?.payment_id != null &&
                            h.pivot?.in_date != null &&
                            h.pivot?.out_date == null
                        )?.code ?? "No"}
                      </div>
                    </div>

                    {student.admittedcustominfos?.length > 0 &&
                      student.admittedcustominfos.map((field) => {
                        if (
                          field?.formfield?.input_type == "select" &&
                          field?.formfield?.input_data != null
                        ) {
                          return (
                            <>
                              <div className='grid grid-cols-2'>
                                <div className='px-4 py-2 font-semibold'>
                                  {field?.formfield?.name}
                                </div>
                                <div className='px-4 py-2 break-all'>
                                  {field?.data}
                                </div>
                              </div>
                            </>
                          );
                        } else if (
                          field?.formfield?.input_type == "select" &&
                          field?.formfield?.input_datai != null
                        ) {
                          if (field?.data && field?.data != null) {
                            let array =
                              field?.formfield?.input_datai?.split(",");

                            let position = array.indexOf(field?.data);

                            let arrayv =
                              field?.formfield?.input_datav?.split(",");

                            let value = arrayv[position];
                            return (
                              <>
                                <div className='grid grid-cols-2'>
                                  <div className='px-4 py-2 font-semibold'>
                                    {field?.formfield?.name}
                                  </div>
                                  <div className='px-4 py-2 break-all'>
                                    {value}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        } else {
                          return (
                            <>
                              <div className='grid grid-cols-2'>
                                <div className='px-4 py-2 font-semibold'>
                                  {field?.formfield?.name}
                                </div>
                                <div className='px-4 py-2 break-all'>
                                  {field?.data}
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
                <div className='relative'>
                  {can("Manage Fellowship") &&
                    (student.unique_id?.toUpperCase().includes("PHD") ||
                      student.unique_idd?.toUpperCase().includes("PHD")) && (
                      <>
                        <button
                          type='button'
                          className='absolute px-2 py-1 text-xs font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm right-10 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          onClick={() => {
                            setShowUpdateFellowshipModal(true);
                          }}
                        >
                          Update Fellowship
                        </button>
                        <UpdateFellowshipModal
                          isOpen={showUpdateFellowshipModal}
                          onClose={() => {
                            setShowUpdateFellowshipModal(false);
                          }}
                          programmeStudent={student}
                        />
                      </>
                    )}

                  {student.fellowship_name_id != null && (
                    <>
                      <div className='flex items-center mt-10 space-x-2 font-semibold leading-8 text-gray-900'>
                        <AcademicCapIcon className='inline w-6 h-6' />
                        <span className='tracking-wide'>
                          Fellowship Details:
                        </span>
                      </div>
                      <div className='text-gray-700 '>
                        <div className='grid grid-cols-4'>
                          <div className='col-span-1 px-4 py-2 font-semibold'>
                            Name of Fellowship:
                          </div>
                          <div className='col-span-3 px-4 py-2'>
                            {student.fellowship_name?.name}
                          </div>
                        </div>
                        <div className='grid grid-cols-4'>
                          <div className='col-span-1 px-4 py-2 font-semibold'>
                            Agency Name:
                          </div>
                          <div className='col-span-3 px-4 py-2'>
                            {student.fellowship_agency?.name}
                          </div>
                        </div>

                        <div className='grid grid-cols-4'>
                          <div className='col-span-1 px-4 py-2 font-semibold'>
                            Start Date:
                          </div>
                          <div className='col-span-3 px-4 py-2'>
                            {moment(student.fellowship_start_date).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </div>
                        <div className='grid grid-cols-4'>
                          <div className='col-span-1 px-4 py-2 font-semibold'>
                            End Date:
                          </div>
                          <div className='col-span-3 px-4 py-2'>
                            {student.fellowship_end_date != null && (
                              <>
                                {student.fellowship_end_date
                                  ? moment(student.fellowship_end_date).format(
                                      "DD/MM/YYYY"
                                    )
                                  : ""}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className='my-4'></div>
            </div>
          </div>

          <div className='mt-10 w-ful no-wrap md:-mx-2'>
            <div className=''>
              <span className='font-medium'>Payment Details:</span>
            </div>

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>Programme</th>
                  <th className='table-head'>Sem.</th>
                  <th className='table-head'>Ref. No.</th>
                  <th className='table-head'>Receipt No.</th>
                  <th className='table-head'>Date</th>

                  <th className='table-head'>Category</th>

                  <th className='table-head'>Total Amount</th>
                  <th className='table-head'>Paid</th>

                  <th className='table-head'>Created By</th>
                  <th className='table-head' colSpan='3'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments?.length > 0 &&
                  payments.map((payment, index) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++index}</td>

                          <td className='table-data'>
                            {payment.programme?.code}
                          </td>
                          <td className='table-data'>{payment.year_sem}</td>
                          <td className='table-data'>{payment.challan_no}</td>
                          <td className='table-data'>
                            {payment.receipt_no}
                            {"-"}
                            {payment.id}
                          </td>
                          <td className='table-data'>
                            {moment(payment.pay_date).format("Do MMM YYYY")}
                          </td>

                          <td className='table-data'>
                            {payment.type} <br />
                            {payment.payment_type}
                          </td>
                          <td className='table-data'>{payment.total_amount}</td>
                          <td className='table-data'>{payment.amount}</td>

                          <td className='table-data'>
                            {payment.staff?.name || "Department"}
                          </td>
                          <td className='table-data'>
                            <a
                              target='_blank'
                              href={`https://staffapi.dibru.work/print_payment/${payment.id}`}
                              className='text-blue-500'
                              rel='noreferrer'
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {payments?.length == 0 && (
              <div className='my-10 text-center'>No Data</div>
            )}
            {paginations?.total > paginations?.per_page && (
              <div className='m-5'>
                <Pagination
                  links={paginations?.links}
                  onClick={(url) => {
                    callPayments(url, null);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
