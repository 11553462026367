import React, { useState, useRef, useEffect } from "react";
import Expertise from "./Components/Expertise";
import EduQualification from "./Components/EduQualification";
import Experience from "./Components/Experience";
import JournalPublication from "./Components/JournalPublication";
import ConferenceSeminar from "./Components/ConferenceSeminar";
import BooksAndChapter from "./Components/BooksAndChapter/BooksAndChapter";
import ConsultancyExternalService from "./Components/ConsultancyExternalService";
import Project from "./Components/Project";
import HonorsAward from "./Components/HonorsAward";
import LecturesTalk from "./Components/LecturesTalk";
import AcademicVisits from "./Components/AcademicVisits";
import CorporateLife from "./Components/CorporateLife";
import MentorMentee from "./Components/MentorMentee";
import Collaboration from "./Components/Collaboration";
import BodiesOrganization from "./Components/BodiesOrganization";
import Committee from "./Components/Committee";
import FIPActivitiesParticipation from "./Components/FIPActivitiesParticipation";
import StartupDevelopment from "./Components/StartupDevelopment";
import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../../../Http/BaseApi";
import { FaArrowLeft, FaSearch, FaShareAlt } from "react-icons/fa";
import { IoCall, IoMail } from "react-icons/io5";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import WorkshopTraining from "./Components/WorkshopTraining";
import PhdGuidance from "./Components/PhdGuidance/PhdGuidance";
import Loader from "../utils/Loader";
import ProgrammeOrganized from "./Components/ProgrammeOrganized";
import EContentDevelopment from "./Components/EContentDevelopment";
import { Link } from "react-router-dom";
import IPR from "./Components/IPR/IPR";
import ExtensionActivitie from "./Components/ExtensionActivitie";
import Support from "./Components/Support";
import StudentsProjectDetail from "./Components/StudentsProjectDetail";
import MPhilAwardee from "./Components/MPhilAwardee";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ensureProtocol = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
};

const badges = [
  {
    label: "Publications",
    name: "journalPublication",

    color: "bg-green-50",
    borderColor: "border-green-300",
  },
  {
    label: "Books & Chapters",
    name: "booksAndChapter",

    color: "bg-blue-50",
    borderColor: "border-blue-300",
  },
  {
    label: "Conferences",
    name: "conferenceSeminar",

    color: "bg-red-50",
    borderColor: "border-red-300",
  },
  {
    label: "Patents",
    name: "patent",

    color: "bg-yellow-50",
    borderColor: "border-yellow-300",
  },
  {
    label: "Honors & Awards",
    name: "honorsAward",

    color: "bg-purple-50",
    borderColor: "border-purple-300",
  },
];

const socialLinks = [
  {
    label: "Scopus",
    name: "scopus_link",
    icon: "./assets/icons/scopus.png",
    color: "bg-green-50",
    borderColor: "border-green-300",
    link: "#",
  },
  {
    label: "ORCiD",
    name: "orcid_link",
    icon: "./assets/icons/orcid.png",
    color: "bg-blue-50",
    borderColor: "border-blue-300",
    link: "#",
  },
  {
    label: "Google Scholar",
    name: "google_scholar_link",
    icon: "./assets/icons/google_scholar.png",
    color: "bg-red-50",
    borderColor: "border-red-300",
    link: "#",
  },
  {
    label: "Web of Science",
    name: "web_of_science_link",
    icon: "./assets/icons/wos.png",
    color: "bg-yellow-50",
    borderColor: "border-yellow-300",
    link: "#",
  },
  {
    label: "Vidwan",
    name: "vidwan_link",
    icon: "./assets/icons/vidwan-logo.png",
    color: "bg-purple-50",
    borderColor: "border-purple-300",
    link: "#",
  },
  {
    label: "LinkedIn",
    name: "linkedin_link",
    icon: "./assets/icons/linkedin.png",
    color: "bg-blue-50",
    borderColor: "border-blue-300",
    link: "#",
  },
  {
    label: "ResearchGate",
    name: "research_gate_link",
    icon: "./assets/icons/researchGate.png",
    color: "bg-blue-50",
    borderColor: "border-blue-300",
    link: "#",
  },
  {
    label: "IRINS",
    name: "irins_link",
    icon: "./assets/icons/IRINS.webp",
    color: "bg-teal-50",
    borderColor: "border-teal-300",
    link: "#",
  },
];

// const colors = [
//   "border-t-blue-300 hover:border-b-blue-300 bg-blue-50 bg-transparent",
//   "border-t-gray-300 hover:border-b-gray-300 bg-gray-50 bg-transparent",
// ];

const WebsiteFacultyProfile = () => {
  // get query params in view variable
  const view = new URLSearchParams(window.location.search).get("view");

  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Expertise",
      // activity: "2",
      name: "expertise",
      content: <Expertise />,
    },
    {
      id: 2,
      name: "educationalQualification",
      label: "Educational Qualifications",
      // activity: "22",
      content: <EduQualification view={view ?? "website"} />,
    },
    {
      id: 3,
      name: "experience",
      label: "Experience",
      content: <Experience view={view ?? "website"} />,
    },
    {
      id: 4,
      name: "journalPublication",
      label: "Research Publications",
      content: <JournalPublication view={view ?? "website"} />,
    },
    {
      id: 5,
      name: "conferenceSeminar",
      label: "Conference/Seminar Paper",
      content: <ConferenceSeminar view={view ?? "website"} />,
    },
    {
      id: 6,
      name: "booksAndChapter",
      label: "Books & Chapters",
      content: <BooksAndChapter view={view ?? "website"} />,
    },
    // {
    //   id: 7,
    //   name: "patent",
    //   label: "Patents",
    //   content: <Patent view={view ?? "website"} />,
    // },
    {
      id: 7,
      name: "ipr",
      label: "IPR",
      content: <IPR />,
    },
    {
      id: 8,
      name: "consultancyExternalService",
      label: "Consultancy/External Services",
      content: <ConsultancyExternalService view={view ?? "website"} />,
    },
    {
      id: 9,
      name: "project",
      label: "Projects",
      content: <Project view={view ?? "website"} />,
    },
    {
      id: 10,
      name: "honorsAward",
      label: "Honors and Awards",
      content: <HonorsAward view={view ?? "website"} />,
    },
    {
      id: 11,
      name: "lectureTalk",
      label: "Invited Lecture/Talk",
      content: <LecturesTalk view={view ?? "website"} />,
    },
    {
      id: 12,
      name: "academicVisit",
      label: "Academic Visit",
      content: <AcademicVisits view={view ?? "website"} />,
    },
    {
      id: 13,
      name: "phdGuidance",
      label: "Ph. D. Guidance",
      content: <PhdGuidance view={view ?? "website"} />,
    },
    {
      id: 14,
      name: "mPhilAwardee",
      label: "M. Phil. Awardees",
      content: <MPhilAwardee view={view ?? "website"} />,
    },
    {
      id: 15,
      name: "corporateLife",
      label: "Contribution to Corporate Life",
      content: <CorporateLife view={view ?? "website"} />,
    },
    // {
    //   id: 16,
    //   name: "mentorMentee",
    //   label: "Mentor-Mentee",
    //   content: <MentorMentee />,
    // },
    {
      id: 17,
      name: "collaboration",
      label: "Collaborations",
      content: <Collaboration view={view ?? "website"} />,
    },
    {
      id: 18,
      name: "bodiesOrganization",
      label: "Memberships in Professional Bodies/Organization",
      content: <BodiesOrganization view={view ?? "website"} />,
    },
    {
      id: 19,
      name: "committee",
      label: "Memberships in Significant Committees :",
      content: <Committee view={view ?? "website"} />,
    },
    {
      id: 20,
      name: "fipActivitiesParticipation",
      label: "FIP activities/Participation in FDP",
      content: <FIPActivitiesParticipation view={view ?? "website"} />,
    },
    {
      id: 21,
      name: "startupDevelopment",
      label: "Startup",
      content: <StartupDevelopment view={view ?? "website"} />,
    },

    {
      id: 22,
      name: "workshopTraining",
      label: "Participation in Programme",
      content: <WorkshopTraining view={view ?? "website"} />,
    },

    {
      id: 23,
      name: "programmeOrganized",
      label: "Programme Organized",
      content: <ProgrammeOrganized view={view ?? "website"} />,
    },
    {
      id: 24,
      name: "eContentDevelopment",
      label: "E-Content Development",
      content: <EContentDevelopment view={view ?? "website"} />,
    },
    {
      id: 25,
      name: "extensionActivitie",
      label: "Outreach/Extension Activities",
      content: <ExtensionActivitie view={view ?? "website"} />,
    },
    {
      id: 26,
      name: "support",
      label: "National/International Fellowship/Financial Support",
      content: <Support view={view ?? "website"} />,
    },
    {
      id: 27,
      name: "studentsProjectDetail",
      label: "Students' Project Details",
      content: <StudentsProjectDetail view={view ?? "website"} />,
    },
  ]);

  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const dispatch = useDispatch();

  const sectionRefs = useRef(tabs.map(() => React.createRef()));
  const authData = useSelector((state) => state.auth);
  const [profile, setProfile] = useState({});
  // const [profileCounts, setProfileCounts] = useState({});

  useEffect(() => {
    getStaff(authData.user.id);
    getProfileCounts(authData.user.id);
  }, [dispatch, authData]);

  const getStaff = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`staff/${id}`, {})
      .then((data) => {
        console.log(data.data);
        setProfile({
          name: data.data.full_name,
          designation: data.data.designation?.name,
          department: data.data.department?.name,
          otherDesignations: data.data.other_designations?.map(
            (designation) => designation.name
          ),
          email: data.data.email,
          contact_no: data.data.phone,
          scopus_link: data.data.scopus_link,
          orcid_link: data.data.orcid_link,
          google_scholar_link: data.data.google_scholar_link,
          web_of_science_link: data.data.web_of_science_link,
          vidwan_link: data.data.vidwan_link,
          linkedin_link: data.data.linkedin_link,
          irins_link: data.data.irins_link,
          research_gate_link: data.data.research_gate_link,
          profile_pic: data.data.profile_pic,
          web_profile_pic: data.data.web_profile_pic,
          mobile_visibility: data.data.mobile_visibility,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getProfileCounts = async (id) => {
    setLoading(true);
    await BaseApi()
      .get(`profile/${id}/counts?view_type=website`, {})
      .then((data) => {
        console.log(data.data);
        const updatedTabs = tabs
          .map((tab) => {
            if (
              tab.name in data.data &&
              (data.data[tab.name] > 0 || data.data[tab.name] == "N/A")
            ) {
              tab.activity = data.data[tab.name];
              return tab;
            }
            return null;
          })
          .filter((tab) => tab !== null);

        setTabs(updatedTabs);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveTab(entry.target.id);
          }
        });
      },
      { threshold: 0.7 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  const visibleTabs = tabs.filter((tab) => tab.content);

  const [chartData, setChartData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/journal-publications");
      console.log("API Response:", response.data);
      if (response.data && response.data.data) {
        prepareChartData(response.data.data);
      } else {
        console.error("No data found in API response");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const prepareChartData = (data) => {
    if (!data) {
      console.error("Data is undefined or null in prepareChartData");
      return;
    }

    console.log("Data for chart preparation:", data);

    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: 5 },
      (_, i) => currentYear - i
    ).reverse();
    const publicationCounts = years.map(
      (year) => data.filter((item) => item.year === year.toString()).length
    );

    console.log("Publication counts:", publicationCounts);

    setChartData({
      labels: years,
      datasets: [
        {
          label: "Number of Publications in Journals",
          data: publicationCounts,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  // State to toggle search input visibility
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Toggle search input visibility on click
  const handleSearchClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <>
      {loading && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75'>
          <div className='loader'></div>
          <span className='ml-4 text-lg font-semibold text-gray-700'>
            <Loader />
          </span>
        </div>
      )}
      {!loading && (
        <>
          {/* Header */}
          <div className='flex items-center gap-4 p-4 bg-gray-900 md:flex-row md:justify-between md:gap-0'>
            {/* Logo and Title */}
            <div className='flex items-center justify-center gap-2'>
              <img
                src='/android-chrome-192x192-removebg.png'
                alt='DU Logo'
                className='h-8 md:h-10' // Logo height adjusts for larger screens
              />
              <h1 className='text-base font-bold tracking-wide text-center text-white md:text-2xl whitespace-nowrap'>
                Dibrugarh University
              </h1>
            </div>

            {/* Search Input and Icon */}
            <div className='flex items-center justify-center gap-2 md:w-auto'>
              {/* Search Icon */}
              <button
                className='p-2 text-white bg-blue-200 rounded-full'
                onClick={handleSearchClick}
              >
                <FaSearch className='w-3 h-3 text-gray-700 lg:w-4 lg:h-4 ' />
              </button>

              {/* Search Input - Hidden on mobile by default, shown when clicked */}
              <input
                type='text'
                placeholder='Search'
                className={`${
                  isSearchVisible ? "block" : "hidden"
                } md:block w-full placeholder:text-xs lg:placeholder:text-sm md:w-auto p-2 text-sm text-gray-900 placeholder-gray-900 bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              />
            </div>
          </div>

          <section className='p-1 my-2 text-center text-red-700 md:mx-32'>
            <Link
              to='/faculty-profile'
              className='flex items-center justify-center float-left gap-2 text-blue-700'
            >
              <FaArrowLeft className='inline mt-2' />{" "}
              <span className='hidden lg:block'> Back to Home </span>
            </Link>
            <span className='text-sm lg:text-base'>
              This is the public view of your profile which will be made
              available on the new website.
            </span>
          </section>

          <section className='p-2 lg:mx-32'>
            <div className='flex gap-3 p-2 bg-white border lg:p-6'>
              {/* Left Side */}
              <div className='flex-1 space-y-2 text-left lg:space-y-4'>
                <div className='flex items-center'>
                  <span className='text-sm font-bold text-blue-900 lg:text-2xl'>
                    {profile.name}
                  </span>
                </div>
                <div className='flex items-center'>
                  <span className='text-xs text-gray-700 lg:text-xl'>
                    {profile.designation}, {profile.department}
                  </span>
                </div>
                <div className='flex items-center'>
                  <span className='text-xs text-gray-700 lg:text-sm'>
                    {profile?.otherDesignations?.join(", ")}
                  </span>
                </div>
                {profile.mobile_visibility ? (
                  <div className='flex items-center text-xs'>
                    <span className='font-semibold text-blue-700 text-md'>
                      <IoCall className='inline w-4' />
                    </span>

                    <span className='ml-2 text-blue-800 '>
                      {profile.contact_no}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                <div className='flex items-center text-xs'>
                  <span className='font-semibold text-blue-700 text-md'>
                    <IoMail className='inline w-4' />
                  </span>
                  <span className='ml-2 text-blue-800 lowercase'>
                    {profile.email}
                  </span>
                </div>

                <div className='flex items-center space-x-1 lg:space-x-4'>
                  {socialLinks.map((socialLink, index) => {
                    const link = profile[socialLink.name];
                    return (
                      link && (
                        <a
                          key={index}
                          href={ensureProtocol(link)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <div
                            className={`bg-gray-100 border-blue-300 border rounded-md p-2  flex items-center space-x-2 transition-transform duration-300 transform hover:scale-105`}
                          >
                            <img
                              src={socialLink.icon}
                              alt={`${socialLink.label} icon`}
                              className='w-4 h-4'
                            />
                            <span className='hidden text-xs font-semibold text-blue-800 lg:block font-extralight'>
                              {socialLink.label}
                            </span>
                          </div>
                        </a>
                      )
                    );
                  })}
                </div>
              </div>

              {/* Right Side */}
              <div className='flex-shrink-0 lg:ml-10'>
                {/* <div
                  className="relative "
                  style={{ width: "168px", height: "224px" }}
                >
                  {" "}
                  <img
                    src={profile.web_profile_pic}
                    alt="Profile Photo"
                    className="object-cover w-full h-full transition-transform duration-300 transform border-2 border-blue-300 rounded-md hover:scale-105"
                  />
                  <div className="absolute inset-0 flex items-center justify-center transition-opacity duration-300 bg-black rounded-lg opacity-0 bg-opacity-20 hover:opacity-100">
                    <span className="text-lg font-bold text-white"></span>
                  </div>
                </div> */}

                <div className='relative h-40 w-28 md:w-44 md:h-60 lg:w-42 lg:h-56'>
                  {/* 4:3 aspect ratio */}
                  <img
                    onError={(e) => {
                      console.log(e.target.src);
                      if (
                        e.target.src !==
                          "https://ui-avatars.com/api/name=?" + profile.name ??
                        "NO"
                      )
                        e.target.src =
                          "https://ui-avatars.com/api/?name=" + profile.name ??
                          "NO";
                    }}
                    src={profile.web_profile_pic}
                    alt='Profile Photo'
                    className='object-cover w-full h-full transition-transform duration-300 transform border-2 border-blue-300 rounded-md hover:scale-105'
                  />
                  <div className='absolute inset-0 flex items-center justify-center transition-opacity duration-300 bg-black rounded-lg opacity-0 bg-opacity-20 hover:opacity-100'>
                    <span className='text-lg font-bold text-white'>
                      {/* Faculty Photo */}
                    </span>
                  </div>
                </div>

                <div className='mt-4 text-center'>
                  <button
                    disabled
                    className='relative z-10 flex items-center justify-between w-full p-2 bg-white border border-blue-400 rounded-md opacity-100 disabled:cursor-not-allowed hover:opacity-70 focus:outline-none'
                  >
                    <span className='text-blue-600 '>Share Profile</span>
                    <FaShareAlt className='inline-block text-blue-600' />
                  </button>
                </div>
              </div>
            </div>

            <div className='items-center justify-center mt-5 lg:flex lg:space-x-4'>
              {badges.map((badge, index) => (
                <>
                  {tabs.find((tab) => tab.name === badge.name)?.activity >
                    0 && (
                    <span
                      key={index}
                      className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'
                    >
                      <span className='text-blue-900'>
                        {tabs.find((tab) => tab.name === badge.name)?.activity}
                      </span>{" "}
                      {badge.label}
                    </span>
                  )}
                </>
              ))}

              {chartData && false && (
                <div style={{ width: "300px" }}>
                  <Bar
                    data={chartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: "Publications in the Last 5 Years",
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            display: false, // Hide the Y-axis labels
                          },
                          grid: {
                            display: false, // Optionally, you can hide the grid lines as well
                          },
                        },
                        x: {
                          ticks: {
                            font: {
                              size: 10, // Reduce the font size of X-axis labels
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <div className='mt-5'>
              <div className='grid-cols-12 lg:grid lg:gap-5'>
                <ul className='flex-col col-span-3 space-y-1 text-sm font-medium text-left text-gray-500 border sm:flex dark:divide-gray-700 dark:text-gray-400'>
                  {tabs.map((tab) => (
                    <li
                      key={tab.label}
                      className='w-full text-base focus-within:z-10'
                    >
                      <button
                        onClick={() => {
                          setActiveTab(tab.label);
                          document.getElementById(tab.label).scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                        className={`inline-block w-full p-4 text-left border-l-4 border-transparent focus:outline-none 
        ${
          activeTab === tab.label
            ? "text-gray-900 bg-gray-200 border-blue-500 dark:bg-gray-700 dark:text-white"
            : "bg-white hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        }`}
                      >
                        {tab.label}
                        {tab.activity > 0 && (
                          <span className='flex float-right px-2 py-1 ml-2 text-xs text-blue-800 bg-blue-100 rounded-full'>
                            {tab.activity}
                          </span>
                        )}
                      </button>
                    </li>
                  ))}
                </ul>

                <div className='col-span-9'>
                  {visibleTabs.map((tab, index) => {
                    // const color = colors[index % colors.length];
                    return (
                      <div
                        key={tab.label}
                        id={tab.label}
                        ref={sectionRefs.current[index]}
                        className={`mb-4 pb-4 border transition duration-300 ease-in-out`}
                      >
                        {tab.content}
                      </div>
                    );
                  })}
                  <div className='my-4 mt-8'>
                    <hr className='w-full border-2 border-t border-red-600' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default WebsiteFacultyProfile;
