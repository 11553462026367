import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { XIcon } from "@heroicons/react/outline";
import Input from "../../../../../Components/Utils/Input";
import Button from "../../../../../Components/Utils/Button";
import Upload from "../../../../../Components/Utils/Upload";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Design
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-3 gap-3 p-5 '>
            <Input
              type='text'
              name='title'
              label={
                <div className='flex items-center'>
                  Title of the Design
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              placeholder='Enter Creator Names separated by commas, if more than one creator'
              name='creators'
              label={
                <div className='flex items-center'>
                  Creators
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='design_number'
              label={
                <div className='flex items-center'>
                  Design Number
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='date'
              name='registration_date'
              label={
                <div className='flex items-center'>
                  Registration Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <Input
              type='text'
              name='country'
              label={
                <div className='flex items-center'>
                  Country
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            <div className='space-y-1'>
              <label>
                <div className='flex items-center'>
                  Upload Design Registration Certificate
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              </label>
              <Upload
                label={
                  <ul className='text-red-600'>
                    <li>File upload max 500 KB</li>
                    <li>Image/PDF only</li>
                  </ul>
                }
                type='file'
                name='registration_certificate_url'
                onChange={(event) => {
                  console.log("asd");
                  let myFile = event.currentTarget.files[0];
                  formikRef.current.setFieldValue(
                    "registration_certificate_url",
                    myFile
                  );
                }}
              />
            </div>

            <div className='flex justify-between col-span-3 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
