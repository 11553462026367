import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import * as Yup from "yup";
import IqacForm from "./IqacForm";
import WebsiteForm from "./WebsiteForm";
import BaseApi from "../../../Http/BaseApi";
import { FaEye, FaGlobe } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import { IoIosLock, IoIosUnlock } from "react-icons/io";
import IqacSubmitPreviewModal from "./IqacSubmitPreviewModal";
import { FaArrowRight } from "react-icons/fa";

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const isDate = (value) => {
  // Regex to match typical date formats e.g., 01/01/2000, 2000-01-01, etc.
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
  return dateRegex.test(value) && !isNaN(Date.parse(value));
};
const formatDate = (value, format = "DD/MM/YYYY") => {
  // Format the date using moment
  return moment(value).format(format);
};
const TableViewProfile = ({ headers, data, setData, type }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formType, setFormType] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isIqacUpdate, setIsIqacUpdate] = useState(false);
  const [isWebsiteUpdate, setIsWebsiteUpdate] = useState(false);
  const [bulkCheck, setBulkCheck] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const handlePreview = () => {
    setPreviewModalOpen(true);
  };

  const handleViewDetails = (id) => {
    window.open(`/profile/details/${type}/${id}`);
  };

  const headerValues = Object.values(headers).slice(0, 3);

  const [initialValues, setInitialValues] = useState({
    field: "",
    visibility: "",
  });

  const handleCancel = () => {
    setModalOpen(false);
    setFormType(null);
    setIsIqacUpdate(false);
    setIsWebsiteUpdate(false);
    setSelectedRows([]);
    setBulkCheck(false);
  };

  const validationSchema = Yup.object({});

  const handleWebsiteModalOpen = (row) => {
    setSelectedRow(row);
    setInitialValues({
      field: "website",
      visibility: row.website,
    });
    setFormType("website");
    setModalOpen(true);
  };

  const handleIqacModalOpen = (row) => {
    setSelectedRow(row);
    setInitialValues({
      field: "iqac",
      visibility: row.iqac,
    });
    setFormType("iqac");
    setModalOpen(true);
  };

  const handleFinalSubmit = () => {
    handleUpdateVisibility(isIqacUpdate ? "iqac" : "website", "yes");
    setPreviewModalOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      await BaseApi().put(
        `/profile/${type}-visibility/${values.field}/${selectedRow.id}`,
        values
      );
      const updatedData = data.map((row) =>
        row.id === selectedRow.id
          ? { ...row, [values.field]: values.visibility }
          : row
      );
      setData(updatedData);
      toast.success("Visibility updated successfully!");
      handleCancel();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update visibility.");
    }
  };

  const handleCheckboxChange = (row) => {
    setSelectedRows((prev) =>
      prev.includes(row.id)
        ? prev.filter((id) => id !== row.id)
        : [...prev, row.id]
    );
  };

  const handleBulkCheckboxChange = () => {
    if (bulkCheck) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.id));
    }
    setBulkCheck(!bulkCheck);
  };
  const handleUpdateVisibility = async (field, value) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      // Separate the selected rows into checked and not checked IDs
      const checkedIds = selectedRows;
      const notCheckedIds = data
        .filter((row) => !selectedRows.includes(row.id))
        .map((row) => row.id);

      // Create the payload
      const payload = {
        field,
        checkedIds,
        notCheckedIds,
      };

      // Send the API request
      await BaseApi().put(`/profile/${type}-visibility/${field}`, payload);

      // Update the data locally
      setData((prevData) =>
        prevData.map((row) =>
          checkedIds.includes(row.id)
            ? { ...row, [field]: value }
            : notCheckedIds.includes(row.id)
            ? { ...row, [field]: "no" } // Assuming "no" is the opposite of "yes"
            : row
        )
      );

      toast.success(`${field} updated successfully!`);
      handleCancel();
    } catch (error) {
      console.error(error);
      toast.error(`Failed to update ${field}.`);
    }
  };

  useEffect(() => {
    if (isIqacUpdate) {
      const iqacSelectedRows = data
        .filter((row) => row.iqac === "yes")
        .map((row) => row.id);
      setSelectedRows(iqacSelectedRows);
      setBulkCheck(iqacSelectedRows.length === data.length);
    } else if (isWebsiteUpdate) {
      const websiteSelectedRows = data
        .filter((row) => row.website === "yes")
        .map((row) => row.id);
      setSelectedRows(websiteSelectedRows);
      setBulkCheck(websiteSelectedRows.length === data.length);
    } else {
      setSelectedRows([]);
      setBulkCheck(false);
    }
  }, [isIqacUpdate, isWebsiteUpdate, data]);

  return (
    <div className='overflow-hidden'>
      <ToastContainer />
      <div className='flex justify-end gap-4 mt-2 mb-4 mr-2'>
        {(isIqacUpdate || (!isIqacUpdate && !isWebsiteUpdate)) && (
          <button
            disabled
            onClick={() => setIsIqacUpdate(!isIqacUpdate)}
            className='inline-flex items-center justify-center text-xs font-medium text-blue-600 disabled:cursor-not-allowed '
          >
            Submit to IQAC
          </button>
        )}
        {(isWebsiteUpdate || (!isIqacUpdate && !isWebsiteUpdate)) &&
          type != "thesis-examiner-universities" && (
            <button
              onClick={() => setIsWebsiteUpdate(!isWebsiteUpdate)}
              className='inline-flex items-center justify-center text-xs font-medium text-green-600 '
            >
              Publish on Website
            </button>
          )}
      </div>
      {data.length ? (
        <table className='min-w-full divide-y divide-gray-200'>
          <thead>
            <tr>
              <th className='px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                #
              </th>
              {(isIqacUpdate || isWebsiteUpdate) && (
                <th className='px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                  <input
                    type='checkbox'
                    checked={bulkCheck}
                    onChange={handleBulkCheckboxChange}
                  />
                </th>
              )}
              {headerValues.map((header, index) => (
                <th
                  key={index}
                  className='px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {header.label}
                </th>
              ))}
              <th className='px-4 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase'>
                Action
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className='px-4 py-1 text-sm text-gray-900'>
                  {rowIndex + 1}
                </td>
                {(isIqacUpdate || isWebsiteUpdate) && (
                  <td className='px-4 py-1 text-sm text-gray-900'>
                    <input
                      type='checkbox'
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleCheckboxChange(row)}
                      disabled={isIqacUpdate && row.iqac === "yes"}
                    />
                  </td>
                )}
                {headerValues.map((header, cellIndex) => (
                  <td
                    key={cellIndex}
                    className='px-4 py-1 text-sm text-gray-900'
                  >
                    {
                      // Check if the value is a date and format it
                      isDate(getValueByPath(row, header.path))
                        ? formatDate(getValueByPath(row, header.path))
                        : getValueByPath(row, header.path)
                    }
                  </td>
                ))}
                <td className='flex justify-end gap-2 px-4 py-1 text-sm text-right text-gray-900'>
                  <FaEye
                    className='text-blue-700 cursor-pointer'
                    size={18}
                    title='View details'
                    onClick={() => handleViewDetails(row.id)}
                  />
                  <FaGlobe
                    className={`cursor-pointer ${
                      row.website === "yes" ? "text-green-600" : "text-gray-500"
                    }`}
                    title='Website'
                    size={18}
                    // onClick={() => handleWebsiteModalOpen(row)}
                  />
                  <div
                    className='cursor-pointer'
                    // onClick={() => handleIqacModalOpen(row)}
                  >
                    {row.iqac == "yes" ? (
                      <>
                        {row["status"] == "Ongoing" ? (
                          <IoIosUnlock
                            title='Partial Submission'
                            className='text-yellow-500'
                            size={20}
                          />
                        ) : (
                          <IoIosLock
                            title='Submitted'
                            className='text-red-600'
                            size={20}
                          />
                        )}
                      </>
                    ) : (
                      <IoIosUnlock
                        className='text-gray-500'
                        title='Not Submitted'
                        size={20}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}

      {(isIqacUpdate || isWebsiteUpdate) && (
        <div className='flex justify-end gap-4 mt-4'>
          <button
            className='flex items-center justify-center px-2 py-1 text-center text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
            onClick={handleCancel}
          >
            <XIcon className='w-5 h-5 mr-1' /> Cancel
          </button>
          <button
            // onClick={() =>
            //   handleUpdateVisibility(isIqacUpdate ? "iqac" : "website", "yes")
            // }

            onClick={handlePreview}
            className='inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-indigo-700 focus:ring-indigo-500'
          >
            <FaArrowRight className='w-5 h-5 mr-2' /> Next
          </button>
        </div>
      )}

      {isModalOpen && (
        <Modal className='sm:max-w-sm'>
          {formType === "website" ? (
            <WebsiteForm
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          ) : (
            <IqacForm
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          )}
        </Modal>
      )}

      {isPreviewModalOpen && (
        <IqacSubmitPreviewModal
          data={data}
          type={isIqacUpdate ? "iqac" : "website"}
          selectedRows={selectedRows}
          headers={headers}
          onSubmit={handleFinalSubmit}
          onCancel={() => setPreviewModalOpen(false)}
        />
      )}
    </div>
  );
};

export default TableViewProfile;
