import React, { useEffect, useState } from "react";
import moment from "moment";
import BaseApi from "../../../../Http/BaseApi";
import { FaCalendarDays, FaGlobe, FaIdCard } from "react-icons/fa6";

const headers = {
  name: { label: "Name of the Award", path: "name" },
  month: { label: "Month of the Award", path: "monthName" },
  year: { label: "Year of the Award", path: "year" },
  from_date: { label: "From date", path: "from_date" },
  to_date: { label: "To date", path: "to_date" },
  awarding_agency: { label: "Awarding Agency", path: "awarding_agency" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Support = ({ view }) => {
  const [supports, setSupports] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/supports");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredSupports = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by from_date (latest date first)
      // const sortedSupports = filteredSupports.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.from_date.path));
      //   const dateB = moment(getValueByPath(b, headers.from_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setSupports(filteredSupports);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(supports.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderSupport = (support) => {
    const name = getValueByPath(support, headers.name.path);
    const month = getValueByPath(support, headers.month.path);
    const year = getValueByPath(support, headers.year.path);
    const fromDate = getValueByPath(support, headers.from_date.path);
    const toDate = getValueByPath(support, headers.to_date.path);
    const awardingAgency = getValueByPath(
      support,
      headers.awarding_agency.path
    );

    // Check if date is valid
    const isFromDateValid = moment(fromDate, "YYYY-MM-DD", true).isValid();
    const isToDateValid = moment(toDate, "YYYY-MM-DD", true).isValid();

    // Format dates
    const formattedFromDate = isFromDateValid
      ? moment(fromDate).format("DD MMM YYYY")
      : null;
    const formattedToDate = isToDateValid
      ? moment(toDate).format("DD MMM YYYY")
      : null;

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {name && (
            <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          )}

          <div className='flex-wrap items-center gap-4'>
            {awardingAgency && (
              <div className='flex gap-1 text-sm '>
                <span className='flex gap-1'>
                  <FaIdCard className='flex-shrink-0 mt-1 text-yellow-500' />
                </span>
                <span>{awardingAgency}</span>
              </div>
            )}

            {isFromDateValid &&
              isToDateValid &&
              formattedFromDate &&
              formattedToDate && (
                <div className='flex items-center gap-1 text-sm '>
                  <FaCalendarDays className='text-yellow-500' />
                  {formattedFromDate} - {formattedToDate}
                </div>
              )}

            {year && month && (
              <span className='flex items-center gap-1 text-sm '>
                <FaCalendarDays className='text-yellow-500' />
                {year} - {month}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900 '>
          <FaGlobe />
          National/International Fellowship/Financial Support
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {supports.length}
        </span>
      </div>
      {supports.length ? (
        <div>
          {supports.slice(0, visibleCount).map((support, index) => (
            <div key={index}>{renderSupport(support)}</div>
          ))}
          {supports.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Support;
