import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaRegComments,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";
import { IoBusiness } from "react-icons/io5";

const headers = {
  startup_name: { label: "Name of the Startup", path: "startup_name" },
  date_started: { label: "Date Started", path: "date_started" },
  registration_details: {
    label: "Registration Details",
    path: "registration_details",
  },
  objective: { label: "Objective of the Startup", path: "objective" },
  details: { label: "Details of the Startup", path: "details" },
  role: { label: "Your Role in the Startup", path: "role" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const StartupDevelopment = ({ view }) => {
  const [startupDevelopments, setStartupDevelopments] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/startup-developments");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by date_started (latest first)
      // const sortedData = filteredData.sort((a, b) => {
      //   const dateA = getValueByPath(a, headers.date_started.path);
      //   const dateB = getValueByPath(b, headers.date_started.path);

      //   return moment(dateB).diff(moment(dateA)); // Latest date first
      // });

      setStartupDevelopments(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(startupDevelopments.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderStartupDevelopment = (startupDevelopment) => {
    const startupName = getValueByPath(
      startupDevelopment,
      headers.startup_name.path
    );
    const dateStarted = formatDate(
      getValueByPath(startupDevelopment, headers.date_started.path)
    );
    const registrationDetails = getValueByPath(
      startupDevelopment,
      headers.registration_details.path
    );
    const objective = getValueByPath(
      startupDevelopment,
      headers.objective.path
    );
    const details = getValueByPath(startupDevelopment, headers.details.path);
    const role = getValueByPath(startupDevelopment, headers.role.path);

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {startupName && (
            <h3 className='text-lg font-medium text-blue-900'>{startupName}</h3>
          )}

          {details && (
            <span className='flex gap-1 text-sm '>
              <FaFileLines className='flex-shrink-0 mt-1 text-yellow-500' />
              {details}
            </span>
          )}

          {registrationDetails && (
            <span className='flex gap-1 text-sm '>
              <FaUserTie className='flex-shrink-0 mt-1 text-yellow-500' />
              Registration Details: {registrationDetails}
            </span>
          )}

          {role && (
            <span className='flex gap-1 text-sm '>
              <FaUsers className='flex-shrink-0 mt-1 text-yellow-500' />
              Role: {role}
            </span>
          )}

          <div className='flex items-center gap-3'>
            {objective && (
              <span className='flex gap-1 text-sm '>
                <FaRegComments className='flex-shrink-0 mt-1 text-yellow-500' />
                Objective: {objective}
              </span>
            )}
          </div>
          {dateStarted && (
            <span className='flex gap-1 text-sm '>
              <FaCalendarDays className='flex-shrink-0 mt-1 text-yellow-500' />
              {dateStarted}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 mb-3 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900 '>
          <IoBusiness />
          Startup
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {startupDevelopments.length}
        </span>
      </div>
      {startupDevelopments.length ? (
        <div>
          {startupDevelopments
            .slice(0, visibleCount)
            .map((startupDevelopment, index) => (
              <div key={index}>
                {renderStartupDevelopment(startupDevelopment)}
              </div>
            ))}
          {startupDevelopments.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default StartupDevelopment;
