import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import Input from "../../../../Components/Utils/Input";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const formikRef = useRef(null);
  // const monthTypes = [
  //   { id: 1, name: "January" },
  //   { id: 2, name: "February" },
  //   { id: 3, name: "March" },
  //   { id: 4, name: "April" },
  //   { id: 5, name: "May" },
  //   { id: 6, name: "June" },
  //   { id: 7, name: "July" },
  //   { id: 8, name: "August" },
  //   { id: 9, name: "September" },
  //   { id: 10, name: "October" },
  //   { id: 11, name: "November" },
  //   { id: 12, name: "December" },
  // ];

  const roleTypes = [
    { id: 1, name: "Resource Person" },
    { id: 2, name: "Session Chair" },
    { id: 3, name: "Session Convener" },
    { id: 4, name: "Trainer" },
    { id: 5, name: "Participant" },
    { id: 6, name: "Panelist" },
  ];
  const levelTypes = [
    { id: 1, name: "National" },
    { id: 2, name: "International" },
  ];
  const programmeTypeTypes = [
    { id: 1, name: "Workshop" },
    { id: 2, name: "Conference" },
    { id: 3, name: "Seminar" },
    { id: 4, name: "Symposium" },
    { id: 5, name: "Training Programme" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Participation in Programme
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-2 gap-3 p-5'>
            <CustomSelect
              defaultInputValue={initialValues.programme_type}
              options={programmeTypeTypes}
              name='programme_type'
              label={
                <div className='flex items-center'>
                  Type of Programme
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "programme_type",
                  selectedOption.name
                );
              }}
            />
            <Input
              type='text'
              label={
                <div className='flex items-center'>
                  Name of the programme
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='name'
            />
            <Input
              type='textarea'
              label={
                <div className='flex items-center'>
                  Brief Description
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='description'
            />
            <Input
              type='text'
              name='organized_by'
              label={
                <div className='flex items-center'>
                  Organized By
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='text'
              name='held_at'
              label={
                <div className='flex items-center'>
                  Held At
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />

            {/* <CustomSelect
              defaultInputValue={
                monthTypes.find((month) => month.id == initialValues.month)
                  ?.name
              }
              options={monthTypes}
              name="month"
              label={
                <div className="flex items-center">
                  Month of Joining
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("month", selectedOption.id);
              }}
            />

            <Input
              type="text"
              name="year"
              label={
                <div className="flex items-center">
                  Year
                  <span className="ml-1 text-red-600">*</span>
                </div>
              }
            /> */}

            <CustomSelect
              defaultInputValue={initialValues.role}
              options={roleTypes}
              name='role'
              label={
                <div className='flex items-center'>
                  Role
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("role", selectedOption.name);
              }}
            />

            {/* {values.programme_type == "Other" && (
              <Input
                type="text"
                name="other"
                label={
                  <div className="flex items-center">
                    Other
                    <span className="ml-1 text-red-600">*</span>
                    </div>
                    }
                    />
                    )} */}

            <Input
              type='date'
              name='start_date'
              label={
                <div className='flex items-center'>
                  Start Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <Input
              type='date'
              name='end_date'
              label={
                <div className='flex items-center'>
                  End Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.level}
              options={levelTypes}
              name='level'
              label={
                <div className='flex items-center'>
                  Level
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("level", selectedOption.name);
              }}
            />

            <div className='flex justify-between col-span-2 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
