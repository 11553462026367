import { toast } from "react-toastify";

const LaravelValidationError = (error) => {
  // Check if the error response has validation errors from Laravel
  if (error.response && error.response.data && error.response.data.errors) {
    const errors = error.response.data.errors;

    // Display each error message in a toast
    Object.values(errors).forEach((messages) => {
      messages.forEach((message) => {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
        });
      });
    });
  } else {
    // Display a generic error message if no validation errors are found
    toast.error("An unexpected error occurred.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
    });
  }
};

export default LaravelValidationError;
