import React from "react";

export default function ActionConfirm(props) {
  let actionState = props.actionState;
  let data = props.data;
  let firstOnClick = props.firstOnClick;
  let btnLabel = props.btnLabel;
  let onYesClick = props.onYesClick;
  let onNoClick = props.onNoClick;
  let classNames = props.classNames;
  console.log(classNames);
  return (
    <>
      {(actionState.state == 0 || actionState.itemId != data.id) && (
        <button className={classNames} onClick={firstOnClick}>
          {btnLabel}
        </button>
      )}
      {actionState.state == 1 && actionState.itemId == data.id && (
        <>
          <p className='mb-1 text-center text-red-700 '> Are You Sure? </p>

          <div className='flex justify-center'>
            <button className='outline-button-danger' onClick={onYesClick}>
              Yes
            </button>
            <span className='mr-2'></span>
            <button className='outline-button-success' onClick={onNoClick}>
              No
            </button>
          </div>
        </>
      )}
    </>
  );
}
