import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import { FaBuilding, FaCalendarDays, FaSchool } from "react-icons/fa6";

const headers = {
  institution: { label: "Institution/Organization", path: "institution" },
  visit_date: { label: "Date of Visit", path: "visit_date" },
  visit_purpose: { label: "Purpose of Visit", path: "visit_purpose" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "";
};

const AcademicVisits = ({ view }) => {
  const [academicVisits, setAcademicVisits] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/academic-visits");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredVisits = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // // Sort by from_date in descending order
      // const sortedVisits = filteredVisits.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.from_date.path));
      //   const dateB = moment(getValueByPath(b, headers.from_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setAcademicVisits(filteredVisits);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    setVisibleCount(isExpanded ? 5 : academicVisits.length); // Toggle between showing all or 5
    setIsExpanded(!isExpanded);
  };

  const renderAcademicVisit = (visit) => {
    const institution = getValueByPath(visit, headers.institution.path);
    const visitPurpose = getValueByPath(visit, headers.visit_purpose.path);
    const fromDate = formatDate(getValueByPath(visit, headers.from_date.path));
    const toDate = getValueByPath(visit, headers.to_date.path)
      ? formatDate(getValueByPath(visit, headers.to_date.path))
      : "Present";

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {institution && (
            <p className='flex gap-1 text-base '>
              <FaBuilding className='flex-shrink-0 mt-1 text-green-600' />
              <span className='text-green-600'>{institution}</span>
            </p>
          )}
          {visitPurpose && (
            <p className='text-sm text-gray-500'>Purpose: {visitPurpose}</p>
          )}
          {fromDate && (
            <div className='flex items-center gap-3'>
              <span className='flex items-center gap-1 text-sm '>
                <FaCalendarDays className='flex-shrink-0 text-yellow-500' />
                {fromDate} - {toDate}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <FaSchool className='mt-1' />
          Academic Visits
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {academicVisits.length}
        </span>
      </div>

      {academicVisits.length ? (
        <div>
          {academicVisits.slice(0, visibleCount).map((visit, index) => (
            <div key={index}>{renderAcademicVisit(visit)}</div>
          ))}
          {academicVisits.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default AcademicVisits;
