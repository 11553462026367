import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  scholar_name: {
    label: "Name of the scholar",
    path: "scholar_name",
  },
  dissertation_title: {
    label: "Title of the Dissertation",
    path: "dissertation_title",
  },
  month: { label: "Awarded Month", path: "monthName" },
  year: { label: "Awarded Year", path: "year" },
};

const MPhilAwardee = () => {
  const [mPhilAwardees, setMPhilAwardees] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/m-phil-awardees");
      setMPhilAwardees(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-center mb-4 text-xl font-medium text-gray-700 uppercase'>
        M. Phil. Awardees
      </h2>
      <TableViewProfile
        headers={headers}
        data={mPhilAwardees}
        type='m-phil-awardees'
        setData={setMPhilAwardees}
      />
    </div>
  );
};

export default MPhilAwardee;
