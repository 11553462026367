import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { FaIdCard, FaUser, FaUsers } from "react-icons/fa";
import { FaCalendarDays, FaRegMap } from "react-icons/fa6";
import moment from "moment";
import { MdOutlineDesignServices } from "react-icons/md";

const headers = {
  title: {
    label: "Title of the Design",
    path: "title",
  },
  creators: {
    label: "Creators",
    path: "creators",
  },
  design_number: {
    label: "Design Number",
    path: "design_number",
  },
  registration_date: {
    label: "Registration Date",
    path: "registration_date",
  },
  country: {
    label: "Country",
    path: "country",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const Design = ({ view }) => {
  const [designs, setDesigns] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Set initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/designs");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredDesigns = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by registration_date (latest date first)
      // const sortedDesigns = filteredDesigns.sort((a, b) => {
      //   const dateA = moment(getValueByPath(a, headers.registration_date.path));
      //   const dateB = moment(getValueByPath(b, headers.registration_date.path));
      //   return dateB - dateA; // Descending order
      // });

      setDesigns(filteredDesigns);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(designs.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderDesign = (design) => {
    const title = getValueByPath(design, headers.title.path);
    const creators = getValueByPath(design, headers.creators.path);
    const designNumber = getValueByPath(design, headers.design_number.path);
    const registrationDate = getValueByPath(
      design,
      headers.registration_date.path
    );
    const country = getValueByPath(design, headers.country.path);

    const isDateValid = (date) => moment(date).isValid();

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{title}</h3>

          {/* Display Creators if available */}
          {creators && (
            <p className='flex items-center gap-2 text-base text-green-600 whitespace-nowrap'>
              <FaUser className='flex-shrink-0' />
              <span className='text-green-600'>{creators}</span>
            </p>
          )}

          <div className='space-y-1 lg:flex lg:gap-3'>
            {/* Display Design Number if available */}
            {designNumber && (
              <div className='flex items-center gap-1 whitespace-nowrap'>
                <span className='flex items-center gap-1 text-sm whitespace-nowrap'>
                  <FaIdCard className='flex-shrink-0 text-yellow-500' />
                  Design Number:
                </span>
                <span>{designNumber}</span>
              </div>
            )}

            {/* Display Registration Date if valid */}
            {isDateValid(registrationDate) && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaCalendarDays className='flex-shrink-0 text-yellow-500' />
                Registration Date:{" "}
                {moment(registrationDate).format("DD MMM YYYY")}
              </div>
            )}

            {/* Display Country if available */}
            {country && (
              <div className='flex items-center gap-1 text-sm whitespace-nowrap'>
                <FaRegMap className='flex-shrink-0 text-yellow-500' />
                {country}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Header with count */}
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex items-center gap-2 text-xl font-medium text-blue-900'>
          <MdOutlineDesignServices />
          Design
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2'>
          {designs.length}
        </span>
      </div>

      {designs.length ? (
        <div>
          {designs.slice(0, visibleCount).map((design, index) => (
            <div key={index}>{renderDesign(design)}</div>
          ))}
          {designs.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default Design;
