import React, { useEffect, useState } from "react";
import {
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
  UsersIcon,
  OfficeBuildingIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAsync,
  getDepartmentsAsync,
  updateDashboardNotesAsync,
} from "../../Redux/MasterSlice";
import { Link, useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Registering the components necessary for the bar chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function EstablishmentDashboard() {
  const dispatch = useDispatch();
  const [savingNote, setSavingNote] = useState(false);
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  const master = useSelector((state) => state.master);
  const dashboardData = { ...master.dashboardData };
  const departments = [...master.departments];

  const [note, setNote] = useState(dashboardData.dashboardNote);
  const [timer, setTimer] = useState(null);
  const [filter, setFilter] = useState("");

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      saveNote({ note: value });
    }, 1000);
    setTimer(newTimer);
  };

  const saveNote = async (noteContent) => {
    setSavingNote(true);
    console.log("Note auto-saved:", noteContent);
    // Simulating a save operation (e.g., API call)
    // localStorage.setItem("quickNote", noteContent);
    await dispatch(updateDashboardNotesAsync(noteContent)).unwrap();
    setSavingNote(false);
  };

  useEffect(() => {
    if (Object.keys(dashboardData).length === 0) {
      dispatch(getDashboardAsync());
    }

    if (departments.length === 0) {
      dispatch(getDepartmentsAsync());
    }
  }, []);
  useEffect(() => {
    setNote(dashboardData.dashboardNote);
  }, [dashboardData.dashboardNote]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='min-h-screen bg-gray-100'>
      {/* Dashboard Stats */}
      &nbsp;
      {/* Recent Activity & Quick Actions */}
      <div className='grid grid-cols-2 gap-4 px-5 mt-8'>
        {/* Quick Note Section */}
        <div className='w-full p-6 bg-white shadow-md rounded-xl '>
          <h2 className='pb-4 text-lg font-semibold text-gray-700 border-b'>
            Quick Note
            <small className='float-right text-gray-400'>
              {savingNote && "Saving..."}
            </small>
          </h2>
          <textarea
            className='w-full h-32 p-4 mt-2 text-base text-gray-700 bg-yellow-100 border-none rounded-md focus:ring-2 focus:ring-yellow-500 focus:outline-none'
            placeholder='Type your notes here...'
            value={note}
            onChange={handleNoteChange}
          ></textarea>
        </div>

        {/* Recent Activity Section */}

        <div className='w-full p-6 bg-white shadow-md rounded-xl '>
          <h2 className='pb-4 text-lg font-semibold text-gray-700 border-b'>
            Quick Links
          </h2>
          <div className='grid grid-cols-1 gap-2 p-2 '>
            {authData.user.id == 495 && (
              <>
                <Link
                  to='/staff/create'
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  href='#!'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                >
                  Add New Teacher/Officer
                  <UserAddIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </Link>{" "}
                <Link
                  to='/staff?type=3'
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  href='#!'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                >
                  List of Officers
                  <UserGroupIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </Link>{" "}
                <button
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                  onClick={() => {
                    openModal();
                  }}
                >
                  List of Teachers
                  <UsersIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </button>
                {showModal && (
                  <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50'>
                    <div className='w-2/3 bg-white shadow-lg rounded-xl'>
                      <div className='flex items-center justify-between p-4 border-b'>
                        <h2 className='text-lg font-semibold text-gray-700'>
                          Select Department
                        </h2>
                        <button
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-6 h-6 text-gray-700'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={2}
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                        </button>
                      </div>
                      <div className='p-4 overflow-scroll h-[70vh]'>
                        <input
                          type='text'
                          className='w-full p-2 border rounded'
                          onChange={(e) => {
                            setFilter(e.target.value);
                          }}
                          placeholder='Search for department'
                        />

                        <div className='grid grid-cols-3 gap-2'>
                          {departments
                            .filter((d) => d.type == "Academic")
                            ?.filter((d) =>
                              d.name
                                .toLowerCase()
                                .includes(filter.toLowerCase())
                            )

                            ?.map((department) => (
                              <Link
                                to={`/staff?type=1&department_id=${department.id}`}
                                key={department.id}
                                className='px-3 py-1 border rounded cursor-pointer hover:text-blue-600 hover:border-blue-600'
                              >
                                {department.name}
                              </Link>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {authData.user.id == 496 && (
              <>
                <Link
                  to='/staff/create'
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  href='#!'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                >
                  Add New Employee
                  <UserAddIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </Link>{" "}
                <Link
                  to='/staff?type=2'
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  href='#!'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                >
                  List of Employees (Grade III)
                  <UserGroupIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </Link>{" "}
                <Link
                  to='/staff?type=6'
                  className='flex items-center justify-between w-full hover:bg-gray-50'
                  data-mdb-ripple='true'
                  data-mdb-ripple-color='primary'
                >
                  List of Employees (Grade IV)
                  <UsersIcon
                    className='inline w-6 h-6 text-green-500'
                    aria-hidden='true'
                  />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
