import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaFileLines,
  FaRegComments,
  FaUserTie,
} from "react-icons/fa6";
import { MdOutlineContacts } from "react-icons/md";

// Define headers for consistent reference
const headers = {
  name: { label: "Name of the Activity ", path: "name" },
  from_date: { label: "From date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  award_received: {
    label: "Whether Any Award received for this activity?",
    path: "award_received",
  },
  award_recognition: {
    label: "Name of the Award/Recognition",
    path: "award_recognition",
  },
  awarding_agency: {
    label: "Name of the Awarding Agency",
    path: "awarding_agency",
  },
  month: { label: "Month of Award", path: "monthName" },
  year: { label: "Year of Award", path: "year" },
};

// Helper function to get value by path
const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

// Helper function to format date
const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "N/A";
};

const ExtensionActivitie = ({ view }) => {
  const [extensionActivities, setExtensionActivities] = useState([]);
  const [error, setError] = useState(null); // Add state for handling errors
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/extension-activities");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by from_date (latest first)
      // const sortedData = filteredData.sort((a, b) => {
      //   const dateA = getValueByPath(a, headers.from_date.path);
      //   const dateB = getValueByPath(b, headers.from_date.path);
      //   return moment(dateB).diff(moment(dateA)); // Latest date first
      // });

      setExtensionActivities(filteredData);
      setError(null); // Reset error on successful fetch
    } catch (error) {
      console.error(error);
      setError(
        "Failed to fetch Outreach/Extension Activities. Please try again later."
      );
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(extensionActivities.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  // Function to render individual activity
  const renderExtensionActivitie = (extensionActivitie) => {
    const name = getValueByPath(extensionActivitie, headers.name.path);
    const fromDate = formatDate(
      getValueByPath(extensionActivitie, headers.from_date.path)
    );
    const toDate = formatDate(
      getValueByPath(extensionActivitie, headers.to_date.path)
    );
    const awardReceived = getValueByPath(
      extensionActivitie,
      headers.award_received.path
    );
    const awardingAgency = getValueByPath(
      extensionActivitie,
      headers.awarding_agency.path
    );
    const awardRecognition = getValueByPath(
      extensionActivitie,
      headers.award_recognition.path
    );
    const month = getValueByPath(extensionActivitie, headers.month.path);
    const year = getValueByPath(extensionActivitie, headers.year.path);

    return (
      <div className='items-start px-2 py-1 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>

          {awardingAgency && (
            <span className='flex gap-1 text-sm'>
              <FaUserTie className='flex-shrink-0 mt-1 text-yellow-500' />
              {awardingAgency}
            </span>
          )}

          {awardReceived && (
            <span className='flex gap-1 text-sm'>
              <FaFileLines className='flex-shrink-0 mt-1 text-yellow-500' />
              Award Received: {awardReceived}
            </span>
          )}

          <div className='flex flex-wrap gap-3'>
            {awardRecognition && (
              <span className='flex gap-1 text-sm'>
                <FaRegComments className='flex-shrink-0 mt-1 text-yellow-500' />
                Award Recognition: {awardRecognition}
              </span>
            )}

            <span className='flex items-center gap-1 text-sm'>
              <FaCalendarDays className='text-yellow-500' />
              {fromDate} - {toDate}
            </span>
            {year && month && (
              <span className='flex items-center gap-1 text-sm'>
                <FaCalendarDays className='text-yellow-500' />
                {year} - {month}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900'>
          <MdOutlineContacts />
          Outreach/Extension Activities
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {extensionActivities.length}
        </span>
      </div>
      {error ? (
        <p className='py-4 text-center text-red-500'>{error}</p>
      ) : extensionActivities.length ? (
        <div>
          {extensionActivities
            .slice(0, visibleCount)
            .map((extensionActivitie, index) => (
              <div key={index}>
                {renderExtensionActivitie(extensionActivitie)}
              </div>
            ))}
          {extensionActivities.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ExtensionActivitie;
