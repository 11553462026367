import { createReactBlockSpec } from "@blocknote/react";

import "./pdf-embed.css";
import { FaImages, FaTimes } from "react-icons/fa";
import { useState } from "react";
import MediaManagement from "../../../Pages/Website/MediaManagement";

export const ImageGallery = createReactBlockSpec(
  {
    type: "gallery",
    propSchema: {
      content: {
        default: [],
      },
    },
    content: "none",
    // isFileBlock: true,
  },
  {
    render: ({ block, editor }) => {
      return <ImageGalleryComponent block={block} editor={editor} />;
    },
  }
);

const ImageGalleryComponent = ({ block, editor }) => {
  const [isMediaManagerOpen, setIsMediaManagerOpen] = useState(false); // Toggle for input field

  const addImages = (imageUrls) => {
    console.log("Adding images:", imageUrls);

    // Validate and update content
    if (Array.isArray(imageUrls)) {
      const newContent = [
        ...block.props.content,
        ...imageUrls.map((url) => ({ url, caption: "" })),
      ];
      editor.updateBlock(block.id, {
        ...block,
        props: {
          ...block.props,
          content: newContent,
        },
      });
    } else {
      console.error("Invalid image URLs received:", imageUrls);
    }

    setIsMediaManagerOpen(false);
  };

  // Update caption for a specific image

  const updateCaption = (index, newCaption) => {
    const newContent = [...block.props.content];
    newContent[index] = {
      ...newContent[index],
      caption: newCaption, // Update the caption for the specific image
    };
    editor.updateBlock(block.id, {
      ...block,
      props: {
        ...block.props,
        content: newContent,
      },
    });
  };

  return (
    <div className='w-full gallery-block'>
      {block.props.content?.length > 0 && (
        <div className='grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
          {block.props.content?.map((item, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                src={item.url}
                alt={`Gallery Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "16/9",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              {!editor.isEditable && item.caption && (
                <p
                  style={{
                    padding: "8px",
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  {item.caption}
                </p>
              )}
              {editor.isEditable && (
                <>
                  <button
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // Remove the image
                      const newContent = [...block.props.content];
                      newContent.splice(index, 1);
                      editor.updateBlock(block.id, {
                        ...block,
                        props: {
                          ...block.props,
                          content: newContent,
                        },
                      });
                    }}
                  >
                    <FaTimes />
                  </button>
                  {/* Caption Input */}
                  <input
                    type='text'
                    value={item.caption}
                    onChange={(e) => updateCaption(index, e.target.value)} // Update caption
                    placeholder='Write a caption...'
                    style={{
                      width: "100%",
                      marginTop: "8px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      )}

      {editor.isEditable && (
        <>
          <button
            type='button'
            onClick={() => setIsMediaManagerOpen(true)}
            className='px-1 py-1 mt-1 ml-2 border border-gray-300 rounded-md'
          >
            Insert Images <FaImages className='inline' />
          </button>

          {isMediaManagerOpen && (
            <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
              <div className='relative w-full p-4 bg-white rounded-lg max-w-7xl'>
                <button
                  className='absolute top-0 right-0 m-2 text-2xl'
                  onClick={() => setIsMediaManagerOpen(false)}
                >
                  <FaTimes />
                </button>
                {/* make scrollable */}
                <div className='h-[90vh] overflow-y-scroll'>
                  <MediaManagement types='image' onMultiSelect={addImages} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
