import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const ProgrammeOrganized = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [programmeOrganizeds, setProgrammeOrganizeds] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    program_type: "",
    other_program_type: "",
    programme: "",
    role: "",
    from_date: "",
    to_date: "",
    published: "",
    proceeding_title: "",
    month: "",
    year: "",
    editor_name: "",
    issn_isbn: "",
    publisher_name: "",
    indexed_in: "",
  });
  const headers = {
    program_type: { label: "Type of the Programme", path: "program_type" },
    other_program_type: {
      label: "Other Programme",
      path: "other_program_type",
    },
    programme: { label: "Title of the Programme", path: "programme" },
    role: { label: "Role", path: "role" },
    from_date: { label: "From Date", path: "from_date" },
    to_date: { label: "To Date", path: "to_date" },
    published: {
      label: "Whether any proceeding published?",
      path: "published",
    },
    proceeding_title: {
      label: "Title of the  Proceeding",
      path: "proceeding_title",
    },
    month: { label: "Month of Publication", path: "monthName" },
    year: { label: "Year of Publication", path: "year" },
    editor_name: { label: "Editor Name with Affiliation", path: "editor_name" },
    issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
    publisher_name: { label: "Publisher Name", path: "publisher_name" },
    indexed_in: { label: "Indexed In", path: "indexed_in" },
  };

  const validationSchema = Yup.object({
    program_type: Yup.string().required("This field is required!"),
    other_program_type: Yup.string().when("program_type", {
      is: "Other",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    programme: Yup.string().required("This field is required!"),
    role: Yup.string().required("This field is required!"),
    published: Yup.string().required("This field is required!"),
    proceeding_title: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    month: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    year: Yup.number().when("published", {
      is: "Yes",
      then: Yup.number()
        .typeError("Year must be a valid year")
        .required("This field is required!")
        .min(1980, "Year must be at least 1980")
        .max(new Date().getFullYear(), `Year can't be in the future`),
      otherwise: Yup.number().notRequired(),
    }),
    editor_name: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    publisher_name: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    indexed_in: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
    issn_isbn: Yup.string().when("published", {
      is: "Yes",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/programme-organizeds");
      setProgrammeOrganizeds(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/programme-organizeds/${editId}`, values);
        toast.success(`Programme Organized Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/programme-organizeds", values);
        toast.success(`Programme Organized Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = programmeOrganizeds.find(
        (programmeOrganized) => programmeOrganized.id === id
      );
      setInitialValues({
        id: editableData.id,
        program_type: editableData.program_type,
        other_program_type: editableData.other_program_type,
        programme: editableData.programme,
        role: editableData.role,
        from_date: editableData.from_date,
        to_date: editableData.to_date,

        published: editableData.published,
        proceeding_title: editableData.proceeding_title,
        month: editableData.month,
        year: editableData.year,
        editor_name: editableData.editor_name,
        issn_isbn: editableData.issn_isbn,
        publisher_name: editableData.publisher_name,
        indexed_in: editableData.indexed_in,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      program_type: "",
      other_program_type: "",
      programme: "",
      role: "",
      from_date: "",
      to_date: "",
      published: "",
      proceeding_title: "",
      month: "",
      year: "",
      editor_name: "",
      issn_isbn: "",
      publisher_name: "",
      indexed_in: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      program_type: "",
      other_program_type: "",
      programme: "",
      role: "",
      from_date: "",
      to_date: "",
      published: "",
      proceeding_title: "",
      month: "",
      year: "",
      editor_name: "",
      issn_isbn: "",
      publisher_name: "",
      indexed_in: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    try {
      await BaseApi().delete(`/profile/programme-organizeds/${id}`);
      toast.success(`Programme Organized Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal uppercase border bg-blue-50'>
          <h1 className='flex flex-col justify-start'>
            Programme Organized :
            <span className='text-sm text-red-600'>
              Conference/Seminar/Workshop/FDP etc.
            </span>
          </h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={programmeOrganizeds}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default ProgrammeOrganized;
