import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import ReactSelect from "react-select";

export default function CustomSelect(props) {
  const [isEditable, setIsEditable] = useState(!props.secureEdit); // State to toggle edit mode

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const classNames = isEditable
    ? "focus:ring-indigo-500 focus:border-indigo-500"
    : "bg-gray-100 cursor-not-allowed";

  return (
    <>
      <div className={`relative group ${props.className}`}>
        <label
          htmlFor={props.id}
          className='block text-sm font-medium text-gray-700'
        >
          {props.label}
        </label>

        <ReactSelect
          readOnly={props.readOnly ? props.readOnly : !isEditable}
          isDisabled={props.disabled ? props.disabled : !isEditable} // Use `disabled` instead of `readOnly`
          // value={props.options?.find((option) => option.id == props.value)}
          defaultInputValue={props.defaultInputValue}
          // key={props.key}
          ref={props.reference}
          isClearable={true}
          backspaceRemovesValue={true}
          className={`   rounded-none relative block w-full   py-2  placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm `}
          options={props.options}
          name={props.name}
          isSearchable={props.isSearchable ?? false}
          getOptionValue={props.getOptionValue}
          getOptionLabel={props.getOptionLabel}
          onChange={props.onChange}
        />

        {/* <Field
          as="select"
          id={props.id}
          name={props.name}
          autoComplete=""
          disabled={props.disabled}
          
        >
          {props.children}
        </Field> */}
        {/* Pencil Icon */}
        {props.secureEdit && !isEditable && (
          <FaPencilAlt
            className='absolute text-gray-400 cursor-pointer top-9 right-3 group-hover:text-indigo-600'
            onClick={handleEditClick}
            title='Edit'
          />
        )}
        <ErrorMessage
          name={props.name}
          component='div'
          className='py-2 text-red-500'
        />
      </div>
    </>
  );
}
