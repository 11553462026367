import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaCog } from "react-icons/fa";
import Loader from "../../utils/Loader";
import { RiUserStarFill } from "react-icons/ri";

const Expertise = () => {
  const [expertise, setExpertise] = useState(); // Array to store qualifications
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await BaseApi().get("/profile/expertises");
      setExpertise(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="flex gap-2 justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b">
        <RiUserStarFill className="mt-1" />
        Expertise
      </h2>

      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="px-2">
          <h3 className="font-semibold text-lg">{expertise?.broad_area}</h3>
          <div className="flex mb-1 items-center text-center gap-2 ml-2 mt-2">
            <FaCog className="text-green-600" size={20} />
            <div className="text-green-600 font-semibold">
              {expertise?.specializations &&
                expertise.specializations
                  .map((spec) => spec.specialization)
                  .filter(Boolean) // Remove any empty specializations
                  .join(", ")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Expertise;
