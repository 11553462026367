import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Project",
    path: "title",
  },
  role: {
    label: "Your Role",
    path: "role",
  },
  student_name: {
    label: "Name(s) of the Student(s)",
    path: "student_name",
  },
  year: { label: "Semester/Year", path: "year" },
  department_centre: {
    label: "Student's Department/Centre",
    path: "department_centre",
  },
  students_organization: {
    label: "Student's Institution",
    path: "students_organization",
  },
  other: {
    label: "Other",
    path: "other",
  },
  start_date: { label: "Project Start Date", path: "start_date" },
  end_date: { label: "Project End Date", path: "end_date" },
};

const StudentsProjectDetail = () => {
  const [studentsProjectDetails, setStudentsProjectDetails] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/students-project-details");
      setStudentsProjectDetails(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Students&apos; Project Details
      </h2>
      <TableViewProfile
        headers={headers}
        data={studentsProjectDetails}
        type='students-project-details'
        setData={setStudentsProjectDetails}
      />
    </div>
  );
};

export default StudentsProjectDetail;
