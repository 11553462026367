import React from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import AccountAdminDashboard from "./AccountAdminDashboard";
import StaffDashboard from "./StaffDashboard";
import RNDDashboard from "./RNDDashboard";
import EstablishmentDashboard from "./EstablishmentDashboard";

export default function Dashboard() {
  const authData = useSelector((state) => state.auth);

  return (
    <>
      {[4604].includes(authData.user.id) && <RNDDashboard />}
      {[1, 559].includes(authData.user.id) && <AdminDashboard />}
      {[7].includes(authData.user.id) && <AccountAdminDashboard />}
      {[495, 496].includes(authData.user.id) && <EstablishmentDashboard />}
      {[1, 2, 3].includes(authData.user.type) && <StaffDashboard />}
    </>
  );
}
