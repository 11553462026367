import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import moment from "moment";
import {
  FaCalendarDays,
  FaMapLocationDot,
  FaUsers,
  FaUserTie,
} from "react-icons/fa6";
import { MdEvent } from "react-icons/md";
import { BsCalendar2EventFill } from "react-icons/bs";

const headers = {
  name: { label: "Name of the workshop/ training programme", path: "name" },
  description: { label: "Brief Description", path: "description" },
  organized_by: { label: "Organized by", path: "organized_by" },
  held_at: { label: "Held at", path: "held_at" },
  programme_type: { label: "Type of Programme", path: "programme_type" },
  role: { label: "Your Role", path: "role" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  const parsedDate = moment(date);
  return parsedDate.isValid() ? parsedDate.format("DD MMM YYYY") : null;
};

const WorkshopTraining = ({ view }) => {
  const [workshopTrainings, setWorkshopTrainings] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/workshop-trainings");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredData = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // const sortedData = filteredData.sort((a, b) => {
      //   const startDateA = getValueByPath(a, headers.start_date.path);
      //   const startDateB = getValueByPath(b, headers.start_date.path);
      //   const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
      //   const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
      //   return dateB - dateA; // Descending order
      // });

      setWorkshopTrainings(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(workshopTrainings.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderWorkshopTraining = (workshopTraining) => {
    const name = getValueByPath(workshopTraining, headers.name.path);
    const organizedBy = getValueByPath(
      workshopTraining,
      headers.organized_by.path
    );
    const heldAt = getValueByPath(workshopTraining, headers.held_at.path);
    const startDate = formatDate(
      getValueByPath(workshopTraining, headers.start_date.path)
    );
    const endDate = formatDate(
      getValueByPath(workshopTraining, headers.end_date.path)
    );
    const programmeType = getValueByPath(
      workshopTraining,
      headers.programme_type.path
    );
    const role = getValueByPath(workshopTraining, headers.role.path);

    return (
      <div className='items-start px-2 py-1 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          {organizedBy && (
            <span className='flex gap-1 text-sm'>
              <FaUserTie className='flex-shrink-0 mt-1 text-yellow-500' />
              Organized By: {organizedBy}
            </span>
          )}
          {role && (
            <span className='flex gap-1 text-sm'>
              <FaUsers className='flex-shrink-0 mt-1 text-yellow-500' />
              Role: {role}
            </span>
          )}
          <div className='flex items-center gap-3'>
            {heldAt && (
              <span className='flex gap-1 text-sm'>
                <FaMapLocationDot className='flex-shrink-0 mt-1 text-red-600' />
                {heldAt}
              </span>
            )}
            {startDate && endDate && (
              <span className='flex gap-1 text-sm'>
                <FaCalendarDays className='flex-shrink-0 mt-1 text-yellow-500' />
                {startDate} - {endDate}
              </span>
            )}
            {programmeType && (
              <span className='flex gap-1 text-sm'>
                <MdEvent className='flex-shrink-0 mt-1 text-yellow-500' />
                {programmeType}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 text-xl font-medium text-blue-900'>
          <BsCalendar2EventFill />
          Participation in Programme
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {workshopTrainings.length}
        </span>
      </div>
      {workshopTrainings.length ? (
        <div>
          {workshopTrainings
            .slice(0, visibleCount)
            .map((workshopTraining, index) => (
              <div key={index}>{renderWorkshopTraining(workshopTraining)}</div>
            ))}
          {workshopTrainings.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default WorkshopTraining;
