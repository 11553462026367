import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { SiHiveBlockchain } from "react-icons/si";
import moment from "moment";
import { FaBriefcase } from "react-icons/fa";
import { FaCalendarDays, FaFile, FaIndianRupeeSign } from "react-icons/fa6";

const headers = {
  name: { label: "Title", path: "name" },
  organization_name: { label: "Organization Name", path: "organization_name" },
  details: { label: "Brief Description", path: "details" },
  role: { label: "Your Role in the Consultancy", path: "role" },
  amount: { label: "Amount Earned through Consultancy", path: "amount" },
  start_date: { label: "Start Date", path: "start_date" },
  end_date: { label: "End Date", path: "end_date" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : null;
};

const ConsultancyExternalService = ({ view }) => {
  const [consultancyExternalServices, setConsultancyExternalServices] =
    useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get(
        "/profile/consultancy-external-services"
      );
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredServices = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by start_date (latest date first)
      // const sortedServices = filteredServices.sort((a, b) => {
      //   const startDateA = getValueByPath(a, headers.start_date.path);
      //   const startDateB = getValueByPath(b, headers.start_date.path);
      //   const dateA = startDateA ? moment(startDateA) : moment("1900-01-01");
      //   const dateB = startDateB ? moment(startDateB) : moment("1900-01-01");
      //   return dateB - dateA;
      // });

      setConsultancyExternalServices(filteredServices);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5);
    } else {
      setVisibleCount(consultancyExternalServices.length);
    }
    setIsExpanded(!isExpanded);
  };

  const renderConsultancyExternalService = (service) => {
    const name = getValueByPath(service, headers.name.path);
    const organizationName = getValueByPath(
      service,
      headers.organization_name.path
    );
    const details = getValueByPath(service, headers.details.path);
    const amount = getValueByPath(service, headers.amount.path);

    const startDate = formatDate(
      getValueByPath(service, headers.start_date.path)
    );
    const endDate = getValueByPath(service, headers.end_date.path)
      ? formatDate(getValueByPath(service, headers.end_date.path))
      : "Present";

    return (
      <div className='items-start px-2 py-1 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <h3 className='text-lg font-medium text-blue-900'>{name}</h3>
          {organizationName && (
            <p className='flex gap-1 text-base text-green-600'>
              <FaBriefcase className='flex-shrink-0 mt-1' />
              <span>{organizationName}</span>
            </p>
          )}
          <div className='flex-wrap lg:flex lg:gap-4'>
            {details && (
              <div className='flex gap-1 text-sm '>
                <FaFile className='flex-shrink-0 mt-1 text-yellow-500' />
                <span>{details}</span>
              </div>
            )}
            {amount > 0 && (
              <div className='flex gap-1 text-sm '>
                <FaIndianRupeeSign className='flex-shrink-0 mt-1 text-yellow-500' />
                <span>{amount}</span>
              </div>
            )}
            {startDate && (
              <div className='flex gap-1 text-sm '>
                <FaCalendarDays className='flex-shrink-0 mt-1 text-yellow-500' />
                <span>
                  {startDate} - {endDate}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-2 mb-3 border-b'>
        <h2 className='flex items-center gap-2 text-xl font-medium text-blue-900'>
          <SiHiveBlockchain />
          Consultancy/External Services
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {consultancyExternalServices.length}
        </span>
      </div>

      {consultancyExternalServices.length ? (
        <div>
          {consultancyExternalServices
            .slice(0, visibleCount)
            .map((service, index) => (
              <div key={index}>{renderConsultancyExternalService(service)}</div>
            ))}
          {consultancyExternalServices.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ConsultancyExternalService;
