import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import {
  FaCalendarDays,
  FaUser,
  FaThumbsUp,
  FaCalendarCheck,
  FaUserTie,
} from "react-icons/fa6";
import { MdManageAccounts, MdSubtitles } from "react-icons/md";
import moment from "moment";

const headers = {
  program_type: { label: "Type of the Programme", path: "program_type" },
  other_program_type: {
    label: "Other Programme",
    path: "other_program_type",
  },
  programme: { label: "Title of the Programme", path: "programme" },
  role: { label: "Role", path: "role" },
  from_date: { label: "From Date", path: "from_date" },
  to_date: { label: "To Date", path: "to_date" },
  published: {
    label: "Whether any proceeding published?",
    path: "published",
  },
  proceeding_title: {
    label: "Title of the  Proceeding",
    path: "proceeding_title",
  },
  month: { label: "Month of Publication", path: "monthName" },
  year: { label: "Year of Publication", path: "year" },
  editor_name: { label: "Editor Name with Affiliation", path: "editor_name" },
  issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

const ProgrammeOrganized = ({ view }) => {
  const [programmeOrganizeds, setProgrammeOrganizeds] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/programme-organizeds");
      const filterField = view === "iqac" ? "iqac" : "website";

      // Filter for iqac or website
      const filteredSeminars = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year, month, and day from the start date
      // const sortedSeminars = filteredSeminars.sort((a, b) => {
      //   const dateA = new Date(a.conference_start_date);
      //   const dateB = new Date(b.conference_start_date);
      //   return dateB - dateA; // Sort in descending order
      // });

      setProgrammeOrganizeds(filteredSeminars);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(programmeOrganizeds.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderConference = (publication) => {
    const programType = getValueByPath(publication, headers.program_type.path);
    const otherProgramType = getValueByPath(
      publication,
      headers.other_program_type.path
    );
    const programme = getValueByPath(publication, headers.programme.path);
    const role = getValueByPath(publication, headers.role.path);
    const fromDateValue = getValueByPath(publication, headers.from_date.path);
    const toDateValue = getValueByPath(publication, headers.to_date.path);
    const published = getValueByPath(publication, headers.published.path);
    const proceedingTitle = getValueByPath(
      publication,
      headers.proceeding_title.path
    );
    const editorName = getValueByPath(publication, headers.editor_name.path);
    const year = getValueByPath(publication, headers.year.path);
    const month = getValueByPath(publication, headers.month.path);

    // Format dates only if they are valid
    const fromDate = fromDateValue ? formatDate(fromDateValue) : null;
    const toDate = toDateValue ? formatDate(toDateValue) : null;

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          {programType && (
            <p className='flex items-center gap-1 text-sm text-green-600 '>
              <FaCalendarCheck />
              <span className='text-green-600'>
                {programType === "Other" ? otherProgramType : programType}
              </span>
            </p>
          )}
          <h3 className='text-lg font-medium text-blue-900'>{programme}</h3>
          {role && (
            <span className='flex items-center gap-1 text-sm '>
              <FaThumbsUp className='text-yellow-500' />
              Role: {role}
            </span>
          )}
          {fromDate && toDate && (
            <span className='flex items-center gap-1 text-sm '>
              <FaCalendarDays className='text-yellow-500' />
              {fromDate} - {toDate}
            </span>
          )}

          {published === "Yes" &&
            proceedingTitle &&
            editorName &&
            year &&
            month && (
              <>
                <div className='gap-3 '>
                  {proceedingTitle && (
                    <span className='flex gap-1 text-sm '>
                      <MdSubtitles className='flex-shrink-0 mt-1 text-yellow-500' />
                      {proceedingTitle}
                    </span>
                  )}
                  {editorName && (
                    <span className='flex gap-1 text-sm '>
                      <FaUser className='flex-shrink-0 mt-1 text-yellow-500' />
                      {editorName}
                    </span>
                  )}
                </div>
                <span className='flex gap-1 text-sm '>
                  <FaCalendarDays className='flex-shrink-0 mt-1 text-yellow-500' />
                  {year} - {month}
                </span>
              </>
            )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-2 px-2 py-1 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex items-center justify-start gap-2 px-2 py-1 text-xl font-medium text-blue-900'>
          <MdManageAccounts />
          Programmes Organized
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {programmeOrganizeds.length}
        </span>
      </div>
      {programmeOrganizeds.length ? (
        <div>
          {programmeOrganizeds
            .slice(0, visibleCount)
            .map((publication, index) => (
              <div key={index}>{renderConference(publication)}</div>
            ))}
          {programmeOrganizeds.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ProgrammeOrganized;
