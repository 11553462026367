import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import { FaNewspaper } from "react-icons/fa6";
import { useSelector } from "react-redux";

const headers = {
  title: {
    label: "Title of the Paper",
    path: "title",
  },
  conference_name: {
    label: "Name of the Conference/Seminar",
    path: "conference_name",
  },
  conference_type: {
    label: "Type of the Conference/Seminar",
    path: "conference_type",
  },
  organizer: { label: "Organizer", path: "organizer" },
  conference_start_date: {
    label: "Start Date",
    path: "conference_start_date",
  },
  conference_end_date: { label: "End Date", path: "conference_end_date" },
  author_names: { label: "Author Names", path: "author_names" },
  published: { label: "Published", path: "published" },
  published_url: { label: "Published URL", path: "published_url" },
  conference_proceeding_title: {
    label: "Title of the Conference Proceeding",
    path: "conference_proceeding_title",
  },
  month: { label: "Month of Publication", path: "monthName" },
  year: { label: "Year of Publication", path: "year" },
  page_no: { label: "Page No", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
  issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  url: { label: "URL/DOI", path: "url" },
  url_doi_published: {
    label: "Upload the first page of the published paper",
    path: "url_doi_published",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const ConferenceSeminar = ({ view }) => {
  const [conferenceSeminars, setConferenceSeminars] = useState([]);
  const [showAll, setShowAll] = useState(false);
  // const [profileCounts, setProfileCounts] = useState(null);
  const authData = useSelector((state) => state.auth);

  useEffect(() => {
    // getProfileCounts(authData.user.id);
    getData();
  }, [authData]);

  // const getProfileCounts = async (id) => {
  //   try {
  //     const response = await BaseApi().get(`profile-counts/${id}`); // Replace with the actual endpoint
  //     setProfileCounts(response.data); // Assuming response.data contains the count
  //   } catch (error) {
  //     console.log("Error fetching profile counts", error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/conference-seminars");
      const filterField = view === "iqac" ? "iqac" : "website";

      // Filter for iqac or website
      const filteredSeminars = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year, month, and day from the start date
      // const sortedSeminars = filteredSeminars.sort((a, b) => {
      //   // Parse the conference start date (assumes YYYY-MM-DD format)
      //   const dateA = new Date(a.conference_start_date);
      //   const dateB = new Date(b.conference_start_date);

      //   // Sort in descending order (latest first)
      //   return dateB - dateA;
      // });

      setConferenceSeminars(filteredSeminars);
    } catch (error) {
      console.error(error);
    }
  };

  const renderConference = (publication) => {
    const authorNames = getValueByPath(publication, headers.author_names.path);
    const title = getValueByPath(publication, headers.title.path);
    const conferenceName = getValueByPath(
      publication,
      headers.conference_name.path
    );
    const conferenceType = getValueByPath(
      publication,
      headers.conference_type.path
    );
    const organizer = getValueByPath(publication, headers.organizer.path);
    const conferenceDate = getValueByPath(
      publication,
      headers.conference_start_date.path
    );
    const published = getValueByPath(publication, headers.published.path);
    const publisher = getValueByPath(publication, headers.publisher_name.path);
    const pageNo = getValueByPath(publication, headers.page_no.path);
    const year = getValueByPath(publication, headers.year.path);

    return (
      <div className='pb-2 mb-2 border-b'>
        <p>
          <span className=''>{authorNames}</span>, {title}, Presented in{" "}
          <i>{conferenceName}</i> ({conferenceType}), Organized by {organizer}{" "}
          on {conferenceDate}
          {published === "yes" && (
            <>
              , and published in <i>{conferenceName}</i>, [{publisher}], pp.
              {pageNo}. ({year})
            </>
          )}
        </p>
      </div>
    );
  };

  // Limit to show only 5 items initially
  const visibleConferenceSeminars = showAll
    ? conferenceSeminars
    : conferenceSeminars.slice(0, 5);

  return (
    <div>
      <div className='flex justify-between px-2 py-2 mb-3 border-b'>
        <h2 className='flex gap-2 text-xl font-medium text-blue-900'>
          <FaNewspaper className='mt-1' />
          Conference/Seminar Paper
        </h2>
        <span className='px-3 py-2 mr-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2 dark:bg-blue-900 dark:text-blue-300'>
          {conferenceSeminars.length}
        </span>
      </div>

      {conferenceSeminars.length ? (
        <div>
          {visibleConferenceSeminars.map((publication, index) => (
            <div key={index} className='flex items-center px-2'>
              <span className='flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full'>
                {index + 1}
              </span>
              <div className='mt-3'>{renderConference(publication)}</div>
            </div>
          ))}

          {/* Show Read More / Read Less only if there are more than 5 items */}
          {conferenceSeminars.length > 5 && (
            <div className='mt-2 text-center'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default ConferenceSeminar;
