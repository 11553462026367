import React, { useEffect, useState } from "react";
import {
  FaAward,
  FaClipboardList,
  FaLink,
  FaUserGraduate,
} from "react-icons/fa6";
import { FaCalendarDay, FaRegThumbsUp, FaUniversity } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
import BaseApi from "../../../../Http/BaseApi";

const headers = {
  scholar_name: {
    label: "Name of the scholar",
    path: "scholar_name",
  },
  dissertation_title: {
    label: "Title of the Dissertation",
    path: "dissertation_title",
  },
  month: { label: "Awarded Month", path: "monthName" },
  year: { label: "Awarded Year", path: "year" },
  m_phil_achievements: {
    label: "M. Phil. Achievements",
    path: "mPhilAchievements",
    isArray: true, // Mark this field as an array
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const MPhilAwardee = ({ view }) => {
  const [mPhilAwardees, setMPhilAwardees] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/m-phil-awardees");
      const filterField = view === "iqac" ? "iqac" : "website";
      setMPhilAwardees(
        response.data.data.filter((q) => q[filterField] === "yes")
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(5); // Collapse to initial count
    } else {
      setVisibleCount(mPhilAwardees.length); // Expand to show all
    }
    setIsExpanded(!isExpanded);
  };

  const renderMPhilAwardee = (guidance) => {
    const scholarName = getValueByPath(guidance, headers.scholar_name.path);
    const dissertationTitle = getValueByPath(
      guidance,
      headers.dissertation_title.path
    );
    const month = getValueByPath(guidance, headers.month.path);
    const year = getValueByPath(guidance, headers.year.path);
    const mPhilAchievements = getValueByPath(
      guidance,
      headers.m_phil_achievements.path
    );

    return (
      <div className='items-start px-2 m-3 text-gray-600 border border-t-2 border-t-green-600'>
        <div className='p-2 space-y-1'>
          <p className='items-center gap-2 '>
            {scholarName && (
              <h3 className='text-lg font-medium text-blue-900 uppercase'>
                {scholarName}
              </h3>
            )}
            {dissertationTitle && (
              <div className='flex gap-1 text-sm'>
                <FaClipboardList className='flex-shrink-0 mt-1 text-yellow-500' />
                <span className='text-gray-800'>{dissertationTitle}</span>
              </div>
            )}
          </p>

          {month && year && (
            <p className='flex items-center gap-1 text-sm text-gray-800'>
              <FaCalendarDay className='inline w-3 mb-1 text-yellow-500' />
              {month} {year}
            </p>
          )}

          {mPhilAchievements?.filter(
            (achievement) => achievement.award_date || achievement.title
          ).length > 0 && (
            <>
              <h4 className='text-sm font-medium text-blue-500'>
                Notable Achievements
              </h4>
              {mPhilAchievements
                .filter(
                  (achievement) => achievement.award_date || achievement.title
                )
                .map((achievement, index) => (
                  <span
                    key={index}
                    className='px-3 py-1 mr-2 text-xs text-gray-800 bg-green-100 rounded-md'
                  >
                    {achievement.date && (
                      <>
                        <FaCalendarDay className='inline w-3 mb-1 text-yellow-500' />{" "}
                        {achievement.date}{" "}
                      </>
                    )}
                    {achievement.title && (
                      <>
                        <FaAward className='inline w-4 mb-1 text-yellow-500' />{" "}
                        {achievement.title}
                      </>
                    )}
                  </span>
                ))}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='flex justify-between px-2 py-1 mb-3 border-b'>
        <h2 className='flex justify-start gap-2 text-xl font-medium text-blue-900'>
          <RiGraduationCapFill className='mt-1' />
          M. Phil. Awardees
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full'>
          {mPhilAwardees.length}
        </span>
      </div>
      {mPhilAwardees.length ? (
        <div>
          {mPhilAwardees.slice(0, visibleCount).map((guidance, index) => (
            <div key={index}>{renderMPhilAwardee(guidance)}</div>
          ))}
          {mPhilAwardees.length > 5 && (
            <div className='flex justify-center mt-2'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default MPhilAwardee;
