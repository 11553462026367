import React, { useEffect, useState } from "react";
import BaseApi from "../../../../Http/BaseApi";
import TableViewProfile from "../../utils/TableViewProfile";

const headers = {
  title: {
    label: "Title of the Paper",
    path: "title",
  },
  conference_name: {
    label: "Name of the Conference/Seminar",
    path: "conference_name",
  },
  conference_type: {
    label: "Type of the Conference/Seminar",
    path: "conference_type",
  },
  organizer: { label: "Organizer", path: "organizer" },
  conference_start_date: {
    label: "Start Date",
    path: "conference_start_date",
  },
  conference_end_date: { label: "End Date", path: "conference_end_date" },
  author_names: { label: "Author Names", path: "author_names" },
  published: { label: "Published", path: "published" },
  published_url: { label: "Published URL", path: "published_url" },
  conference_proceeding_title: {
    label: "Title of the Conference Proceeding",
    path: "conference_proceeding_title",
  },
  month: { label: "Month of Publication", path: "monthName" },
  year: { label: "Year of Publish", path: "year" },
  page_no: { label: "Page No", path: "page_no" },
  editor_name: { label: "Editor Name", path: "editor_name" },
  publisher_name: { label: "Publisher Name", path: "publisher_name" },
  indexed_in: { label: "Indexed In", path: "indexed_in" },
  issn_isbn: { label: "ISSN/ISBN", path: "issn_isbn" },
  url: { label: "URL/DOI", path: "url" },
  url_doi_published: {
    label: "Upload the first page of the published paper",
    path: "url_doi_published",
  },
};

const ConferenceSeminar = () => {
  const [conferenceSeminars, setConferenceSeminars] = useState([]); // Array to store qualifications

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/conference-seminars");
      setConferenceSeminars(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <h2 className='flex justify-start px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        Conference/Seminar Paper
      </h2>
      <TableViewProfile
        headers={headers}
        data={conferenceSeminars.filter((cs) => cs.status == "Submitted")}
        type='conference-seminars'
        setData={setConferenceSeminars}
      />
    </div>
  );
};

export default ConferenceSeminar;
