import React, { useEffect, useState } from "react";
import BaseApi from "../../../../../../Http/BaseApi";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { MdInsertPageBreak } from "react-icons/md";

const headers = {
  title: {
    label: "Title of the Book",
    path: "title",
  },
  book_name: {
    label: "Book Name",
    path: "book_name",
  },
  book_type: {
    label: "Book Type",
    path: "book_type",
  },
  book_type_others: {
    label: "Others",
    path: "book_type_others",
  },
  publisher: {
    label: "Publisher",
    path: "publisher",
  },
  month: {
    label: "Month of Publication",
    path: "monthName",
  },
  year: {
    label: "Year of Publication",
    path: "year",
  },
  author_names: {
    label: "Author Names",
    path: "author_names",
  },
  editor_names: {
    label: "Editor Names",
    path: "editor_names",
  },
  page_numbers: {
    label: "Page Numbers",
    path: "page_numbers",
  },
  isbn: {
    label: "ISBN",
    path: "isbn",
  },
  editors: {
    label: "Editor(s)",
    path: "editors",
  },
};

const getValueByPath = (obj, path) =>
  path?.split(".").reduce((acc, part) => acc && acc[part], obj);

const BookChapter = ({ view }) => {
  const [bookChapters, setBookChapters] = useState([]);
  const [showAll, setShowAll] = useState(false); // State to control "Read More/Less"

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/book-chapters");
      const filterField = view === "iqac" ? "iqac" : "website";
      const filteredChapters = response.data.data.filter(
        (q) => q[filterField] === "yes"
      );

      // Sort by year and month (descending)
      // const sortedChapters = filteredChapters.sort((a, b) => {
      //   const yearDiff = parseInt(b.year) - parseInt(a.year);
      //   if (yearDiff !== 0) return yearDiff;

      //   // Assuming months are stored as full month names (e.g., "January")
      //   const monthNames = [
      //     "January",
      //     "February",
      //     "March",
      //     "April",
      //     "May",
      //     "June",
      //     "July",
      //     "August",
      //     "September",
      //     "October",
      //     "November",
      //     "December",
      //   ];
      //   return (
      //     monthNames.indexOf(b.monthName) - monthNames.indexOf(a.monthName)
      //   );
      // });

      setBookChapters(filteredChapters);
    } catch (error) {
      console.error(error);
    }
  };

  const renderBookChapter = (chapter) => {
    const authorNames = getValueByPath(chapter, headers.author_names.path);
    const year = getValueByPath(chapter, headers.year.path);
    const title = getValueByPath(chapter, headers.title.path);
    const editorNames = getValueByPath(chapter, headers.editor_names.path);
    const bookName = getValueByPath(chapter, headers.book_name.path);
    const pageNumbers = getValueByPath(chapter, headers.page_numbers.path);
    const publisher = getValueByPath(chapter, headers.publisher.path);
    const isbn = getValueByPath(chapter, headers.isbn.path);

    return (
      <div className='pb-2 mb-2 border-b'>
        <p>
          <span className='font-semibold'>{authorNames}</span> ({year}).{" "}
          <i>{title}</i>, In {editorNames} (Ed.), <i>{bookName}</i>,{" "}
          {pageNumbers}, {publisher}, ISBN: {isbn}
        </p>
      </div>
    );
  };

  // Limit to 5 chapters initially, show all if `showAll` is true
  const visibleChapters = showAll ? bookChapters : bookChapters.slice(0, 5);

  return (
    <div>
      {/* Updated header with count */}
      <div className='flex items-center justify-between px-2 py-2 mb-3 text-xl font-medium text-blue-900 border-b'>
        <h2 className='flex gap-2 '>
          <MdInsertPageBreak className='mt-1' />
          Book Chapters
        </h2>
        <span className='px-3 py-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full me-2 dark:bg-blue-900 dark:text-blue-300'>
          {bookChapters.length}
        </span>
      </div>

      {bookChapters.length ? (
        <div>
          {visibleChapters.map((chapter, index) => (
            <div key={index} className='flex items-start px-2'>
              <span className='flex items-center justify-center w-6 h-6 p-2 mt-1 mr-2 text-gray-700 bg-blue-200 rounded-full'>
                {index + 1}
              </span>
              <div className=''>{renderBookChapter(chapter)}</div>
            </div>
          ))}

          {/* Show Read More / Show Less button based on number of chapters */}
          {bookChapters.length > 5 && (
            <div className='mt-2 text-center'>
              <button
                className='text-sm text-blue-500 rounded hover:text-blue-900'
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "Read More"}
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className='py-4 text-center text-gray-500'>No data found</p>
      )}
    </div>
  );
};

export default BookChapter;
