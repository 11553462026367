import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";

const Support = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [supports, setSupports] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    month: "",
    year: "",
    from_date: "",
    to_date: "",
    awarding_agency: "",
  });
  const headers = {
    name: { label: "Name of the Award", path: "name" },
    month: { label: "Month of the Award", path: "monthName" },
    year: { label: "Year of the Award", path: "year" },
    from_date: { label: "From date", path: "from_date" },
    to_date: {
      label: "To date",
      path: "to_date",
    },
    awarding_agency: { label: "Awarding Agency", path: "awarding_agency" },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    from_date: Yup.string().required("This field is required!"),
    to_date: Yup.string().required("This field is required!"),
    awarding_agency: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/supports");
      setSupports(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        await BaseApi().put(`/profile/supports/${editId}`, values);
        toast.success(`Support Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        await BaseApi().post("/profile/supports", values);
        toast.success(`Support Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = supports.find((support) => support.id === id);
      setInitialValues({
        id: editableData.id,
        name: editableData.name,
        month: editableData.month,
        year: editableData.year,
        from_date: editableData.from_date,
        to_date: editableData.to_date,
        awarding_agency: editableData.awarding_agency,
      });

      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      name: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      awarding_agency: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      name: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      awarding_agency: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/supports/${id}`);
      toast.success(`Data Deleted`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>National/International Fellowship/Financial Support :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={supports}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default Support;
